import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { axiosPrivate } from "../../api/axios";
import { addToast } from "../../store/toastSlice";

export default function SignoutPage() {
  const dispatch = useDispatch();
  const [msg, setMsg] = useState("processing...");

  useEffect(() => {
    const logout = async () => {
      try {
        await axiosPrivate.get(`/user/signout`);
        // navigate 사용하면 (replace: true 로 설정해도)
        // 로그아웃 후 다른 아이디로 로그인 하면 기존의 정보가 그대로 남아있음!
        // await localStorage.setItem("smpAt", ""); // dispatch 에 포함되어 있음.
        await dispatch({
          type: "auth/logout",
        });
        await addToast({
          type: "success",
          text: "로그아웃 되었습니다.",
        });
        setTimeout(() => {
          window.location.href = "/";
        }, 100);
      } catch (err) {
        console.log(err);
        if (err.code === "ERR_NETWORK")
          setMsg(`${err.message}.<br />잠시 후 다시 시도해주세요.`);
        else setMsg("로그아웃에 실패했습니다.<br />잠시 후 다시 시도해주세요.");
      }
    };
    logout();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <section className="container-xl">
      <div
        className="row empty-box text-center"
        dangerouslySetInnerHTML={{ __html: msg }}
      ></div>
    </section>
  );
}
