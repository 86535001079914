import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import TopGroups from "./TopGroups";
import TopSearch from "./TopSearch";

const TopGroupLinks = ({ curPath, getParameter }) => {
  const userId = useSelector((state) => state.auth?.userId);
  const myGroups = useSelector((state) => state.auth?.myGroups);
  const startGroupNo = useSelector((state) => state.auth?.startGroupNo);

  const [selectedGroup, setSelectedGroup] = useState(null);
  const [gParameter, setGParameter] = useState("");

  useEffect(() => {
    setGParameter(getParameter("group") || "");
  }, [getParameter]);

  // const initSelGroup = useCallback(async () => {
  //   await Delay(1000); // 1초 후에 실행
  //   // [1] 파라미터로 접근하는 그룹
  //   if (gParameter) {
  //     await setSelectedGroup(gParameter);
  //   } else {
  //     // [2] 파라미터 값이 없으면 시작페이지로 설정 된 그룹
  //     if (startGroupNo) {
  //       await setSelectedGroup(startGroupNo);
  //     } else {
  //       // [3] 아무것도 없으면 첫번째 그룹
  //       const firstGroup = document.querySelector(
  //         "#ul-group > li:nth-child(1)"
  //       );
  //       if (firstGroup)
  //         await setSelectedGroup(firstGroup.getAttribute("data-no"));
  //       //else initSelGroup();
  //     }
  //   }
  // }, [gParameter, startGroupNo]);

  useEffect(() => {
    const initSelGroup = async () => {
      // [1] 파라미터로 접근하는 그룹
      if (gParameter) {
        await setSelectedGroup(gParameter);
      } else {
        // [2] 파라미터 값이 없으면 시작페이지로 설정 된 그룹
        if (startGroupNo) {
          await setSelectedGroup(startGroupNo);
        } else {
          // [3] 아무것도 없으면 첫번째 그룹
          const firstGroup = document.querySelector(
            "#ul-group > li:nth-child(1)"
          );
          if (firstGroup)
            await setSelectedGroup(firstGroup.getAttribute("data-no"));
          //else initSelGroup();
        }
      }
    };

    //if (topGroupLinksData && topGroupLinksData.length) {
    if (myGroups && myGroups.length) {
      if (curPath.toUpperCase().includes("/myBookmarks".toUpperCase())) {
        if (document.readyState === "complete") {
          initSelGroup();
        } else {
          window.addEventListener("load", initSelGroup);
          return () => window.removeEventListener("load", initSelGroup);
        }
      }
    }
  }, [curPath, gParameter, myGroups, startGroupNo]);

  return (
    <header
      className={
        userId
          ? "container-fluid sticky-top border-bottom bg-light"
          : "container-fluid sticky-top border-bottom bg-light d-none"
      }
      id="group-link"
    >
      <nav className="container-xl navbar navbar-expand-xl">
        <div className="container-fluid navbar-expand">
          <ul
            className="nav nav-pills nav-fill text-nowrap flex-nowrap col-md-7 col-lg-8 list-group list-group-horizontal overflow-auto"
            id="ul-group"
          >
            {/* <li className="nav-item" key="c1a6b8f0-1a8c-5d59-8abb-b8f28a56fdd7">
              최신북마크
            </li> */}
            <TopGroups
              selectedGroup={selectedGroup}
              setSelectedGroup={setSelectedGroup}
              curPath={curPath}
            />
          </ul>
          <TopSearch />
        </div>
      </nav>
    </header>
  );
};

export default TopGroupLinks;
