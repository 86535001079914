/**
 * [React] react-cookie를 이용해 쿠키 관리하고 로그인 인증하기
 * https://jrepository.tistory.com/m/65
 */
import { useRef, useState } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { IsValidValue, TaskCatchError } from "../../Common";
import { CommonMessage } from "../../CommonMessage";
import { axiosPrivate } from "../../api/axios";
import MsgBox from "../MsgBox";

export default function SigninForm({ setIsLoading }) {
  const [loginId, setLoginId] = useState("");
  const [loginPass, setLoginPass] = useState("");
  const [isInvalidId, setIsInvalidId] = useState(true);
  const [isPersist, setIsPersist] = useState(false);
  const [cautionMsg, setCautionMsg] = useState("");
  const [msg, setMsg] = useState("");

  const dispatch = useDispatch();
  const loginRef = useRef();
  const passwordRef = useRef();

  const handleSigninClick = async (e) => {
    e.preventDefault();
    try {
      await setIsLoading(true);
      setMsg("");
      const data = {
        loginId,
        loginPass,
        isPersist,
        provider: "local",
      };
      if (!data.loginId) {
        setMsg(CommonMessage.loginIdRequiredBasicAlert);
        setIsInvalidId(false);
        loginRef.current.classList.add("invalid-box-border");
        loginRef.current.focus();
        await setIsLoading(false);
        return;
      }
      setMsg("");
      loginRef.current.classList.remove("invalid-box-border");
      if (!data.loginPass) {
        setMsg(CommonMessage.passwordRequiredAlert);
        passwordRef.current.classList.add("invalid-box-border");
        passwordRef.current.focus();
        await setIsLoading(false);
        return;
      }
      setMsg("");
      passwordRef.current.classList.remove("invalid-box-border");
      const signin = await axiosPrivate.post("/user/signin", data);
      if (signin?.code === "ERR_NETWORK") {
        const addMsg = "잠시 후 다시 시도해주세요.";
        setMsg(`${signin?.message}\n${addMsg}` || `네트워크 오류\n${addMsg}`);
        // dispatch({
        //   type: "auth/logout",
        // });
        // return;
      }
      if (signin?.data?.ok) {
        const data = await signin?.data;
        const config = await data?.userConfig;
        await dispatch({
          type: "auth/login",
          payload: {
            accessToken: data.result,
            userId: data.userId,
            userRoles: data.userRoles,
            provider: data.provider,
            appTitle: config?.appTitle,
            startGroupNo: config?.startGroupNo,
            myGroups: data.myGroups,
            isAllowMultiDevice: config?.isAllowMultiDevice,
            // target: config?.target,
            // theme: config?.theme,
            // basicSort: config?.basicSort,
          },
        });
        //setMsg(signin.data.message || CommonMessage.loginDone);
        // localStorage 에 nb (new bookmark) 값이 있으면 /bookmarkAdd 로 이동
        if (localStorage.getItem("nb")) {
          const nb = localStorage.getItem("nb");
          window.location.replace(
            `/bookmarkAdd/?url=${nb.url}&title=${nb.title}&text=${nb.text}`
          );
          return;
        } else {
          if (IsValidValue(config?.startGroupNo)) {
            window.location.replace(
              `/myBookmarks/?group=${config?.startGroupNo}`
            );
          } else {
            window.location.replace(`/`);
          }
        }
      } else {
        setMsg(signin.data.message || "error");
        setLoginId("");
        setLoginPass("");
        // 포커스 해제
        loginRef.current.blur();
        passwordRef.current.blur();
      }
    } catch (err) {
      setMsg("로그인에 실패했습니다.\n잠시 후 다시 시도해주세요.");
      TaskCatchError(err);
    } finally {
      setIsLoading(false);
    }
  };

  // 로그인 유지 체크박스 클릭 시 이벤트
  const handlePersistClick = (e) => {
    // e.preventDefault(); 하지말것!
    if (e.target.checked) {
      setIsPersist(true);
      setCautionMsg(CommonMessage.signoutCaution);
    } else {
      setIsPersist(false);
      setCautionMsg("");
    }
  };

  return (
    <>
      <MsgBox msg={msg} />
      <Form onSubmit={handleSigninClick}>
        <Form.Group className="mb-3" controlId="loginId">
          {/* <Form.Label>로그인 아이디</Form.Label> */}
          <Form.Control
            ref={loginRef}
            type="text"
            placeholder={CommonMessage.loginIdRequiredBasicAlert}
            className={isInvalidId ? `` : `invalid-box-border`}
            autoComplete="off"
            onChange={(e) => setLoginId(e.target.value.trim())}
            value={loginId || ""}
          />
        </Form.Group>
        <Form.Group className="mb-3" controlId="password">
          {/* <Form.Label>비밀번호</Form.Label> */}
          <Form.Control
            ref={passwordRef}
            type="password"
            placeholder={CommonMessage.passwordRequiredAlert}
            onChange={(e) => setLoginPass(e.target.value.trim())}
            value={loginPass || ""}
          />
        </Form.Group>
        {/** 왼쪽에 로그인 유지 체크박스, 오른쪽에 아이디/비밀버호 찾기 링크 추가 */}
        <div className="d-flex justify-content-between">
          <div className="d-flex justify-content-start">
            <Form.Group
              controlId="rememberMe"
              style={{ paddingLeft: "0.375rem" }}
            >
              <Form.Check
                type="checkbox"
                label="로그인 유지"
                className="my-1"
                style={{ fontSize: "0.9rem" }}
                checked={isPersist || false}
                onChange={handlePersistClick}
              />
            </Form.Group>
          </div>
          <div className="d-flex justify-content-end">
            <Link to="/findId" className="text-decoration-none">
              <Button variant="link" style={{ fontSize: "0.9rem" }}>
                아이디 찾기
              </Button>
            </Link>
            <Link to="/findPass" className="text-decoration-none">
              <Button variant="link" style={{ fontSize: "0.9rem" }}>
                비밀번호 찾기
              </Button>
            </Link>
          </div>
        </div>
        <label className="fs-6 text-danger">{cautionMsg}</label>
        <Button
          variant="outline-primary"
          className="col-12 fs-5 mt-3"
          type="submit"
        >
          로그인
        </Button>
      </Form>
    </>
  );
}
