import { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { GetDateText, PassDateEasyText } from "../../Common";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import MsgBox from "../MsgBox";

export default function UsersManage({ count }) {
  const [msg, setMsg] = useState("");
  const [users, setUsers] = useState([]);
  const axiosToken = useAxiosPrivate();

  const resetStates = () => {
    setMsg("");
  };

  const getUsers = useCallback(async () => {
    await resetStates();
    let res = [];
    if (count && count > 0) res = await axiosToken.get(`/users/${count}`);
    else res = await axiosToken.get("/todayUsers");
    if (res && res.status === 200 && res.data) {
      await setUsers(res.data);
    } else {
      setMsg("목록을 가져오는데 실패했습니다.");
    }
  }, [axiosToken, count]);

  useEffect(() => {
    getUsers();
  }, [getUsers]);

  return (
    <div className="row d-flex justify-content-center">
      <Link to="/admin/usersManage/100">
        <h2 className="h2">사용자 관리</h2>
      </Link>
      <MsgBox msg={msg} />
      <div className="col-md-7 mt-md-0">
        <div className="list-group">
          {users?.map((user) => (
            <div
              className="list-group-item list-group-item-action py-3"
              key={user._id}
            >
              <div className="d-flex w-100 justify-content-between">
                <h5 className="pt-3">
                  [{user.idx}] <strong>{user.loginId || user.email}</strong>
                </h5>
              </div>
              <small>
                {GetDateText(user.createdAt, 6) +
                  " (" +
                  PassDateEasyText(user.createdAt) +
                  ")"}{" "}
                &nbsp;&nbsp;|&nbsp;&nbsp;
                {GetDateText(user.updatedAt, 6)}
                <strong>{" (" + PassDateEasyText(user.updatedAt) + ")"}</strong>
              </small>
              <hr />
              id:{" "}
              <Link to={`/admin/userBookmarkManage/${user._id}/100`}>
                {user._id}
              </Link>
              <br />
              provider: {user.provider}
              <br />
              roles: {user.roles}
              <br />
              email: {user.email}
              <br />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
