import "bootstrap/dist/css/bootstrap.min.css";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Outlet, Route, Routes, useLocation } from "react-router-dom";
import { IsValidValue, UserRoles } from "./Common";
import RequireAuth from "./RequireAuth";
import Footer from "./components/Footer";
import Toast from "./components/Toast";
import TopAdminMenus from "./components/admin/TopAdminMenus";
import GoogleAuth from "./components/socials/GoogleAuth";
import KakaoAuth from "./components/socials/KakaoAuth";
import NaverAuth from "./components/socials/NaverAuth";
import TitleAndMenus from "./components/topmenus/TitleAndMenus";
import TopGroupLinks from "./components/topmenus/TopGroupLinks";
import TopShareMenus from "./components/topmenus/TopShareMenus";
import "./css/main.css";
import useRefreshToken from "./hooks/useRefreshToken";
import useToast from "./hooks/useToast";
import AdminPage from "./pages/AdminPage/AdminMainPage";
import AllBookmarkManage from "./pages/AdminPage/AllBookmarkManagePage";
import ErrorList from "./pages/AdminPage/ErrorListPage";
import UserBookmarkManage from "./pages/AdminPage/UserBookmarkManagePage";
import UsersManagePage from "./pages/AdminPage/UsersManagePage";
import BookmarkAddPage from "./pages/BookmarkPages/BookmarkAddPage";
import BookmarkManagePage from "./pages/BookmarkPages/BookmarkManagePage";
import BookmarkPreAddPage from "./pages/BookmarkPages/BookmarkPreAddPage";
import MyBookmarksPage from "./pages/BookmarkPages/MyBookmarksPage";
import ShareCategoryPage from "./pages/BookmarkPages/ShareCategoryPage";
import ShareGroupPage from "./pages/BookmarkPages/ShareGroupPage";
import CategoryManagePage from "./pages/CategoryPages/CategoryManagePage";
import ConfigPage from "./pages/ConfigPage";
import DevHistoryPage from "./pages/DevHistoryPage";
import GroupManagePage from "./pages/GroupPages/GroupManagePage";
import BrowserSettingPage from "./pages/GuidePages/BrowserSettingPage";
import PluginGuidePage from "./pages/GuidePages/PluginGuidePage";
import PwaGuidePage from "./pages/GuidePages/PwaGuidePage";
import MainPage from "./pages/MainPage";
import NotFound from "./pages/NotFoundPage";
import SearchPage from "./pages/SearchPage";
import UnAuthorized from "./pages/UnAuthorizedPage";
import FindIdPage from "./pages/UserPages/FindIdPage";
import FindPassPage from "./pages/UserPages/FindPassPage";
import MyInfoPage from "./pages/UserPages/MyInfoPage";
import SigninPage from "./pages/UserPages/SigninPage";
import SignoutPage from "./pages/UserPages/SignoutPage";
import SignupPage from "./pages/UserPages/SignupPage";
import CodeManagePage from "./pages/code/CodeManagePage";
import EnjCompanyPage from "./pages/enj/EnjCompanyPage";
import EnjEmployeePage from "./pages/enj/EnjEmployeePage";
import EnjMoviePage from "./pages/enj/EnjMoviePage";
import EnjIndexPage from "./pages/enj/IndexPage";

/** 누구나 접근할 수 있는 레이아웃 */
const EveryoneLayout = () => {
  const location = useLocation();
  const curPath = location.pathname;
  const getParameter = (key) => {
    if (new URLSearchParams(window.location.search).get(key)) {
      return new URLSearchParams(window.location.search).get(key);
    }
    return "";
  };
  return (
    <div className="App">
      <TitleAndMenus />
      <TopGroupLinks curPath={curPath} getParameter={getParameter} />
      <Outlet />
      <Footer />
    </div>
  );
};

/** 공유 페이지 전용 레이아웃
 * 로그인 불필요
 * 독립적
 * 새로고침이나 직접 주소를 치고 들어오는 경우에도 반응하지 않는다.
 * 링크는 새창으로 거는 것을 추천한다.
 */
const ShareLayout = () => {
  const location = useLocation();
  const curPath = location.pathname;
  const getParameter = (key) => {
    if (new URLSearchParams(window.location.search).get(key)) {
      return new URLSearchParams(window.location.search).get(key);
    }
    return "";
  };

  return (
    <div className="App">
      <TopShareMenus curPath={curPath} getParameter={getParameter} />
      <Outlet />
      <Footer />
    </div>
  );
};

/** 일반회원용 레이아웃 */
const Layout = () => {
  const accessToken = localStorage.getItem("smpAt");

  const [loading, setLoading] = useState(true);
  const location = useLocation();
  const curPath = location.pathname;
  const getParameter = (key) => {
    if (new URLSearchParams(window.location.search).get(key)) {
      return new URLSearchParams(window.location.search).get(key);
    }
    return "";
  };
  const refresh = useRefreshToken();
  const RenewAccessToken = async () => {
    await setLoading(true);
    try {
      await refresh();
      // const rf = await refresh();
      // const newAccessToken = rf?.newAccessToken;
      // if (
      //   !IsValidValue(newAccessToken) ||
      //   newAccessToken === "notCreatedToken"
      // ) {
      //   window.location.href = "/signout";
      //   return;
      // }
    } catch (err) {
      console.error(err);
      window.location.href = "/signout";
      // return;
    } finally {
      await setLoading(false);
    }
  };

  useEffect(() => {
    if (IsValidValue(accessToken)) {
      RenewAccessToken();
    }
    else { // 없으면 화면이 멈춤
      window.location.href = "/signout";
      return;
    }

    /** 상단 작업이 끝날 때까지 로딩중 표시
     이 작업이 없으면 아래 부분들이 진행되기에 화면이 깜빡거림. */
    // setLoading(false);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /** 상단 작업이 끝날 때까지 로딩중 표시
     이 작업이 없으면 아래 부분들이 진행되기에 화면이 깜빡거림. */
  if (loading) return <></>;

  return (
    <div className="App">
      <TitleAndMenus curPath={curPath} />
      <TopGroupLinks curPath={curPath} getParameter={getParameter} />
      <Outlet />
      <Footer />
    </div>
  );
};

/** 관리자용 레이아웃 */
const AdminLayout = () => {
  const accessToken = localStorage.getItem("smpAt");

  const [loading, setLoading] = useState(true);
  const refresh = useRefreshToken();
  const RenewAccessToken = async () => {
    await setLoading(true);
    try {
      const rf = await refresh();
      const newAccessToken = rf?.newAccessToken;
      if (
        !IsValidValue(newAccessToken) ||
        newAccessToken === "notCreatedToken"
      ) {
        window.location.href = "/signout";
        return;
      }
    } catch (err) {
      console.error(err);
      window.location.href = "/signout";
      return;
    } finally {
      await setLoading(false);
    }
  };

  useEffect(() => {
    if (IsValidValue(accessToken)) {
      // smpAt 만료
      RenewAccessToken();
    } else {
      // smpAt가 없으면 무조건 로그아웃
      window.location.href = "/signout";
      return;
    }

    /** 상단 작업이 끝날 때까지 로딩중 표시
     이 작업이 없으면 아래 부분들이 진행되기에 화면이 깜빡거림. */
    setLoading(false);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /** 상단 작업이 끝날 때까지 로딩중 표시
     이 작업이 없으면 아래 부분들이 진행되기에 화면이 깜빡거림. */
  if (loading) return <></>;

  return (
    <div className="App">
      <TopAdminMenus />
      <Outlet />
      <Footer />
    </div>
  );
};

/** 관리자용 enj 레이아웃 */
const EnjLayout = () => {
  const accessToken = localStorage.getItem("smpAt");

  const [loading, setLoading] = useState(true);
  const refresh = useRefreshToken();
  const RenewAccessToken = async () => {
    await setLoading(true);
    try {
      const rf = await refresh();
      const newAccessToken = rf?.newAccessToken;
      if (
        !IsValidValue(newAccessToken) ||
        newAccessToken === "notCreatedToken"
      ) {
        window.location.href = "/signout";
        return;
      }
    } catch (err) {
      console.error(err);
      window.location.href = "/signout";
      return;
    } finally {
      await setLoading(false);
    }
  };

  useEffect(() => {
    if (IsValidValue(accessToken)) {
      RenewAccessToken();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  
  if (loading) return <></>;
  
  if (!IsValidValue(accessToken)) {
    window.location.href = "/signout";
    return;
  }

  return (
    <div className="App">
      <div className="container">
        <Outlet />
      </div>
    </div>
  );
};

function App() {
  const toasts = useSelector((state) => state.toast.toasts);
  const { deleteToast } = useToast();

  return (
    <>
      <Toast toasts={toasts} deleteToast={deleteToast} />
      <Routes>
        {/* 전체공개 공백페이지 */}
        {/* <Route path="tokenRenew" element={<TokenRenew />} /> */}
        <Route path="bookmarkPreAdd" element={<BookmarkPreAddPage />} />
        <Route path="/" element={<EveryoneLayout />}>
          <Route index element={<MainPage />} />
          <Route path="devHistory" element={<DevHistoryPage />} />
          <Route path="signup" element={<SignupPage />} />
          <Route path="naverAuth" element={<NaverAuth />} />
          <Route path="kakaoAuth" element={<KakaoAuth />} />
          <Route path="googleAuth" element={<GoogleAuth />} />
          <Route path="signin" element={<SigninPage />} />
          <Route path="signout" element={<SignoutPage />} />
          <Route path="findId" element={<FindIdPage />} />
          <Route path="findPass" element={<FindPassPage />} />
          <Route path="browserSetting" element={<BrowserSettingPage />} />
          <Route path="pluginGuide" element={<PluginGuidePage />} />
          <Route path="pwaGuide" element={<PwaGuidePage />} />
        </Route>
        {/* 공유페이지 */}
        <Route path="/" element={<ShareLayout />}>
          <Route
            path="openGroup/:groupId/bookmarks"
            element={<ShareGroupPage />}
          />
          <Route
            path="openCategory/:categoryId/bookmarks"
            element={<ShareCategoryPage />}
          />
          <Route path="/unAuthorized" element={<UnAuthorized />} />
          <Route path="/*" element={<NotFound />} />
        </Route>
        {/* 일반회원 */}
        <Route path="/" element={<Layout />}>
          <Route
            element={<RequireAuth allowedRoles={[UserRoles.JoinedUser]} />}
          >
            <Route path="config" element={<ConfigPage />} />
            <Route path="groupManage" element={<GroupManagePage />} />
            <Route path="categoryManage" element={<CategoryManagePage />} />
            <Route path="bookmarkManage" element={<BookmarkManagePage />} />
            <Route path="bookmarkAdd" element={<BookmarkAddPage />} />
            <Route path="myinfo" element={<MyInfoPage />} />
            <Route path="myBookmarks" element={<MyBookmarksPage />} />
            <Route path="search/:keyword" element={<SearchPage />} />
          </Route>
        </Route>
        {/* 관리자1 */}
        <Route path="/" element={<AdminLayout />}>
          <Route
            element={<RequireAuth allowedRoles={[UserRoles.SuperAdmin]} />}
          >
            <Route path="admin" element={<AdminPage />} />
            <Route
              path="admin/usersManage/:count"
              element={<UsersManagePage />}
            />
            <Route path="admin/errorList/:count" element={<ErrorList />} />
            <Route
              path="admin/allBookmarkManage/:count"
              element={<AllBookmarkManage />}
            />
            <Route
              path="admin/userBookmarkManage/:userId/:count"
              element={<UserBookmarkManage />}
            />
          </Route>
        </Route>
        {/* 관리자2 enj */}
        <Route path="/enj" element={<EnjLayout />}>
          <Route
            element={<RequireAuth allowedRoles={[UserRoles.SuperAdmin]} />}
          >
            <Route index element={<EnjIndexPage />} />
            <Route path="company/:_id" element={<EnjCompanyPage />} />
            <Route path="employee/:_id" element={<EnjEmployeePage />} />
            <Route path="movie" element={<EnjMoviePage />} />
            <Route path="code" element={<CodeManagePage />} />
          </Route>
        </Route>
      </Routes>
    </>
  );
}

export default App;
