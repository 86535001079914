import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

import authReducer from "./authSlice";
import enjCodeReducer from "./enjCodeSlice";
import enjDomainCodeReducer from "./enjDomainCodeSlice";
import enjReducer from "./enjSlice";
import toastReducer from "./toastSlice";

// export const store = configureStore({
//   reducer: {
//     toast: toastReducer,
//     auth: authReducer,
//     enjCode: enjCodeReducer,
//     enjDomainCode: enjDomainCodeReducer,
//     enj: enjReducer,
//   },
// });

const reducers = combineReducers({
  toast: toastReducer,
  auth: authReducer,
  enjCode: enjCodeReducer,
  enjDomainCode: enjDomainCodeReducer,
  enj: enjReducer,
});

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["auth", "enjCode", "enjDomainCode", "enj", "toast"],
};

const persistedReducer = persistReducer(persistConfig, reducers);

const store = configureStore({
  reducer: persistedReducer,
  middleware: getDefaultMiddleware({
    serializableCheck: false,
  }),
});

export default store;
