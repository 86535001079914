import { useLocation, useNavigate } from "react-router-dom";

export default function GroupSel({
  groupData,
  groupNo,
  setGroupNo,
  setCategoryData,
  setCategoryNo,
  setBookmarkData,
}) {
  const navigate = useNavigate();
  const location = useLocation();

  const handleSelect = (e) => {
    if (e.target && e.target.closest("li").dataset["value"]) {
      const groupNo = e.target.closest("li").dataset["value"];
      setGroupNo(groupNo);
      setCategoryData(null);
      setCategoryNo(null);
      if (setBookmarkData) setBookmarkData(null);
      const currentURL = location.pathname;
      navigate(`${currentURL}?group=${groupNo}`, { replace: false });
    } else {
      navigate(`/groupManage`, { replace: false });
    }
  };

  const handleOpenGroupClick = (e) => {
    e.preventDefault();
    const selectedGroupId = e.target.getAttribute("data-Id");
    window.open(`/openGroup/${selectedGroupId}/bookmarks`);
  };

  return (
    <div style={{ maxHeight: "calc(100vh - 340px)", overflowY: "auto" }}>
      <ul className="list-group" id="ul-list-group">
        {groupData?.map((item) => {
          const groupId = item._id,
            gNo = item.groupNo,
            groupName = item.groupName,
            isImportantClass = item.isImportant ? "txt-important" : "",
            isLineThroughClass = item.isLineThrough ? "txt-line-through" : "";
          return (
            <li
              key={groupId}
              data-value={gNo}
              className={`list-group-item text-truncate cursor-pointer 
                ${Number(gNo) === Number(groupNo) ? "bg-selected-row" : ""}`}
              onClick={handleSelect}
              title={item.groupName}
            >
              {item.isPublic ? (
                <img
                  src="/img/share.png"
                  width={22}
                  className="cursor-pointer me-2"
                  alt={groupName}
                  data-id={groupId}
                  data-isshare="true"
                  onClick={handleOpenGroupClick}
                />
              ) : (
                ""
              )}
              <span className={`${isImportantClass} ${isLineThroughClass}`}>
                {groupName}
              </span>
            </li>
          );
        })}
        <li
          data-value=""
          className="list-group-item text-truncate cursor-pointer"
          onClick={handleSelect}
          title="그룹 관리"
        >
          :: 그룹 관리 ::
        </li>
      </ul>
    </div>
  );
}
