import { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { GetDateText, PassDateEasyText } from "../../Common";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import MsgBox from "../MsgBox";

export default function ErrorList({ count }) {
  const axiosToken = useAxiosPrivate();
  const [msg, setMsg] = useState("");
  const [errors, setErrors] = useState([]);

  const resetStates = () => {
    setMsg("");
  };

  const getErrors = useCallback(async () => {
    await resetStates();
    let errors = [];
    if (count) errors = await axiosToken.get(`/errors/${count}`);
    else errors = await axiosToken.get(`/todayErrors`);
    if (errors && errors.status === 200 && errors.data) {
      await setErrors(errors.data);
    } else {
      setMsg("목록을 가져오는데 실패했습니다.");
    }
  }, [axiosToken, count]);

  const viewStack = (e) => {
    e.target
      .closest("div")
      .nextSibling.nextSibling.nextSibling.nextSibling.classList.toggle("d-none");
  };

  useEffect(() => {
    getErrors();
  }, [getErrors]);

  return (
    <div className="row d-flex justify-content-center">
      <h2 className="h2">
        <Link to="/admin/errorList/100">에러</Link>
      </h2>
      <MsgBox msg={msg} />
      <div className="col-md-7 mt-md-0">
        <div className="list-group">
          {errors?.map((error) => (
            <div
              className="list-group-item list-group-item-action pb-5"
              key={error._id}
            >
              <div className="d-flex w-100 justify-content-between">
                <span className="pt-3" onClick={viewStack}>
                  [{error.idx}] <span className="fw-bold">{error.message}</span>
                </span>
              </div>
              <small>
                <Link
                  to={`/admin/userBookmarkManage/${
                    error.userId || error.user
                  }/100`}
                >
                  {error.userId || error.user}
                </Link>
              </small>
              <br />
              <small>
                {error.createdAt ? GetDateText(error.createdAt, 6) : ""}
                <strong>
                  {error.createdAt
                    ? " (" + PassDateEasyText(error.createdAt) + ")"
                    : ""}
                </strong>
              </small>
              <div className="d-none">
                <hr />
                {error.stack}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
