import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import validator from "validator";
import { IsValidValue } from "../../Common";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import useToast from "../../hooks/useToast";
import {
  addMovies,
  editMovies,
  initModalShow,
  initMovie,
  resetMovie,
} from "../../store/enjSlice";
import LoadingPopSpinner from "../LoadingPopSpinner";

const MovieLinkFormModal = ({ handleDeleteMovieClick }) => {
  const { addToast } = useToast();
  const dispatch = useDispatch();
  const apiServer = process.env.REACT_APP_API_DOAMIN;

  const movie = useSelector((state) => state.enj.movie);
  const ydTypeCodes = useSelector((state) => state.enjCode?.ydTypeCodes);
  const ydTypeCode = useSelector((state) => state.enjCode?.ydTypeCode);
  const countryCodes = useSelector((state) => state.enjCode.countryCodes);
  const domainCodes = useSelector((state) => state.enjCode.domainCodes);
  const countryCode = useSelector((state) => state.enjCode.countryCode);
  const domainCode = useSelector((state) => state.enjCode.domainCode);
  /** Modal States */
  const modalShow = useSelector((state) => state.enj.modalShow);
  const [type, setType] = useState("");
  const [country, setCountry] = useState("");
  const [domain, setDomain] = useState("");
  const [domainUri, setDomainUri] = useState("");
  const [uri, setUri] = useState("");
  const [title, setTitle] = useState("");
  const [memo, setMemo] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  /** Error States */
  const [typeError, setTypeError] = useState("");
  const [countryError, setCountryError] = useState("");
  const [siteDomainError, setSiteDomainError] = useState("");
  const [siteUriError, setSiteUriError] = useState("");
  const [siteNameError, setSiteNameError] = useState("");

  const axiosToken = useAxiosPrivate();

  const resetModalForm = () => {
    setUri("");
    setTitle("");
    setMemo("");
    setTypeError("");
    setCountryError("");
    setSiteDomainError("");
    setSiteUriError("");
    setSiteNameError("");

    dispatch(resetMovie());
    dispatch(initModalShow(false));
  };

  /** typCode, countryCode, domainCode가 변경되면
   * type, country, domain, domainUri 변경 */
  useEffect(() => {
    const initFormValues = () => {
      setType(ydTypeCode || "");
      setCountry(countryCode || "");
      setDomain(domainCode || "");
    };
    initFormValues();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ydTypeCode, countryCode, domainCode, modalShow]);

  /** domain이 변경되면 domainUri가 변경 */
  useEffect(() => {
    const initFormValues = () => {
      setDomainUri(domainCodes.find((d) => d.code === domain)?.memo || "");
    };
    initFormValues();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [domain]);

  /** movie가 변경되면 form values도 변수값도 변경된다. */
  useEffect(() => {
    const initFormValues = () => {
      if (!modalShow) return;
      if (movie) {
        setType(movie?.type?.code);
        setCountry(movie?.country?.code);
        setDomain(movie?.domain?.code);
        setUri(movie?.uri);
        setTitle(movie?.title);
        setMemo(movie?.memo);
      }
    };
    initFormValues();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [movie, modalShow]);

  const fillUriTitle = async (uri) => {
    const titleInput = document.querySelector("#title");
    if (titleInput.value?.trim()) return;
    try {
      // setIsLoading(true);
      const res = await axiosToken.get(`${apiServer}/bookmark/getTitle/${encodeURIComponent(uri)}`);
      if (res?.data) {
        titleInput.value = res.data?.trim();
        setTitle(res.data?.trim());
        setSiteNameError("");
      }
    } catch (err) {
      console.error(err);
    }finally{
      setIsLoading(false);
    }
  };

  /** validate */
  const validate = () => {
    let result = true;
    // ydTypeCode: 타입
    if (!IsValidValue(type)) {
      setTypeError("타입 선택이 올바르지 않습니다.");
      result = false;
    }
    // countryCode: 국가
    if (!IsValidValue(country)) {
      setCountryError("국가 선택이 올바르지 않습니다.");
      result = false;
    }
    // dom: 도메인
    if (!IsValidValue(domain)) {
      setSiteDomainError("도메인 선택이 올바르지 않습니다.");
      result = false;
    }
    // uri: 주소
    if (!IsValidValue(uri)) {
      setSiteUriError("주소가 올바르지 않습니다.");
      result = false;
    }
    // domainUri + uri 가 유효한 도메인인지 체크
    if (!validator.isURL(domainUri + uri)) {
      setSiteUriError(
        "주소가 올바르지 않습니다. 도메인과 주소를 확인해주세요."
      );
      result = false;
    }
    // title: 주소명
    if (!IsValidValue(title)) {
      setSiteNameError("주소명이 올바르지 않습니다.");
      result = false;
    }
    return result;
  };

  /** handleSubmit */
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validate()) return;
    let isEdit = false;
    const data = {
      type,
      country,
      domain,
      uri,
      title,
      memo,
    };
    // movie._id가 있으면 수정, 없으면 신규등록
    if (IsValidValue(movie?._id)) {
      isEdit = true;
      data._id = movie._id;
    }
    const apiUrl = IsValidValue(data._id) ? "/enjMovie/edit" : "/enjMovie/add";
    try {
      setIsLoading(true);
      const res = isEdit
        ? await axiosToken.put(apiUrl, data)
        : await axiosToken.post(apiUrl, data);
      if (res?.data?.ok) {
        if (isEdit) {
          dispatch(editMovies(res.data.result));
          addToast({
            type: "success",
            text: res.data.message || "movie가 수정되었습니다.",
          });
        } else {
          dispatch(addMovies(res.data.result));
          addToast({
            type: "success",
            text: res.data.message || "새 movie가 생성되었습니다.",
          });
        }
        resetModalForm();
      } else {
        if (isEdit) {
          addToast({
            type: "danger",
            text: res.data.message || "movie가 수정되지 않았습니다.",
          });
        } else {
          addToast({
            type: "danger",
            text: res.data.message || "movie가 생성되지 않았습니다.",
          });
        }
      }
    } catch (err) {
      console.error(err);
      addToast({
        type: "danger",
        text: err.message || "movie 생성/수정 중 오류가 발생하였습니다.",
      });
    } finally{
      setIsLoading(false);
    }
  };

  return (
    <>
      {/** 모달 보기 버튼 */}
      <button
        className="btn btn-outline-primary w-100"
        onClick={() => {
          dispatch(initMovie(null));
          dispatch(initModalShow(true));
        }}
      >
        영상 링크 등록
      </button>

      {/** 웹사이트 링크 등록폼
       * 타입, 국가, 도메인이 선택되지 않으면 저장, 추가 버튼 비활성화
       * 모든 폼을 Bootstrap 반응형으로 구성
       * 폼 간에 적당한 간격을 두고 구성
       */}
      <Modal
        animation={false}
        centered
        show={modalShow}
        onHide={resetModalForm}
      >
        <Modal.Header closeButton>
          <Modal.Title>영상 링크 등록</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/** [주의!!] <form></form> 을 Modal.Body 이외의 위치에 두면 에러 발생! */}
          <form>
            <div className="container">
              <div className="row">
                {/**
                 * 1. 타입 선택(typeCodes) */}
                <div className="col-12 mb-2">
                  <div>
                    <select
                      className={`form-select ${
                        typeError ? "border border-danger" : ""
                      }`}
                      value={type || ""}
                      onChange={(e) => {
                        setType(e.target.value);
                        if (IsValidValue(e.target.value)) setTypeError("");
                      }}
                    >
                      <option value="">타입 선택</option>
                      {ydTypeCodes?.map((type, index) => {
                        return (
                          <option key={index} value={type.code}>
                            {type.codeName}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  <div className={`text-danger ${typeError ? "" : "d-none"}`}>
                    * {typeError}
                  </div>
                </div>
                {/**
                 * 2. 국가 선택(countryCodes) */}
                <div className="col-12 mb-2">
                  <div>
                    <select
                      className={`form-select ${
                        countryError ? "border border-danger" : ""
                      }`}
                      value={country || ""}
                      onChange={(e) => {
                        setCountry(e.target.value);
                        if (IsValidValue(e.target.value)) setCountryError("");
                      }}
                    >
                      <option value="">국가 선택</option>
                      {countryCodes?.map((country, index) => {
                        return (
                          <option key={index} value={country.code}>
                            {country.codeName}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  <div
                    className={`text-danger ${countryError ? "" : "d-none"}`}
                  >
                    * {countryError}
                  </div>
                </div>
                {/**
                 * 3. 도메인 선택(siteDomains) */}
                <div className="col-12 mb-2">
                  <div className="mb-2">
                    <select
                      className={`form-select ${
                        siteDomainError ? "border border-danger" : ""
                      }`}
                      value={domain || ""}
                      onChange={(e) => {
                        setDomain(e.target.value);
                        if (IsValidValue(e.target.value))
                          setSiteDomainError("");
                      }}
                    >
                      <option value="">도메인 선택</option>
                      {domainCodes?.map((domain, index) => {
                        return (
                          <option key={index} value={domain.code}>
                            {domain.codeName}
                          </option>
                        );
                      })}
                    </select>
                    <div
                      className={`text-danger ${
                        siteDomainError ? "" : "d-none"
                      }`}
                    >
                      * {siteDomainError}
                    </div>
                  </div>
                  {/**
                   * 4. 도메인 제외한 주소 */}
                  <div className="mb-2">
                    <input
                      type="text"
                      className={`form-control me-1 ${
                        typeError ? "border border-danger" : ""
                      }`}
                      placeholder="도메인을 제외한 주소."
                      value={uri || ""}
                      onChange={(e) => {
                        setUri(e.target.value);
                        setSiteUriError("");
                      }}
                      onBlur={() => {
                        if (validator.isURL(domainUri + uri)) {
                          setSiteDomainError("");
                          setSiteUriError("");
                          fillUriTitle(domainUri + uri);
                        } else {
                          setSiteUriError(
                            "주소가 올바르지 않습니다. 도메인과 주소를 확인해주세요."
                          );
                        }
                      }}
                    />
                    <div
                      className={`text-danger ${siteUriError ? "" : "d-none"}`}
                    >
                      * {siteUriError}
                    </div>
                  </div>
                  {/**
                   * 5. 링크명 */}
                  <div className="mb-2">
                    <input
                      type="text"
                      className={`form-control me-1 ${
                        typeError ? "border border-danger" : ""
                      }`}
                      id="title"
                      placeholder="주소명을 입력하세요."
                      value={title || ""}
                      onChange={(e) => {
                        setTitle(e.target.value);
                        if (IsValidValue(e.target.value)) setSiteNameError("");
                      }}
                      onBlur={(e) => {
                        setTitle(e.target.value);
                        if (IsValidValue(e.target.value)) setSiteNameError("");
                      }}
                    />
                    <div
                      className={`text-danger ${siteNameError ? "" : "d-none"}`}
                    >
                      * {siteNameError}
                    </div>
                  </div>
                  <div className="mb-2">
                    <a
                      href={domainUri + uri}
                      target="_blank"
                      rel="noreferrer noopener"
                    >
                      {title}
                    </a>
                  </div>
                  {/** memo */}
                  <div className="mb-2">
                    <textarea
                      className="form-control"
                      rows={2}
                      placeholder="메모를 입력하세요."
                      value={memo || ""}
                      onChange={(e) => {
                        setMemo(e.target.value);
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <button
              type="submit"
              className="btn btn-outline-primary w-100 mb-2"
              onClick={handleSubmit}
            >
              {movie ? "링크 수정" : "신규 등록"}
            </button>
            <button
              className={`btn btn-outline-danger w-100
              ${movie ? "" : "d-none"}
              `}
              onClick={(e) => handleDeleteMovieClick(e, movie._id)}
            >
              삭제
            </button>
          </form>
        </Modal.Body>

        <Modal.Footer></Modal.Footer>
      </Modal>
      <LoadingPopSpinner isLoading={isLoading} />
    </>
  );
};

export default MovieLinkFormModal;
