import { useCallback, useEffect, useState } from "react";
import Masonry from "react-masonry-css";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Delay, JsonLocalStorage, TaskCatchError } from "../../Common";
import LoadingPop from "../../components/LoadingPop";
import BookmarkModal from "../../components/bookmarks/BookmarkModal";
import MyBookmarksByCategory from "../../components/bookmarks/MyBookmarksByCategory";
import MyBookmarksCategoryTitle from "../../components/bookmarks/MyBookmarksCategoryTitle";
import CategoryModal from "../../components/categories/CategoryModal";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";

export default function MyBookmarksPage() {
  const axiosToken = useAxiosPrivate();
  const queryParams = new URLSearchParams(window.location.search);
  const group = queryParams.get("group");
  const myGroups = useSelector((state) => state.auth?.myGroups);

  // const ref = queryParams.get("ref");
  const [selGroupNo, setSelGoupNo] = useState(() => {
    return group;
  });
  const [groupTitle, setGroupTitle] = useState("");
  const [selCategoryId, setSelCategoryId] = useState(null);
  const [selCategoryNo, setSelCategoryNo] = useState(null);
  const [selBookmarkId, setSelBookmarkId] = useState(null);
  const [prevSelBookmarkId, setPrevSelBookmarkId] = useState(null);
  /** groupsElList: 선택그룹 및 클릭이벤트 등록을 위함 */
  const [groupsElList, setGroupElList] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [bookmarkList, setBookmarkList] = useState([]);
  const [categoryShow, setCategoryShow] = useState(false);
  const [bookmarkShow, setBookmarkShow] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [aTarget, setATarget] = useState("_blank");
  const [isCategoriesReady, setIsCategoriesReady] = useState(false);
  const [errMsg, setErrMsg] = useState(null);
  const [isCategoryDelete, setIsCategoryDelete] = useState(false);

  const getTarget = useCallback(async () => {
    const myConfig = await axiosToken.get("/myConfig");
    if (myConfig?.status === 200 && myConfig?.data) {
      const { target } = myConfig.data;
      setATarget(target || "_blank");
    }
  }, [axiosToken]);

  useEffect(() => {
    getTarget();
  }, [getTarget]);

  // 그룹 엘리먼트(groupsElList) 할당
  useEffect(() => {
    setGroupTitle('<span className="fst-italic">Loading...</span>');
    // setCategoryList([]);
    // setSelCategoryId(null);
    // setSelCategoryNo(null);
    // setSelBookmarkId(null);
    let startCount = 0;
    const initGroup = setInterval(
      () => {
        const groupEl = document.querySelectorAll(
          "#ul-group > li.nav-item.short-title"
        );
        // groupEl 노드가 완전히 로딩되었는지 체크?

        const activeGroup = document.querySelector(
          "#ul-group > li.nav-item.short-title.active"
        );
        // 무한루프 방지를 위해 5번(5초) 까지만 반복한다.
        if (
          (groupEl?.length === myGroups?.length && activeGroup) ||
          startCount > 5
        ) {
          clearInterval(initGroup); // 그룹링크가 모두 로딩되면 중지한다.
          setGroupElList(groupEl);
          if (activeGroup) {
            // setSelGoupNo(activeGroup.getAttribute("data-no"));
            setGroupTitle(activeGroup.innerText);
            // .active 그룹으로 스크롤한다.
            Delay(1000).then(() => {
              // 약간의 시간차를 두는 것이 좋음.
              activeGroup.scrollIntoView({
                behavior: "smooth",
                block: "center",
                inline: "center",
              });
            });
          }
        }
        startCount++;
      },
      //ref === "signin" ? 2000 : 500
      1000
    );
  }, [myGroups, group]);

  // 그룹번호(selGroupNo) 할당 및 그룹링크 클릭이벤트 등록
  useEffect(() => {
    if (!groupsElList) return;
    groupsElList.forEach((el) => {
      // 상단 그룹리스트 UI에 선택 된 엘리먼트를 선택 된 그룹번호로 할당
      if (el.classList.contains("active")) {
        if (el.getAttribute("data-no"))
          setSelGoupNo(el.getAttribute("data-no"));
      }
      // 이벤트 등록: 클릭 시 선택된 엘리먼트를 선택 된 그룹번호로 할당
      el.addEventListener("click", (e) => {
        if (e.target.getAttribute("data-no"))
          setSelGoupNo(e.target.getAttribute("data-no"));
      });
    });
    JsonLocalStorage.removeItem("selCategoryNo");
  }, [groupsElList]);

  const initBookmarkDataList = useCallback(async () => {
    if (!selGroupNo || !parseInt(selGroupNo)) {
      return;
    }
    try {
      await setErrMsg(null);
      await setIsLoading(true);
      // await setCategoryList([]);
      // await setBookmarkList([]);
      await setIsCategoriesReady(false);

      const categoryList = await axiosToken.get(
        `/myGroup/${selGroupNo}/categories`
      );
      if (categoryList?.data?.length) {
        if (categoryList.data === "error") return;
        await setCategoryList(categoryList.data);
        await setIsCategoriesReady(true);
      }
      const bookmarks = await axiosToken.get(
        `/myGroup/${selGroupNo}/bookmarks`
      );
      if (bookmarks?.data?.length) {
        if (bookmarks?.data === "error") return;
        await setBookmarkList(bookmarks.data);
        Delay(1000).then(() => {
          window.scrollTo({
            top: 0,
            behavior: "auto",
          });
        });
      }
    } catch (err) {
      setErrMsg(
        "Error! : 하단의 연락하기 링크를 클릭해서 관리자에게 문의하세요."
      );
      TaskCatchError(err);
    } finally {
      setIsLoading(false);
    }
  }, [selGroupNo, axiosToken]);

  useEffect(() => {
    if (selGroupNo) initBookmarkDataList();
  }, [selGroupNo, initBookmarkDataList]);

  const showCategoryModal = async (e) => {
    e.preventDefault();
    // await setSelCategoryId(null);
    await setSelCategoryId(e.target.closest("span").dataset["categoryid"]);
    await setCategoryLocalStorage(
      e.target.closest("span").dataset["categoryno"]
    );
    await setCategoryShow(true); // 카테고리 타이틀에서 부모창을 상단으로 이동시킨다.
  };

  const showBookmarkModal = async (e) => {
    e.preventDefault();
    try {
      // 선택 된 그룹이 없으면 상단 그룹 엘리먼트에서 선택 된 그룹을 찾아서 그룹번호를 할당한다.
      //if (!selGroupNo) {
      const activeGroupNo = document
        .querySelector("#ul-group > li.nav-item.short-title.active > a")
        ?.getAttribute("data-no");
      if (activeGroupNo) {
        await setSelGoupNo(activeGroupNo);
      }
      //return;
      //}
      // await setSelCategoryNo(null);
      // await setSelBookmarkId(null);
      let target;
      if (e.target.closest("span")) {
        // == [MyBookmarksCategoryTitle] ==
        target = e.target.closest("span");
        await setSelCategoryNo(target.dataset["categoryno"]);
        await setCategoryLocalStorage(target.dataset["categoryno"]);
        await setSelBookmarkId("newBookmark");
      } else if (e.target.closest("p")) {
        // == [MyBookmarksByCategory] ==
        //await setSelGoupNo(queryParams.get("group"));
        e.target.closest("p").classList.remove("mark-moccasin");
        e.target.closest("p").classList.add("mark-green");
        target = e.target.closest("p");
        await setSelCategoryNo(target.dataset["categoryno"]);
        await setCategoryLocalStorage(target.dataset["categoryno"]);
        await setSelBookmarkId(target.dataset["bookmarkid"]);
      }
      // 모달이 뜨기 전에 시간차를 두지 않으면
      // 이전에 선택했던 카테고리(selCategoryNo) 상태로 모달이 뜬다!! - 해결된듯...
      // await Delay(2000); // 임시 개발용!
      await setBookmarkShow(true);
    } catch (err) {
      TaskCatchError(err);
    }
  };

  const handleCategoryDelete = async () => {
    if (!selCategoryId || selCategoryId === "newCategory") {
      alert("삭제할 카테고리를 선택하세요.");
      return;
    }
    const msg =
      "삭제 하시겠습니까?\n해당 카테고리에 속하는 북마크도 함께 삭제됩니다.";
    if (!window.confirm(msg)) return;
    try {
      const url = `/category/delete`;
      const data = {
        _id: selCategoryId,
      };
      const res = await axiosToken.delete(url, { data });
      if (res && res.data === "ok") {
        Delay(200).then(() => {
          // const delitem = document.querySelector(
          //   `span[data-categoryid="${selCategoryId}"]`
          // );
          // if (delitem) delitem.remove();
          // 해당 카테고리의 부모 div 노드 삭제
          const delitem2 = document.querySelector(
            `span[data-categoryid="${selCategoryId}"]`
          );
          if (delitem2) delitem2.closest("div").remove();
        });
        Delay(500).then(() => {
          setCategoryShow(false);
          setSelBookmarkId(null);
          setSelCategoryId(null);
          setIsCategoryDelete(true);
          if (localStorage.getItem("selCategoryNo"))
            JsonLocalStorage.removeItem("selCategoryNo");
        });
      } else {
        alert("삭제되지 않았습니다.");
      }
    } catch (err) {
      TaskCatchError(err);
    }
  };

  const handleBookmarkDelete = async (e) => {
    e.preventDefault();
    if (!selBookmarkId) {
      alert("삭제할 북마크 아이디가 올바르지 않습니다.");
      return;
    }
    if (!window.confirm("삭제 하시겠습니까?")) return;
    try {
      //await setIsLoading(true);
      const url = `/bookmark/delete`;
      const data = {
        _id: selBookmarkId,
      };
      const res = await axiosToken.delete(url, { data }); // {data}: delete는 중괄호로 감싸야 함!
      if (res?.data === "ok") {
        Delay(100).then(() => {
          // 해당 북마크 엘리먼트 삭제
          const delitem = document.querySelector(
            `#root > div > section > div.my-masonry-grid > div p[data-bookmarkid="${selBookmarkId}"]`
          );
          if (delitem) delitem.remove();
        });
        Delay(200).then(() => {
          setBookmarkShow(false);
          setSelBookmarkId(null);
        });
      } else {
        alert("삭제되지 않았습니다.");
      }
    } catch (err) {
      TaskCatchError(err);
    } finally {
      //await setIsLoading(false);
    }
  };

  const setCategoryLocalStorage = (categoryNo) => {
    JsonLocalStorage.setItem("selCategoryNo", categoryNo);
  };

  const myBreakpointsAndCols = {
    default: 4,
    1100: 3,
    700: 2,
    500: 1,
  };

  return (
    <section className="container-xl py-2">
      {/* span 으로 감싼 groupTitle 을 중앙에 정렬
      groupTitle을 html로 받아서 dangerouslySetInnerHTML로 처리하면
      html 태그가 적용되어 표시된다.
      */}
      <span
        className="faj-center fs-4 pb-2 text-muted"
        dangerouslySetInnerHTML={{ __html: groupTitle }}
      ></span>
      <Masonry
        breakpointCols={myBreakpointsAndCols}
        className="my-masonry-grid"
        columnClassName="my-masonry-grid_column"
      >
        {isLoading ? (
          <article className="empty-box text-muted text-secondary fst-italic">
            Loading...
          </article>
        ) : isCategoriesReady ? (
          categoryList?.map((item, idx) => {
            return (
              <div
                key={item._id}
                data-categoryid={item._id}
                data-categoryno={item.categoryNo}
              >
                <MyBookmarksCategoryTitle
                  key={idx}
                  item={item}
                  showCategoryModal={showCategoryModal}
                  showBookmarkModal={showBookmarkModal}
                  setCategoryLocalStorage={setCategoryLocalStorage}
                />
                <MyBookmarksByCategory
                  key={item._id + idx}
                  categoryNo={item.categoryNo}
                  bookmarkList={bookmarkList?.filter(
                    (bookmark) => bookmark.categoryNo === item.categoryNo
                  )}
                  showBookmarkModal={showBookmarkModal}
                  aTarget={aTarget}
                  prevSelBookmarkId={prevSelBookmarkId}
                  setCategoryLocalStorage={setCategoryLocalStorage}
                />
              </div>
            );
          })
        ) : errMsg ? (
          <article className="empty-box text-danger">{errMsg}</article>
        ) : (
          <article className="empty-box text-center">
            <p>
              No Items
              <br />
              <Link to={`/categoryManage/?group=${selGroupNo}}`}>
                카테고리 추가하기
              </Link>
            </p>
          </article>
        )}
      </Masonry>

      <CategoryModal
        selgroup={selGroupNo}
        selcategoryid={selCategoryId}
        setselcategoryid={setSelCategoryId}
        setIsLoading={setIsLoading}
        show={categoryShow}
        setshow={setCategoryShow}
        initBookmarkDataList={initBookmarkDataList}
        handledelete={handleCategoryDelete}
        isCategoryDelete={isCategoryDelete}
        setIsCategoryDelete={setIsCategoryDelete}
      />

      <BookmarkModal
        groupNo={selGroupNo}
        categoryNo={selCategoryNo}
        bookmarkId={selBookmarkId}
        categoryData={categoryList}
        show={bookmarkShow}
        setShow={setBookmarkShow}
        initBookmarkDataList={initBookmarkDataList}
        handleBookmarkDelete={handleBookmarkDelete}
        setPrevSelBookmarkId={setPrevSelBookmarkId}
        showBookmarkModal={showBookmarkModal}
      />

      <LoadingPop isLoading={isLoading} />
    </section>
  );
}
