import React, { useCallback, useEffect, useRef, useState } from "react";
import { ButtonGroup } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { HexColorPicker } from "react-colorful";
import { useSelector } from "react-redux";
import validator from "validator";
import {
  Delay,
  IsEnterkey,
  IsValidValue,
  JsonLocalStorage,
  TaskCatchError,
} from "../../Common";
import LoadingPop from "../../components/LoadingPop";
import MsgBox from "../../components/MsgBox";
import CategorySel from "../../components/categories/CategorySel";
import GroupSel from "../../components/groups/GroupSel";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";

export default function BookmarkAddPage() {
  const userId = useSelector((state) => state.auth?.userId);
  const myGroups = useSelector((state) => state.auth?.myGroups);

  const queryParams = new URLSearchParams(window.location.search);
  const group = queryParams.get("group");
  const category = queryParams.get("category");

  const [msg, setMsg] = useState("");
  const axiosToken = useAxiosPrivate();
  const [groupNo, setGroupNo] = useState(() => {
    return group;
  });
  const [categoryNo, setCategoryNo] = useState(() => {
    return category;
  });
  const [groupData, setGroupData] = useState([]);
  const [categoryData, setCategoryData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [errorUriMessage, setErrorUriMessage] = useState("");
  const [isInvalidUri, setIsInvalidUri] = useState(false);
  const [isInvalidName, setIsInvalidName] = useState(false);
  const [url, setUrl] = useState("");
  const [title, setTitle] = useState("");
  const [color, setColor] = useState("#000000");
  const [isImportant, setIsImportant] = useState(false);
  const [isLineThrough, setIsLineThrough] = useState(false);
  const [isInvalidNameClass, setIsInvalidNameClass] = useState("");
  const [isImportantClass, setIsImportantClass] = useState("");
  const [isLineThroughClass, setIsLineThroughClass] = useState("");

  const bookmarkUriRef = useRef();
  const btnSubmitRef = useRef();

  /**
   * isInvalidName 변경되면 isInvalidNameClass 변경
   */
  useEffect(() => {
    setIsInvalidNameClass(isInvalidName ? "invalid-box-border" : "");
  }, [isInvalidName]);

  /**
   * isImportant 변경되면 isImportantClass 변경
   */
  useEffect(() => {
    setIsImportantClass(isImportant ? "text-important fw-bold" : "");
  }, [isImportant]);

  /**
   * isLineThrough 변경되면 isLineThroughClass 변경
   */
  useEffect(() => {
    setIsLineThroughClass(isLineThrough ? "txt-line-through" : "");
  }, [isLineThrough]);

  // const paramTitle = queryParams.get("title");
  // const paramText = queryParams.get("text");
  // const paramUrl = queryParams.get("url");
  const nb = localStorage.getItem("nb")
    ? JSON.parse(localStorage.getItem("nb"))
    : null;

  useEffect(() => {
    if (!nb) return;

    let realUrl = ""; // url과 text 중에 어느 항목이 url인지 모르므로 먼저 url을 검사한다.

    const paramTitle = nb?.title || "";
    const paramText = nb?.text || "";
    const paramUrl = nb?.url || "";

    if (validator.isURL(decodeURIComponent(paramUrl))) {
      realUrl = decodeURIComponent(paramUrl);
      setIsInvalidUri(false);
    } else {
      if (validator.isURL(decodeURIComponent(paramText))) {
        realUrl = decodeURIComponent(paramText);
        setIsInvalidUri(false);
      }
    }
    uriValidate(realUrl);
    setUrl(realUrl);
    setTitle(paramTitle);
    setTimeout(() => {
      if (isInvalidUri) {
        // 주소가 올바르지 않으면 주소 입력창에 포커스
        bookmarkUriRef.current.focus();
        bookmarkUriRef.current.classList.add("invalid-box-border");
      } else {
        // 올바른 주소면 저장 버튼에 포커스
        btnSubmitRef.current.focus();
      }
    }, 1000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nb]);

  const uriValidate = useCallback((value) => {
    if (!value) {
      setErrorUriMessage("");
      setIsInvalidUri(true);
    } else {
      if (validator.isURL(value)) {
        setErrorUriMessage("유효한 주소입니다.");
        setIsInvalidUri(false);
      } else {
        setErrorUriMessage("유효한 주소가 아닙니다.");
        setIsInvalidUri(true);
      }
    }
  }, []);

  const nameValidate = useCallback((e) => {
    if (!e || !e?.target) return;
    const value = e.target.value;
    if (!IsValidValue(value)) {
      setIsInvalidName(true);
    } else {
      setIsInvalidName(false);
    }
  }, []);

  useEffect(() => {
    if (!url || !title) return;
    // url 검사
    uriValidate(url);
    // 북마크명 검사
    if (!title?.trim()) {
      setIsInvalidName(true);
    } else {
      setIsInvalidName(false);
    }
  }, [uriValidate, url, title]);

  // 사용자의 그룹 목록을 가져온다.
  const initGroupData = useCallback(() => {
    try {
      setIsLoading(true);
      setGroupData(myGroups || []);
    } catch (err) {
      TaskCatchError(err);
    } finally {
      setIsLoading(false);
    }
  }, [setIsLoading, myGroups]);

  useEffect(() => {
    initGroupData();
  }, [initGroupData]);

  // 선택된 그룹
  useEffect(() => {
    if (!groupData || !groupData.length) return;
    setGroupNo((group && parseInt(group)) || groupData[0].groupNo);
  }, [groupData, group]);

  // 그룹이 선택되면 해당 그룹의 카테고리 목록을 가져온다.
  const initCategoryData = useCallback(async () => {
    if (!groupNo || !parseInt(groupNo)) {
      return;
    }
    try {
      const categories = await axiosToken.get(`/myGroup/${groupNo}/categories`);
      if (categories?.status === 200) {
        if (categories?.data?.length) {
          if (categories?.data === "error") return;
          await setCategoryData(categories?.data);
          await setIsImportant(categories?.data?.isImportant);
          await setIsLineThrough(categories?.data?.isLineThrough);
        }
      }
    } catch (err) {
      TaskCatchError(err);
    }
  }, [groupNo, axiosToken]);

  useEffect(() => {
    if (!groupNo || !parseInt(groupNo)) return;
    initCategoryData();
  }, [groupNo, initCategoryData]);

  // 카테고리 데이터가 로딩되면 선택된 카테고리를 설정한다.
  useEffect(() => {
    if (!categoryData || !categoryData.length) return;
    setCategoryNo(
      (category && parseInt(category)) || categoryData[0].categoryNo
    );
  }, [categoryData, category]);

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    try {
      setIsLoading(true);
      const target = e.target.closest("#newBookmarkForm");
      const gNo = groupNo,
        cNo = categoryNo;
      const bookmarkName = target.querySelector("#bookmarkName"),
        bookmarkUri = target.querySelector("#bookmarkUri"),
        isImportant = target.querySelector("#chkImportant"),
        isLineThrough = target.querySelector("#chkLinethrough"),
        memo = target.querySelector("#bookmarkMemo");

      const data = {
        userId,
        groupNo: gNo,
        categoryNo: cNo,
        bookmarkUri: bookmarkUri.value,
        bookmarkName: bookmarkName.value,
        isImportant: isImportant.checked,
        isLineThrough: isLineThrough.checked,
        memo: memo.value,
        color,
      };

      // 공백 체크
      if (!data?.userId) {
        setMsg(
          "사용자 정보가 올바르지 않습니다.\n로그인 후 다시 시도해주세요."
        );
        return;
      }
      if (!data?.groupNo) {
        setMsg("그룹 정보가 올바르지 않습니다.");
        return;
      }
      if (!data?.categoryNo) {
        setMsg("카테고리 정보가 올바르지 않습니다.");
        return;
      }
      if (!data?.bookmarkUri) {
        setIsInvalidUri(true);
        bookmarkUri.focus();
        return;
      }
      if (!data?.bookmarkName) {
        setIsInvalidName(true);
        bookmarkName.focus();
        return;
      }

      // 유효성 체크
      if (!parseInt(data?.groupNo)) {
        setMsg("유효한 그룹 정보가 아닙니다.");
        return;
      }
      if (!parseInt(data?.categoryNo)) {
        setMsg("유효한 카테고리 정보가 아닙니다.");
        return;
      }
      if (isInvalidUri) {
        setErrorUriMessage("유효한 주소가 아닙니다.");
        bookmarkUri.focus();
        return;
      }

      // data?._id = null // 사용금지!!
      // _id를 직접 null로 지정하면 생성된 _id 값을 null로 반환한다.
      const res = await axiosToken.post(`/bookmark/add`, data);

      if (!res?.data?.ok) {
        setMsg("북마크가 저장되지 않았습니다.");
      } else {
        setMsg("북마크가 저장되었습니다.");
        Delay(500).then(() => {
          localStorage.removeItem("nb");
          window.location.href = `/bookmarkManage/?group=${gNo}&category=${cNo}&bookmark=${res.data.result}`;
        });
      }
    } catch (err) {
      JsonLocalStorage.removeItem("nb");
      TaskCatchError(err);
    } finally {
      setIsLoading(false);
    }
  };

  const handleChkSubmit = (e) => {
    if (IsEnterkey(e)) handleFormSubmit(e);
  };

  if (isLoading) return <LoadingPop isLoading={isLoading} />;

  return (
    <section className="container-xl">
      <div className="row">
        <h2 className="h2">북마크 추가</h2>
        <MsgBox msg={msg} />
        <p className="faj-center fst-italic">
          * 그룹이나 카테고리가 선택되지 않으면 북마크 저장이 되지 않습니다.
          그룹이나 카테고리가 없다면 먼저 생성해주세요!
        </p>
        <div className="col-md-2">
          <div className="col-sm-12 col-lg-12 mb-4">
            <h3 className="h4 p-2 bg-gradient bg-dark bg-opacity-25 faj-center">
              그룹
            </h3>
            <GroupSel
              groupData={groupData}
              groupNo={groupNo}
              setGroupNo={setGroupNo}
              setCategoryData={setCategoryData}
              setCategoryNo={setCategoryNo}
            />
          </div>
        </div>
        <div className="col-md-3">
          <div className="col-sm-12 col-lg-12">
            <h3 className="h4 p-2 bg-gradient bg-dark bg-opacity-25 faj-center">
              카테고리
            </h3>
            <CategorySel
              categoryData={categoryData}
              groupNo={groupNo}
              categoryNo={categoryNo}
              setCategoryNo={setCategoryNo}
            />
          </div>
        </div>
        <div className="col-md mt-4 mt-md-0">
          <h3 className="h4 p-2 bg-gradient bg-dark bg-opacity-25 faj-center">
            북마크
          </h3>
          <Form id="newBookmarkForm" onSubmit={handleFormSubmit}>
            <Form.Group className="mb-3" controlId="bookmarkUri">
              <Form.Control
                type="url"
                ref={bookmarkUriRef}
                className={isInvalidUri ? `mb-1 invalid-box-border` : `mb-1`}
                placeholder="북마크 주소를 입력하세요."
                defaultValue={decodeURIComponent(url)}
                onChange={(e) => uriValidate(e.target.value)}
                onKeyDown={handleChkSubmit}
              />
              <small className="text-danger">{errorUriMessage}</small>
            </Form.Group>
            <Form.Group className="mb-3" controlId="bookmarkName">
              <Form.Control
                type="text"
                // className={isInvalidName ? `mb-1 invalid-box-border` : `mb-1`}
                className={`${isInvalidNameClass} ${isImportantClass} ${isLineThroughClass}`}
                style={{ color }}
                placeholder="북마크 명을 입력하세요."
                defaultValue={decodeURIComponent(title)}
                onBlur={nameValidate}
                onKeyDown={handleChkSubmit}
              />
              <ButtonGroup className="mt-2">
                <Form.Check
                  inline
                  label="중요"
                  type="checkbox"
                  id="chkImportant"
                  onClick={(e) => setIsImportant(e.target.checked)}
                />
                <Form.Check
                  inline
                  label="취소선"
                  type="checkbox"
                  id="chkLinethrough"
                  onClick={(e) => setIsLineThrough(e.target.checked)}
                />
              </ButtonGroup>
              <ButtonGroup className="mt-2 w-100">
                <HexColorPicker
                  className="w-100"
                  style={{ height: "80px" }}
                  color={color || "#000000"}
                  onChange={setColor}
                />
              </ButtonGroup>
            </Form.Group>
            <Form.Group className="mb-3" controlId="bookmarkMemo">
              <Form.Control
                as="textarea"
                rows={3}
                placeholder="메모를 입력하세요.(선택)"
              />
            </Form.Group>
            <Button
              variant="outline-primary"
              className="col-12 fs-5"
              type="submit"
              ref={btnSubmitRef}
            >
              북마크 저장
            </Button>
          </Form>
        </div>
      </div>
      {/* <LoadingPop isLoading={isLoading} /> */}
    </section>
  );
}
