/** 공통메시지(알파벳 순으로 배치, frontend-backend 동기화 할 것!) */
const CommonMessage = {
  /** (400) 잘못된 요청입니다. */
  badRequest: "잘못된 요청입니다.",
  /** (409) 중복입니다. */
  conflict: "중복입니다.",
  /** 이메일을 입력하세요. */
  emailPlaceholder: "이메일을 입력하세요.",
  /** (419) 사용자 인증이 만료되었습니다.\n다시 로그인해주세요. */
  expired: "사용자 인증이 만료되었습니다.\n다시 로그인해주세요.",
  /** (403) 접근이 거부되었습니다. */
  forbidden: "접근이 거부되었습니다.",
  /** (500) 서버에 오류가 발생했습니다. */
  internalServerError: "서버에 오류가 발생했습니다.",
  /** 닉네임은 1글자 이상 30글자 이하로 입력해야 합니다.\n(한글, 영문, 숫자를 혼용할 수 있습니다.) */
  nicknamePlaceholder:
    "닉네임은 1글자 이상 32글자 이하로 입력해야 합니다.\n(한글, 영문, 숫자를 혼용할 수 있습니다.)",
  /** 사용할 닉네임을 입력하세요. */
  nicknameRequiredAlert: "사용할 닉네임을 입력하세요.",
  /** 인증 정보가 존재하지 않거나 만료 되었습니다.\n잠시 후 다시 로그인 해주세요. */
  noAuth: "인증 정보가 존재하지 않거나 만료 되었습니다.\n잠시 후 다시 로그인 해주세요.",
  /** 로그인 되었습니다. */
  loginDone: "로그인 되었습니다.",
  /** 아이디는 3글자 이상 32글자 이하로 입력해야 합니다.\n(영문, 숫자를 혼용할 수 있습니다.) */
  loginIdPlaceholder:
    "아이디는 3글자 이상 32글자 이하로 입력해야 합니다.\n(영문, 숫자를 혼용할 수 있습니다.)",
  /** 사용할 로그인 아이디를 입력하세요. */
  loginIdRequiredAlert: "사용할 로그인 아이디를 입력하세요.",
  /** 로그인 아이디를 입력하세요. */
  loginIdRequiredBasicAlert: "로그인 아이디를 입력하세요.",
  /** 로그인 정보가 올바르지 않습니다. */
  noLoginMatch: "로그인 정보가 올바르지 않습니다.",
  /** 사용할 수 없는 아이디 입니다. */
  notAllowedIdAlert: "사용할 수 없는 아이디 입니다.",
  /** 사용할 수 없는 닉네임 입니다. */
  notAllowedNicknameAlert: "사용할 수 없는 닉네임 입니다.",
  /** (404) 존재하지 않는 페이지입니다. */
  notFound: "존재하지 않는 페이지입니다.",
  /** 비밀번호 확인을 입력하세요. */
  passwordConfirmRequiredAlert: "비밀번호 확인을 입력하세요.",
  /** 비밀번호는 단방향 해쉬화 되서 관리되므로 관리자도 알 수 없습니다. */
  passwordHashPlaceholder:
    "* 비밀번호는 단방향 해쉬화 되서 관리되므로 관리자도 알 수 없습니다.",
  /** 비밀번호는 8글자 이상 32글자 이하로 입력해야 합니다.\n(영문, 숫자, 특수문자를 혼용할 수 있습니다.) */
  passwordPlaceholder:
    "비밀번호는 4글자 이상 32글자 이하로 입력해야 합니다.\n(영문, 숫자, 특수문자를 혼용할 수 있습니다.)",
  /** 비밀번호를 입력하세요. */
  passwordRequiredAlert: "비밀번호를 입력하세요.",
  /** 비밀번호화 비밀번호 확인이 일치하지 않습니다. */
  passwordNotMatchAlert: "비밀번호화 비밀번호 확인이 일치하지 않습니다.",
  /** 카테고리가 존재하지 않습니다.\n 카테고리를 먼저 생성 후 진행해주세요. */
  requireCategoryFirst: "카테고리가 존재하지 않습니다.\n카테고리를 먼저 생성 후 진행해주세요.",
  /** 카테고리가 존재하지 않습니다.\n 카테고리를 먼저 선택해주세요. */
  requireCategorySelect: "카테고리가 존재하지 않습니다.\n카테고리를 먼저 선택해주세요.",
  /** 그룹이 존재하지 않습니다.\n 그룹을 먼저 생성 후 진행해주세요. */
  requireGroupFirst: "그룹이 존재하지 않습니다.\n그룹을 먼저 생성 후 진행해주세요.",
  /** PC방이나 공용 PC에서는 로그아웃을 꼭 해주세요! */
  signoutCaution: "PC방이나 공용 PC에서는 로그아웃을 꼭 해주세요!",
  /** 회원가입이 완료되었습니다. */
  signupDone: "회원가입이 완료되었습니다.",
  /** (200) 성공 */
  success: "성공",
  /** (401) 사용자 인증이 올바르지 않습니다.\n다시 로그인 해주세요. */
  unauthorized: "사용자 인증이 올바르지 않습니다.\n다시 로그인 해주세요.",
};

exports.CommonMessage = CommonMessage;
