import { faArrowDownAZ } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { IsValidValue } from "../../Common";
import LoadingPopSpinner from "../../components/LoadingPopSpinner";
import CompanyFormModal from "../../components/enj/CompanyFormModal";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import useToast from "../../hooks/useToast";
import {
  initAreaCode,
  initAreaCodes,
  initDetailAreaCode,
  initDetailAreaCodes,
  initDomainCodes,
  initDoorCode,
  initDoorCodes,
  initTypeCode,
  initTypeCodes,
  resetAreaCode,
  resetDetailAreaCode,
  resetDoorCode,
  resetTypeCode,
} from "../../store/enjCodeSlice";
import {
  initDomainAVDB,
  initDomainHam,
  initDomainHub,
  initDomainIJA,
  initDomainKI,
  initDomainMSAV,
  initDomainMatong,
  initDomainNabiya,
  initDomainPD,
  initDomainSVD,
  initDomainSb,
} from "../../store/enjDomainCodeSlice";
import {
  initCompanies,
  initCompany,
  initModalShow,
  resetCompany,
} from "../../store/enjSlice";
import "./enj.css";

const IndexPage = () => {
  const dispatch = useDispatch();
  const { addToast } = useToast();
  const apiServer = process.env.REACT_APP_API_DOAMIN;
  const axiosToken = useAxiosPrivate();

  const typeCodes = useSelector((state) => state.enjCode?.typeCodes);
  const areaCodes = useSelector((state) => state.enjCode?.areaCodes);
  const detailAreaCodes = useSelector(
    (state) => state.enjCode?.detailAreaCodes
  );
  const doorCodes = useSelector((state) => state.enjCode?.doorCodes);
  const doorCode = useSelector((state) => state.enjCode?.doorCode);
  const typeCode = useSelector((state) => state.enjCode?.typeCode);
  const areaCode = useSelector((state) => state.enjCode?.areaCode);
  const detailAreaCode = useSelector((state) => state.enjCode?.detailAreaCode);
  const companies = useSelector((state) => state.enj?.companies);
  const company = useSelector((state) => state.enj?.company);

  const [filteredCompanies, setFilteredCompanies] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");

  /** 회사 검색
   * searchText가 변경될 때마다 회사 리스트를 검색해서 filteredCompanies에 담는다.
   */
  useEffect(() => {
    const searchCompanies = async () => {
      let filteredData = [...companies];
      /** 버튼 조건 모두 추가 */
      // 종목
      if (IsValidValue(typeCode))
        filteredData = filteredData.filter((d) => d.type?.code === typeCode);

      // 지역
      if (IsValidValue(areaCode))
        filteredData = filteredData.filter((d) => d.area?.code === areaCode);

      // 상세지역
      if (IsValidValue(detailAreaCode))
        filteredData = filteredData.filter(
          (d) => d.detailArea?.code === detailAreaCode
        );

      // 출구
      if (IsValidValue(doorCode))
        filteredData = filteredData.filter((d) => d.door?.code === doorCode);

      // 대소문자 구분 안함.
      if (IsValidValue(searchText)) {
        filteredData = filteredData.filter((d) =>
          d.companyName.toLowerCase().includes(searchText.toLowerCase())
        );
      }
      setFilteredCompanies(filteredData);
    };
    searchCompanies();
  }, [companies, typeCode, areaCode, detailAreaCode, doorCode, searchText]);

  /** 모든 코드 가져오기 */
  useEffect(() => {
    const fetchCodes = async () => {
      // 가져올 자식 코드의 부모 코드들을 배열 형식으로 전달
      const codes = "enj-type|seoul|subway-door|siteDomain";
      setIsLoading(true);
      await axiosToken
        .get(`${apiServer}/manage/codesChildCodes/${codes}`)
        .then((res) => {
          const data = res.data;
          // parentCode 가 YD-type 인 결과들 typeCodes 에 담기
          const typeCodes = data.filter((d) => d.parentCode === "enj-type");
          dispatch(initTypeCodes(typeCodes));
          // parentCode 가 seoul 인 결과들 areaCodes 에 담기
          const areaCodes = data.filter((d) => d.parentCode === "seoul");
          dispatch(initAreaCodes(areaCodes));
          // parentCode 가 siteDomain 인 결과들 DomainCodes 에 담기
          const DomainCodes = data.filter((d) => d.parentCode === "siteDomain");
          dispatch(initDomainCodes(DomainCodes));
          // parentCode 가 subway-door 인 결과들 doorCodes 에 담기
          const doorCodes = data.filter((d) => d.parentCode === "subway-door");
          dispatch(initDoorCodes(doorCodes));
          // 도메인 할당
          dispatch(
            initDomainSb(DomainCodes.filter((d) => d.code === "sbam")[0].memo)
          );
          dispatch(
            initDomainKI(
              DomainCodes.filter((d) => d.code === "ki-info")[0].memo
            )
          );
          dispatch(
            initDomainNabiya(
              DomainCodes.filter((d) => d.code === "1004ya")[0].memo
            )
          );
          dispatch(
            initDomainMatong(
              DomainCodes.filter((d) => d.code === "msgtong")[0].memo
            )
          );
          dispatch(
            initDomainHam(
              DomainCodes.filter((d) => d.code === "hamster")[0].memo
            )
          );
          dispatch(
            initDomainHub(
              DomainCodes.filter((d) => d.code === "pornhub")[0].memo
            )
          );
          dispatch(
            initDomainIJA(
              DomainCodes.filter((d) => d.code === "ijavhd")[0].memo
            )
          );
          dispatch(
            initDomainPD(
              DomainCodes.filter((d) => d.code === "theporndude")[0].memo
            )
          );
          dispatch(
            initDomainAVDB(
              DomainCodes.filter((d) => d.code === "avdbs")[0].memo
            )
          );
          dispatch(
            initDomainMSAV(
              DomainCodes.filter((d) => d.code === "missav")[0].memo
            )
          );
          dispatch(
            initDomainSVD(
              DomainCodes.filter((d) => d.code === "sendvid")[0].memo
            )
          );
        })
        .catch((e) => {
          console.error(e);
          setError(e.message);
        })
        .finally(() => {
          setIsLoading(false);
        });
    };
    fetchCodes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /** areaCode가 변경될 때마다 해당 코드의 하위코드들 detailAreas에 할당 */
  useEffect(() => {
    const fetchDetailAreas = async () => {
      // 지역코드가 변경되면 상세지역코드, 출구코드 초기화
      // dispatch(resetDetailAreaCodes());
      // dispatch(resetDetailAreaCode());
      // dispatch(resetDoorCode());
      // areaCode가 없으면 return
      if (!IsValidValue(areaCode)) return;
      // areaCode가 유효한 값이면 하위 코드들을 가져온다.
      setIsLoading(true);
      await axiosToken
        .get(`${apiServer}/manage/codesChildCodes/${areaCode}`)
        .then((res) => {
          const data = res.data;
          // parentCode 가 seoul의 children인 결과들 detailAreas에 담기
          dispatch(initDetailAreaCodes(data));
        })
        .catch((e) => {
          console.error(e);
          setError(e.message);
        })
        .finally(() => {
          setIsLoading(false);
        });
    };
    fetchDetailAreas();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [axiosToken, apiServer, dispatch, areaCode]);

  /** 모든 회사리스트 가져오기 */
  useEffect(() => {
    const fetchCompanies = async () => {
      setIsLoading(true);
      await axiosToken
        .get(`${apiServer}/1000/enjCompanies`)
        .then((res) => {
          const data = res.data;
          dispatch(initCompanies(data));
          let filteredData = [...data];
          setFilteredCompanies(filteredData);
        })
        .catch((e) => {
          console.error(e);
          setError(e.message);
        })
        .finally(() => {
          setIsLoading(false);
        });
    };
    fetchCompanies();
  }, [axiosToken, apiServer, dispatch]);

  /**
   * 종목, 지역, 상세지역이 변경되면 회사 리스트를 필터해서 가져온다.
   */
  useEffect(() => {
    const filterCompanies = async () => {
      let filteredData = await [...companies];
      if (IsValidValue(typeCode))
        filteredData = filteredData.filter((d) => d.type?.code === typeCode);
      if (IsValidValue(areaCode))
        filteredData = filteredData.filter((d) => d.area?.code === areaCode);
      if (IsValidValue(detailAreaCode))
        filteredData = filteredData.filter(
          (d) => d.detailArea?.code === detailAreaCode
        );
      if (IsValidValue(doorCode))
        filteredData = filteredData.filter((d) => d.door?.code === doorCode);

      await setFilteredCompanies(filteredData);
    };
    filterCompanies();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companies, typeCode, areaCode, detailAreaCode, doorCode]);

  const handleDeleteCompanyClick = async (e, _id) => {
    e.preventDefault();
    if (!IsValidValue(_id)) {
      addToast({
        type: "danger",
        text: "선택된 객체가 올바르지 않습니다.",
      });
      return;
    }
    if (!window.confirm("삭제하시겠습니까?")) return;
    setIsLoading(true);
    await axiosToken
      .delete(`${apiServer}/enjCompany/delete`, {
        data: { _id },
      })
      .then((res) => {
        if (res.data.ok) {
          dispatch(initModalShow(false));
          addToast({
            type: "success",
            text: res.data.message || "company 삭제 성공",
          });
          /** companies 에서 삭제된 아이템 없이 재할당 하기
           * dispatch(initCompanies()),
           * setFilteredCompanies() */
          const data = companies.filter((d) => d._id !== _id);
          dispatch(initCompanies(data));
          setFilteredCompanies([...data]);
          dispatch(resetCompany());
        } else {
          addToast({
            type: "danger",
            text: res.data.message || "company 삭제 실패",
          });
        }
      })
      .catch((e) => {
        console.error(e);
        addToast({
          type: "danger",
          text: e.message || "company 삭제 실패",
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  if (error) return <div>{error}</div>;

  return (
    <>
      <ul className="nav nav-pills nav-fill text-nowrap flex-nowrap list-group list-group-horizontal overflow-auto justify-content-center border pb-2 my-2">
        <li className="mt-2">
          <Link
            className={`nav-link active`}
            style={{ backgroundColor: "#dc67c2" }}
            to="/enj"
          >
            홈
          </Link>
        </li>
        <li className="mt-2">
          <Link className={`nav-link`} to="/enj/movie">
            영상
          </Link>
        </li>
        <li className="mt-2">
          <Link className={`nav-link`} to="/enj/code">
            코드
          </Link>
        </li>
      </ul>
      {/** 전체 조건 초기화 */}
      <div className="border-bottom py-2">
        <button
          className={`me-2 my-2 btn btn-outline-secondary w-100`}
          onClick={() => {
            dispatch(resetTypeCode());
            dispatch(resetAreaCode());
            dispatch(resetDetailAreaCode());
            dispatch(resetDoorCode());
          }}
        >
          전체 조건 초기화
        </button>
      </div>
      {/** types */}
      <div className="border-bottom py-2">
        <button
          className={`me-2 my-2 btn btn-outline-danger`}
          onClick={() => {
            dispatch(resetTypeCode());
          }}
        >
          전체 종목
        </button>
        {typeCodes?.map((tc, index) => {
          return (
            <button
              className={`me-2 my-2 btn ${
                typeCode === tc.code ? "btn-success" : "btn-outline-success"
              }
              ${
                tc.useStatus === "USN"
                  ? "text-decoration-line-through"
                  : tc.useStatus === "USD"
                  ? "text-decoration-line-through fst-italic text-muted"
                  : tc.useStatus === "USP"
                  ? "text-success"
                  : ""
              }`}
              key={index}
              onClick={() => {
                dispatch(initTypeCode(tc.code));
              }}
            >
              {tc.codeName}
            </button>
          );
        })}
      </div>
      {/** areaCodes */}
      <div className="border-bottom py-2">
        <button
          className={`me-2 my-2 btn btn-outline-danger`}
          onClick={() => {
            dispatch(resetAreaCode());
            dispatch(resetDetailAreaCode());
          }}
        >
          전체 지역
        </button>
        {areaCodes?.map((cc, index) => {
          return (
            <button
              className={`me-2 my-2 btn ${
                areaCode === cc.code ? "btn-success" : "btn-outline-success"
              }
              ${
                cc.useStatus === "USN"
                  ? "text-decoration-line-through"
                  : cc.useStatus === "USD"
                  ? "text-decoration-line-through fst-italic text-muted"
                  : cc.useStatus === "USP"
                  ? "text-success"
                  : ""
              }`}
              key={index}
              onClick={() => {
                dispatch(initAreaCode(cc.code));
                dispatch(resetDetailAreaCode());
              }}
            >
              {cc.codeName}
            </button>
          );
        })}
      </div>
      {/** detailAreaCode Codes */}
      <div
        className={`border-bottom py-2 ${
          IsValidValue(areaCode) ? "" : "d-none"
        }`}
      >
        <button
          className={`me-2 my-2 btn btn-outline-danger`}
          onClick={() => {
            dispatch(resetDetailAreaCode());
          }}
        >
          전체 상세지역
        </button>
        {detailAreaCodes?.map((sd, index) => {
          return (
            <button
              className={`me-2 my-2 btn ${
                detailAreaCode === sd.code
                  ? "btn-success"
                  : "btn-outline-success"
              }
              ${
                sd.useStatus === "USN"
                  ? "text-decoration-line-through"
                  : sd.useStatus === "USD"
                  ? "text-decoration-line-through fst-italic text-muted"
                  : sd.useStatus === "USP"
                  ? "text-success"
                  : ""
              }`}
              key={index}
              onClick={() => {
                dispatch(initDetailAreaCode(sd.code));
              }}
            >
              {sd.codeName}
            </button>
          );
        })}
      </div>
      {/** door Codes */}
      <div className={`border-bottom py-2`}>
        <button
          className={`me-2 my-2 btn btn-outline-danger`}
          onClick={() => {
            dispatch(resetDoorCode());
          }}
        >
          전체 출구
        </button>
        {doorCodes?.map((sd, index) => {
          return (
            <button
              className={`me-2 my-2 btn ${
                doorCode === sd.code ? "btn-success" : "btn-outline-success"
              }
              ${
                sd.useStatus === "USN"
                  ? "text-decoration-line-through"
                  : sd.useStatus === "USD"
                  ? "text-decoration-line-through fst-italic text-muted"
                  : sd.useStatus === "USP"
                  ? "text-success"
                  : ""
              }`}
              key={index}
              onClick={() => {
                dispatch(initDoorCode(sd.code));
              }}
            >
              {sd.codeName}
            </button>
          );
        })}
      </div>
      {/** 검색 폼 */}
      <div className="col-12 mt-3">
        <form>
          <input
            className="form-control me-2"
            type="search"
            placeholder="회사명 검색"
            aria-label="Search"
            value={searchText}
            onChange={(e) => {
              setSearchText(e.target.value);
            }}
          />
        </form>
      </div>
      <div className="col-12 py-3">
        <CompanyFormModal handleDeleteCompanyClick={handleDeleteCompanyClick} />
      </div>
      {/** 회사 리스트 */}
      <div className="table-responsive">
        <table
          className="table table-sm table-hover align-middle text-center"
          id="companyTable"
        >
          <thead>
            <tr>
              <th style={{ width: "60px" }}>idx</th>
              <th style={{ width: "110px" }}>종목</th>
              <th style={{ width: "90px" }}>지역</th>
              <th style={{ width: "80px" }}>상세지역</th>
              <th style={{ width: "80px" }}>출구</th>
              <th style={{ width: "140px" }}>
                회사명&nbsp;
                <FontAwesomeIcon icon={faArrowDownAZ} />
              </th>
              <th style={{ minWidth: "100px", width: "auto" }}>메모</th>
              <th style={{ width: "60px" }}>방문</th>
              <th style={{ width: "60px" }}>수정</th>
              <th style={{ width: "60px" }}>삭제</th>
            </tr>
          </thead>
          <tbody>
            {filteredCompanies?.map((cp, index) => {
              return (
                <tr
                  key={index}
                  className={`${cp?._id === company?._id ? "bg-warning" : ""}`}
                  onClick={() => {
                    dispatch(initCompany(cp));
                  }}
                >
                  <td
                    style={{
                      maxWidth: "1px",
                      textDecoration: "none",
                      color: "black",
                    }}
                  >
                    {filteredCompanies?.length - index}
                  </td>
                  <td
                    style={{ maxWidth: "1px", cursor: "pointer" }}
                    onClick={() => {
                      dispatch(initTypeCode(cp?.type?.code));
                    }}
                  >
                    {cp?.type?.codeName}
                  </td>
                  <td
                    style={{ maxWidth: "1px", cursor: "pointer" }}
                    onClick={() => {
                      dispatch(initAreaCode(cp?.area?.code));
                    }}
                  >
                    {cp?.area?.codeName}
                  </td>
                  <td
                    style={{ maxWidth: "1px", cursor: "pointer" }}
                    onClick={() => {
                      dispatch(initDetailAreaCode(cp?.detailArea?.code));
                    }}
                  >
                    {cp?.detailArea?.codeName}
                  </td>
                  <td
                    style={{ maxWidth: "1px", cursor: "pointer" }}
                    onClick={() => {
                      dispatch(initDoorCode(cp?.door?.code));
                    }}
                  >
                    {cp?.door?.codeName}
                  </td>
                  <td
                    className={`${cp?.isImportant ? "fw-bold" : ""} ${
                      cp?.isLineThrough ? "text-decoration-line-through" : ""
                    }`}
                    style={{
                      maxWidth: "1px",
                      textAlign: "left",
                    }}
                    title={cp?.companyName}
                  >
                    <Link
                      style={{
                        color: cp?.companyColor,
                      }}
                      to={`/enj/company/${cp?._id}`}
                    >
                      {cp?.companyName}
                    </Link>
                  </td>
                  <td
                    className={`${cp?.isImportant ? "fw-bold" : ""} ${
                      cp?.isLineThrough ? "text-decoration-line-through" : ""
                    }`}
                    style={{
                      maxWidth: "1px",
                      textAlign: "left",
                    }}
                    title={cp?.memo}
                  >
                    <Link
                      style={{
                        color: cp?.companyColor,
                      }}
                      to={`/enj/company/${cp?._id}`}
                    >
                      {cp?.memo}
                    </Link>
                  </td>
                  <td
                    className={
                      cp?.totalMeetCount > 1
                        ? cp?.totalMeetCount < 1
                          ? "text-muted"
                          : "fw-bold"
                        : ""
                    }
                    style={{ maxWidth: "1px" }}
                  >
                    {cp?.totalMeetCount}
                  </td>
                  <td>
                    <button
                      className="btn btn-outline-success btn-sm"
                      onClick={() => {
                        dispatch(resetCompany());
                        dispatch(initCompany(cp));
                        dispatch(initModalShow(true));
                      }}
                    >
                      수정
                    </button>
                  </td>
                  <td>
                    <button
                      className="btn btn-outline-danger btn-sm"
                      onClick={(e) => {
                        handleDeleteCompanyClick(e, cp?._id);
                      }}
                    >
                      삭제
                    </button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
        <div style={{ height: "100px" }}></div>
      </div>
      <LoadingPopSpinner isLoading={isLoading} />
    </>
  );
};

export default IndexPage;
