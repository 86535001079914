import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

export default function AppTitle() {
  const navigate = useNavigate();

  const title = useSelector((state) => state.auth?.appTitle);
  const startGroupNo = useSelector((state) => state.auth?.startGroupNo);

  const [appTitle, setAppTitle] = useState("");

  useEffect(() => {
    setAppTitle(title || process.env.REACT_APP_BASIC_APP_TITLE);
    document.title = `${
      title || process.env.REACT_APP_BASIC_APP_TITLE
    } - startmypage.com`;
  }, [title]);

  const handleTitleClick = () => {
    // groupsElList 의 a href 속성값과 startGroupNo 값이 같은 것을 찾아서 클릭한다.
    const groupEl = document.querySelector(
      `#ul-group > li.nav-item.short-title > a[data-no="${startGroupNo}"]`
    );
    if (groupEl) {
      groupEl.click();
    } else {
      navigate("/");
    }
  };

  return (
    <h1
      className="h2 col-md-5 col-lg-6 col-xl-6 mt-3 mt-sm-2 overflow-hidden cursor-pointer"
      onClick={handleTitleClick}
    >
      {appTitle}
    </h1>
  );
}
