/* eslint-disable no-script-url */
/* eslint-disable eqeqeq */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Delay } from "../../Common";
import "../../css/browser_setting.css";

export default function PluginGuidePage() {
  const menus = [
    {
      id: 1,
      title: "크롬 플러그인 설치방법",
      name: "chromePluginDownload",
    },
    {
      id: 2,
      title: "크롬 플러그인 사용방법",
      name: "chromePluginUse",
    },
  ];

  const [curMenuId, setCurMenuId] = useState("1");
  const [curMenuTitle, setCurMenuTitle] = useState("크롬 플러그인 설치방법");

  useEffect(() => {
    document.title = "플러그인 가이드 - startmypage.com";
  }, []);

  const handleMenuClick = (e) => {
    setCurMenuId(e.target.getAttribute("menu-id"));
    setCurMenuTitle(e.target.outerText);
    Title();
    Content();
  };

  const Title = () => {
    return (
      <>
        <ul className="nav nav-pills nav-fill bg-light">
          {menus?.map((m) => (
            <li key={m.id} className="nav-item">
              <Link
                to="#"
                className={
                  m.id == curMenuId
                    ? "bg-warning text-secondary nav-link"
                    : "nav-link"
                }
                onClick={handleMenuClick}
                menu-id={m.id}
              >
                {m.title}
              </Link>
            </li>
          ))}
        </ul>
        <h2>{curMenuTitle}</h2>
      </>
    );
  };

  const Content = () => {
    let htmlContent = "";
    if (curMenuId === "1") {
      htmlContent = `<li>
        <p>1. <a target="_blank" rel="noopener noreferrer" href="https://chrome.google.com/webstore/detail/one-click-bookmark-keeper/jbobmmfakemebmlcojkjcbdephnnmcdh"><strong>여기를 클릭</strong></a> 하세요.</p>
      </li>
      <li>
        <p>2. 보이는 화면에서 우측상단 'Chrome에 추가' 클릭</p>
        <img src="/img/chrome-plugin/1-webStore.png" alt="크롬 플러그인 설치 및 사용방법" style="max-width: 100%;" />
      </li>
      <li>
          <p>3. 추가</p>
          <img src="/img/chrome-plugin/2-add.png" alt="크롬 플러그인 설치 및 사용방법" style="max-width: 100%;" />
      </li>
      <li>
          <img src="/img/chrome-plugin/3-add.png" alt="크롬 플러그인 설치 및 사용방법" style="max-width: 100%;" />
          </li>
      <li>
          <p>4. 브라우저에 고정 설정</p>
          <img src="/img/chrome-plugin/4-accessOption.png" alt="크롬 플러그인 설치 및 사용방법" style="max-width: 100%;" />
      </li>`;
    } else if (curMenuId === "2") {
      htmlContent = `<li>
        <p>1. 북마크 하고싶은 페이지에서 설치한 우측상단의 플러그인 클릭</p>
        <img src="/img/chrome-plugin/1-pluginClick.png" alt="크롬 플러그인 설치 및 사용방법" style="max-width: 100%;" />
      </li>
      <li>
        <p>2. 원하는 곳에 저장</p>
        <img src="/img/chrome-plugin/2-saveBookmark.png" alt="크롬 플러그인 설치 및 사용방법" style="max-width: 100%;" />
      </li>`;
    }

    return (
      <ul
        className="container"
        id="contentList"
        dangerouslySetInnerHTML={{ __html: htmlContent }}
      />
    );
  };

  useEffect(() => {
    Delay(1000).then(() => {
      window.scrollTo(0, 0);
    });
  }, []);

  return (
    <section className="container-xl">
      <div className="col-lg-9" id="mainWrap">
        <div className="pb-3">
          * 크롬 플러그인은 크롬 브라우저에서 열람 중인 페이지를 클릭 한번으로
          북마크 할 수 있는 툴 입니다.
        </div>
        <Title />
        <Content />
      </div>
    </section>
  );
}
