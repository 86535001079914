/**
 * 추후에 validator, state 등을 이용해서 실시간 에러메시지 방식도 구현
 * https://www.geeksforgeeks.org/how-to-validate-url-in-reactjs/
 * https://gom20.tistory.com/160
 */
import { useCallback, useEffect, useRef, useState } from "react";
import { ButtonGroup, Col, Container, Row } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import { useSelector } from "react-redux";
import { Delay, IsEnterkey, TaskCatchError } from "../../Common";
import { CommonMessage } from "../../CommonMessage";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import MsgBox from "../MsgBox";

function CategoryModal({
  selgroup,
  selcategoryid,
  setselcategoryid,
  setinitialcategory,
  isloading,
  setIsLoading,
  handledelete,
  show,
  setshow,
  isCategoryDelete,
  setIsCategoryDelete,
  initBookmarkDataList,
}) {
  const userId = useSelector((state) => state.auth?.userId);
  const myGroups = useSelector((state) => state.auth?.myGroups);

  const [msg, setMsg] = useState("");
  const axiosToken = useAxiosPrivate();
  const [groups, setGroups] = useState([]);
  const [category, setCategory] = useState(null);
  const [isInvalidName, setIsInvalidName] = useState(false);

  const submitRef = useRef();
  const categorynameRef = useRef();

  const [isImportant, setIsImportant] = useState(false);
  const [isLineThrough, setIsLineThrough] = useState(false);
  const [isInvalidNameClass, setIsInvalidNameClass] = useState("");
  const [isImportantClass, setIsImportantClass] = useState("");
  const [isLineThroughClass, setIsLineThroughClass] = useState("");

  /**
   * isImportant 변경되면 isImportantClass 변경
   */
  useEffect(() => {
    setIsImportantClass(isImportant ? "text-important fw-bold" : "");
  }, [isImportant]);

  /**
   * isLineThrough 변경되면 isLineThroughClass 변경
   */
  useEffect(() => {
    setIsLineThroughClass(isLineThrough ? "txt-line-through" : "");
  }, [isLineThrough]);

  /**
   * isInvalidName 변경되면 isInvalidNameClass 변경
   */
  useEffect(() => {
    setIsInvalidNameClass(isInvalidName ? "invalid-box-border" : "");
  }, [isInvalidName]);

  // 카테고리 팝업이 열릴 때마다 categorynameRef에 포커스를 준다.
  useEffect(() => {
    if (!show) return;
    categorynameRef?.current?.focus();
  }, [show]);

  // 중복되는 메시지만 활용
  const placeholderMsg = {
    newGroup: "새 그룹 명을 입력하세요.",
    newCate: "새 카테고리 명을 입력하세요",
    cName: "카테고리 명을 입력하세요.",
    memo: "메모를 입력하세요.(선택)",
  };

  const resetStates = useCallback(() => {
    setshow(false);
    setselcategoryid("null");
    setIsInvalidName(false);
    setCategory(null); // 모달 내의 data
    setMsg("");
    setIsImportant(false);
    setIsLineThrough(false);
    setIsImportantClass("");
    setIsLineThroughClass("");
  }, [setshow, setselcategoryid, setCategory, setIsInvalidName]);

  const nameValidate = useCallback(
    (e) => {
      if (!e || !e.target) resetStates();
      if (!selgroup) resetStates();
      const value = e.target.value;
      if (!value.trim()) {
        setIsInvalidName(true);
      } else {
        setIsInvalidName(false);
      }
    },
    [setIsInvalidName, selgroup, resetStates]
  );

  useEffect(() => {
    if (!isCategoryDelete) return;
    setCategory(null);
    setIsCategoryDelete(false);
  }, [isCategoryDelete, setIsCategoryDelete]);

  useEffect(() => {
    const initGroupData = async () => {
      await setIsLoading(true);
      try {
        if (!myGroups?.length) {
          setMsg(CommonMessage.requireGroupFirst);
          resetStates();
          return;
        }
        setMsg("");
        setGroups(myGroups);
      } catch (err) {
        TaskCatchError(err);
      } finally {
        setIsLoading(false);
      }
    };
    initGroupData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [myGroups]);

  // |이 코드는 useEffect 훅을 사용하여 컴포넌트가 렌더링될 때마다 실행되는 함수를 정의하고 있습니다. 이 함수는 show와 selcategoryid의 값이 변경될 때마다 실행됩니다.
  // |
  // |좋은 점:
  // |- useEffect를 사용하여 비동기 작업을 수행하고 있습니다. initData 함수 내에서 비동기 작업을 수행하고 있으며, 이를 통해 컴포넌트의 상태를 업데이트하고 필요한 작업을 수행할 수 있습니다.
  // |- setIsLoading, setCategory, document.querySelector 등의 함수를 사용하여 컴포넌트의 상태를 업데이트하고 있습니다. 이를 통해 컴포넌트의 렌더링을 제어하고 필요한 동작을 수행할 수 있습니다.
  // |
  // |나쁜 점:
  // |- 코드의 가독성이 좋지 않습니다. 들여쓰기가 일관되지 않고, 코드 블록이 잘 구분되어 있지 않습니다. 이로 인해 코드를 이해하기 어려울 수 있습니다. 코드의 가독성을 높이기 위해 들여쓰기와 코드 블록의 구분을 명확하게 해주는 것이 좋습니다.
  // |- catch 블록에서 TaskCatchError와 ConsoleError 함수를 호출하고 있지만, 이 함수들이 어떤 역할을 하는지 주석이나 추가적인 설명이 없습니다. 이 함수들이 어떤 동작을 수행하는지 명확하게 설명하는 것이 좋습니다.
  // |
  // |개선점:
  // |- 코드의 가독성을 높이기 위해 들여쓰기와 코드 블록의 구분을 명확하게 해주는 것이 좋습니다. 들여쓰기를 일관되게 적용하고, 코드 블록을 중괄호로 감싸는 등의 방법을 사용하여 코드의 구조를 명확하게 표현해야 합니다.
  // |- TaskCatchError와 ConsoleError 함수가 어떤 동작을 수행하는지 주석이나 추가적인 설명을 추가하는 것이 좋습니다. 이 함수들이 어떤 에러 처리를 수행하는지 명확하게 설명해야 합니다.
  useEffect(() => {
    const initData = async () => {
      if (!show) return;
      try {
        // setIsLoading 을 빼니 부모창이 위로 스크롤되는 오류가 없어졌다!!
        await setCategory(null);
        if (selcategoryid !== "newCategory") {
          const cate = await axiosToken.get(`/category/${selcategoryid}`);
          if (cate && cate.status === 200 && cate.data) {
            if (cate.data === "error") return;
            await setCategory(cate.data);
            await setIsImportant(cate.data?.isImportant);
            await setIsLineThrough(cate.data?.isLineThrough);
          }
        }
      } catch (err) {
        TaskCatchError(err);
      } finally {
        // document.querySelector("#categoryName").focus();
      }
    };
    initData();
  }, [show, selcategoryid, axiosToken, setCategory]);

  const handleFormSubmit = async (e) => {
    if (isloading) return; // [중요] 중복 방지!!
    e.preventDefault();
    if (!selcategoryid) {
      setMsg("선택 된 카테고리가 없습니다.");
      return;
    }
    await setMsg("");
    await setIsLoading(true);
    try {
      const target = e.target.closest(".modal-dialog");
      const groupNo = target.querySelector("#bookmarkGroup").value || selgroup,
        categoryName = target.querySelector("#categoryName"),
        isImportant = target.querySelector("#chkImportant"),
        isLineThrough = target.querySelector("#chkLinethrough"),
        isPublic = target.querySelector("#chkPublic"),
        memo = target.querySelector("#bookmarkMemo");
      const data = {
        userId,
        groupNo,
        categoryName: categoryName.value,
        isImportant: isImportant.checked,
        isLineThrough: isLineThrough.checked,
        isPublic: isPublic.checked,
        memo: memo.value,
      };
      // 공백 체크
      if (!data.userId) {
        setMsg(
          "사용자 정보가 올바르지 않습니다.\n로그인 후 다시 시도해주세요."
        );
        return;
      }
      setMsg("");
      if (!data.groupNo) {
        setMsg(
          "그룹 정보가 올바르지 않습니다.\n- 그룹이 생성되지 않았다면 그룹 생성 후, 생성된 그룹을 먼저 선택하세요."
        );
        return;
      }
      setMsg("");
      if (!data.categoryName) {
        setIsInvalidName(true);
        categoryName.focus();
        return;
      }
      let url, res, savedId;
      if (selcategoryid === "newCategory") {
        // 신규
        url = `/category/add`;
        res = await axiosToken.post(url, data);
        savedId = res.data?.result;
      } else {
        // 수정
        data._id = selcategoryid;
        url = `/category/edit`;
        res = await axiosToken.put(url, data);
        savedId = selcategoryid;
      }
      if (!res?.data?.ok) {
        setMsg("저장되지 않았습니다.");
        return;
      }
      setMsg(res?.data?.message || "저장 되었습니다.");
      // CategoryManage 용
      if (setinitialcategory) {
        await setinitialcategory(data.groupNo); // 카테고리 리스트 리로딩
        // 변경 된 카테고리 강조
        Delay(1000).then(() => {
          const row = document.querySelector(
            `#ul-list-category > li[data-id="${savedId}"]`
          );
          if (row) {
            row.scrollIntoView({
              behavior: "smooth",
              block: "center",
            });
            row.click();
          }
        });
      }
      // MyBookmarks 용
      else if (initBookmarkDataList) {
        /** 기존의 마이북마크에서 업데이트 후 북마크 데이터를 리로딩하고,
         * 변경 된 카테고리를 강조하는 방식에서
         * 리로딩 없이 변경 된 해당 카테고리 내용만 변경하는 방식으로 변경
         * 서버부하 절감 및 사용자 체감 속도가 빨라지고 가벼워짐
         */
        if (res.data?.result) {
          const category = res.data?.result;
          const savedGroupNo = category.groupNo;
          if (selgroup !== savedGroupNo) {
            // 그룹이 변경되었을 경우 해당 노드 삭제
            Delay(100).then(() => {
              const row = document.querySelector(
                `#root > div > section > div.my-masonry-grid > div > div[data-categoryid="${savedId}"]`
              );
              if (row) {
                row.remove();
                resetStates();
              }
              return;
            });
          }
          // 그룹이 변경되지 않았을 경우 해당 노드 업데이트
          const categoryName = category.categoryName,
            isImportant = category.isImportant,
            isLineThrough = category.isLineThrough,
            isPublic = category.isPublic,
            memo = category.memo;
          Delay(100).then(() => {
            const shareCategoryIcon = document.querySelector(
              `#root > div > section > div.my-masonry-grid > div > div[data-categoryid="${savedId}"] > h6 > a`
            );
            if (shareCategoryIcon) {
              // 좌측 공개 카테고리 아이콘
              if (isPublic) {
                shareCategoryIcon?.classList?.remove("d-none");
              } else {
                shareCategoryIcon?.classList?.add("d-none");
              }
            }
            const h6Title = document.querySelector(
              `div.my-masonry-grid > div > div > h6 > span.txt-important-title.me-3[data-categoryid="${savedId}"]`
            );
            if (h6Title) {
              // 카테고리 명
              h6Title.innerHTML = `<strong>${categoryName}</strong>`;

              if (isImportant) h6Title?.classList?.add("txt-important-title");
              else h6Title?.classList?.remove("txt-important-title");

              if (isLineThrough) h6Title?.classList?.add("txt-line-through");
              else h6Title?.classList?.remove("txt-line-through");
            }
            // 카테고리 명에 메모 추가
            if (memo) {
              h6Title.innerHTML += `<br><small className="text-white text-opacity-75"><em>(${memo})</em></small>`;
            }
          });
        }
      }
      Delay(100).then(() => {
        resetStates();
      });
    } catch (err) {
      setMsg("저장되지 않았습니다.");
      console.error(err);
      TaskCatchError(err);
    } finally {
      setIsLoading(false);
    }
  };

  const handleChkSubmit = (e) => {
    if (IsEnterkey(e)) handleFormSubmit(e);
  };

  return (
    <Modal centered show={show} onHide={resetStates}>
      <Modal.Header closeButton>
        <Modal.Title>카테고리 관리</Modal.Title>
      </Modal.Header>
      <MsgBox msg={msg} />
      <Modal.Body>
        <Form>
          <Form.Group
            className={`mb-3 ${
              selcategoryid === "newCategory" ? "d-none" : ""
            }`}
            controlId="bookmarkGroup"
          >
            <Form.Label>그룹을 선택하세요.</Form.Label>
            <Form.Select defaultValue={selgroup || ""}>
              {groups?.length &&
                groups?.map((group) => {
                  return (
                    <option key={group._id} value={group.groupNo}>
                      {group.groupName}
                    </option>
                  );
                })}
            </Form.Select>
          </Form.Group>
          <Form.Group className="mb-3 d-none" controlId="newGroupname">
            <Form.Control type="text" placeholder="새 그룹명을 입력하세요." />
          </Form.Group>
          <Form.Group className="mb-3 d-none" controlId="newCategoryname">
            <Form.Control
              type="text"
              placeholder="새 카테고리명을 입력하세요."
              defaultValue={
                !selcategoryid || selcategoryid === "newCategory"
                  ? ""
                  : category?.categoryName
              }
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="categoryName">
            <Form.Control
              type="text"
              className={`${isInvalidNameClass} ${isImportantClass} ${isLineThroughClass}`}
              ref={categorynameRef}
              placeholder={placeholderMsg.cName}
              defaultValue={
                !selcategoryid || selcategoryid === "newCategory"
                  ? ""
                  : category?.categoryName
                  ? category?.categoryName
                  : ""
              }
              onBlur={nameValidate}
              onKeyDown={handleChkSubmit}
              required
            />
            <ButtonGroup className="mt-2">
              <Form.Check
                inline
                label="중요"
                type="checkbox"
                id="chkImportant"
                defaultChecked={category && category.isImportant}
                onClick={(e) => setIsImportant(e.target.checked)}
              />
              <Form.Check
                inline
                label="취소선"
                type="checkbox"
                id="chkLinethrough"
                defaultChecked={category && category.isLineThrough}
                onClick={(e) => setIsLineThrough(e.target.checked)}
              />
              <Form.Check
                inline
                label="공개"
                type="checkbox"
                id="chkPublic"
                defaultChecked={category?.isPublic}
              />
            </ButtonGroup>
          </Form.Group>
          <Form.Group className="mb-3" controlId="bookmarkMemo">
            <Form.Control
              as="textarea"
              rows={4}
              placeholder={placeholderMsg.memo}
              defaultValue={category?.memo}
            />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Container>
          <Row>
            <Col>
              <Button
                variant="danger w-100"
                type="button"
                disabled={!selcategoryid || selcategoryid === "newCategory"}
                onClick={handledelete}
              >
                삭제
              </Button>
            </Col>
            <Col>
              <Button
                ref={submitRef}
                type="submit"
                variant="primary w-100"
                onClick={handleFormSubmit}
              >
                저장
              </Button>
            </Col>
          </Row>
        </Container>
      </Modal.Footer>
    </Modal>
  );
}

export default CategoryModal;
