import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import validator from "validator";
import { HandleAutoHeight, IsValidValue } from "../../Common";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import useToast from "../../hooks/useToast";
import {
  addLinks,
  editLinks,
  initLink,
  initModalShow,
  resetLink,
} from "../../store/enjSlice";
import LoadingPopSpinner from "../LoadingPopSpinner";

const LinkFormModal = ({ handleDeleteLinkClick }) => {
  const { addToast } = useToast();
  const dispatch = useDispatch();
  const apiServer = process.env.REACT_APP_API_DOAMIN;
  const axiosToken = useAxiosPrivate();
  /** Redux 전역 변수 */
  const domainCodes = useSelector((state) => state.enjCode.domainCodes);
  const link = useSelector((state) => state.enj.link);
  const employees = useSelector((state) => state.enj.employees);
  const employee = useSelector((state) => state.enj.employee);
  /** Modal States */
  const modalShow = useSelector((state) => state.enj.modalShow);
  const [isLoading, setIsLoading] = useState(false);
  /** Form States */
  const [newLink, setNewLink] = useState(null);
  const [domainUri, setDomainUri] = useState("");
  /** Error States */
  const [employeeError, setEmployeeError] = useState("");
  const [domainError, setDomainError] = useState("");
  const [titleError, setTitleError] = useState("");
  const [uriError, setUriError] = useState("");

  /** employe가 변경되면 newEmploye에 깊은복사 한다. */
  useEffect(() => {
    if (link?._id && modalShow) {
      setNewLink({ ...link });
    } else {
      setNewLink({ enjContentId: employee?._id });
    }
  }, [link, modalShow, employee]);

  const resetModalForm = () => {
    dispatch(initModalShow(false));
    setNewLink({ enjContentId: employee?._id });
    setEmployeeError("");
    setDomainError("");
    setTitleError("");
    setUriError("");
  };

  const fillUriTitle = async (fullUrl) => {
    const titleInput = document.querySelector("#txtLinkTitle");
    if (titleInput.value?.trim()) return;
    try {
      // setIsLoading(true);
      const res = await axiosToken.get(
        `${apiServer}/bookmark/getTitle/${encodeURIComponent(fullUrl)}`
      );
      if (res?.data) {
        titleInput.value = res.data?.trim();
        setNewLink({ ...newLink, title: res.data?.trim() });
        setTitleError("");
      }
    } catch (err) {
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  };

  /** validate */
  const validate = () => {
    let result = true;
    if (!IsValidValue(newLink?.enjContentId)) {
      setEmployeeError("직원 선택이 올바르지 않습니다.");
      result = false;
    }
    if (!IsValidValue(newLink?.domain)) {
      setDomainError("도메인 선택이 올바르지 않습니다.");
      result = false;
    }
    if (!IsValidValue(newLink?.title)) {
      setTitleError("링크명이 올바르지 않습니다.");
      result = false;
    }
    if (!IsValidValue(newLink?.uri)) {
      setUriError("주소가 올바르지 않습니다.");
      result = false;
    }
    return result;
  };

  /** handleSubmit */
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validate()) return;
    let isEdit = false;
    let apiUrl = "";
    // newLink._id가 있으면 수정, 없으면 신규등록
    if (IsValidValue(newLink?._id)) {
      isEdit = true;
      apiUrl = `${apiServer}/enjLink/edit`;
    } else {
      apiUrl = `${apiServer}/enjLink/add`;
    }
    try {
      setIsLoading(true);
      const res = isEdit
        ? await axiosToken.put(apiUrl, newLink)
        : await axiosToken.post(apiUrl, newLink);
      if (res?.data?.ok) {
        if (isEdit) {
          dispatch(editLinks(res.data.result));
          dispatch(initLink(res.data.result));
          addToast({
            type: "success",
            text: res.data.message || "링크가 수정되었습니다.",
          });
        } else {
          dispatch(addLinks(res.data.result));
          dispatch(initLink(res.data.result));
          addToast({
            type: "success",
            text: res.data.message || "새 링크가 생성되었습니다.",
          });
        }
        setTimeout(() => {
          /** [중요!!] 시간차를 두지 않으면 resetModalForm()이 반영되지 않는다!
           * - 실행 순서가 변경될 수도 있어서 그런 것 같음.
           */
          resetModalForm();
        }, 500);
      } else {
        if (isEdit) {
          addToast({
            type: "danger",
            text: res.data.message || "링크가 수정되지 않았습니다.",
          });
        } else {
          addToast({
            type: "danger",
            text: res.data.message || "링크가 생성되지 않았습니다.",
          });
        }
      }
    } catch (err) {
      console.error(err);
      addToast({
        type: "danger",
        text: err.message || "링크 생성/수정 중 오류가 발생하였습니다.",
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      {/** 모달 보기 버튼 */}
      <button
        className="btn btn-outline-primary w-100"
        onClick={() => {
          dispatch(resetLink());
          dispatch(initModalShow(true));
        }}
      >
        링크 등록
      </button>

      {/** 웹사이트 링크 등록폼
       * 타입, 지역, 도메인이 선택되지 않으면 저장, 추가 버튼 비활성화
       * 모든 폼을 Bootstrap 반응형으로 구성
       * 폼 간에 적당한 간격을 두고 구성
       */}
      <Modal
        animation={false}
        centered
        show={modalShow}
        onHide={resetModalForm}
      >
        <Modal.Header closeButton>
          <Modal.Title>{link ? "링크 수정" : "새 링크 등록"}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/** [주의!!] <form></form> 을 Modal.Body 이외의 위치에 두면 에러 발생! */}
          <form>
            <div className="container">
              <div className="row">
                <div className="col-12 mb-2">
                  <div>
                    <select
                      className={`form-select ${
                        employeeError ? "border border-danger" : ""
                      }`}
                      value={newLink?.enjContentId || ""}
                      disabled={true}
                    >
                      <option value="">:: 직원 선택 ::</option>
                      {employees?.map((employee, index) => {
                        return (
                          <option key={index} value={employee?._id}>
                            {employee.employeeName}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  <div
                    className={`text-danger ${employeeError ? "" : "d-none"}`}
                  >
                    * {employeeError}
                  </div>
                </div>
                <div className="col-12 mb-2">
                  <select
                    className={`form-select ${
                      domainError ? "border border-danger" : ""
                    }`}
                    value={newLink?.domain || ""}
                    onChange={(e) => {
                      setNewLink({ ...newLink, domain: e.target.value });
                      setDomainUri(
                        domainCodes.find((d) => d._id === e.target.value)
                          ?.memo || ""
                      );
                      if (IsValidValue(e.target.value)) setDomainError("");
                    }}
                  >
                    <option value="">도메인 선택</option>
                    {domainCodes?.map((item, index) => {
                      return (
                        <option key={index} value={item._id}>
                          {item.codeName}
                        </option>
                      );
                    })}
                  </select>
                  <div className={`text-danger ${domainError ? "" : "d-none"}`}>
                    * {domainError}
                  </div>
                </div>
                <div className="col-12 mb-2">
                  <input
                    type="text"
                    className={`form-control`}
                    placeholder="주소를 입력하세요."
                    value={newLink?.uri || ""}
                    onChange={(e) => {
                      setNewLink({
                        ...newLink,
                        uri: e.target.value,
                      });
                    }}
                    onBlur={() => {
                      const fullUrl = `${domainUri}${newLink?.uri}`;
                      if (validator.isURL(fullUrl)) {
                        setDomainError("");
                        setUriError("");
                        fillUriTitle(fullUrl);
                      } else {
                        setUriError(
                          "주소가 올바르지 않습니다. 도메인과 주소를 확인해주세요."
                        );
                      }
                    }}
                  />
                  <div className={`text-danger ${uriError ? "" : "d-none"}`}>
                    * {uriError}
                  </div>
                </div>
                <div className="col-12 mb-2">
                  <input
                    type="text"
                    id="txtLinkTitle"
                    className={`form-control ${
                      newLink?.isImportant ? "fw-bold" : ""
                    } ${
                      newLink?.isLineThrough
                        ? "text-decoration-line-through"
                        : ""
                    } ${titleError ? "border border-danger" : ""}`}
                    style={{ color: newLink?.linkColor }}
                    placeholder="링크명을 입력하세요."
                    value={newLink?.title || ""}
                    onChange={(e) => {
                      setNewLink({
                        ...newLink,
                        title: e.target.value,
                      });
                    }}
                  />
                  <div className={`text-danger ${titleError ? "" : "d-none"}`}>
                    * {titleError}
                  </div>
                </div>
                <div className="row mb-2">
                  <div className={`col-auto`}>
                    <input
                      type="checkbox"
                      className="btn-check"
                      id="ck-isImportant"
                      autoComplete="off"
                      checked={newLink?.isImportant || false}
                      // value={isImportant || false}
                      onChange={(e) => {
                        setNewLink({
                          ...newLink,
                          isImportant: e.target.checked,
                        });
                      }}
                    />
                    <label
                      className="btn btn-outline-primary"
                      htmlFor="ck-isImportant"
                    >
                      강조
                    </label>
                  </div>
                  <div className="col-auto p-0">
                    <input
                      type="checkbox"
                      className="btn-check"
                      id="cb-isLineThrough"
                      autoComplete="off"
                      checked={newLink?.isLineThrough || false}
                      // value={isLineThrough || false}
                      onChange={(e) => {
                        setNewLink({
                          ...newLink,
                          isLineThrough: e.target.checked,
                        });
                      }}
                    />
                    <label
                      className="btn btn-outline-primary"
                      htmlFor="cb-isLineThrough"
                    >
                      취소선
                    </label>
                  </div>
                  <div className="col-auto">
                    <input
                      type="color"
                      className="form-control form-control-color"
                      id="exampleColorInput"
                      title="Choose your color"
                      value={newLink?.linkColor || "#000000"}
                      onChange={(e) => {
                        setNewLink({
                          ...newLink,
                          linkColor: e.target.value,
                        });
                      }}
                    />
                  </div>
                </div>
                {/** memo */}
                <div className="mb-2">
                  <textarea
                    className="form-control"
                    style={{ maxHeight: "60vh" }}
                    rows={2}
                    placeholder="메모를 입력하세요."
                    value={newLink?.memo || ""}
                    onChange={(e) => {
                      setNewLink({ ...newLink, memo: e.target.value });
                    }}
                    onFocus={HandleAutoHeight}
                    onKeyUp={HandleAutoHeight}
                    onKeyDown={HandleAutoHeight}
                    onCut={HandleAutoHeight}
                    onPaste={HandleAutoHeight}
                  />
                </div>
              </div>
            </div>
            <button
              type="submit"
              className="btn btn-outline-primary w-100 mb-2"
              onClick={handleSubmit}
            >
              {link ? "저장" : "신규 등록"}
            </button>
            <button
              className={`btn btn-outline-danger w-100
              ${link ? "" : "d-none"}
              `}
              onClick={(e) => handleDeleteLinkClick(e, link?._id)}
            >
              삭제
            </button>
          </form>
        </Modal.Body>

        <Modal.Footer></Modal.Footer>
      </Modal>
      <LoadingPopSpinner isLoading={isLoading} />
    </>
  );
};

export default LinkFormModal;
