import { useParams } from "react-router-dom";
import ErrorList from "../../components/admin/ErrorList";

export default function ErrorListPage() {
  const { count } = useParams();

  return (
    <section className="container-xxl">
      <ErrorList count={count} />
    </section>
  );
}
