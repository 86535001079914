import {
  faBookmark,
  faChartPie,
  faCircleInfo,
  faGears,
  faHome,
  faObjectGroup,
  faPowerOff,
  faRightToBracket,
  faUserPlus,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect } from "react";
import { FaLaptop, FaMobileAlt, FaTabletAlt } from "react-icons/fa";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { GetStorageValue, ScrollToWCenter } from "../../Common";

const TopMenus = ({ curPath, width }) => {
  const navigate = useNavigate();

  const userId = useSelector((state) => state.auth?.userId);
  const userRoles = useSelector((state) => state.auth?.userRoles);

  const queryParams = new URLSearchParams(window.location.search);
  const group = queryParams.get("group") || "";

  useEffect(() => {
    const links = document.querySelectorAll("#ul-topmenu .nav-link");
    links.forEach((link) => {
      // e.preventDefault(); // 이 기능을 사용하면 a 태그의 href 기능이 동작하지 않는다.
      link.addEventListener("click", (e) => {
        ScrollToWCenter(e);
      });
    });
  }, []);

  useEffect(() => {
    if (curPath && document.querySelectorAll("#ul-topmenu .nav-link")) {
      const menuLinks = document.querySelectorAll("#ul-topmenu .nav-link");
      menuLinks.forEach((link) => {
        link.classList.remove("active");
        if (curPath === link?.getAttribute("url")) link.classList.add("active");
      });
    }
  }, [curPath]);

  const goToBookmarkManage = (e) => {
    e.preventDefault();
    e.target.classList.add("active");
    const selCategory = GetStorageValue("selCategoryNo") || "";
    const url = `/bookmarkManage/?group=${group}&category=${selCategory}`;
    navigate(url);
  };

  return (
    <ul
      className="navbar-nav navbar-nav-scroll ms-auto mb-2 mb-lg-0 text-center"
      id="ul-topmenu"
    >
      <li className="nav-item">
        <Link className="nav-link" to="/" url="/">
          <FontAwesomeIcon icon={faHome} size={"lg"} />
          <p>Home</p>
        </Link>
      </li>
      <li className={`${userId ? "nav-item" : "nav-item d-none"}`}>
        <Link className="nav-link" to="/groupManage" url="/groupManage">
          <FontAwesomeIcon icon={faObjectGroup} size={"lg"} />
          <p>그룹</p>
        </Link>
      </li>
      <li
        className={`${
          userId ? "nav-item top-wide-menu" : "nav-item top-wide-menu d-none"
        }`}
      >
        <Link
          className="nav-link"
          to={`/categoryManage/?group=${group}`}
          url="/categoryManage/"
        >
          <FontAwesomeIcon icon={faChartPie} size={"lg"} />
          <p>카테고리</p>
        </Link>
      </li>
      <li className={`${userId ? "nav-item" : "nav-item d-none"}`}>
        <div
          className="nav-link cursor-pointer"
          onClick={goToBookmarkManage}
          url="/bookmarkManage/"
        >
          <FontAwesomeIcon icon={faBookmark} size={"lg"} />
          <p>북마크</p>
        </div>
      </li>
      <li className={`${userId ? "nav-item" : "nav-item d-none"}`}>
        <Link className="nav-link" to="/config" url="/config">
          <FontAwesomeIcon icon={faGears} size={"lg"} />
          <p>설정</p>
        </Link>
      </li>
      <li
        className={`${
          !userId ? "nav-item top-wide-menu" : "nav-item top-wide-menu d-none"
        }`}
      >
        {" "}
        <Link className="nav-link" to="/signup" url="/signup">
          <FontAwesomeIcon icon={faUserPlus} size={"lg"} />
          <p>회원가입</p>
        </Link>
      </li>
      <li className={`${!userId ? "nav-item" : "nav-item d-none"}`}>
        {" "}
        <Link className="nav-link" to="/signin" url="/signin">
          <FontAwesomeIcon icon={faRightToBracket} size={"lg"} />
          <p>로그인</p>
        </Link>
      </li>
      {/* <li className="nav-item d-block"> */}
      <li className={`${userId ? "nav-item" : "nav-item d-none"}`}>
        <Link className="nav-link" to="/myinfo" url="/myinfo">
          <FontAwesomeIcon icon={faCircleInfo} size={"lg"} />
          <p>내정보</p>
        </Link>
      </li>
      <li
        className={`${
          userId && userRoles.includes(9000) ? "nav-item" : "nav-item d-none"
        }`}
      >
        <Link className="nav-link" to="/admin" url="/admin">
          <FontAwesomeIcon icon={faCircleInfo} size={"lg"} />
          <p>Admin</p>
        </Link>
      </li>
      {/* <li className="nav-item d-block top-wide-menu"> */}
      <li
        className={`${
          userId ? "nav-item top-wide-menu" : "nav-item top-wide-menu d-none"
        }`}
      >
        <Link className="nav-link" to="/signout">
          <FontAwesomeIcon icon={faPowerOff} size={"lg"} />
          <p>로그아웃</p>
        </Link>
      </li>
      <li className="nav-item d-block top-wide-menu">
        <p>
          {width < 768 ? (
            <FaMobileAlt style={{ width: "65%", height: "10%" }} />
          ) : width < 992 ? (
            <FaTabletAlt style={{ width: "65%", height: "10%" }} />
          ) : (
            <FaLaptop style={{ width: "65%", height: "10%" }} />
          )}
        </p>
      </li>
    </ul>
  );
};

export default TopMenus;
