import propTypes from "prop-types";
import { useCallback, useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import LoadingPopSpinner from "../LoadingPopSpinner";
import MsgBox from "../MsgBox";

const CodeForm = ({
  codeParam,
  setCodeParam,
  parentCodeParam,
  setParentCodeParam,
  isCodeChanged,
  setIsCodeChanged,
}) => {
  const axiosHook = useAxiosPrivate();
  const apiServer = process.env.REACT_APP_API_DOAMIN;

  const [parentCode, setParentCode] = useState("");
  const [code, setCode] = useState("");
  const [originalParentCode, setOriginalParentCode] = useState("");
  const [codeName, setCodeName] = useState("");
  const [originalCodeName, setOriginalCodeName] = useState("");
  const [useStatus, setUseStatus] = useState("");
  const [originalUseStatus, setOriginalUseStatus] = useState("");
  const [publicStatus, setPublicStatus] = useState("");
  const [originalPublicStatus, setOriginalPublicStatus] = useState("");
  const [memo, setMemo] = useState("");
  const [originalMemo, setOriginalMemo] = useState("");
  const [codeError, setCodeError] = useState(false);
  const [codeNameError, setCodeNameError] = useState(false);
  const [publicStatusError, setPublicStatusError] = useState(false);
  const [useStatusError, setUseStatusError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [sameLevelCodes, setSameLevelCodes] = useState([]);

  const resetForm = useCallback(() => {
    // setParentCode("");
    // setCode("");
    setOriginalParentCode("");
    setSameLevelCodes([]);
    setCodeName("");
    setOriginalCodeName("");
    setMemo("");
    setOriginalMemo("");
    setUseStatus("");
    setOriginalUseStatus("");
    setPublicStatus("");
    setOriginalPublicStatus("");
    setCodeError(false);
    setCodeNameError(false);
    setUseStatusError(false);
    setPublicStatusError(false);
    setError("");
    setIsLoading(false);
    setIsLoading(false);
  }, []);

  /** 부모코드 select용 :
   * 선택 된 코드의 부모코드가 같은 뎁스의 코드들 가져오기 */
  useEffect(() => {
    const loadDatas = async () => {
      await resetForm();
      await setCode(codeParam);
      await setParentCode(parentCodeParam);
      if (parentCodeParam && codeParam) {
        setError("Error: 부모코드와 수정할 코드가 동시에 선택되었습니다.");
        return;
      }
      if (parentCodeParam) {
        /** 추가 모드
         * 선택 된 코드의 같은 레벨 코드 (select용)
         */
        const parentSelectApiUri = `/${parentCodeParam}/sameParent/code`;
        setIsLoading(true);
        await axiosHook
          .get(`${apiServer}${parentSelectApiUri}`)
          .then((res) => {
            setSameLevelCodes(res.data);
          })
          .catch((e) => {
            console.error(e);
            setError("Something went wrong in database");
          })
          .finally(() => {
            setIsLoading(false);
          });
      } else if (codeParam) {
        /** 수정 모드
         * 선택 된 코드의 부모코드 (select용)
         */
        const parentSelectApiUri = `${apiServer}/${codeParam}/sameParent/parentCodes`;
        setIsLoading(true);
        await axiosHook
          .get(`${parentSelectApiUri}`)
          .then((res) => {
            if (res.data.length) setSameLevelCodes(res.data);
          })
          .catch((e) => {
            console.error(e);
            setError("Something went wrong in database");
          })
          .finally(() => {
            setIsLoading(false);
          });
        // 수정할 코드 정보 가져오기
        const getCodeApiUri = `${apiServer}/code/${codeParam}`;
        setIsLoading(true);
        await axiosHook
          .get(`${getCodeApiUri}`)
          .then((res) => {
            const data = res.data;
            setParentCode(data.parentCode);
            setOriginalParentCode(data.parentCode);
            setCode(data.code);
            setCodeName(data.codeName);
            setOriginalCodeName(data.codeName);
            setUseStatus(data.useStatus);
            setOriginalUseStatus(data.useStatus);
            setPublicStatus(data.publicStatus);
            setOriginalPublicStatus(data.publicStatus);
            setMemo(data.memo);
            setOriginalMemo(data.memo);
          })
          .catch(() => {
            setError("something went wrong in db");
            alert("something went wrong in db");
          })
          .finally(() => {
            setIsLoading(false);
          });
      }
    };
    loadDatas();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apiServer, axiosHook, codeParam, parentCodeParam]);

  const isEdited = () => {
    return (
      parentCode !== originalParentCode ||
      codeName !== originalCodeName ||
      memo !== originalMemo ||
      useStatus !== originalUseStatus ||
      publicStatus !== originalPublicStatus
    );
  };

  const handleFormReset = (e) => {
    e.preventDefault();
    setCodeParam("");
    setParentCodeParam("");
  };

  useEffect(() => {
    if (isCodeChanged) {
      setCodeParam("");
      setParentCodeParam("");
      setIsCodeChanged(false);
      setTimeout(() => {
        setCodeParam(code);
      }, 500);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCodeChanged]);

  const validateForm = () => {
    let validated = true;
    if (!code) {
      setCodeError(true);
      setIsLoading(false);
      validated = false;
    }
    if (!codeName) {
      setCodeNameError(true);
      setIsLoading(false);
      validated = false;
    }
    if (!useStatus) {
      setUseStatusError(true);
      setIsLoading(false);
      validated = false;
    }
    if (!publicStatus) {
      setPublicStatusError(true);
      setIsLoading(false);
      validated = false;
    }
    return validated;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    await setCodeNameError(false);
    await setCodeError(false);
    setUseStatusError(false);
    setPublicStatusError(false);
    await setError("");
    await setIsLoading(false);
    if (!validateForm()) return;
    if (codeParam && code) {
      // edit
      setIsLoading(true);
      axiosHook
        .put(`${apiServer}/code/edit`, {
          parentCode,
          code,
          codeName,
          memo,
          useStatus,
          publicStatus,
        })
        .then((res) => {
          setIsLoading(false);
          if (res.data.ok) {
            setIsCodeChanged(true);
          } else {
            alert(res.data.message || "코드 수정에 실패했습니다.");
          }
        })
        .catch((e) => {
          console.error(e);
          alert(e?.message || "코드 수정에 실패했습니다.");
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      // add
      setIsLoading(true);
      axiosHook
        .post(`${apiServer}/code/add`, {
          parentCode,
          code,
          codeName,
          memo,
          useStatus,
          publicStatus,
        })
        .then((res) => {
          setIsLoading(false);
          if (res.data.ok) {
            setParentCodeParam("");
            setCodeParam(res.data.result.code);
            setIsCodeChanged(true);
          } else {
            alert(res.data.message || "코드 생성에 실패했습니다.");
          }
        })
        .catch((e) => {
          console.error(e);
          setIsLoading(false);
          alert(e?.message || "코드 생성에 실패했습니다.");
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  if (error) {
    return <MsgBox msg={error} bg="danger" />;
  }

  return (
    <div>
      <h4 className="h4">{codeParam ? "코드 수정" : "코드 생성"}</h4>
      {/* {`bootstrap colors: primary, secondary, success, danger, warning, info, light, dark`} */}
      <button className="btn btn-success col-12" onClick={handleFormReset}>
        최상위 신규 생성(초기화)
      </button>
      <hr />
      <Form>
        <div className="mb-3">
          <label className="form-label">부모 코드</label>
          <br />
          <label
            className="form-label text-muted fst-italic"
            style={{ fontSize: "90%" }}
          >
            * 최상위 코드는 선택 안함
          </label>
          <select
            id="sel-parent-code"
            className="form-select"
            value={parentCode || ""}
            readOnly={!codeParam} /** 수정모드 일때만 수정 가능 */
            disabled={!codeParam} /** 수정모드 일때만 수정 가능 */
            onChange={(e) => {
              setParentCode(e.target.value);
            }}
          >
            <option value="">선택 안함</option>
            {sameLevelCodes?.map((codeObj) => {
              return (
                <option key={codeObj.code} value={codeObj.code}>
                  {codeObj.codeName}
                </option>
              );
            })}
          </select>
        </div>
        <div className="mb-3">
          <label className="form-label">코드</label>
          <br />
          <label
            className="form-label text-muted fst-italic"
            style={{ fontSize: "90%" }}
          >
            * 허용문자 : 영문, 숫자, -, _
          </label>
          <input
            type="text"
            className={`form-control ${codeNameError ? "border-danger" : ""}`}
            maxLength="20"
            value={code || ""}
            readOnly={codeParam} /** 코드는 수정할 수 없다. */
            disabled={codeParam} /** 코드는 수정할 수 없다. */
            onChange={(e) => {
              setCode(e.target.value);
              setCodeName(e.target.value);
            }}
          />
          {codeError && <div className="text-danger">코드를 입력해주세요.</div>}
        </div>
        <div className="mb-3">
          <label className="form-label">코드명</label>
          <input
            type="text"
            className={`form-control ${codeNameError ? "border-danger" : ""}`}
            value={codeName || ""}
            onChange={(event) => {
              setCodeName(event.target.value);
            }}
          />
          {codeNameError && (
            <div className="text-danger">코드명을 입력해주세요.</div>
          )}
        </div>
        <div className="mb-3">
          <label className="form-label">메모(선택)</label>
          <textarea
            className="form-control"
            value={memo || ""}
            onChange={(e) => {
              setMemo(e.target.value);
            }}
            rows="2"
          />
        </div>
        <div className="mb-3">
          <label className="form-label">사용여부</label>
          <select
            className={`form-select ${useStatusError ? "border-danger" : ""}`}
            value={useStatus || ""}
            onChange={(e) => {
              setUseStatus(e.target.value);
            }}
          >
            <option value="">::선택::</option>
            <option value="USP">대기</option>
            <option value="USY">사용</option>
            <option value="USN">사용안함</option>
            <option value="USD">삭제</option>
          </select>
        </div>
        <div className="mb-3">
          <label className="form-label">공개여부</label>
          <select
            className={`form-select ${
              publicStatusError ? "border-danger" : ""
            }`}
            value={publicStatus || ""}
            onChange={(e) => {
              setPublicStatus(e.target.value);
            }}
          >
            <option value="">::선택::</option>
            <option value="PSP">전체공개</option>
            <option value="PSM">부분공개</option>
            <option value="PSU">회원공개</option>
            <option value="PSB">관리자공개</option>
          </select>
        </div>

        <button
          className="btn btn-primary col-12"
          onClick={handleSubmit}
          disabled={codeParam && !isEdited()}
        >
          {codeParam ? "업데이트" : "신규등록"}
        </button>
        <button
          className="btn btn-warning mt-2 col-12"
          onClick={handleFormReset}
        >
          취소
        </button>
      </Form>
      <LoadingPopSpinner isLoading={isLoading} />
    </div>
  );
};

CodeForm.propTypes = {
  codeParam: propTypes.string,
  parentCode: propTypes.string,
};

CodeForm.defaultProps = {
  codeParam: "",
  parentCode: "",
};

export default CodeForm;
