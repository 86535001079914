import { faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { Delay, TaskCatchError } from "../../Common";
import LoadingPop from "../../components/LoadingPop";
import MsgBox from "../../components/MsgBox";
import CategoryItemManage from "../../components/categories/CategoryItemManage";
import CategoryModal from "../../components/categories/CategoryModal";
import GroupSel from "../../components/groups/GroupSel";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";

export default function CategoryManagePage() {
  const axiosToken = useAxiosPrivate();
  const queryParams = new URLSearchParams(window.location.search);
  const group = queryParams.get("group");
  const myGroups = useSelector((state) => state.auth?.myGroups);

  const [msg, setMsg] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [groupNo, setGroupNo] = useState(null);
  const [categoryList, setCategoryList] = useState([]);
  const [selCategoryId, setSelCategoryId] = useState(null);
  const [selectedLi, setSelectedLi] = useState(null);
  const [show, setShow] = useState(false);
  const [isCategoryDelete, setIsCategoryDelete] = useState(false);

  const categoryTitleRef = useRef();

  const resetCategorylistEvent = () => {
    document.querySelectorAll("#ul-list-category li").forEach((el) => {
      el.querySelector("input[type=radio]").checked = false;
      el.classList.remove("bg-selected-row");
    });
  };

  useEffect(() => {
    setTimeout(() => {
      categoryTitleRef.current.focus();
    }, 1000);
  }, [myGroups]);

  useEffect(() => {
    if (!myGroups || !myGroups.length) return;
    setGroupNo((group && parseInt(group)) || myGroups[0].groupNo);
  }, [myGroups, group]);

  // |이 코드는 useCallback을 사용하여 초기 카테고리를 설정하는 함수입니다.
  const setInitialCategory = useCallback(
    async (g) => {
      try {
        if (g) {
          await setCategoryList([]);
          await setGroupNo(g);
          const category = await axiosToken.get(`/myGroup/${g}/categories`); // abc는 로컬스토리지에서...
          if (category?.status === 200 && category?.data?.length) {
            if (category?.data === "error") return;
            if (category?.data?.length) {
              await setCategoryList(category.data);
            } else {
              await setCategoryList([]);
            }
          }
        }
      } catch (err) {
        TaskCatchError(err);
      }
    },
    [axiosToken]
  );

  useEffect(() => {
    async function init() {
      if (!myGroups || !groupNo) return;
      await setInitialCategory(groupNo);
    }
    init();
  }, [myGroups, groupNo, setInitialCategory]);

  useEffect(() => {
    Delay(1000).then(() => {
      document
        .querySelector(
          "#root > div > section > div > div.col-md-8.mt-4.mt-md-0 > h3"
        )
        .scrollIntoView({ behavior: "smooth", block: "center" });
    });
  }, []);

  const showNewCategoryModal = (e) => {
    e.preventDefault();
    resetCategorylistEvent();
    setSelCategoryId("newCategory");
    setShow(true);
  };

  const handleDelete = async (e) => {
    if (!selCategoryId || selCategoryId === "newCategory") {
      setMsg("삭제할 카테고리를 선택하세요.");
      return;
    }
    const msg =
      "삭제 하시겠습니까?\n해당 카테고리에 속하는 북마크도 함께 삭제됩니다.";
    if (!window.confirm(msg)) return;
    try {
      await LoadingPop(true);
      const url = `/category/delete`;
      const data = {
        _id: selCategoryId,
      };
      const res = await axiosToken.delete(url, { data });
      if (res && res.data === "ok") {
        await setIsCategoryDelete(true);
        await setInitialCategory(groupNo);
        await setShow(false);
        await setSelCategoryId(null);
        await setSelectedLi(null);
      } else {
        setMsg("삭제되지 않았습니다.");
      }
    } catch (err) {
      TaskCatchError(err);
    } finally {
      await LoadingPop(false);
    }
  };

  return (
    <section className="container-xl">
      <div className="row">
        <h2 className="h2">카테고리 관리</h2>
        <MsgBox msg={msg} />
        <div className="col-md">
          <div className="col-sm-12 col-lg-12 mb-4">
            <h3 className="h4 p-2 bg-gradient bg-dark bg-opacity-25 faj-center">
              그룹
            </h3>
            <GroupSel
              groupData={myGroups}
              groupNo={groupNo}
              setGroupNo={setGroupNo}
              setCategoryData={setCategoryList}
              setCategoryNo={setSelCategoryId}
            />
          </div>
        </div>
        <div className="col-md-8 mt-4 mt-md-0">
          <h3
            className="h4 p-2 bg-gradient bg-dark bg-opacity-25 faj-center"
            ref={categoryTitleRef}
          >
            카테고리 &nbsp;
            <span className="align-middle" title="카테고리 추가">
              <FontAwesomeIcon
                icon={faPlusCircle}
                size="lg"
                onClick={showNewCategoryModal}
              />
            </span>
          </h3>
          <CategoryItemManage
            categoryList={categoryList}
            selgroup={groupNo}
            selcategoryid={selCategoryId}
            setselcategoryid={setSelCategoryId}
            selectedli={selectedLi}
            setselectedli={setSelectedLi}
            resetCategorylistEvent={resetCategorylistEvent}
            setisloading={setIsLoading}
            handledelete={handleDelete}
            setshow={setShow}
            setInitialCategory={setInitialCategory}
          />
        </div>
        <LoadingPop isLoading={isLoading} />
      </div>
      <CategoryModal
        selgroup={groupNo}
        setselgroup={setGroupNo}
        selcategoryid={selCategoryId}
        setselcategoryid={setSelCategoryId}
        setinitialcategory={setInitialCategory}
        setIsLoading={setIsLoading}
        isloading={isLoading}
        handledelete={handleDelete}
        show={show}
        setshow={setShow}
        isCategoryDelete={isCategoryDelete}
        setIsCategoryDelete={setIsCategoryDelete}
      />
    </section>
  );
}
