import propTypes from "prop-types";

const CodeTreeItem = ({
  codeObj,
  code,
  setCode,
  setParentCode,
  parentCode,
}) => {
  const depthBlank = `${codeObj.depth}rem`;

  return (
    <>
      <div
        key={codeObj.code}
        className="code-tree-div"
        data-parent-code={codeObj.parentCode}
        data-code={codeObj.code}
      >
        <span style={{ paddingInline: `${depthBlank}` }}></span>
        <span
          className={`mb-1 cursor-pointer ${
            codeObj.useStatus === "USN"
              ? "text-decoration-line-through"
              : codeObj.useStatus === "USD"
              ? "text-decoration-line-through fst-italic text-muted"
              : codeObj.useStatus === "USP"
              ? "text-success"
              : ""
          } ${
            code === codeObj.code ? "text-light fw-bolder text-bg-info" : ""
          }`}
          onClick={(e) => {
            e.stopPropagation();
            setCode(codeObj.code);
            setParentCode("");
          }}
        >
          {codeObj.codeName}
        </span>
        &nbsp;
        <span
          className={`mb-1 cursor-pointer
              ${
                parentCode === codeObj.code ? "fw-bolder text-bg-secondary" : ""
              }`}
          onClick={(e) => {
            e.stopPropagation();
            setCode("");
            setParentCode(codeObj.code);
          }}
        >
          {parentCode === codeObj.code ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              className="bi bi-folder2-open"
              viewBox="0 0 16 16"
            >
              <path d="M1 3.5A1.5 1.5 0 0 1 2.5 2h2.764c.958 0 1.76.56 2.311 1.184C7.985 3.648 8.48 4 9 4h4.5A1.5 1.5 0 0 1 15 5.5v.64c.57.265.94.876.856 1.546l-.64 5.124A2.5 2.5 0 0 1 12.733 15H3.266a2.5 2.5 0 0 1-2.481-2.19l-.64-5.124A1.5 1.5 0 0 1 1 6.14V3.5zM2 6h12v-.5a.5.5 0 0 0-.5-.5H9c-.964 0-1.71-.629-2.174-1.154C6.374 3.334 5.82 3 5.264 3H2.5a.5.5 0 0 0-.5.5V6zm-.367 1a.5.5 0 0 0-.496.562l.64 5.124A1.5 1.5 0 0 0 3.266 14h9.468a1.5 1.5 0 0 0 1.489-1.314l.64-5.124A.5.5 0 0 0 14.367 7H1.633z" />
            </svg>
          ) : (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              className="bi bi-folder-plus"
              viewBox="0 0 16 16"
            >
              <path d="m.5 3 .04.87a1.99 1.99 0 0 0-.342 1.311l.637 7A2 2 0 0 0 2.826 14H9v-1H2.826a1 1 0 0 1-.995-.91l-.637-7A1 1 0 0 1 2.19 4h11.62a1 1 0 0 1 .996 1.09L14.54 8h1.005l.256-2.819A2 2 0 0 0 13.81 3H9.828a2 2 0 0 1-1.414-.586l-.828-.828A2 2 0 0 0 6.172 1H2.5a2 2 0 0 0-2 2Zm5.672-1a1 1 0 0 1 .707.293L7.586 3H2.19c-.24 0-.47.042-.683.12L1.5 2.98a1 1 0 0 1 1-.98h3.672Z" />
              <path d="M13.5 9a.5.5 0 0 1 .5.5V11h1.5a.5.5 0 1 1 0 1H14v1.5a.5.5 0 1 1-1 0V12h-1.5a.5.5 0 0 1 0-1H13V9.5a.5.5 0 0 1 .5-.5Z" />
            </svg>
          )}
          {/*  */}
        </span>
      </div>
      {codeObj?.children?.map((child) => {
        return (
          <CodeTreeItem
            key={child.code}
            codeObj={child}
            code={code}
            setCode={setCode}
            setParentCode={setParentCode}
            parentCode={parentCode}
          />
        );
      })}
    </>
  );
};

CodeTreeItem.propTypes = {
  isManagePage: propTypes.bool,
};

CodeTreeItem.defaultProps = {
  isManagePage: false,
};

export default CodeTreeItem;
