import { useEffect, useState } from "react";
import Masonry from "react-masonry-css";
import { useParams } from "react-router-dom";
import { TaskCatchError } from "../../Common";
import { axiosPrivate } from "../../api/axios";

export default function ShareCategoryPage() {
  const { categoryId } = useParams();

  const [bookmarkList, setBookmarkList] = useState([]);
  let numberingIdx = 1;

  useEffect(() => {
    if (!categoryId) return;
    const initData = async () => {
      try {
        const category = await axiosPrivate.get(`/openCategory/${categoryId}`);
        if (category?.status === 200 && category.data) {
          if (category.data === "error") return;
          const isPublic = category.data.isPublic;
          const memo = category.data.memo;
          let memoHtml = "";
          if (memo) {
            // bootstrap italic: <em></em>
            memoHtml = `<p className="text-muted fs-6 mb-0"><em>(${memo})</em></p>`;
          }
          if (!isPublic) {
            const categoryName = "공유된 북마크가 아닙니다.";
            document.title = categoryName + " - startmypage.com";
            document.querySelector(
              "#root > div > header > div > div.flex-grow-1"
            ).innerHTML = categoryName + memoHtml;
          } else {
            const categoryName = await category.data.categoryName;
            document.title = categoryName + " - startmypage.com";
            document.querySelector(
              "#root > div > header > div > div.flex-grow-1"
            ).innerHTML = categoryName + memoHtml;

            const bookmarks = await axiosPrivate.get(
              `/openCategory/${categoryId}/bookmarks`
            );
            if (
              bookmarks?.status === 200 &&
              bookmarks.data &&
              bookmarks.data?.length
            ) {
              if (bookmarks.data === "error") return;
              await setBookmarkList(bookmarks.data);
            }
          }
        }
      } catch (err) {
        TaskCatchError(err);
      } finally {
        window.scrollTo(0, 0);
      }
    };
    initData();
  }, [categoryId]);

  const bookmaks = bookmarkList?.map(function (item, idx) {
    const id = item._id,
      url = item.bookmarkUri,
      isImportantClass = item.isImportant ? "txt-important fs-5" : "",
      isLineThroughClass = item.isLineThrough ? "txt-line-through" : "",
      name =
        '<strong class="' +
        isImportantClass +
        isLineThroughClass +
        '"' +
        ' style="color: ' +
        item.color +
        '">' +
        numberingIdx++ +
        ". " +
        item.bookmarkName +
        "</strong>",
      memo = item.memo?.trim()
        ? `<br /><small className="fw-normal fst-italic text-muted">${item?.memo?.replaceAll(
            "\n",
            "<br />"
          )}</small>`
        : "";
    const htmlContent = `${name}${memo}`;
    return (
      <p
        key={idx}
        className="m-2 p-3 list-group-item cursor-pointer"
        title={item.bookmarkName}
        onMouseOver={(e) =>
          (e.target.style.backgroundColor = "rgb(250, 226, 177, 0.5)")
        }
        onMouseOut={(e) => (e.target.style.backgroundColor = "")}
        onClick={(e) => {
          e.target.classList.add("mark-moccasin");
          window.open(url);
        }}
      >
        <span
          className=""
          data-bookmarkno={id}
          dangerouslySetInnerHTML={{ __html: htmlContent }}
        />
      </p>
    );
  });

  const myBreakpointsAndCols = {
    default: 2,
    1100: 2,
    800: 1,
  };

  return (
    <section className="container-xl py-2">
      <Masonry
        breakpointCols={myBreakpointsAndCols}
        className="my-masonry-grid"
        columnClassName="my-masonry-grid_column"
      >
        <div className={bookmarkList?.length ? "" : "d-none"}>{bookmaks}</div>
      </Masonry>
    </section>
  );
}
