/**
 * TEST ONLY
*/
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { AddComma, IsValidValue } from "../../Common";
import LoadingPopSpinner from "../../components/LoadingPopSpinner";
import LinkFormModal from "../../components/enj/LinkFormModal";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import useToast from "../../hooks/useToast";
import {
  initLink,
  initLinks,
  initModalShow,
  resetLink,
} from "../../store/enjSlice";
import "./enj.css";

const EnjLinkPage = () => {
  const dispatch = useDispatch();
  const { addToast } = useToast();
  const apiServer = process.env.REACT_APP_API_DOAMIN;
  const axiosToken = useAxiosPrivate();

  const links = useSelector((state) => state.enj?.links);
  const link = useSelector((state) => state.enj?.link);
  const company = useSelector((state) => state.enj?.company);
  const employee = useSelector((state) => state.enj?.employee);

  // 도메인
  const domainSb = useSelector((state) => state.enjDomainCode?.domainSb);
  const domainKI = useSelector((state) => state.enjDomainCode?.domainKI);
  // const domainNabiya = useSelector(
  //   (state) => state.enjDomainCode?.domainNabiya
  // );
  // const domainHam = useSelector((state) => state.enjDomainCode?.domainHam);
  // const domainHub = useSelector((state) => state.enjDomainCode?.domainHub);
  // const domainIJA = useSelector((state) => state.enjDomainCode?.domainIJA);
  // const domainPD = useSelector((state) => state.enjDomainCode?.domainPD);
  // const domainAVDB = useSelector((state) => state.enjDomainCode?.domainAVDB);
  // const domainMSAV = useSelector((state) => state.enjDomainCode?.domainMSAV);
  // const domainSVD = useSelector((state) => state.enjDomainCode?.domainSVD);

  const [isLoading, setIsLoading] = useState(false);
  const [sbMid, setSbMid] = useState("");

  /** 직원 typeCode가 변경될 때마다 sbam mid 변경 */
  useEffect(() => {
    if (company?.typeCode === "gunma") {
      setSbMid("sjoy3");
    } else if (company?.typeCode === "kiss") {
      setSbMid("sjoy0");
    }
  }, [company?.typeCode]);

  /**
   * 특정 직원의 모든 링크 목록 가져오기
   */
  useEffect(() => {
    const getLinks = async () => {
      if (!employee?._id) return;
      const res = await axiosToken.get(
        `${apiServer}/enjLinks/${employee?._id}`
      );
      dispatch(initLinks(res.data));
    };
    getLinks();
  }, [axiosToken, apiServer, employee?._id, dispatch]);

  const handleDeleteLinkClick = async (e, _id) => {
    e.preventDefault();
    if (!IsValidValue(_id)) {
      addToast({
        type: "danger",
        text: "선택된 객체가 올바르지 않습니다.",
      });
      return;
    }
    if (!window.confirm("삭제하시겠습니까?")) return;
    setIsLoading(true);
    await axiosToken
      .delete(`${apiServer}/enjLink/delete`, {
        data: { _id },
      })
      .then((res) => {
        if (res.data.ok) {
          dispatch(initModalShow(false));
          addToast({
            type: "success",
            text: res.data.message || "link 삭제 성공",
          });
          /** links 에서 삭제된 아이템 없이 재할당 하기
           * dispatch(initLinks()),
           */
          const data = links.filter((d) => d._id !== _id);
          dispatch(initLinks(data));
        } else {
          addToast({
            type: "danger",
            text: res.data.message || "link 삭제 실패",
          });
        }
      })
      .catch((e) => {
        console.error(e);
        addToast({
          type: "danger",
          text: e.message || "link 삭제 실패",
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <>
      <ul className="nav nav-pills nav-fill text-nowrap flex-nowrap list-group list-group-horizontal overflow-auto justify-content-center border pb-2 my-2">
        <li className="mt-2">
          <Link
            className={`nav-link active`}
            style={{ backgroundColor: "#dc67c2" }}
            to="/enj"
          >
            홈
          </Link>
        </li>
        <li className="mt-2">
          <Link className={`nav-link`} to="/enj/movie">
            영상
          </Link>
        </li>
        <li className="mt-2">
          <Link className={`nav-link`} to="/enj/code">
            코드
          </Link>
        </li>
      </ul>
      {/** 직원 정보 */}
      <div className="col-12 py-3">
        <h2>직원 정보</h2>
        {/** employee 정보를 보기 좋게 나열한다. */}
        <div className="row border rounded p-3" id="dvEmployeeInfo">
          <div
            className={`col-auto fw-bold 
          ${employee?.isLineThrough ? "text-decoration-line-through" : ""}`}
            style={{
              color: employee?.employeeColor || "",
              fontSize: employee?.isImportant ? "1.5rem" : "",
            }}
          >
            {employee?.employeeName}
          </div>
          <div className={`col-auto`}>
            <Link to={`/enj/company/${company?._id}`}>
              {company?.companyName}
            </Link>
          </div>
          <div
            className={`col-auto ${
              IsValidValue(employee?.height) ? "" : "d-none"
            }`}
          >
            {employee?.height}
          </div>
          <div
            className={`col-auto ${
              IsValidValue(employee?.weight) ? "" : "d-none"
            }`}
          >
            {employee?.weight}
          </div>
          <div
            className={`col-auto ${
              IsValidValue(employee?.chest) ? "" : "d-none"
            }`}
          >
            {employee?.chest}
          </div>
          <div
            className={`col-auto ${
              IsValidValue(employee?.currentPrice) ? "" : "d-none"
            }`}
          >
            {AddComma(employee?.currentPrice)}원
          </div>
          <div
            className={`col-auto ${
              IsValidValue(employee?.totalMeetCount) ? "" : "d-none"
            } 
            ${employee?.totalMeetCount > 1 ? "fw-bold" : 0}
            `}
          >
            총 미팅횟수: {employee?.totalMeetCount}
          </div>
          <div
            className={`col-auto ${
              IsValidValue(employee?.score) ? "" : "d-none"
            } `}
          >
            {employee?.score}점
          </div>
          {/** 메모
           * html 방식으로 출력하기 위해 innerHTML 사용
           */}
          <div
            className={`col-12 ${IsValidValue(employee?.memo) ? "" : "d-none"}`}
            dangerouslySetInnerHTML={{
              __html: "<hr />" + employee?.memo?.replaceAll("\n", "<br />"),
            }}
          >
          </div>
          {/** 관련 링크 */}
          <div>
            <hr className="pb-2" />
            <a
              rel="noreferrer"
              href={`${domainSb}/?_filter=search&act=&vid=&mid=${sbMid}&category=${
                company?.sbamReviewIdNo
              }&search_keyword=${encodeURIComponent(
                employee?.employeeName
              )}&search_target=extra_vars2`}
              target="_blank"
            >
              <button className="btn btn-outline-primary me-2 mb-2">
                {`스밤 '${employee?.employeeName}' 리뷰`}
              </button>
            </a>
            <a
              rel="noreferrer"
              href={`${domainKI}/yc/store_review?shop_name=${company?.companyName}&wr_4=${employee?.employeeName}`}
              target="_blank"
              className={`${company?.typeCode === "kiss" ? "" : "d-none"}`}
            >
              <button className="btn btn-outline-primary me-2 mb-2">
                {`키인포 '${employee?.employeeName}' 리뷰`}
              </button>
            </a>
          </div>
        </div>
      </div>
      {/** 링크 정보 */}
      <div className="col-12 my-2">
        <h2>링크 정보</h2>
      </div>
      <div className="col-12 mb-3">
        <LinkFormModal handleDeleteLinkClick={handleDeleteLinkClick} />
      </div>
      {/** 링크 리스트 */}
      <div className={`table-responsive text-nowrap`}>
        <table
          className="table table-sm table-hover align-middle text-center"
          id="linkTable"
        >
          <thead className="table-primary">
            <tr>
              <th style={{ width: "50px" }}>idx</th>
              <th style={{ width: "70px" }}>도메인</th>
              <th style={{ width: "auto" }}>제목</th>
              <th style={{ width: "auto" }}>Memo</th>
              <th style={{ width: "50px" }}>수정</th>
              <th style={{ width: "50px" }}>삭제</th>
            </tr>
          </thead>
          <tbody>
            {links?.map((item, index) => {
              return (
                <tr
                  key={index}
                  className={`${link?._id === item._id ? "bg-warning" : ""}`}
                  onClick={() => dispatch(initLink(item))}
                >
                  <td>{links.length - index}</td>
                  <td className="small">{item?.domain?.codeName}</td>
                  <td
                    title={item?.title}
                    style={{
                      maxWidth: "1px",
                      textAlign: "left",
                    }}
                  >
                    <a
                      href={`${item.domain?.memo}${item.uri}`}
                      rel="noreferrer"
                      target="_blank"
                      className={`${item.isImportant ? "fw-bold" : ""} ${
                        item.isLineThrough ? "text-decoration-line-through" : ""
                      }`}
                      style={{
                        color: item.linkColor,
                      }}
                    >
                      {item?.title}
                    </a>
                  </td>
                  <td
                    title={item?.memo}
                    className={`${item.isImportant ? "fw-bold" : ""} ${
                      item.isLineThrough ? "text-decoration-line-through" : ""
                    }`}
                    style={{
                      maxWidth: "1px",
                      textAlign: "left",
                    }}
                  >
                    <a
                      href={`${item.domain?.memo}${item.uri}`}
                      rel="noreferrer"
                      target="_blank"
                      className={`${item.isImportant ? "fw-bold" : ""} ${
                        item.isLineThrough ? "text-decoration-line-through" : ""
                      }`}
                      style={{
                        // color: item.linkColor,
                        color: "#333",
                      }}
                    >
                      {item?.memo}
                    </a>
                  </td>
                  <td>
                    <div
                      className="btn btn-outline-success btn-sm"
                      onClick={() => {
                        dispatch(resetLink());
                        dispatch(initLink(item));
                        dispatch(initModalShow(true));
                      }}
                    >
                      수정
                    </div>
                  </td>
                  <td>
                    <div
                      className="btn btn-outline-danger btn-sm"
                      onClick={(e) => handleDeleteLinkClick(e, item?._id)}
                    >
                      삭제
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <LoadingPopSpinner isLoading={isLoading} />
      <div style={{ height: "100px" }}></div>
    </>
  );
};

export default EnjLinkPage;
