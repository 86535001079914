import AllBookmarkManage from "../../components/admin/AllBookmarkManage";
import ErrorList from "../../components/admin/ErrorList";
import UsersManage from "../../components/admin/UsersManage";

export default function AdminPage() {
  return (
    <section className="container-xxl">
      <div className="row d-flex justify-content-center">
        <h2>최근 24시간 이슈</h2>
        <ErrorList />
        <div>&nbsp;</div>
        <UsersManage />
        <div>&nbsp;</div>
        <AllBookmarkManage />
      </div>
    </section>
  );
}
