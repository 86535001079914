import { faSquareShareNodes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import * as Icon from "react-bootstrap-icons";
import { Link } from "react-router-dom";
import { Delay, ScrollTo, TaskCatchError } from "../../Common";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import MsgBox from "../MsgBox";

export default function CategoryItemManage({
  categoryList,
  selgroup,
  selcategoryid,
  setselcategoryid,
  selectedli,
  setselectedli,
  resetCategorylistEvent,
  setisloading,
  handledelete,
  setshow,
  setInitialCategory,
}) {
  const [msg, setMsg] = useState("");
  const axiosToken = useAxiosPrivate();

  const handleCategoryRowClick = (e) => {
    resetCategorylistEvent();
    const target = e.target.closest("li");
    target.querySelector("input[type=radio]").checked = true;
    target.classList.add("bg-selected-row");
    setselectedli(target);
    setselcategoryid(target.dataset["id"]);
  };

  const moveTo = (e) => {
    if (!selectedli || !selcategoryid) {
      setMsg("카테고리 라디오버튼을 선택하세요.");
      return;
    }
    const ul = document.getElementById("ul-list-category");
    const direction = e.target.closest("button").dataset["direction"];
    if (direction === "top") {
      ul.insertBefore(selectedli, ul.firstChild);
    } else if (direction === "up") {
      const wrapperParent = selectedli.parentNode;
      const wrapperPervious = selectedli.previousElementSibling;
      if (wrapperPervious)
        wrapperParent.insertBefore(selectedli, wrapperPervious);
    } else if (direction === "down") {
      const wrapperParent = selectedli.parentNode;
      const wrapperNext = selectedli.nextElementSibling;
      if (wrapperNext) wrapperParent.insertBefore(wrapperNext, selectedli);
    } else if (direction === "bottom") {
      ul.insertBefore(selectedli, null);
    }
  };

  const handleSortSave = async (e) => {
    e.preventDefault();
    try {
      await setisloading(true);
      const data = [],
        arrLi = document.querySelectorAll("#ul-list-category li");
      if (!arrLi.length) {
        setMsg("저장할 카테고리가 없습니다.");
        return;
      }
      let idx = 0;
      await arrLi.forEach((li) => {
        data.push({
          _id: li.dataset["id"],
          sortNo: idx,
        });
        parseInt(idx++);
      });
      const url = `/category/edit/sort`;
      await axiosToken.put(url, data).then(() => {
        setisloading(false);
        ScrollTo(
          "#root > div > section > div > div.col-md-8.mt-4.mt-md-0 > h3"
        );
        setMsg("순서가 변경되었습니다.");
      });
      await Delay(2000).then(() => {
        setMsg("");
      });
    } catch (err) {
      TaskCatchError(err);
    } finally {
      setisloading(false);
    }
  };

  return (
    <>
      <MsgBox msg={msg} />
      <div
        style={{
          maxHeight: "calc(100vh - 430px)",
          overflowY: "auto",
          backgroundColor: "#f0f0f0",
        }}
      >
        <ul className="list-group" id="ul-list-category">
          {categoryList?.map((item) => {
            const groupNo = item.groupNo,
              categoryId = item._id,
              categoryNo = item.categoryNo,
              categoryName = item.categoryName,
              memo = item.memo ?? "",
              isImportantClass = item.isImportant ? "txt-important" : "",
              isLineThroughClass = item.isLineThrough ? "txt-line-through" : "";
            return (
              <li
                key={categoryId}
                // className={`list-group-item text-truncate ${categoryId === selcategoryid ? "bg-selected-row" : ""}`
                className={`list-group-item text-truncate`}
                data-id={item._id}
                onClick={handleCategoryRowClick}
              >
                <input
                  className="form-check-input me-3"
                  type="radio"
                  name="categoryRadios"
                  id={`category-${item.categoryNo}`}
                  value={categoryId}
                />
                <Icon.PencilSquare
                  className="align-middle me-3"
                  title="카테고리 수정"
                  onClick={() => {
                    setselcategoryid(categoryId);
                    setshow(true);
                  }}
                />
                <Link
                  className={`${item.isPublic ? "" : "d-none"} me-3`}
                  to={`/openCategory/${categoryId}/bookmarks`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FontAwesomeIcon
                    icon={faSquareShareNodes}
                    size="lg"
                    color="rgb(74, 116, 144)"
                  />
                </Link>
                <Link
                  to={`/bookmarkManage/?group=${groupNo}&category=${categoryNo}`}
                >
                  <span
                    className={`${isImportantClass} ${isLineThroughClass}`}
                    data-category-id={item.categoryId}
                  >
                    {categoryName}
                  </span>
                </Link>
                {memo && (
                  <div className="py-2">
                    <small
                      className="text-muted"
                      style={{ whiteSpace: "pre-line" }}
                    >
                      {memo}
                    </small>
                  </div>
                )}
              </li>
            );
          })}
        </ul>
      </div>
      <div className="btn-group col-12 mt-2">
        <button
          type="button"
          className="col btn btn-outline-secondary"
          onClick={moveTo}
          data-direction="top"
        >
          <Icon.ChevronDoubleUp />
        </button>
        <button
          type="button"
          className="col btn btn-outline-secondary"
          onClick={moveTo}
          data-direction="up"
        >
          <Icon.ChevronUp />
        </button>
        <button
          type="button"
          className="col btn btn-outline-secondary"
          onClick={moveTo}
          data-direction="down"
        >
          <Icon.ChevronDown />
        </button>
        <button
          type="button"
          className="col btn btn-outline-secondary"
          onClick={moveTo}
          data-direction="bottom"
        >
          <Icon.ChevronDoubleDown />
        </button>
      </div>
      <div className="btn-group col-12 mt-2">
        <button
          type="button"
          className="col btn btn-outline-danger"
          onClick={handledelete}
        >
          선택 삭제
        </button>
        <button
          type="button"
          className="col btn btn-outline-primary"
          onClick={handleSortSave}
        >
          순서 저장
        </button>
      </div>
    </>
  );
}
