/**
 * 추후에 validator, state 등을 이용해서 실시간 에러메시지 방식도 구현
 * https://www.geeksforgeeks.org/how-to-validate-url-in-reactjs/
 * https://gom20.tistory.com/160
 */
import { useCallback, useEffect, useRef, useState } from "react";
import { ButtonGroup, Col, Container, Row } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import { useSelector } from "react-redux";
import validator from "validator";
import { Delay, IsEnterkey, IsValidValue, TaskCatchError } from "../../Common";
import { CommonMessage } from "../../CommonMessage";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import LoadingPopSpinner from "../LoadingPopSpinner";
import MsgBox from "../MsgBox";

function BookmarkModal({
  groupNo,
  categoryNo,
  bookmarkId,
  show,
  setShow,
  initBookmarkDataList,
  handleBookmarkDelete,
  setPrevSelBookmarkId,
  categoryData,
}) {
  const axiosToken = useAxiosPrivate();

  const apiServer = process.env.REACT_APP_API_DOAMIN;

  const userId = useSelector((state) => state.auth?.userId);
  const myGroups = useSelector((state) => state.auth?.myGroups);

  const [msg, setMsg] = useState("");
  const [gNo, setGNo] = useState(groupNo);
  const [cNo, setCNo] = useState(categoryNo);
  const [cData, setCData] = useState([]); // 팝업에서 그룹을 변경하면 카테고리 데이타도 변경된다.
  const [bookmark, setBookmark] = useState(null);
  const [errorUriMessage, setUrierrorUriMessage] = useState("");
  const [isInvalidUri, setIsInvalidUri] = useState(false);
  const [isInvalidName, setIsInvalidName] = useState(false);
  const [color, setColor] = useState("#000000");
  const [previewUrl, setPreviewUrl] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const bookmarkNameRef = useRef();
  const bookmarkUriRef = useRef();

  const [isImportant, setIsImportant] = useState(false);
  const [isLineThrough, setIsLineThrough] = useState(false);
  const [isInvalidNameClass, setIsInvalidNameClass] = useState("");
  const [isImportantClass, setIsImportantClass] = useState("");
  const [isLineThroughClass, setIsLineThroughClass] = useState("");

  /**
   * isImportant 변경되면 isImportantClass 변경
   */
  useEffect(() => {
    setIsImportantClass(isImportant ? "text-important fw-bold" : "");
  }, [isImportant]);

  /**
   * isLineThrough 변경되면 isLineThroughClass 변경
   */
  useEffect(() => {
    setIsLineThroughClass(isLineThrough ? "txt-line-through" : "");
  }, [isLineThrough]);

  /**
   * isInvalidName 변경되면 isInvalidNameClass 변경
   */
  useEffect(() => {
    setIsInvalidNameClass(isInvalidName ? "invalid-box-border mb-1" : "mb-1");
  }, [isInvalidName]);

  useEffect(() => {
    if (!show) return;
    bookmarkUriRef?.current?.focus();
  }, [show]);

  const resetStates = useCallback(() => {
    setShow(false);
    setGNo(groupNo);
    setCData(categoryData);
    setCNo(categoryNo);
    setBookmark(null);
    setUrierrorUriMessage("");
    setIsInvalidUri(false);
    setIsInvalidName(false);
    setMsg("");
    setColor("#000000");
    setPreviewTitle("");
    setIsImportant(false);
    setIsLineThrough(false);
    setIsImportantClass("");
    setIsLineThroughClass("");
  }, [setShow, categoryData, groupNo, categoryNo]);

  useEffect(() => {
    const initMyGroup = async () => {
      try {
        if (!myGroups?.length) {
          setMsg(CommonMessage.requireGroupFirst);
          return;
        }
        if (!groupNo) return;
        await setCData(categoryData);
        await setCNo(categoryNo);
      } catch (err) {
        console.error("initMyGroup catch error => ", err);
      }
    };
    initMyGroup();
  }, [
    groupNo,
    categoryNo,
    categoryData,
    myGroups,
    setMsg,
    setCData,
    setCNo,
    axiosToken,
  ]);

  const uriValidate = useCallback((e) => {
    if (!e || !e.target) return;
    const value = e.target.value;
    if (value.trim() === "") {
      setUrierrorUriMessage("");
      setIsInvalidUri(false);
    } else {
      if (validator.isURL(value)) {
        setUrierrorUriMessage("유효한 주소입니다.");
        setIsInvalidUri(false);
        setPreviewUrl(value);
      } else {
        setUrierrorUriMessage("유효한 주소가 아닙니다.");
        setIsInvalidUri(true);
      }
    }
  }, []);

  const nameValidate = useCallback((e) => {
    if (!e || !e.target) return;
    const value = e.target.value;
    if (!value.trim()) {
      setIsInvalidName(true);
    } else {
      setIsInvalidName(false);
    }
  }, []);

  useEffect(() => {
    if (!groupNo) {
      setMsg(CommonMessage.requireGroupFirst);
      return;
    }
    setMsg("");
    setGNo(groupNo);
  }, [groupNo]);

  useEffect(() => {
    if (!categoryNo) {
      setMsg(CommonMessage.requireCategorySelect);
      return;
    }
    setMsg("");
  }, [categoryNo]);

  /** 바로 아래에서의 북마크 데이터 초기화는 삭제 직후 먹히지 않아서
   * 북마크 아이디가 변경되면 북마크 데이터를 초기화 하는 로직을 별도로 추가함!
   */
  useEffect(() => {
    setBookmark(null);
  }, [bookmarkId]);

  useEffect(() => {
    const initBookmarkData = async () => {
      if (!show) return;
      try {
        await setBookmark(null);
        if (bookmarkId && bookmarkId !== "newBookmark") {
          const book = await axiosToken.get(`/bookmark/${bookmarkId}`);
          if (book?.status === 200 && book?.data) {
            if (book.data === "error") return;
            await setBookmark(book.data);
            await setColor(book.data.color);
            await uriValidate(book.data.bookmarkUri);
          }
        }
      } catch (err) {
        console.error("initBookmarkData catch error => ", err);
        TaskCatchError(err);
      } finally {
        // await document.getElementById("bookmarkUri").focus();
      }
    };
    initBookmarkData();
  }, [bookmarkId, show, uriValidate, axiosToken]);

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    if (!bookmarkId) {
      setMsg("선택 된 북마크가 없습니다.");
      return;
    }
    try {
      const target = e.target.closest(".modal-dialog");
      const bookmarkName = target.querySelector("#bookmarkName"),
        bookmarkUri = target.querySelector("#bookmarkUri"),
        isImportant = target.querySelector("#chkImportant"),
        isLineThrough = target.querySelector("#chkLinethrough"),
        memo = target.querySelector("#bookmarkMemo");
      const data = {
        userId,
        groupNo: gNo,
        categoryNo: cNo,
        bookmarkUri: bookmarkUri.value,
        bookmarkName: bookmarkName.value,
        isImportant: isImportant.checked,
        isLineThrough: isLineThrough.checked,
        memo: memo.value,
        color,
      };

      // 공백 체크
      if (!data?.userId) {
        setMsg(
          "사용자 정보가 올바르지 않습니다.\n로그인 후 다시 시도해주세요."
        );
        return;
      }
      if (!data?.groupNo) {
        setMsg("그룹 정보가 올바르지 않습니다.");
        return;
      }
      if (!data?.categoryNo) {
        setMsg("카테고리 정보가 올바르지 않습니다.");
        return;
      }
      if (!data?.bookmarkUri) {
        setIsInvalidUri(true);
        bookmarkUri.focus();
        return;
      }
      if (!data?.bookmarkName) {
        setIsInvalidName(true);
        bookmarkName.focus();
        return;
      }

      // 유효성 체크
      if (!parseInt(data?.groupNo)) {
        setMsg("유효한 그룹 정보가 아닙니다.");
        return;
      }
      if (!parseInt(data?.categoryNo)) {
        setMsg("유효한 카테고리 정보가 아닙니다.");
        return;
      }
      if (isInvalidUri) {
        //setMsg('유효한 주소가 아닙니다.')
        setUrierrorUriMessage("유효한 주소가 아닙니다.");
        bookmarkUri.focus();
        return;
      }
      let res = null;
      if (bookmarkId === "newBookmark") {
        // data?._id = null // 사용금지!!
        // _id를 직접 null로 지정하면 생성된 _id 값을 null로 반환한다.
        res = await axiosToken.post(`/bookmark/add`, data);
      } else {
        data._id = bookmarkId;
        res = await axiosToken.put(`/bookmark/edit`, data);
      }
      if (res?.data === "error") {
        setMsg("북마크가 저장되지 않았습니다.");
        return;
      }
      await initBookmarkDataList();
      const taskId =
        (await bookmarkId) === "newBookmark" ? res?.data?.result : bookmarkId;
      if (setPrevSelBookmarkId) await setPrevSelBookmarkId(taskId);
      await resetStates();
      await setShow(false);
      Delay(1000).then(() => {
        // myBookmark용
        const mybookmark = document.querySelector(
          `#root > div > section > div.my-masonry-grid > div p[data-bookmarkid="${taskId}"]`
        );
        if (mybookmark) {
          mybookmark.classList.add("mark-moccasin");
          mybookmark.scrollIntoView({
            behavior: "smooth",
            block: "center",
            inline: "center",
          });
        }
        // 북마크 매니저용
        const managebookmark = document.querySelector(
          `#ul-list-bookmark > li[data-id="${taskId}"]`
        );
        if (managebookmark) {
          managebookmark.scrollIntoView({
            behavior: "smooth",
            block: "center",
            inline: "center",
          });
          managebookmark.click();
        }
      });
    } catch (err) {
      setMsg("북마크가 저장되지 않았습니다.");
      console.error("submit error => ", err);
      TaskCatchError(err);
    } finally {
      setMsg("");
    }
  };

  const handleChkSubmit = (e) => {
    if (IsEnterkey(e)) handleFormSubmit(e);
  };

  const handleGroupChange = async (e) => {
    e.preventDefault();
    if (e.target.value) {
      if (e.target.value === "newGroup") return;
      const sGroup = e.target.value;
      await setGNo(sGroup);
      await setCData([]);
      try {
        const categories = await axiosToken.get(
          `/myGroup/${sGroup}/categories`
        );
        if (categories?.status === 200 && categories?.data.length) {
          if (categories.data === "error") return;
          await setCData(categories.data);
          const categoryno = await categories.data[0].categoryNo;
          // cNo 변경해줘도 select 의 선택값이 변경되지 않는다. 방법 물색중...
          document.querySelector("#bookmarkCategory").value = categoryno;
          setCNo(categoryno);
        }
      } catch (err) {
        console.error("group change error => ", err);
        TaskCatchError(err);
      }
    }
  };

  const handleCateogryChange = async (e) => {
    e.preventDefault();
    if (e.target.value) {
      if (e.target.value === "newGroup") return;
      try {
        const sCategory = e.target.value;
        await setCNo(sCategory);
      } catch (err) {
        console.error("handleCateogryChange error => ", err);
        TaskCatchError(err);
      }
    }
  };

  const fillUriTitle = async (e) => {
    if (bookmarkNameRef.current.value?.trim()) return;
    try {
      // const data = {
      //   uri: e.target.value?.trim(),
      // };
      // setIsLoading(true);
      // const res = await axiosToken.post(`/bookmark/getTitle`, data);
      const res = await axiosToken.get(
        `${apiServer}/bookmark/getTitle/${encodeURIComponent(
          e.target.value?.trim()
        )}`
      );
      if (IsValidValue(res.data) && res.data?.ok !== false) {
        bookmarkNameRef.current.value = res.data?.trim();
        setPreviewTitle(res.data?.trim());
      }
    } catch (err) {
      console.error("fillUriTitle error => ", err);
      TaskCatchError(err);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <Modal centered show={show} onHide={resetStates}>
        <Modal.Header closeButton>
          <Modal.Title>북마크 관리</Modal.Title>
        </Modal.Header>
        <MsgBox msg={msg} />
        <Modal.Body>
          <Form onSubmit={handleFormSubmit}>
            <Form.Group
              className={`mb-3 ${bookmarkId === "newBookmark" ? "d-none" : ""}`}
              controlId="bookmarkGroup"
            >
              <Form.Label>그룹을 선택하세요.</Form.Label>
              <Form.Select defaultValue={gNo} onChange={handleGroupChange}>
                {myGroups?.length &&
                  myGroups?.map((g) => {
                    return (
                      <option key={g._id} value={g.groupNo}>
                        {g.groupName}
                      </option>
                    );
                  })}
                {/* <option value="newGroup">:: 새 그룹 ::</option> */}
              </Form.Select>
            </Form.Group>
            <Form.Group className="mb-3 d-none" controlId="newGroupname">
              <Form.Control
                type="text"
                placeholder="새 그룹 명을 입력하세요."
              />
            </Form.Group>
            <Form.Group
              className={`mb-3 ${bookmarkId === "newBookmark" ? "d-none" : ""}`}
              controlId="bookmarkCategory"
            >
              <Form.Label>카테고리를 선택하세요.</Form.Label>
              <Form.Select defaultValue={cNo} onChange={handleCateogryChange}>
                {cData?.length &&
                  cData.map((c) => {
                    return (
                      <option key={c._id} value={c.categoryNo}>
                        {c.categoryName}
                      </option>
                    );
                  })}
                {/* <option value="newCategory">:: 새 카테고리 ::</option> */}
              </Form.Select>
            </Form.Group>
            <Form.Group className="mb-3 d-none" controlId="newCategoryname">
              <Form.Control
                type="text"
                placeholder="새 카테고리 명을 입력하세요"
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="bookmarkUri">
              <Form.Control
                type="url"
                ref={bookmarkUriRef}
                className={isInvalidUri ? `mb-1 invalid-box-border` : `mb-1`}
                placeholder="북마크 주소를 입력하세요."
                defaultValue={bookmark?.bookmarkUri}
                onChange={uriValidate}
                onKeyDown={handleChkSubmit}
                onBlur={fillUriTitle}
              />
              <small className="text-danger">{errorUriMessage}</small>
            </Form.Group>
            <div className="m-2">
              <a href={previewUrl} rel="noreferrer noopener" target="_blank">
                {previewTitle}
              </a>
            </div>
            <Form.Group className="mb-3" controlId="bookmarkName">
              <Form.Control
                type="text"
                ref={bookmarkNameRef}
                className={`${isInvalidNameClass} ${isImportantClass} ${isLineThroughClass}`}
                style={{ color }}
                placeholder="북마크 명을 입력하세요."
                defaultValue={bookmark?.bookmarkName}
                onBlur={nameValidate}
                onKeyDown={handleChkSubmit}
                onChange={(e) => setPreviewTitle(e.target.value)}
              />
              <ButtonGroup className="mt-2 d-flex align-items-center">
                <Form.Check
                  inline
                  label="중요"
                  type="checkbox"
                  id="chkImportant"
                  defaultChecked={bookmark?.isImportant}
                  onClick={(e) => setIsImportant(e.target.checked)}
                />
                <Form.Check
                  inline
                  label="취소선"
                  type="checkbox"
                  id="chkLinethrough"
                  defaultChecked={bookmark?.isLineThrough}
                  onClick={(e) => setIsLineThrough(e.target.checked)}
                />
                <input
                  type="color"
                  className="form-control form-control-color"
                  id="exampleColorInput"
                  title="Choose your color"
                  value={bookmark?.color || "#000000"}
                  onChange={(e) => setColor(e.target.value)}
                />
              </ButtonGroup>
              {/* <ButtonGroup className="mt-2 w-100">
                <HexColorPicker
                  className="w-100"
                  style={{ height: "80px" }}
                  color={bookmark?.color || "#000000"}
                  onChange={setColor}
                />
              </ButtonGroup> */}
            </Form.Group>
            <Form.Group className="mb-3" controlId="bookmarkMemo">
              <Form.Control
                as="textarea"
                rows={4}
                placeholder="메모를 입력하세요.(선택)"
                defaultValue={bookmark?.memo}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Container>
            <Row>
              <Col>
                <Button
                  variant="danger w-100"
                  type="button"
                  disabled={!bookmarkId || bookmarkId === "newBookmark"}
                  onClick={handleBookmarkDelete}
                >
                  삭제
                </Button>
              </Col>
              <Col>
                <Button
                  type="submit"
                  variant="primary w-100"
                  onClick={handleFormSubmit}
                >
                  저장
                </Button>
              </Col>
            </Row>
          </Container>
        </Modal.Footer>
      </Modal>
      <LoadingPopSpinner isLoading={isLoading} />
    </>
  );
}

export default BookmarkModal;
