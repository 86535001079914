import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  domainSb: "",
  domainKI: "",
  domainNabiya: "",
  domainMatong: "",
  domainHam: "",
  domainHub: "",
  domainIJA: "",
  domainPD: "",
  domainAVDB: "",
  domainMSAV: "",
  domainSVD: "",
};

const enjDomainCodeSlice = createSlice({
  name: "enjDomainCode",
  initialState,
  reducers: {
    initDomainSb: (state, action) => {
      state.domainSb = action.payload;
    },
    initDomainKI: (state, action) => {
      state.domainKI = action.payload;
    },
    initDomainNabiya: (state, action) => {
      state.domainNabiya = action.payload;
    },
    initDomainMatong: (state, action) => {
      state.domainMatong = action.payload;
    },
    initDomainHam: (state, action) => {
      state.domainHam = action.payload;
    },
    initDomainHub: (state, action) => {
      state.domainHub = action.payload;
    },
    initDomainIJA: (state, action) => {
      state.domainIJA = action.payload;
    },
    initDomainPD: (state, action) => {
      state.domainPD = action.payload;
    },
    initDomainAVDB: (state, action) => {
      state.domainAVDB = action.payload;
    },
    initDomainMSAV: (state, action) => {
      state.domainMSAV = action.payload;
    },
    initDomainSVD: (state, action) => {
      state.domainSVD = action.payload;
    },
  },
});

export const {
  initDomainSb,
  initDomainKI,
  initDomainNabiya,
  initDomainMatong,
  initDomainHam,
  initDomainHub,
  initDomainIJA,
  initDomainPD,
  initDomainAVDB,
  initDomainMSAV,
  initDomainSVD,
} = enjDomainCodeSlice.actions;

export default enjDomainCodeSlice.reducer;
