import { useDispatch } from "react-redux";
import { IsValidValue, TaskCatchError } from "../Common";
import { axiosPrivate } from "../api/axios";

const useRefreshToken = () => {
  const dispatch = useDispatch();

  try {
    const refresh = async () => {
      // 토큰 갱신 요청
      const response = await axiosPrivate.get("/user/refreshToken");
      const newAccessToken = response?.data?.result?.newAccessToken;
      //if (!newAccessToken || !userId || !userRoles) {
      if (!IsValidValue(newAccessToken)) {
        // 토큰 갱신 실패
        return { newAccessToken: "notCreatedToken" };
      } else {
        // 토큰 갱신 성공
        // config collection에서 userConfig 가져오기
        axiosPrivate.defaults.headers.common[
          "Authorization"
        ] = `Bearer ${newAccessToken}`;
        const userId = response?.data?.result?.userId,
          userRoles = response?.data?.result?.userRoles,
          provider = response?.data?.result?.provider;
        const responseConfig = await axiosPrivate.get("/myConfig");
        const {
          appTitle,
          startGroupNo,
          isAllowMultiDevice,
          // , target, theme, basicSort
        } = responseConfig?.data;
        const myGroups = await axiosPrivate.get("/myGroups");
        await dispatch({
          type: "auth/login",
          payload: {
            accessToken: newAccessToken,
            userId,
            userRoles,
            provider,
            appTitle,
            startGroupNo,
            isAllowMultiDevice,
            myGroups: myGroups?.data,
            // target,
            // theme,
            // basicSort,
          },
        });
      }
      return { newAccessToken };
    };
    return refresh;
  } catch (err) {
    TaskCatchError(err);
  }
};

export default useRefreshToken;
