/**
 * 현재 인증된 사용자의 _id 로 정보를 조회해서 화면에 표시한다.
 * 인증 _id가 없을 경우 로그인 화면으로 이동한다.
 * 닉네임은 변경할 수 있지만 30일에 1번으로 제한하고,
 * 혼란을 줄이기 위해 현재 사용중인 닉네임은 사용할 수 없다.
 * 자세한 사항은 backend의 usersSchema.js 참조
 */
import { useCallback, useEffect, useRef, useState } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { useSelector } from "react-redux";
import { Delay, ScrollTo } from "../../Common";
import { CommonMessage } from "../../CommonMessage";
import { NotAllowedNicknames } from "../../NotAllowedWord";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import LoadingPop from "../LoadingPop";
import MsgBox from "../MsgBox";

export default function MyInfoForm() {
  const axiosToken = useAxiosPrivate();

  const provider = useSelector((state) => state.auth?.provider);

  const [prevNickName, setPrevNickName] = useState("");
  const [nickName, setNickName] = useState("");
  const [nickNameChangeDate, setNickNameChangeDate] = useState("");
  const [loginId, setLoginId] = useState(null);
  const [email, setEmail] = useState(null);
  const [loginPass, setLoginPass] = useState("");
  const [loginPassConfirm, setLoginPassConfirm] = useState("");
  const [msg, setMsg] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const nickNameRef = useRef();
  const emailRef = useRef();
  const passwordConfirmRef = useRef();
  const passwordRef = useRef();

  const getUserInfo = useCallback(async () => {
    const res = await axiosToken.get("/user/myinfo");
    if (res?.data) {
      const data = res.data;
      const loginId = data?.loginId,
        nickName = data?.nickName,
        email = data?.email;
      setPrevNickName(nickName);
      setNickName(nickName);
      setEmail(email);
      // res.data?.nickNameChangeDate localTime으로 변환
      setNickNameChangeDate(
        res.data?.nickNameChangeDate
          ? new Date() < new Date(res.data?.nickNameChangeDate)
            ? new Date(res.data?.nickNameChangeDate).toLocaleString() +
              " 이후 가능"
            : "가능"
          : "가능"
      );
      setLoginId(loginId);
    }
  }, [axiosToken]);

  useEffect(() => {
    getUserInfo();
  }, [getUserInfo]);

  const handleSave = async (e) => {
    e.preventDefault();
    // 모든 focus() 초기화
    document.activeElement.blur();
    ScrollTo("#root > div > section > div > h2");
    if (!nickName) {
      setMsg("닉네임을 입력하세요.");
      nickNameRef.current.classList.add("invalid-box-border");
      return;
    }
    setMsg("");
    nickNameRef.current.classList.remove("invalid-box-border");
    if (nickName.length < 1 || nickName.length > 32) {
      setMsg(CommonMessage.nicknamePlaceholder);
      nickNameRef.current.classList.add("invalid-box-border");
      return;
    }
    setMsg("");
    nickNameRef.current.classList.remove("invalid-box-border");
    if (provider === "local") {
      // 비밀번호는 local provider만 수정 가능
      setMsg("");
      nickNameRef.current.classList.remove("invalid-box-border");
      if (!loginPass) {
        setMsg("새 비밀번호를 입력하세요.");
        passwordRef.current.classList.add("invalid-box-border");
        return;
      }
      setMsg("");
      passwordRef.current.classList.remove("invalid-box-border");
      if (loginPass.length < 4 || loginPass.length > 32) {
        setMsg(CommonMessage.passwordPlaceholder);
        passwordRef.current.classList.add("invalid-box-border");
        return;
      }
      setMsg("");
      passwordRef.current.classList.remove("invalid-box-border");
      if (!loginPassConfirm) {
        setMsg("새 비밀번호 확인을 입력하세요.");
        passwordConfirmRef.current.classList.add("invalid-box-border");
        return;
      }
      setMsg("");
      passwordConfirmRef.current.classList.remove("invalid-box-border");
      if (loginPass !== loginPassConfirm) {
        setMsg("새 비밀번호와 새 비밀번호 확인이 일치하지 않습니다.");
        passwordConfirmRef.current.classList.add("invalid-box-border");
        passwordConfirmRef.current.value = "";
        return;
      }
      setMsg("");
      passwordConfirmRef.current.classList.remove("invalid-box-border");
      if (!checkValidEmail(emailRef)) {
        return;
      }
      setMsg("");
      emailRef.current.classList.remove("invalid-box-border");
    }
    const data = {
      prevNickName,
      nickName,
      loginPass,
      loginPassConfirm,
      provider: provider,
      email,
    };
    // 소셜계정의 경우
    if (provider === "naver" || provider === "kakao" || provider === "google") {
      // 기존의 닉네임과 같으면 진행하지 않는다 */
      if (prevNickName === nickName) {
        setMsg("닉네임이 기존과 같습니다.");
        return;
      }
    } else if (provider === "local") {
      // 로컬계정의 경우 닉네임과 비밀번호가 기존과 같으면 진행하지 않는다.
      if (prevNickName === nickName && !loginPass) {
        setMsg("닉네임과 비밀번호가 기존과 같습니다.");
        return;
      }
    }
    setIsLoading(true);
    const res =
      provider === "local"
        ? await axiosToken.put("/user/localAccountEdit", data)
        : await axiosToken.put("/user/socialAccountEdit", data);
    if (res?.data?.ok) {
      await setMsg(res.data.message || "회원정보가 수정 되었습니다.");
      passwordRef.current.value = "";
      passwordConfirmRef.current.value = "";
      await getUserInfo();
      await setIsLoading(false);
      // fadeout after 3sec
      await Delay(3000).then(() => {
        setMsg("");
      });
    } else {
      setIsLoading(false);
      const failMsg =
        "회원정보 수정에 실패했습니다.\n\n" +
        "다시 시도해도 문제가 있으면 관리자에게 문의하세요.\n" +
        "- 하단의 연락하기 클릭.";
      setMsg(failMsg);
    }
  };

  /** 회원탈퇴 */
  const handleAccountRemove = async (e) => {
    e.preventDefault();
    const confirmMsg =
      "정말로 회원탈퇴 하시겠습니까?\n\n" +
      "탈퇴 시 모든 정보가 삭제되고\n" +
      "탈퇴 후에는 복구할 수 없습니다.";
    if (window.confirm(confirmMsg)) {
      const response = await axiosToken.delete("/user/remove");
      if (response?.data?.ok) {
        setMsg(response.data.message);
        window.location.href = "/signout";
      } else {
        const failMsg =
          "회원탈퇴에 실패했습니다.\n\n" +
          "다시 시도해도 문제가 있으면 관리자에게 문의하세요.\n" +
          "- 하단의 연락하기 클릭.";
        setMsg(failMsg);
      }
    }
  };

  /** 닉네임 체크
   * - 금지 닉네임 체크
   * - 한글, 영문, 숫자만 입력 가능
   * - 특수문자 불가 */
  const checkNickname = (e) => {
    setNickName(e.target.value);
    const regex = /^[ㄱ-ㅎ|가-힣|a-z|A-Z|0-9|\s]+$/;
    if (!regex.test(e.target.value)) {
      setMsg("닉네임은 한글, 영문, 숫자, 공백만 입력 가능합니다.");
      e.target.value = "";
      setNickName("");
      e.target.classList.add("invalid-box-border");
      return;
    }
    // 금지된 닉네임
    if (e.target.value && NotAllowedNicknames.includes(e.target.value)) {
      setMsg(CommonMessage.notAllowedNicknameAlert);
      e.target.value = "";
      setNickName(e.target.value);
      return;
    }
    // 통과하면 원래대로
    setMsg("");
    e.target.classList.remove("invalid-box-border");
  };

  // 이메일 유효성 체크
  const checkValidEmail = () => {
    emailRef.current.classList.remove("invalid-box-border");
    setMsg("");
    const regex = /^([a-zA-Z0-9_-]+)@([a-zA-Z0-9_-]+)(\.[a-zA-Z0-9_-]+){1,2}$/;
    if (!regex.test(emailRef?.current.value)) {
      setMsg("이메일 형식이 올바르지 않습니다.");
      emailRef.current.classList.add("invalid-box-border");
      emailRef.value = "";
      return false;
    }
    return true;
  };

  return (
    <>
      <MsgBox msg={msg} />
      <Form onSubmit={handleSave}>
        <Form.Group className="mb-3">
          <Form.Label>닉네임</Form.Label>
          <Form.Control
            type="text"
            ref={nickNameRef}
            placeholder={CommonMessage.nicknamePlaceholder}
            defaultValue={prevNickName}
            onChange={checkNickname}
            autoComplete="off"
          />
          <Form.Label className="form-text text-muted fst-italic">
            * 닉네임은 하루(24시간)에 1번만 변경할 수 있습니다.
            <br />* 닉네임 변경 가능 여부 : {nickNameChangeDate}
          </Form.Label>
        </Form.Group>
        <Form.Group className={provider === "local" ? `mb-3` : `d-none`}>
          <Form.Label>로그인 아이디</Form.Label>
          <Form.Control
            type="text"
            placeholder="로그인 아이디는 변경할 수 없습니다."
            value={loginId}
            autoComplete="off"
            disabled
            readOnly
          />
          <Form.Label className="form-text text-muted fst-italic">
            * 로그인 아이디는 변경할 수 없습니다.
          </Form.Label>
        </Form.Group>
        <Form.Group className={provider === "local" ? `mb-3` : `d-none`}>
          <Form.Label>새 비밀번호</Form.Label>
          <Form.Control
            type="password"
            ref={passwordRef}
            placeholder={CommonMessage.passwordPlaceholder}
            onChange={(e) => setLoginPass(e.target.value)}
          />
          <small className="form-text text-muted fst-italic">
            * 기존의 비밀번호를 사용해도 됩니다.
            <br />* 비밀번호는 단방향 해쉬화 되서 관리되므로 관리자도 알 수
            없습니다.
          </small>
        </Form.Group>
        <Form.Group className={provider === "local" ? `mb-3` : `d-none`}>
          <Form.Label>새 비밀번호 확인</Form.Label>
          <Form.Control
            type="password"
            ref={passwordConfirmRef}
            placeholder="새 비밀번호를 한번 더 입력하세요."
            onChange={(e) => setLoginPassConfirm(e.target.value)}
          />
        </Form.Group>
        {/* local provider 일때만 보이기 */}
        <Form.Group
          controlId="email"
          className={provider === "local" ? `mb-3` : `d-none`}
        >
          <Form.Label>이메일</Form.Label>
          <Form.Control
            type="text"
            placeholder={CommonMessage.emailPlaceholder}
            autoComplete="off"
            defaultValue={email}
            ref={emailRef}
            onChange={(e) => setEmail(e.target.value)}
            onBlur={checkValidEmail}
          />
        </Form.Group>
        <Button variant="outline-primary" className="col-12 fs-3" type="submit">
          저장
        </Button>
      </Form>
      <hr className="my-5" />
      <Form onSubmit={handleAccountRemove}>
        <Button variant="outline-danger" className="col-12 fs-6" type="submit">
          회원탈퇴
        </Button>
      </Form>
      <LoadingPop isLoading={isLoading} />
    </>
  );
}
