import propTypes from "prop-types";
import { useEffect, useState } from "react";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import LoadingPopSpinner from "../LoadingPopSpinner";
import MsgBox from "../MsgBox";
import CodeTreeItem from "./CodeTreeItem";

const CodeTree = ({
  code,
  setCode,
  parentCode,
  setParentCode,
  isCodeChanged,
  setIsCodeChanged,
}) => {
  const axiosHook = useAxiosPrivate();
  const [codes, setCodes] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [, setNumberOfCodes] = useState(0);
  const apiServer = process.env.REACT_APP_API_DOAMIN;
  const limit = 1000;

  useEffect(() => {
    setIsLoading(true);
    axiosHook
      .get(`${apiServer}/${limit}/code`)
      .then((res) => {
        setNumberOfCodes(res.data.length);
        setCodes(res.data);
      })
      .catch((e) => {
        console.error(e);
        setError("Something went wrong in database");
      })
      .finally(() => {
        setIsLoading(false);
        setIsCodeChanged(false);
      });
  }, [axiosHook, apiServer, isCodeChanged, setIsCodeChanged]);

  if (error) {
    return <MsgBox msg={error} bg="danger" />;
  }

  return (
    <>
      <div
        className="ps-2 list-group text-truncate"
        // style={{ maxHeight: "calc(100% - 80px)" }}
        // id="ul-list-bookmark"
      >
        {codes?.map((codeObj) => {
          return (
            <CodeTreeItem
              key={codeObj.code}
              codeObj={codeObj}
              code={code}
              setCode={setCode}
              setParentCode={setParentCode}
              parentCode={parentCode}
            />
          );
        })}
      </div>
      {/* <hr />
      <div
        className="mt-2 d-flex justify-content-between"
        style={{ backgroundColor: "#e7e8eb" }}
      >
        <div className="col-6 me-1 btn-group">
          <button
            type="button"
            className="col-6 btn btn-outline-secondary"
            onClick={moveTo}
            data-direction="up"
          >
            <span>
              <Icon.ChevronUp />
            </span>
          </button>
          <button
            type="button"
            className="col-6 me-2 btn btn-outline-secondary"
            onClick={moveTo}
            data-direction="down"
          >
            <span>
              <Icon.ChevronDown />
            </span>
          </button>
        </div>
        <div className="col-6">
          <button
            type="button"
            className="col-12 btn btn-outline-primary"
            onClick={handleSaveSort}
          >
            순서 저장
          </button>
        </div>
      </div> */}
      <LoadingPopSpinner isLoading={isLoading} />
    </>
  );
};

CodeTree.propTypes = {
  isManagePage: propTypes.bool,
};

CodeTree.defaultProps = {
  isManagePage: false,
};

export default CodeTree;
