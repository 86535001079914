import React, { useCallback, useEffect, useState } from "react";
import Masonry from "react-masonry-css";
import { useParams } from "react-router-dom";
import { TaskCatchError } from "../../Common";
import { axiosPrivate } from "../../api/axios";
import LoadingPop from "../../components/LoadingPop";
import OpenBookmarksByCategory from "../../components/bookmarks/OpenBookmarksByCategory";
import OpenBookmarksCategoryTitle from "../../components/bookmarks/OpenBookmarksCategoryTitle";

export default function ShareGroupPage() {
  const { groupId } = useParams();
  const [selGroupId, setSelGoupId] = useState(null);
  const [categoryList, setCategoryList] = useState([]);
  const [bookmarkList, setBookmarkList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setSelGoupId(groupId);
  }, [groupId]);

  const initBookmarkDataList = useCallback(async () => {
    if (!selGroupId) return;
    try {
      await setIsLoading(true);
      await setCategoryList([]);
      await setBookmarkList([]);
      const tempOpenCategroies = [];

      const group = await axiosPrivate.get(`/openGroup/${selGroupId}`);
      let groupName = "";
      if (group?.data?.isPublic) {
        if (group.data === "error") return;
        const memo = group.data.memo;
        // bootstrap fs options: fs-1, fs-2, fs-3, fs-4, fs-5, fs-6
        const memoHtml = memo
          ? `<p className="text-muted fs-6 mb-0"><em>(${memo.trim()})</em></p>`
          : "";
        groupName = group.data.groupName;
        document.title = groupName + " - startmypage.com";
        document.querySelector(
          "#root > div > header > div > div.flex-grow-1"
        ).innerHTML = groupName + memoHtml;

        const categoryList = await axiosPrivate.get(
          `/openGroup/${selGroupId}/categories`
        );
        if (
          categoryList?.status === 200 &&
          categoryList.data &&
          categoryList.data.length
        ) {
          const categoryListData = categoryList.data;
          await setCategoryList(categoryListData);
          // openCategries에 isPublic 카테고리 담기
          categoryListData.forEach((item) => {
            if (item.isPublic) tempOpenCategroies.push(item.categoryNo);
          });
        }

        const bookmarkList = await axiosPrivate.post(`/openGroup/bookmarks`, {
          groupId: selGroupId,
          openCategories: tempOpenCategroies,
        });
        if (
          bookmarkList?.status === 200 &&
          bookmarkList.data &&
          bookmarkList.data.length
        ) {
          if (bookmarkList.data === "error") return;
          await setBookmarkList(bookmarkList.data);
        }
      } else {
        groupName = "공유된 북마크가 아닙니다.";
        document.title = groupName + " - startmypage.com";
        document.querySelector(
          "#root > div > header > div > div.flex-grow-1"
        ).innerHTML = groupName;
      }
    } catch (err) {
      TaskCatchError(err);
    } finally {
      setIsLoading(false);
      window.scrollTo(0, 0);
    }
  }, [selGroupId]);

  useEffect(() => {
    if (selGroupId) initBookmarkDataList();
  }, [selGroupId, initBookmarkDataList]);

  const myBreakpointsAndCols = {
    default: 4,
    1100: 3,
    700: 2,
    500: 1,
  };

  // 모듈 형태로 넣으면 정렬이 비정상적으로 되서 변수 활용함!
  const BookItems = categoryList?.map(function (item, idx) {
    return (
      <div key={item._id}>
        <OpenBookmarksCategoryTitle key={item._id + idx} item={item} />
        <OpenBookmarksByCategory
          key={item._id + idx + item._id}
          categoryNo={item.categoryNo}
          bookmarkList={bookmarkList}
        />
      </div>
    );
  });

  return (
    <section className="container-xl py-2">
      <Masonry
        breakpointCols={myBreakpointsAndCols}
        className="my-masonry-grid"
        columnClassName="my-masonry-grid_column"
      >
        {BookItems}
      </Masonry>
      <LoadingPop isLoading={isLoading} />
    </section>
  );
}
