import { useEffect, useState } from "react";
import * as Icon from "react-bootstrap-icons";
import { Delay, ScrollTo, TaskCatchError } from "../../Common";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import MsgBox from "../MsgBox";

export default function BookmarkItemManage({
  bookmarkData,
  showBookmarkModal,
  handleBookmarkRowClick,
  selectedLi,
  bookmarkId,
  handleBookmarkDelete,
  setisloading,
}) {
  const [msg, setMsg] = useState("");
  const axiosToken = useAxiosPrivate();

  useEffect(() => {
    const autuScrollClickBookmark = async () => {
      let li = null;
      Delay(1000).then(() => {
        li = document.querySelector(
          `#ul-list-bookmark > li[data-id="${bookmarkId}"]`
        );
      });
      Delay(1500).then(() => {
        if (li) {
          li.scrollIntoView({ behavior: "smooth", block: "center" });
          li.click();
        }
      });
    };
    autuScrollClickBookmark();
  }, [bookmarkId]);

  const moveTo = (e) => {
    e.preventDefault();
    if (!selectedLi) {
      setMsg("북마크 라디오버튼을 선택하세요.");
      return;
    }
    const ul = document.getElementById("ul-list-bookmark");
    const direction = e.target.closest("button").getAttribute("data-direction");
    if (direction === "top") {
      ul.insertBefore(selectedLi, ul.firstChild);
    } else if (direction === "up") {
      const wrapperParent = selectedLi.parentNode;
      const wrapperPervious = selectedLi.previousElementSibling;
      if (wrapperPervious)
        wrapperParent.insertBefore(selectedLi, wrapperPervious);
    } else if (direction === "down") {
      const wrapperParent = selectedLi.parentNode;
      const wrapperNext = selectedLi.nextElementSibling;
      if (wrapperNext) wrapperParent.insertBefore(wrapperNext, selectedLi);
    } else if (direction === "bottom") {
      ul.insertBefore(selectedLi, null);
    }
  };

  const handleSortSave = async (e) => {
    e.preventDefault();
    try {
      await setisloading(true);
      const data = [],
        arrLi = document.querySelectorAll("#ul-list-bookmark li");
      if (!arrLi.length) {
        setMsg("저장할 북마크가 없습니다.");
        return;
      }
      let idx = 0;
      await arrLi.forEach((li) => {
        data.push({
          _id: li.dataset["id"],
          sortNo: idx,
        });
        parseInt(idx++);
      });
      const url = `/bookmark/edit/sort`;
      await axiosToken.put(url, data).then(() => {
        ScrollTo(
          "#root > div > section > div.row > div.col-md.mt-4.mt-md-0 > h3"
        );
        setMsg("순서가 변경되었습니다.");
      });
      // await setBookmarkData([]);
      // await initBookmarkData(categoryNo);
      await setTimeout(() => {
        document.querySelectorAll("#ul-list-bookmark li").forEach((el) => {
          if (el.dataset["id"] === bookmarkId) el.click();
        });
      }, 100);
      await Delay(2000).then(() => {
        setMsg("");
      });
    } catch (err) {
      ScrollTo(
        "#root > div > section > div.row > div.col-md.mt-4.mt-md-0 > h3"
      );
      setMsg("순서 변경에 실패했습니다.");
      TaskCatchError(err);
    } finally {
      await setisloading(false);
    }
  };

  const bookmarkClick = (e) => {
    e.preventDefault();
    window.open(e.target.closest("li")?.dataset["uri"]);
  };

  return (
    <>
      <MsgBox msg={msg} />
      <div
        style={{
          maxHeight: "calc(lc(100vh - 430px)",
          overflowY: "auto",
          backgroundColor: "#f0f0f0",
        }}
      >
        <ul className="list-group" id="ul-list-bookmark">
          {bookmarkData?.map((item) => {
            const bookmarkName = item.bookmarkName,
              bookmarkUri = item.bookmarkUri,
              isImportantClass = item.isImportant ? "txt-important" : "",
              isLineThroughClass = item.isLineThrough ? "txt-line-through" : "",
              memo = item.memo ?? "",
              color = item.color || "";
            return (
              <li
                key={item._id}
                className={`list-group-item text-truncate 
                ${bookmarkId === item._id ? "bg-selected-row" : ""}`}
                data-id={item._id}
                data-uri={bookmarkUri}
                onClick={handleBookmarkRowClick}
              >
                <input
                  className="form-check-input"
                  type="radio"
                  name="bookmarkRadios"
                />
                <span>
                  <Icon.PencilSquare
                    className="align-middle mx-2 cursor-pointer"
                    title="북마크 수정"
                    onClick={showBookmarkModal}
                  />
                </span>
                <span
                  className={`cursor-pointer text-primary ${isImportantClass} ${isLineThroughClass}`}
                  onClick={bookmarkClick}
                >
                  <span style={{ color: color }}>{bookmarkName}</span>
                </span>
                {memo && (
                  <div className="py-2">
                    <small
                      className={`text-muted ${isLineThroughClass}`}
                      style={{ whiteSpace: "pre-line" }}
                    >
                      {memo}
                    </small>
                  </div>
                )}
              </li>
            );
          })}
        </ul>
      </div>
      <div className="btn-group col-12 mt-2">
        <button
          type="button"
          className="col btn btn-outline-secondary"
          onClick={moveTo}
          data-direction="top"
        >
          <span>
            <Icon.ChevronDoubleUp />
          </span>
        </button>
        <button
          type="button"
          className="col btn btn-outline-secondary"
          onClick={moveTo}
          data-direction="up"
        >
          <span>
            <Icon.ChevronUp />
          </span>
        </button>
        <button
          type="button"
          className="col btn btn-outline-secondary"
          onClick={moveTo}
          data-direction="down"
        >
          <span>
            <Icon.ChevronDown />
          </span>
        </button>
        <button
          type="button"
          className="col btn btn-outline-secondary"
          onClick={moveTo}
          data-direction="bottom"
        >
          <span>
            <Icon.ChevronDoubleDown />
          </span>
        </button>
      </div>
      <div className="btn-group col-12 mt-2">
        <button
          type="button"
          className="col btn btn-outline-danger"
          onClick={handleBookmarkDelete}
        >
          선택 삭제
        </button>
        <button
          type="button"
          className="col btn btn-outline-primary"
          onClick={handleSortSave}
        >
          순서 저장
        </button>
      </div>
    </>
  );
}
