import { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { PassDateEasyText } from "../../Common";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import MsgBox from "../MsgBox";

export default function AllBookmarkManage({ count }) {
  const [msg, setMsg] = useState("");
  const [bookmarks, setBookmarks] = useState([]);
  const axiosToken = useAxiosPrivate();

  const resetStates = () => {
    setMsg("");
  };

  const getBookmarks = useCallback(async () => {
    await resetStates();
    let bookmarks = [];
    if (count) bookmarks = await axiosToken.get(`/${count}/bookmarks`);
    else bookmarks = await axiosToken.get(`/todayBookmarks`);
    if (bookmarks && bookmarks.status === 200 && bookmarks.data) {
      await setBookmarks(bookmarks.data);
    } else {
      setMsg("목록을 가져오는데 실패했습니다.");
    }
  }, [axiosToken, count]);

  useEffect(() => {
    getBookmarks();
  }, [getBookmarks]);

  return (
    <div className="row d-flex justify-content-center">
      <Link to="/admin/allBookmarkManage/100">
        <h2 className="h2">전체 북마크</h2>
      </Link>
      <MsgBox msg={msg} />
      <div className="col-md-7 mt-md-0">
        <div className="list-group">
          {bookmarks?.map((bookmark) => (
            <div
              className="list-group-item list-group-item-action py-3"
              key={bookmark._id}
            >
              <div className="d-flex w-100 justify-content-between">
                <p className="mb-0">
                  [{bookmark.bookmarkNo}]{" "}
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={bookmark.bookmarkUri}
                  >
                    <span className="fw-bold">{bookmark.bookmarkName}</span>
                    {bookmark.memo && ` - ${bookmark.memo}`}
                  </a>
                </p>
              </div>
              <small>
                {new Date(bookmark.updatedAt)?.toLocaleString()}{" "}
                <strong>
                  {" (" + PassDateEasyText(bookmark.updatedAt) + ")"}
                </strong>
                <br />
                <Link to={`/admin/userBookmarkManage/${bookmark.userId}/100`}>
                  {bookmark.userId}
                </Link>
              </small>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
