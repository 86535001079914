const LoadingPopSpinner = ({isLoading}) => {
  return (
    <div
      id="loading-image"
      className={isLoading ? "" : "d-none"}
    ></div>
  );
};

export default LoadingPopSpinner;
