import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { HandleAutoHeight, IsValidValue } from "../../Common";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import useToast from "../../hooks/useToast";
import {
  addEmployees,
  editEmployees,
  initEmployee,
  initModalShow,
  resetEmployee,
} from "../../store/enjSlice";
import LoadingPopSpinner from "../LoadingPopSpinner";

const EmployeeFormModal = ({ handleDeleteEmployeeClick }) => {
  const { addToast } = useToast();
  const dispatch = useDispatch();
  const apiServer = process.env.REACT_APP_API_DOAMIN;
  const axiosToken = useAxiosPrivate();
  /** Redux 전역 변수 */
  const employee = useSelector((state) => state.enj.employee);
  const companies = useSelector((state) => state.enj.companies);
  const company = useSelector((state) => state.enj.company);
  /** Modal States */
  const modalShow = useSelector((state) => state.enj.modalShow);
  const [isLoading, setIsLoading] = useState(false);
  /** Form States */
  const [newEmployee, setNewEmployee] = useState(null);
  /** Error States */
  const [companyError, setCompanyError] = useState("");
  const [employeNameError, setEmployeNameError] = useState("");

  /** employe가 변경되면 newEmploye에 깊은복사 한다. */
  useEffect(() => {
    if (employee?._id && modalShow) {
      setNewEmployee({ ...employee, companyId: employee?.companyId });
    } else {
      setNewEmployee({ companyId: company?._id });
    }
  }, [employee, modalShow, company?._id]);

  const resetModalForm = () => {
    dispatch(initModalShow(false));
    setNewEmployee({ companyId: company?._id });
    setCompanyError("");
    setEmployeNameError("");
  };

  /** validate */
  const validate = () => {
    let result = true;
    if (!IsValidValue(newEmployee?.companyId)) {
      setCompanyError("회사 선택이 올바르지 않습니다.");
      result = false;
    }
    if (!IsValidValue(newEmployee?.employeeName)) {
      setEmployeNameError("직원명이 올바르지 않습니다.");
      result = false;
    }
    return result;
  };

  /** handleSubmit */
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validate()) return;
    let isEdit = false;
    let apiUrl = "";
    // newEmployee._id가 있으면 수정, 없으면 신규등록
    if (IsValidValue(newEmployee?._id)) {
      isEdit = true;
      apiUrl = `${apiServer}/enjEmployee/edit`;
    } else {
      apiUrl = `${apiServer}/enjEmployee/add`;
    }
    try {
      setIsLoading(true);
      const res = isEdit
        ? await axiosToken.put(apiUrl, newEmployee)
        : await axiosToken.post(apiUrl, newEmployee);
      if (res?.data?.ok) {
        if (isEdit) {
          dispatch(editEmployees(res.data.result));
          dispatch(initEmployee(res.data.result));
          addToast({
            company: "success",
            text: res.data.message || "employe가 수정되었습니다.",
          });
        } else {
          dispatch(addEmployees(res.data.result));
          dispatch(initEmployee(res.data.result));
          addToast({
            company: "success",
            text: res.data.message || "새 employe가 생성되었습니다.",
          });
        }
        setTimeout(() => {
          /** [중요!!] 시간차를 두지 않으면 resetModalForm()이 반영되지 않는다!
           * - 실행 순서가 변경될 수도 있어서 그런 것 같음.
           */
          resetModalForm();
        }, 500);
      } else {
        if (isEdit) {
          addToast({
            company: "danger",
            text: res.data.message || "employe가 수정되지 않았습니다.",
          });
        } else {
          addToast({
            company: "danger",
            text: res.data.message || "employe가 생성되지 않았습니다.",
          });
        }
      }
    } catch (err) {
      console.error(err);
      addToast({
        company: "danger",
        text: err.message || "employee 생성/수정 중 오류가 발생하였습니다.",
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      {/** 모달 보기 버튼 */}
      <button
        className="btn btn-outline-primary w-100"
        onClick={() => {
          dispatch(resetEmployee());
          dispatch(initModalShow(true));
        }}
      >
        직원 등록
      </button>

      {/** 웹사이트 직원 등록폼
       * 타입, 지역, 도메인이 선택되지 않으면 저장, 추가 버튼 비활성화
       * 모든 폼을 Bootstrap 반응형으로 구성
       * 폼 간에 적당한 간격을 두고 구성
       */}
      <Modal
        animation={false}
        centered
        show={modalShow}
        onHide={resetModalForm}
      >
        <Modal.Header closeButton>
          <Modal.Title>{employee ? "직원 수정" : "새 직원 등록"}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/** [주의!!] <form></form> 을 Modal.Body 이외의 위치에 두면 에러 발생! */}
          <form>
            <div className="container">
              <div className="row">
                <div className="col-12 mb-2">
                  <div>
                    <select
                      className={`form-select ${
                        companyError ? "border border-danger" : ""
                      }`}
                      value={newEmployee?.companyId || ""}
                      onChange={(e) => {
                        setNewEmployee({
                          ...newEmployee,
                          companyId: e.target.value,
                        });
                      }}
                    >
                      <option value="">:: 회사 선택 ::</option>
                      {companies?.map((company, index) => {
                        return (
                          <option key={index} value={company?._id}>
                            {company.companyName}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  <div
                    className={`text-danger ${companyError ? "" : "d-none"}`}
                  >
                    * {companyError}
                  </div>
                </div>
                <div className="col-12 mb-2">
                  <input
                    type="text"
                    className={`form-control ${
                      newEmployee?.isImportant ? "fw-bold" : ""
                    } ${
                      newEmployee?.isLineThrough
                        ? "text-decoration-line-through"
                        : ""
                    } ${employeNameError ? "border border-danger" : ""}`}
                    style={{ color: newEmployee?.employeeColor }}
                    placeholder="직원명을 입력하세요."
                    value={newEmployee?.employeeName || ""}
                    onChange={(e) => {
                      setNewEmployee({
                        ...newEmployee,
                        employeeName: e.target.value,
                      });
                    }}
                  />
                  <div
                    className={`text-danger ${
                      employeNameError ? "" : "d-none"
                    }`}
                  >
                    * {employeNameError}
                  </div>
                </div>
                <div className="row mb-2">
                  <div className={`col-auto`}>
                    <input
                      type="checkbox"
                      className="btn-check"
                      id="ck-isImportant"
                      autoComplete="off"
                      checked={newEmployee?.isImportant || false}
                      // value={isImportant || false}
                      onChange={(e) => {
                        setNewEmployee({
                          ...newEmployee,
                          isImportant: e.target.checked,
                        });
                      }}
                    />
                    <label
                      className="btn btn-outline-primary"
                      htmlFor="ck-isImportant"
                    >
                      강조
                    </label>
                  </div>
                  <div className="col-auto p-0">
                    <input
                      type="checkbox"
                      className="btn-check"
                      id="cb-isLineThrough"
                      autoComplete="off"
                      checked={newEmployee?.isLineThrough || false}
                      // value={isLineThrough || false}
                      onChange={(e) => {
                        setNewEmployee({
                          ...newEmployee,
                          isLineThrough: e.target.checked,
                        });
                      }}
                    />
                    <label
                      className="btn btn-outline-primary"
                      htmlFor="cb-isLineThrough"
                    >
                      취소선
                    </label>
                  </div>
                  <div className="col-auto">
                    <input
                      type="color"
                      className="form-control form-control-color"
                      id="employeeColorInput"
                      title="Choose your color"
                      value={newEmployee?.employeeColor || "#000000"}
                      onChange={(e) => {
                        setNewEmployee({
                          ...newEmployee,
                          employeeColor: e.target.value,
                        });
                      }}
                    />
                  </div>
                </div>
                <div className="row mb-2">
                  {/**
                   * 키, 몸무게, 마음 입력폼
                   */}
                  <div className="col">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="마음"
                      value={newEmployee?.chest || ""}
                      onChange={(e) => {
                        setNewEmployee({
                          ...newEmployee,
                          chest: e.target.value,
                        });
                      }}
                    />
                  </div>
                  <div className="col-5">
                    <input
                      type="number"
                      className="form-control"
                      placeholder="키 (숫자만)"
                      value={newEmployee?.height || ""}
                      onChange={(e) => {
                        setNewEmployee({
                          ...newEmployee,
                          height: e.target.value,
                        });
                      }}
                    />
                  </div>
                  <div className="col">
                    <input
                      type="number"
                      className="form-control"
                      placeholder="몸무게 (숫자만)"
                      value={newEmployee?.weight || ""}
                      onChange={(e) => {
                        setNewEmployee({
                          ...newEmployee,
                          weight: e.target.value,
                        });
                      }}
                    />
                  </div>
                </div>
                <div className="row mb-2">
                  {/**
                   * 현재가, 미팅횟수, 점수 숫자 입력폼
                   */}
                  <div className="col-5">
                    <input
                      type="number"
                      className="form-control"
                      placeholder="현재가 (숫자만)"
                      value={newEmployee?.currentPrice || ""}
                      onChange={(e) => {
                        setNewEmployee({
                          ...newEmployee,
                          currentPrice: e.target.value,
                        });
                      }}
                    />
                  </div>
                  <div className="col">
                    <input
                      type="number"
                      className="form-control"
                      placeholder="미팅횟수 (숫자만)"
                      value={newEmployee?.totalMeetCount || ""}
                      onChange={(e) => {
                        setNewEmployee({
                          ...newEmployee,
                          totalMeetCount: e.target.value,
                        });
                      }}
                    />
                  </div>
                  <div className="col">
                    <input
                      type="number"
                      className="form-control"
                      placeholder="점수 (숫자만)"
                      value={newEmployee?.score || ""}
                      onChange={(e) => {
                        setNewEmployee({
                          ...newEmployee,
                          score: e.target.value,
                        });
                      }}
                    />
                  </div>
                </div>
                {/** memo */}
                <div className="mb-2">
                  <textarea
                    className="form-control"
                    rows={2}
                    placeholder="메모를 입력하세요."
                    value={newEmployee?.memo || ""}
                    onChange={(e) => {
                      setNewEmployee({ ...newEmployee, memo: e.target.value });
                    }}
                    onFocus={HandleAutoHeight}
                    onKeyUp={HandleAutoHeight}
                    onKeyDown={HandleAutoHeight}
                    onCut={HandleAutoHeight}
                    onPaste={HandleAutoHeight}
                  />
                </div>
              </div>
              <button
                type="submit"
                className="btn btn-outline-primary w-100 mb-2"
                onClick={handleSubmit}
              >
                {employee ? "직원 수정" : "신규 등록"}
              </button>
              <button
                className={`btn btn-outline-danger w-100
              ${employee ? "" : "d-none"}
              `}
                onClick={(e) => handleDeleteEmployeeClick(e, employee?._id)}
              >
                삭제
              </button>
            </div>
          </form>
        </Modal.Body>

        <Modal.Footer></Modal.Footer>
      </Modal>
      <LoadingPopSpinner isLoading={isLoading} />
    </>
  );
};

export default EmployeeFormModal;
