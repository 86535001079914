import { useNavigate } from "react-router-dom";

export default function UnAuthorizedPage() {
  const navigate = useNavigate();
  const goBack = () => navigate("/");

  return (
    <div className="py-5 my-5">
      <h1 className="h4 faj-center">접근 권한이 없습니다.</h1>
      <div className="faj-center pt-3">
        <button className="btn btn-warning" onClick={goBack}>
          Go Back
        </button>
      </div>
    </div>
  );
}
