import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { HandleAutoHeight, IsValidValue } from "../../Common";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import useToast from "../../hooks/useToast";
import { initDetailAreaCodes } from "../../store/enjCodeSlice";
import {
  addCompanies,
  editCompanies,
  initCompany,
  initCompanyModalShow,
} from "../../store/enjSlice";
import LoadingPopSpinner from "../LoadingPopSpinner";

const CompanyFormEditModal = ({ handleDeleteCompanyClick }) => {
  const { addToast } = useToast();
  const dispatch = useDispatch();
  const apiServer = process.env.REACT_APP_API_DOAMIN;
  const axiosToken = useAxiosPrivate();
  /** Redux 전역 변수 */
  const company = useSelector((state) => state.enj.company);
  const typeCodes = useSelector((state) => state.enjCode.typeCodes);
  const areaCodes = useSelector((state) => state.enjCode.areaCodes);
  const doorCodes = useSelector((state) => state.enjCode.doorCodes);
  const typeCode = useSelector((state) => state.enjCode.typeCode);
  const areaCode = useSelector((state) => state.enjCode.areaCode);
  const detailAreaCode = useSelector((state) => state.enjCode.detailAreaCode);
  const doorCode = useSelector((state) => state.enjCode.doorCode);
  /** Modal States */
  const companyModalShow = useSelector((state) => state.enj.companyModalShow);
  const [isLoading, setIsLoading] = useState(false);
  /** Form States */
  const [newCompany, setNewCompany] = useState({});
  const [localDetailAreaCodes, setLocalDetailAreaCodes] = useState([]);
  /** Error States */
  const [typeError, setTypeError] = useState("");
  const [areaError, setAreaError] = useState("");
  const [detailAreaError, setDetailAreaError] = useState("");
  const [companyNameError, setCompanyNameError] = useState("");

  /** company가 변경되면 newCompany에 깊은복사 한다. */
  useEffect(() => {
    if (company?._id && companyModalShow) {
      setNewCompany({
        _id: company?._id || "",
        typeCode: company?.typeCode || "",
        areaCode: company?.areaCode || "",
        detailAreaCode: company?.detailAreaCode || "",
        doorCode: company?.doorCode || "",
        companyName: company?.companyName || "",
        isImportant: company?.isImportant || false,
        isLineThrough: company?.isLineThrough || false,
        companyColor: company?.companyColor || "#4566b5",
        sbamScheduleIdNo: company?.sbamScheduleIdNo || "",
        sbamReviewIdNo: company?.sbamReviewIdNo || "",
        kinfoScheduleIdNo: company?.kinfoScheduleIdNo || "",
        nabiyaScheduleIdNo: company?.nabiyaScheduleIdNo || "",
        matongId: company?.matongId || "",
        memo: company?.memo || "",
        todayScheduled: company?.todayScheduled || "",
      });
    } else {
      setNewCompany(null);
    }
  }, [company, companyModalShow]);

  /** areaCode가 변경될 때마다 해당 코드의 하위코드들 detailAreas에 할당 */
  useEffect(() => {
    const fetchDetailAreas = async () => {
      if (!IsValidValue(newCompany?.areaCode)) {
        setLocalDetailAreaCodes([]);
        setNewCompany({ ...newCompany, detailAreaCode: "" });
        return;
      }
      setIsLoading(true);
      await axiosToken
        .get(`${apiServer}/manage/codesChildCodes/${newCompany?.areaCode}`)
        .then((res) => {
          const data = res.data;
          // parentCode 가 seoul의 children인 결과들 detailAreas에 담기
          setLocalDetailAreaCodes(data);
        })
        .catch((e) => {
          console.error(e);
          addToast({
            type: "danger",
            text: e.message || "detailAreas를 가져오지 못했습니다.",
          });
        })
        .finally(() => {
          setIsLoading(false);
        });
    };
    fetchDetailAreas();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [axiosToken, apiServer, dispatch, newCompany?.areaCode]);

  const resetModalForm = () => {
    dispatch(initCompanyModalShow(false));
    setNewCompany({});
    setTypeError("");
    setAreaError("");
    setDetailAreaError("");
    setCompanyNameError("");
  };

  /** areaCode가 변경될 때마다 해당 코드의 하위코드들 detailAreas에 할당 */
  useEffect(() => {
    const fetchDetailAreas = async () => {
      if (!IsValidValue(areaCode)) return;
      setIsLoading(true);
      await axiosToken
        .get(`${apiServer}/manage/codesChildCodes/${areaCode}`)
        .then((res) => {
          const data = res.data;
          // parentCode 가 seoul의 children인 결과들 detailAreas에 담기
          dispatch(initDetailAreaCodes(data));
        })
        .catch((e) => {
          console.error(e);
          addToast({
            type: "danger",
            text: e.message || "detailAreas를 가져오지 못했습니다.",
          });
        })
        .finally(() => {
          setIsLoading(false);
        });
    };
    fetchDetailAreas();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [axiosToken, apiServer, areaCode, dispatch]);

  /** typCode, areaCode, domainCode가 변경되면
   * 모달 내의 코드들도 함께 반영
   * 새로운 company를 등록할 때만 반영
   */
  useEffect(() => {
    const initFormValues = () => {
      if (!companyModalShow) return;
      if (!company?._id) {
        setNewCompany({
          ...newCompany,
          typeCode,
          areaCode,
          detailAreaCode,
          doorCode,
        });
      }
    };
    initFormValues();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [typeCode, areaCode, detailAreaCode, doorCode, companyModalShow]);

  /** validate */
  const validate = () => {
    let result = true;
    // typeCode: 타입
    if (!IsValidValue(newCompany?.typeCode)) {
      setTypeError("타입 선택이 올바르지 않습니다.");
      result = false;
    }
    // areaCode: 지역
    if (!IsValidValue(newCompany?.areaCode)) {
      setAreaError("지역 선택이 올바르지 않습니다.");
      result = false;
    }
    // detailAreaCode
    if (!IsValidValue(newCompany?.detailAreaCode)) {
      setDetailAreaError("상세지역 선택이 올바르지 않습니다.");
      result = false;
    }
    // companyName
    if (!IsValidValue(newCompany?.companyName)) {
      setCompanyNameError("회사명을 입력하세요.");
      result = false;
    }
    return result;
  };

  /** handleSubmit */
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validate()) return;
    let isEdit = false;
    let apiUrl = "";
    // newCompany._id가 있으면 수정, 없으면 신규등록
    if (IsValidValue(newCompany?._id)) {
      isEdit = true;
      apiUrl = `${apiServer}/enjCompany/edit`;
    } else {
      apiUrl = `${apiServer}/enjCompany/add`;
    }
    try {
      setIsLoading(true);
      const res = isEdit
        ? await axiosToken.put(apiUrl, newCompany)
        : await axiosToken.post(apiUrl, newCompany);
      if (res?.data?.ok) {
        if (isEdit) {
          dispatch(editCompanies(res.data.result));
          dispatch(initCompany(res.data.result));
          addToast({
            type: "success",
            text: res.data.message || "company가 수정되었습니다.",
          });
        } else {
          dispatch(addCompanies(res.data.result));
          dispatch(initCompany(res.data.result));
          addToast({
            type: "success",
            text: res.data.message || "새 company가 생성되었습니다.",
          });
        }
        setTimeout(() => {
          /** [중요!!] 시간차를 두지 않으면 resetModalForm()이 반영되지 않는다!
           * - 실행 순서가 변경될 수도 있어서 그런 것 같음.
           */
          resetModalForm();
        }, 500);
      } else {
        if (isEdit) {
          addToast({
            type: "danger",
            text: res.data.message || "company가 수정되지 않았습니다.",
          });
        } else {
          addToast({
            type: "danger",
            text: res.data.message || "company가 생성되지 않았습니다.",
          });
        }
      }
    } catch (err) {
      console.error(err);
      addToast({
        type: "danger",
        text: err.message || "company 생성/수정 중 오류가 발생하였습니다.",
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      {/** 웹사이트 회사 등록폼
       * 타입, 지역, 도메인이 선택되지 않으면 저장, 추가 버튼 비활성화
       * 모든 폼을 Bootstrap 반응형으로 구성
       * 폼 간에 적당한 간격을 두고 구성
       */}
      <Modal
        animation={false}
        centered
        show={companyModalShow}
        onHide={resetModalForm}
      >
        <Modal.Header closeButton>
          <Modal.Title>{company ? "회사 수정" : "새 회사 등록"}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/** [주의!!] <form></form> 을 Modal.Body 이외의 위치에 두면 에러 발생! */}
          <form>
            <div className="container">
              <div className="row">
                {/**
                 * 1. 타입 선택(typeCodes) */}
                <div className="col-6 mb-2">
                  <div>
                    <select
                      className={`form-select ${
                        typeError ? "border border-danger" : ""
                      }`}
                      value={newCompany?.typeCode || ""}
                      onChange={(e) => {
                        setNewCompany({
                          ...newCompany,
                          typeCode: e.target.value,
                        });
                      }}
                    >
                      <option value="">:: 타입 선택 ::</option>
                      {typeCodes?.map((type, index) => {
                        return (
                          <option key={index} value={type.code}>
                            {type.codeName}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  <div className={`text-danger ${typeError ? "" : "d-none"}`}>
                    * {typeError}
                  </div>
                </div>
                {/**
                 * 2. 지역 선택(areaCodes) */}
                <div className="col-6 mb-2">
                  <select
                    className={`form-select ${
                      areaError ? "border border-danger" : ""
                    }`}
                    value={newCompany?.areaCode || ""}
                    onChange={(e) => {
                      setNewCompany({
                        ...newCompany,
                        areaCode: e.target.value,
                        detailAreaCode: "",
                        doorCode: "",
                      });
                    }}
                  >
                    <option value="">:: 지역 선택 ::</option>
                    {areaCodes?.map((area, index) => {
                      return (
                        <option key={index} value={area.code}>
                          {area.codeName}
                        </option>
                      );
                    })}
                  </select>
                  <div className={`text-danger ${areaError ? "" : "d-none"}`}>
                    * {areaError}
                  </div>
                </div>
                {/**
                 * localDetailAreaCodes select box
                 */}
                <div className="col-6 mb-2">
                  <select
                    className={`form-select ${
                      detailAreaError ? "border border-danger" : ""
                    }`}
                    value={newCompany?.detailAreaCode || ""}
                    onChange={(e) => {
                      setNewCompany({
                        ...newCompany,
                        detailAreaCode: e.target.value,
                      });
                    }}
                  >
                    <option value="">:: 상세지역 선택 ::</option>
                    {localDetailAreaCodes?.map((ea, index) => {
                      return (
                        <option key={index} value={ea.code}>
                          {ea.codeName}
                        </option>
                      );
                    })}
                  </select>
                  <div
                    className={`text-danger ${detailAreaError ? "" : "d-none"}`}
                  >
                    * {detailAreaError}
                  </div>
                </div>
                {/**
                 * doorCodes select box
                 */}
                <div className="col-6 mb-2">
                  <select
                    className="form-select"
                    value={newCompany?.doorCode || ""}
                    onChange={(e) => {
                      setNewCompany({
                        ...newCompany,
                        doorCode: e.target.value,
                      });
                    }}
                  >
                    <option value="">:: 지하철 출구 선택 ::</option>
                    {doorCodes?.map((ea, index) => {
                      return (
                        <option key={index} value={ea.code}>
                          {ea.codeName}
                        </option>
                      );
                    })}
                  </select>
                </div>
                <div className="col-12 mb-2">
                  <input
                    type="text"
                    className={`form-control ${
                      newCompany?.isImportant ? "fw-bold" : ""
                    } ${
                      newCompany?.isLineThrough
                        ? "text-decoration-line-through"
                        : ""
                    } ${companyNameError ? "border border-danger" : ""}`}
                    style={{ color: newCompany?.companyColor }}
                    placeholder="회사명을 입력하세요."
                    value={newCompany?.companyName || ""}
                    onChange={(e) => {
                      setNewCompany({
                        ...newCompany,
                        companyName: e.target.value,
                      });
                    }}
                  />
                  <div
                    className={`text-danger ${
                      companyNameError ? "" : "d-none"
                    }`}
                  >
                    * {companyNameError}
                  </div>
                </div>
                <div className="row mb-2">
                  <div className={`col-auto`}>
                    <input
                      type="checkbox"
                      className="btn-check"
                      id="ck-isImportant"
                      autoComplete="off"
                      checked={newCompany?.isImportant || false}
                      // value={isImportant || false}
                      onChange={(e) => {
                        setNewCompany({
                          ...newCompany,
                          isImportant: e.target.checked,
                        });
                      }}
                    />
                    <label
                      className="btn btn-outline-primary"
                      htmlFor="ck-isImportant"
                    >
                      강조
                    </label>
                  </div>
                  <div className="col-auto p-0">
                    <input
                      type="checkbox"
                      className="btn-check"
                      id="cb-isLineThrough"
                      autoComplete="off"
                      checked={newCompany?.isLineThrough || false}
                      // value={isLineThrough || false}
                      onChange={(e) => {
                        setNewCompany({
                          ...newCompany,
                          isLineThrough: e.target.checked,
                        });
                      }}
                    />
                    <label
                      className="btn btn-outline-primary"
                      htmlFor="cb-isLineThrough"
                    >
                      취소선
                    </label>
                  </div>
                  <div className="col-auto">
                    <input
                      type="color"
                      className="form-control form-control-color"
                      id="exampleColorInput"
                      title="Choose your color"
                      value={newCompany?.companyColor || "#000000"}
                      onChange={(e) => {
                        setNewCompany({
                          ...newCompany,
                          companyColor: e.target.value,
                        });
                      }}
                    />
                  </div>
                </div>
                <div className="row mb-2">
                  <input
                    type="number"
                    className="form-control ms-3 my-1"
                    style={{ width: "200px" }}
                    placeholder="스밤 출근부 아이디넘버"
                    value={newCompany?.sbamScheduleIdNo || ""}
                    onChange={(e) => {
                      setNewCompany({
                        ...newCompany,
                        sbamScheduleIdNo: e.target.value,
                      });
                    }}
                  />
                  <input
                    type="number"
                    className="form-control ms-3 my-1"
                    style={{ width: "200px" }}
                    placeholder="스밤 리뷰 아이디넘버"
                    value={newCompany?.sbamReviewIdNo || ""}
                    onChange={(e) => {
                      setNewCompany({
                        ...newCompany,
                        sbamReviewIdNo: e.target.value,
                      });
                    }}
                  />
                  <input
                    type="number"
                    className="form-control ms-3 my-1"
                    style={{ width: "200px" }}
                    placeholder="키인포 출근부 아이디넘버"
                    value={newCompany?.kinfoScheduleIdNo || ""}
                    onChange={(e) => {
                      setNewCompany({
                        ...newCompany,
                        kinfoScheduleIdNo: e.target.value,
                      });
                    }}
                  />
                  <input
                    type="number"
                    className="form-control ms-3 my-1"
                    style={{ width: "200px" }}
                    placeholder="나비야넷 출근부 아이디넘버"
                    value={newCompany?.nabiyaScheduleIdNo || ""}
                    onChange={(e) => {
                      setNewCompany({
                        ...newCompany,
                        nabiyaScheduleIdNo: e.target.value,
                      });
                    }}
                  />
                  <input
                    type="text"
                    className="form-control ms-3 my-1"
                    style={{ width: "200px" }}
                    placeholder="마통 회원사 아이디"
                    value={newCompany?.matongId}
                    onChange={(e) => {
                      setNewCompany({
                        ...newCompany,
                        matongId: e.target.value,
                      });
                    }}
                  />
                </div>
                {/** memo */}
                <div className="mb-2">
                  <textarea
                    className="form-control"
                    rows={2}
                    placeholder="메모를 입력하세요."
                    value={newCompany?.memo || ""}
                    onChange={(e) => {
                      setNewCompany({ ...newCompany, memo: e.target.value });
                    }}
                    onFocus={HandleAutoHeight}
                    onKeyUp={HandleAutoHeight}
                    onKeyDown={HandleAutoHeight}
                    onCut={HandleAutoHeight}
                    onPaste={HandleAutoHeight}
                  />
                </div>
                {/** 출근부 */}
                <div className="mb-2">
                  <textarea
                    className="form-control"
                    rows={2}
                    placeholder="금일 출근 직원을 입력하세요.(구분자: , ❤️ /)"
                    value={newCompany?.todayScheduled || ""}
                    onChange={(e) => {
                      setNewCompany({
                        ...newCompany,
                        todayScheduled: e.target.value,
                      });
                    }}
                    onFocus={HandleAutoHeight}
                    onKeyUp={HandleAutoHeight}
                    onKeyDown={HandleAutoHeight}
                    onCut={HandleAutoHeight}
                    onPaste={HandleAutoHeight}
                  />
                </div>
              </div>
              <button
                type="submit"
                className="btn btn-outline-primary w-100 mb-2"
                onClick={handleSubmit}
              >
                {company ? "저장" : "신규 등록"}
              </button>
              <button
                className={`btn btn-outline-danger w-100
              ${company ? "" : "d-none"}
              `}
                onClick={(e) => handleDeleteCompanyClick(e, company?._id)}
              >
                삭제
              </button>
            </div>
          </form>
        </Modal.Body>

        <Modal.Footer></Modal.Footer>
      </Modal>
      <LoadingPopSpinner isLoading={isLoading} />
    </>
  );
};

export default CompanyFormEditModal;
