/**
 * 추후에 validator, state 등을 이용해서 실시간 에러메시지 방식도 구현
 * https://www.geeksforgeeks.org/how-to-validate-url-in-reactjs/
 * https://gom20.tistory.com/160
 */
import React, { useCallback, useEffect, useRef, useState } from "react";
import { ButtonGroup, Col, Container, Row } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import { useDispatch, useSelector } from "react-redux";
import { IsEnterkey, TaskCatchError } from "../../Common";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import AlertModal from "../AlertModal";
import MsgBox from "../MsgBox";

function GroupModal({
  selgroup,
  isloading,
  setIsLoading,
  modalshow,
  setmodalshow,
}) {
  const axiosToken = useAxiosPrivate();
  const dispatch = useDispatch();

  const userId = useSelector((state) => state.auth?.userId);

  const [msg, setMsg] = useState("");
  const [modalShow, setModalShow] = useState(false);
  const [isInvalidName, setIsInvalidName] = useState(false);
  const [currentGroupData, setCurrentGroupData] = useState(null);

  const groupnameRef = useRef();

  const [isImportant, setIsImportant] = useState(false);
  const [isLineThrough, setIsLineThrough] = useState(false);
  const [isInvalidNameClass, setIsInvalidNameClass] = useState("");
  const [isImportantClass, setIsImportantClass] = useState("");
  const [isLineThroughClass, setIsLineThroughClass] = useState("");

  /**
   * isImportant 변경되면 isImportantClass 변경
   */
  useEffect(() => {
    setIsImportantClass(isImportant ? "text-important fw-bold" : "");
  }, [isImportant]);

  /**
   * isLineThrough 변경되면 isLineThroughClass 변경
   */
  useEffect(() => {
    setIsLineThroughClass(isLineThrough ? "txt-line-through" : "");
  }, [isLineThrough]);

  /**
   * isInvalidName 변경되면 isInvalidNameClass 변경
   */
  useEffect(() => {
    setIsInvalidNameClass(isInvalidName ? "invalid-box-border" : "");
  }, [isInvalidName]);

  useEffect(() => {
    if (!modalShow) return;
    groupnameRef?.current?.focus();
  }, [modalShow]);

  // 중복되는 메시지만 활용
  const placeholderMsg = {
    gName: "그룹 명을 입력하세요.",
    memo: "메모를 입력하세요.(선택)",
  };

  const handleGroupDelete = async () => {
    if (!selgroup || selgroup === "newGroup") return;
    const msg =
      "삭제 하시겠습니까?\n해당 그룹에 속하는 카테고리, 북마크도 함께 삭제됩니다.";
    if (!window.confirm(msg)) return;
    try {
      await setIsLoading(true);
      const data = {
        _id: selgroup,
      };
      const url = `/group/delete`;
      const res = await axiosToken.delete(url, { data: data });
      if (res && res.status === 200 && res.data && res.data === "ok") {
        const groups = await axiosToken.get(`/myGroups`);
        await dispatch({
          type: "auth/fetchMyGroups",
          payload: groups?.data,
        });
        await resetStates();
      } else {
        AlertModal(
          true,
          "삭제에 실패했습니다.\n잠시 후 다시 시도해주세요.\n그룹관리 에서도 바로 삭제할 수 있습니다."
        );
      }
    } catch (err) {
      AlertModal(
        true,
        "삭제에 실패했습니다.\n잠시 후 다시 시도해주세요.\n그룹관리 에서도 바로 삭제할 수 있습니다."
      );
      TaskCatchError(err);
    } finally {
      await setIsLoading(false);
      await setmodalshow(false);
    }
  };

  const resetStates = () => {
    setModalShow(false);
    setmodalshow(false);
    setCurrentGroupData(null);
    setIsInvalidName(false);
    setMsg("");
    setIsImportant(false);
    setIsLineThrough(false);
    setIsImportantClass("");
    setIsLineThroughClass("");
  };

  const nameValidate = useCallback((e) => {
    if (!e || !e.target) return;
    const value = e.target.value;
    if (!value.trim()) {
      setIsInvalidName(true);
    } else {
      setIsInvalidName(false);
    }
  }, []);

  useEffect(() => {
    setModalShow(modalshow);
  }, [modalshow]);

  useEffect(() => {
    const initData = async () => {
      if (!modalShow || !selgroup) return;
      try {
        await setIsLoading(true);
        // await setGroupData(null);
        if (selgroup !== "newGroup") {
          const url = `/group/${selgroup}`;
          const res = await axiosToken.get(url);
          if (res.data === "error") return;
          await setCurrentGroupData(res.data);
          await setIsImportant(res.data?.isImportant);
          await setIsLineThrough(res.data?.isLineThrough);
          await setModalShow(true);
        }
      } catch (err) {
        TaskCatchError(err);
      } finally {
        await setIsLoading(false);
        // await document.querySelector("#groupName").focus();
      }
    };
    initData();
  }, [selgroup, setIsLoading, modalShow, axiosToken]);

  const handleFormSubmit = async (e) => {
    if (isloading) return; // [중요] 중복 방지!!
    e.preventDefault();
    try {
      await setIsLoading(true);
      const target = e.target.closest(".modal-dialog");
      const groupName = target.querySelector("#groupName"),
        isImportant = target.querySelector("#chkImportant"),
        chkLinethrough = target.querySelector("#chkLinethrough"),
        isPublic = target.querySelector("#chkPublic"),
        memo = target.querySelector("#bookmarkMemo");
      const data = {
        userId,
        groupName: groupName.value,
        isImportant: isImportant.checked,
        isLineThrough: chkLinethrough.checked,
        isPublic: isPublic.checked,
        memo: memo.value,
      };
      // 공백 체크
      if (!data.userId) {
        setMsg(
          "사용자 정보가 올바르지 않습니다.\n로그인 후 다시 시도해주세요."
        );
        return;
      }
      setMsg("");
      if (!data.groupName) {
        setIsInvalidName(true);
        groupName.focus();
        return;
      }
      let url;
      if (!selgroup || selgroup === "newGroup") {
        // 신규
        url = `/group/add`;
        const res = await axiosToken.post(url, data);
        if (res?.data?.ok) {
          setMsg(res?.data?.message || "새 그룹이 생성되었습니다.");
          const myGroups = await axiosToken.get(`/myGroups`);
          if (myGroups?.data === "error") return;
          await dispatch({
            type: "auth/fetchMyGroups",
            payload: myGroups?.data,
          });
          await resetStates();
        } else {
          await setMsg("새 그룹 생성에 실패했습니다.");
        }
      } else {
        // 수정
        data._id = selgroup;
        url = `/group/edit`;
        const res = await axiosToken.put(url, data);
        if (res?.data === "ok") {
          await setMsg("수정되었습니다.");
          const myGroups = await axiosToken.get(`/myGroups`);
          if (myGroups?.status === 200) {
            if (myGroups.data?.length) {
              if (myGroups.data === "error") return;
              await dispatch({
                type: "auth/fetchMyGroups",
                payload: myGroups.data,
              });
            }
          }
          await resetStates();
        } else {
          await setMsg("수정에 실패했습니다.");
        }
      }
    } catch (err) {
      TaskCatchError(err);
    } finally {
      await setIsLoading(false);
    }
  };

  const handleChkSubmit = (e) => {
    if (IsEnterkey(e)) handleFormSubmit(e);
  };

  return (
    <Modal centered show={modalShow} onHide={resetStates}>
      <Modal.Header closeButton>
        <Modal.Title>그룹 관리</Modal.Title>
      </Modal.Header>
      <MsgBox msg={msg} />
      <Modal.Body>
        <Form>
          <Form.Group className="mb-3 d-none" controlId="newGroupname">
            <Form.Control type="text" placeholder="새 그룹명을 입력하세요." />
          </Form.Group>
          <Form.Group className="mb-3 d-none" controlId="newCategoryname">
            <Form.Control type="text" placeholder="새 그룹명을 입력하세요." />
          </Form.Group>
          <Form.Group className="mb-3" controlId="groupName">
            <Form.Control
              type="text"
              ref={groupnameRef}
              className={`${isInvalidNameClass} ${isImportantClass} ${isLineThroughClass}`}
              placeholder={placeholderMsg.gName}
              defaultValue={currentGroupData?.groupName}
              onBlur={nameValidate}
              onKeyDown={handleChkSubmit}
              required
            />
            <ButtonGroup className="mt-2">
              <Form.Check
                inline
                label="중요"
                type="checkbox"
                id="chkImportant"
                defaultChecked={currentGroupData?.isImportant}
                onClick={(e) => setIsImportant(e.target.checked)}
              />
              <Form.Check
                inline
                label="취소선"
                type="checkbox"
                id="chkLinethrough"
                defaultChecked={currentGroupData?.isLineThrough}
                onClick={(e) => setIsLineThrough(e.target.checked)}
              />
              <Form.Check
                inline
                label="공개"
                type="checkbox"
                id="chkPublic"
                defaultChecked={currentGroupData?.isPublic}
              />
            </ButtonGroup>
          </Form.Group>
          <Form.Group className="mb-3" controlId="bookmarkMemo">
            <Form.Control
              as="textarea"
              rows={4}
              placeholder={placeholderMsg.memo}
              defaultValue={currentGroupData?.memo}
            />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Container>
          <Row>
            <Col>
              <Button
                variant="danger w-100"
                type="button"
                disabled={!selgroup || selgroup === "newGroup"}
                onClick={handleGroupDelete}
              >
                삭제
              </Button>
            </Col>
            <Col>
              <Button
                variant="primary w-100"
                type="submit"
                onClick={handleFormSubmit}
              >
                저장
              </Button>
            </Col>
          </Row>
        </Container>
      </Modal.Footer>
    </Modal>
  );
}

export default GroupModal;
