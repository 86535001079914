import { useEffect } from "react";
import { Link } from "react-router-dom";
import { Delay, ScrollToWCenter } from "../../Common";

const TopAdminMenus = () => {
  useEffect(() => {
    const links = document.querySelectorAll("#ul-group > li > a");
    links.forEach((link) => {
      // e.preventDefault(); // 이 기능을 사용하면 a 태그의 href 기능이 동작하지 않는다.
      link.addEventListener("click", (e) => {
        ScrollToWCenter(e);
        Delay(1000).then(() => {
          window.scrollTo(0, 0);
        });
      });
    });
  }, []);

  return (
    <header
      className="container-fluid sticky-top border-bottom bg-light"
      id="group-link"
    >
      <nav className="container-xl navbar navbar-expand-xl">
        <div className="container-fluid navbar-expand">
          <ul
            className="nav nav-pills nav-fill text-nowrap flex-nowrap col-md-7 col-lg-8 list-group list-group-horizontal overflow-auto"
            style={{ width: "-webkit-fill-available" }}
            id="ul-group"
          >
            <li
              className="nav-item fw-bold my-0 nav-font-style"
              style={{ padding: "0.5em" }}
            >
              <Link className="nav-link" to="/admin">
                관리홈
              </Link>
            </li>
            <li
              className="nav-item fw-bold my-0 nav-font-style"
              style={{ padding: "0.5em" }}
            >
              <Link className="nav-link" to="/admin/errorList/100">
                에러
              </Link>
            </li>
            <li
              className="nav-item fw-bold my-0 nav-font-style"
              style={{ padding: "0.5em" }}
            >
              <Link className="nav-link" to="/admin/usersManage/100">
                사용자 관리
              </Link>
            </li>
            <li
              className="nav-item fw-bold my-0 nav-font-style"
              style={{ padding: "0.5em" }}
            >
              <Link className="nav-link" to="/admin/allBookmarkManage/100">
                북마크 관리
              </Link>
            </li>
            <li
              className="nav-item fw-bold my-0 nav-font-style"
              style={{ padding: "0.5em" }}
            >
              <Link className="nav-link" to="/">
                (프론트메인)
              </Link>
            </li>
          </ul>
        </div>
      </nav>
    </header>
  );
};

export default TopAdminMenus;
