import { useEffect, useState } from "react";
import * as Icon from "react-bootstrap-icons";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import LoadingPopSpinner from "../LoadingPopSpinner";
import MsgBox from "../MsgBox";

const CodeSort = ({ code, isCodeChanged, setIsCodeChanged }) => {
  const axiosHook = useAxiosPrivate();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [selectedLi, setSelectedLi] = useState(null);
  const [selectedCode, setSelectedCode] = useState(null);
  const [firstSelectedCode, setFirstSelectedCode] = useState(null);
  const [codeList, setCodeList] = useState([]);
  const apiServer = process.env.REACT_APP_API_DOAMIN;

  useEffect(() => {
    if (isCodeChanged) {
      // setCode(null);
      setSelectedCode(null);
      setSelectedLi(null);
      setFirstSelectedCode(null);
      setIsCodeChanged(false);
      // setTimeout(() => {
      //   setCode(selectedCode);
      // }, 500);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCodeChanged]);

  const moveTo = (e) => {
    e.preventDefault();
    if (!selectedCode || !selectedLi) {
      alert("먼저 코드를 선택하세요.");
      return;
    }
    const ul = document.getElementById("ul-list-bookmark");
    const direction = e.target.closest("button").getAttribute("data-direction");
    if (direction === "top") {
      ul.insertBefore(selectedLi, ul.firstChild);
    } else if (direction === "up") {
      const wrapperParent = selectedLi.parentNode;
      const wrapperPervious = selectedLi.previousElementSibling;
      if (wrapperPervious)
        wrapperParent.insertBefore(selectedLi, wrapperPervious);
    } else if (direction === "down") {
      const wrapperParent = selectedLi.parentNode;
      const wrapperNext = selectedLi.nextElementSibling;
      if (wrapperNext) wrapperParent.insertBefore(wrapperNext, selectedLi);
    } else if (direction === "bottom") {
      ul.insertBefore(selectedLi, null);
    }
  };

  const handleSaveSort = async (e) => {
    e.preventDefault();
    const codeList = [];
    const ul = document.getElementById("ul-list-bookmark");
    const liList = ul.querySelectorAll("li");
    let idx = 0;
    await liList.forEach((li) => {
      codeList.push({
        code: li.dataset["code"],
        sortNo: idx,
      });
      parseInt(idx++);
    });
    const saveSortApiUri = `${apiServer}/code/edit/sort`;
    setIsLoading(true);
    await axiosHook
      .put(saveSortApiUri, {
        codeList,
      })
      .then((res) => {
        if (res.data === "ok") {
          setIsCodeChanged(true);
        } else {
          alert("순서 저장에 실패하였습니다.");
        }
      })
      .catch((e) => {
        console.error(e);
        alert("순서 저장에 실패하였습니다.");
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    // 선택 된 코드와 같은 부모코드를 가진 코드들을 불러와서 setCodeList 에 할당
    setFirstSelectedCode(code);
    if (!code) {
      setCodeList([]);
      setIsLoading(false);
      return;
    }
    const parentSelectApiUri = `/${code}/sameParent/code`;
    setIsLoading(true);
    axiosHook
      .get(`${apiServer}${parentSelectApiUri}`)
      .then((res) => {
        setCodeList(res.data);
      })
      .catch((e) => {
        setCodeList([]);
        console.error(e);
        setError("Something went wrong in database");
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [axiosHook, apiServer, code]);

  if (error) {
    return <MsgBox msg={error} bg="danger" />;
  }

  return (
    <div className="h-100">
      <h4>순서변경</h4>
      <label className="text-muted fst-italic" style={{ fontSize: "85%" }}>
        * 먼저 코드를 선택하세요.
      </label>
      <hr />
      <ul
        className="list-group text-truncate overflow-y-scroll"
        style={{ maxHeight: "calc(100% - 190px)", overflowY: "scroll" }}
        id="ul-list-bookmark"
      >
        {codeList?.map((codeObj) => {
          return (
            <li
              key={codeObj.code}
              className={
                `list-group-item` +
                (selectedCode === codeObj.code ? " bg-info text-light" : "") +
                (firstSelectedCode === codeObj.code ? " fw-bold" : "")
              }
              style={{ backgroundColor: "#e7e8eb" }}
              data-code={codeObj.code}
              onClick={(e) => {
                setFirstSelectedCode(codeObj.code);
                setSelectedCode(codeObj.code);
                setSelectedLi(e.target.closest("li"));
              }}
            >
              <label className="form-check-label">{codeObj.codeName}</label>
            </li>
          );
        })}
      </ul>
      <div
        className="btn-group col-12 mt-2"
        style={{ backgroundColor: "#e7e8eb" }}
      >
        <button
          type="button"
          className="col btn btn-outline-secondary"
          onClick={moveTo}
          data-direction="top"
        >
          <span>
            <Icon.ChevronDoubleUp />
          </span>
        </button>
        <button
          type="button"
          className="col btn btn-outline-secondary"
          onClick={moveTo}
          data-direction="up"
        >
          <span>
            <Icon.ChevronUp />
          </span>
        </button>
        <button
          type="button"
          className="col btn btn-outline-secondary"
          onClick={moveTo}
          data-direction="down"
        >
          <span>
            <Icon.ChevronDown />
          </span>
        </button>
        <button
          type="button"
          className="col btn btn-outline-secondary"
          onClick={moveTo}
          data-direction="bottom"
        >
          <span>
            <Icon.ChevronDoubleDown />
          </span>
        </button>
      </div>
      <div className="btn-group col-12 mt-2 bg-light">
        <button
          type="button"
          className="col btn btn-secondary"
          onClick={handleSaveSort}
        >
          순서 저장
        </button>
      </div>
      <LoadingPopSpinner isLoading={isLoading} />
    </div>
  );
};

export default CodeSort;
