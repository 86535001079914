import AppTitle from "./AppTitle";
import TopMenus from "./TopMenus";
import useWindowSize from "../../hooks/useWindowSize";

export default function TitleAndMenus({ curPath }) {
  const { width } = useWindowSize();
  return (
    <header className="container-fluid bg-light">
      <nav className="container-xl navbar navbar-expand-xl">
        <div className="container-fluid navbar-expand">
          <AppTitle />
          <div
            className="collapse navbar-collapse col-md-6 col-lg-5 col-xl-5 pt-3 pt-lg-1 overflow-auto"
            id="navbarText2"
          >
            <TopMenus curPath={curPath} width={width} />
          </div>
        </div>
      </nav>
    </header>
  );
}
