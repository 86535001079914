import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import MsgBox from "../MsgBox";

export default function UserBookmarkManage() {
  const [msg, setMsg] = useState("");
  const [bookmarks, setBookmarks] = useState([]);
  const axiosToken = useAxiosPrivate();
  const [user, setUser] = useState({});

  const { userId, count } = useParams();

  const resetStates = () => {
    setMsg("");
  };

  const getUserInfo = useCallback(async () => {
    await resetStates();
    const user = await axiosToken.get(`/user/${userId}/info`);
    if (user && user.status === 200 && user.data) {
      await setUser(user.data);
    } else {
      setMsg("사용자 정보를 가져오는데 실패했습니다.");
    }
  }, [axiosToken, userId]);

  const getBookmarks = useCallback(async () => {
    await resetStates();
    const bookmarks = await axiosToken.get(`/${userId}/bookmarks/${count}`);
    if (bookmarks && bookmarks.status === 200 && bookmarks.data) {
      await setBookmarks(bookmarks.data);
    } else {
      setMsg("북마크 목록을 가져오는데 실패했습니다.");
    }
  }, [axiosToken, count, userId]);

  useEffect(() => {
    getUserInfo();
  }, [getUserInfo]);

  useEffect(() => {
    getBookmarks();
  }, [getBookmarks]);

  return (
    <div className="row d-flex justify-content-center">
      <h2 className="h2">사용자 북마크</h2>
      <MsgBox msg={msg} />
      <div className="col-md-7 mt-md-0">
        <div className="list-group">
          <div className="list-group-item list-group-item-action py-3">
            <span className="text-muted">nickname</span>: {user.nickName} <br />
            <span className="text-muted">login</span>: {user.loginId} <br />
            <span className="text-muted">email</span>: {user.email}
          </div>
        </div>
        <div>&nbsp;</div>
        <div className="list-group">
          {bookmarks?.map((bookmark) => (
            <div
              className="list-group-item list-group-item-action py-3"
              key={bookmark._id}
            >
              <div className="d-flex w-100 justify-content-between">
                <p className="mb-0">
                  [{bookmark.bookmarkNo}]{" "}
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={bookmark.bookmarkUri}
                  >
                    {bookmark.bookmarkName}
                    {bookmark.memo && ` - ${bookmark.memo}`}
                  </a>
                </p>
              </div>
              <small>
                {new Date(bookmark.updatedAt)?.toLocaleString()} <br />
                {bookmark.userId}
              </small>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
