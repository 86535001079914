import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  typeCodes: [],
  ydTypeCodes: [],
  countryCodes: [],
  areaCodes: [],
  detailAreaCodes: [],
  doorCodes: [],
  domainCodes: [],
  typeCode: "",
  ydTypeCode: "",
  countryCode: "",
  areaCode: "",
  detailAreaCode: "",
  doorCode: "",
  domainCode: "",
};

const enjCodeSlice = createSlice({
  name: "enjCode",
  initialState,
  reducers: {
    /** typeCodes */
    initTypeCodes: (state, action) => {
      state.typeCodes = action.payload;
    },
    /** ydTypeCodes */
    initYdTypeCodes: (state, action) => {
      state.ydTypeCodes = action.payload;
    },
    addTypeCodes: (state, action) => {
      // 앞쪽에 추가
      state.typeCodes = [action.payload, ...state.typeCodes];
    },
    addYdTypeCodes: (state, action) => {
      // 앞쪽에 추가
      state.ydTypeCodes = [action.payload, ...state.ydTypeCodes];
    },
    editTypeCodes: (state, action) => {
      state.typeCodes = state.typeCodes.map((tc) => {
        if (tc.code === action.payload?.code) {
          return action.payload;
        } else {
          return tc;
        }
      });
    },
    editYdTypeCodes: (state, action) => {
      state.ydTypeCodes = state.ydTypeCodes.map((tc) => {
        if (tc.code === action.payload?.code) {
          return action.payload;
        } else {
          return tc;
        }
      });
    },
    resetTypeCodes: (state) => {
      state.typeCodes = [];
    },
    resetYdTypeCodes: (state) => {
      state.ydTypeCodes = [];
    },
    /** countryCodes */
    initCountryCodes: (state, action) => {
      state.countryCodes = action.payload;
    },
    addCountryCodes: (state, action) => {
      // 앞쪽에 추가
      state.countryCodes = [action.payload, ...state.countryCodes];
    },
    editCountryCodes: (state, action) => {
      state.countryCodes = state.countryCodes.map((cc) => {
        if (cc.code === action.payload?.code) {
          return action.payload;
        } else {
          return cc;
        }
      });
    },
    resetCountryCodes: (state) => {
      state.countryCodes = [];
    },
    /** AREA Codes */
    initAreaCodes: (state, action) => {
      state.areaCodes = action.payload;
    },
    addAreaCodes: (state, action) => {
      // 앞쪽에 추가
      state.areaCodes = [action.payload, ...state.areaCodes];
    },
    editAreaCodes: (state, action) => {
      state.areaCodes = state.areaCodes.map((cc) => {
        if (cc.code === action.payload?.code) {
          return action.payload;
        } else {
          return cc;
        }
      });
    },
    resetAreaCodes: (state) => {
      state.areaCodes = [];
    },
    /** detailAreaCode Codes */
    initDetailAreaCodes: (state, action) => {
      state.detailAreaCodes = action.payload;
    },
    addDetailAreas: (state, action) => {
      // 앞쪽에 추가
      state.detailAreaCodes = [action.payload, ...state.detailAreaCodes];
    },
    editDetailAreas: (state, action) => {
      state.detailAreaCodes = state.detailAreaCodes.map((cc) => {
        if (cc.code === action.payload?.code) {
          return action.payload;
        } else {
          return cc;
        }
      });
    },
    resetDetailAreaCodes: (state) => {
      state.detailAreaCodes = [];
    },
    /** domainCodes */
    initDomainCodes: (state, action) => {
      state.domainCodes = action.payload;
    },
    addDomainCodes: (state, action) => {
      // 앞쪽에 추가
      state.domainCodes = [action.payload, ...state.domainCodes];
    },
    editDomainCodes: (state, action) => {
      state.domainCodes = state.domainCodes.map((sd) => {
        if (sd.code === action.payload?.code) {
          return action.payload;
        } else {
          return sd;
        }
      });
    },
    resetDomainCodes: (state) => {
      state.domainCodes = [];
    },
    /** doorCodes */
    initDoorCodes: (state, action) => {
      state.doorCodes = action.payload;
    },
    addDoorCodes: (state, action) => {
      // 앞쪽에 추가
      state.doorCodes = [action.payload, ...state.doorCodes];
    },
    editDoorCodes: (state, action) => {
      state.doorCodes = state.doorCodes.map((sd) => {
        if (sd.code === action.payload?.code) {
          return action.payload;
        } else {
          return sd;
        }
      });
    },
    resetDoorCodes: (state) => {
      state.doorCodes = [];
    },
    /** typeCode */
    initTypeCode: (state, action) => {
      state.typeCode = action.payload;
    },
    /** ydTypeCode */
    initYdTypeCode: (state, action) => {
      state.ydTypeCode = action.payload;
    },
    resetTypeCode: (state) => {
      state.typeCode = "";
    },
    resetYdTypeCode: (state) => {
      state.ydTypeCode = "";
    },
    /** countryCode */
    initCountryCode: (state, action) => {
      state.countryCode = action.payload;
    },
    resetCountryCode: (state) => {
      state.countryCode = "";
    },
    /** areaCode */
    initAreaCode: (state, action) => {
      state.areaCode = action.payload;
    },
    resetAreaCode: (state) => {
      state.areaCode = "";
    },
    /** detailAreaCode */
    initDetailAreaCode: (state, action) => {
      state.detailAreaCode = action.payload;
    },
    resetDetailAreaCode: (state) => {
      state.detailAreaCode = "";
    },
    /** doorCode */
    initDoorCode: (state, action) => {
      state.doorCode = action.payload;
    },
    resetDoorCode: (state) => {
      state.doorCode = "";
    },
    /** domainCode */
    initDomainCode: (state, action) => {
      state.domainCode = action.payload;
    },
    resetDomainCode: (state) => {
      state.domainCode = "";
    },
  },
});

export const {
  initTypeCodes,
  initYdTypeCodes,
  initDoorCodes,
  addTypeCodes,
  addYdTypeCodes,
  addDoorCodes,
  editTypeCodes,
  editYdTypeCodes,
  editDoorCodes,
  resetTypeCodes,
  resetYdTypeCodes,
  resetDoorCodes,
  initCountryCodes,
  initAreaCodes,
  initDetailAreaCode,
  initDetailAreaCodes,
  addCountryCodes,
  addAreaCodes,
  editCountryCodes,
  editAreaCodes,
  resetCountryCodes,
  resetAreaCodes,
  resetDetailAreaCodes,
  initDomainCodes,
  addDomainCodes,
  editDomainCodes,
  resetDomainCodes,
  initTypeCode,
  initYdTypeCode,
  resetTypeCode,
  resetYdTypeCode,
  initCountryCode,
  initAreaCode,
  resetCountryCode,
  resetAreaCode,
  resetDetailAreaCode,
  initDomainCode,
  resetDomainCode,
  resetDoorCode,
  initDoorCode,
} = enjCodeSlice.actions;

export default enjCodeSlice.reducer;
