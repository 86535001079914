export default function OpenBookmarksCategoryTitle({ item }) {
  if (!item) return null;

  const categoryId = item._id,
    categoryName = item.categoryName,
    isImportant = item.isImportant,
    isLineThrough = item.isLineThrough,
    memo = item.memo;
  const isImportantClass = isImportant ? "txt-important-title" : "",
    isLineThroughClass = isLineThrough ? "txt-line-through" : "",
    memoHtml = memo
      ? `<br><small className="text-white text-opacity-75"><em>(${memo})</em></small>`
      : "";

  return (
    <>
      <h6
        key={categoryId + categoryId}
        className="faj-center"
        style={{ lineHeight: "1.7rem" }}
      >
        <p
          className={`${isImportantClass} ${isLineThroughClass} me-3`}
          style={{ cursor: "default" }} // cursor 종류: auto, default, none, context-menu, help, pointer, progress, wait, cell, crosshair, text, vertical-text, alias, copy, move, no-drop, not-allowed, e-resize, n-resize, ne-resize, nw-resize, s-resize, se-resize, sw-resize, w-resize, ew-resize, ns-resize, nesw-resize, nwse-resize, col-resize, row-resize, all-scroll, zoom-in, zoom-out, grab, grabbing
          dangerouslySetInnerHTML={{
            __html: `<strong>${categoryName}</strong>${memoHtml}`,
          }}
        />
      </h6>
    </>
  );
}
