/* eslint-disable no-script-url */
/* eslint-disable eqeqeq */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Delay } from "../../Common";
import "../../css/browser_setting.css";

export default function PwaGuidePage() {
  useEffect(() => {
    const getParameter = (key) => {
      if (new URLSearchParams(window.location.search).get(key)) {
        return new URLSearchParams(window.location.search).get(key);
      }
      return "";
    };
    if (getParameter("no") == "2") {
      setCurMenuId("2");
      setCurMenuTitle("자동입력으로 북마크 한번에 저장하기");
    }
  }, []);

  const menus = [
    {
      id: 1,
      title: "모바일 크롬 PWA 설치방법",
      name: "chromeMobilePWA",
    },
    {
      id: 2,
      title: "자동입력으로 북마크 한번에 저장하기",
      name: "pwaAuthBookmark",
    },
  ];

  const [curMenuId, setCurMenuId] = useState("1");
  const [curMenuTitle, setCurMenuTitle] = useState("모바일 크롬 PWA 설치방법");

  useEffect(() => {
    document.title = "PWA 가이드 - startmypage.com";
  }, []);

  const handleMenuClick = (e) => {
    setCurMenuId(e.target.getAttribute("menu-id"));
    setCurMenuTitle(e.target.outerText);
    Title();
    Content();
  };

  const Title = () => {
    return (
      <>
        <ul className="nav nav-pills nav-fill bg-light">
          {menus?.map((m) => (
            <li key={m.id} className="nav-item">
              <Link
                to="#"
                className={
                  m.id == curMenuId
                    ? "bg-warning text-secondary nav-link"
                    : "nav-link"
                }
                onClick={handleMenuClick}
                menu-id={m.id}
              >
                {m.title}
              </Link>
            </li>
          ))}
        </ul>
        <h2 style={{ margin: "1.5rem auto" }}>{curMenuTitle}</h2>
      </>
    );
  };

  const Content = () => {
    let htmlContent = "";
    if (curMenuId === "1") {
      htmlContent = `<li>
                    <p className="fs-4">1. 우측상단 점 세개(...) 터치</p>
                    <img src="/img/pwa/0-ready.jpg" alt="PWA 설치방법0" style="max-width:80%;" />
                </li>
                <li>
                <p className="fs-4">2. '앱설치' 터치</p>
                <img src="/img/pwa/1-install.jpg" alt="PWA 설치방법1" style="max-width:80%;" />
            </li>
                <li>
                    <p className="fs-4">3. '설치' 터치</p>
                    <img src="/img/pwa/2-install.jpg" alt="PWA 설정방법2" style="max-width:80%;" />
                </li>
                <li>
                    <p className="fs-4">4. 10초 이내로 바탕화면에 설치됩니다.</p>
                    <img src="/img/pwa/3-done.jpg" alt="PWA 설치방법3" style="max-width:80%;" />
                </li>
                <li>
                    <p className="fs-4"><a href="/pwaGuide/?no=2" rel="noopener noreferrer">5. 자동 입력으로 북마크 한번에 저장하기(클릭)</a></p>
                    PWA가 설치된 스마트폰 에서는 자동입력으로 북마크를 한번에 저장할 수 있습니다.
                </li>`;
    } else if (curMenuId === "2") {
      htmlContent = `<li>
                    <p className="fs-4">1. 사전 준비</p>
                    자동입력으로 북마크를 저장하려면 먼저 PWA를 설치해야 합니다.
                </li>
                <li>
                  <p className="fs-4">2. 우측 상단 점세개(...) 터치</p>
                  북마크 할 페이지의 우측 상단 점세개(...)를 터치합니다.<br />  
                  <img src="/img/pwa-oneclick-bookmark/2-select.jpg" alt="2. 우측 상단 점세개(...) 터치" style="max-width:80%;" />
                </li>
                <li>
                  <p className="fs-4">3. '공유' 메뉴 터치</p>
                  <img src="/img/pwa-oneclick-bookmark/3-share.jpg" alt="3. '공유' 메뉴 터치" style="max-width:80%;" />
                </li>
                <li>
                  <p className="fs-4">4. '더보기' 터치</p>
                  <img src="/img/pwa-oneclick-bookmark/4-share.jpg" alt="4. '더보기' 터치" style="max-width:80%;" />
                </li>
                <li>
                  <p className="fs-4">5. SMP 선택</p>
                  <img src="/img/pwa-oneclick-bookmark/5-share.jpg" alt="5. SMP 선택" style="max-width:80%;" />
                </li>
                <li>
                  <p className="fs-4">6. 북마크 저장</p>
                  <strong>주소와 제목이 자동으로 채워져 있습니다.<br />
                  원하는 그룹과 카테고리 선택 후 북마크 저장을 터치합니다.<br />
                  * 로그아웃 상태에서는 로그인 후 북마크를 저장할 수 있습니다.</strong>
                  <img src="/img/pwa-oneclick-bookmark/6-save.jpg" alt="6. 북마크 저장" style="max-width:80%;" />
                </li>
                <li>
                  <p className="fs-4">7. 좀 더 간편하게</p>
                  <strong>이후 북마크 저장은 한번에 간편하게 할 수 있습니다.</strong>
                  <img src="/img/pwa-oneclick-bookmark/7-ready.jpg" alt="7. 좀 더 간편하게" style="max-width:80%;" />
                </li>
                <!--<li>
                  <p className="fs-4"><a href="/pluginGuide" rel="noopener noreferrer">7. 데스크탑 북마크 원클릭 저장(클릭)</a></p>
                  데이크탑 에서도 플러그인을 (간단) 설치하면 PWA와 비슷한 방법으로 북마크를 저장할 수 있습니다.<br />
                </li>-->`;
    }

    return (
      <ul
        className="container"
        id="contentList"
        dangerouslySetInnerHTML={{ __html: htmlContent }}
      />
    );
  };

  useEffect(() => {
    Delay(1000).then(() => {
      window.scrollTo(0, 0);
    });
  }, []);

  return (
    <section className="container-xl">
      <div className="col-lg-9" id="mainWrap">
        <div className="pb-3">
          <p>
            <strong>
              PWA를 설치하면{" "}
              <span className="text-danger">바탕화면에 바로가기</span>가
              생성되서 일반 앱처럼 편리하게 이용할 수 있습니다. 또한
              자동입력으로 편리하게 북마크를 저장할 수 있습니다.
            </strong>
          </p>
          <p>
            삼성 갤럭시 스마트폰의 크롬 브라우저를 기준으로 설명합니다. 대부분의
            기기와 브라우저에서 유사하게 설치할 수 있습니다.
          </p>
        </div>
        <Title />
        <p></p>
        <Content />
      </div>
    </section>
  );
}
