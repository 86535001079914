import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import MsgBox from "./MsgBox";

function AlertModal({ isAlertModalShow, alertMsg }) {
  const [msg, setMsg] = useState("");
  const [show, setShow] = useState(false);

  const resetStates = () => {
    setShow(false);
    setMsg("");
  };

  useState(() => {
    if (isAlertModalShow) {
      setMsg(alertMsg);
      setShow(true);
    }
  }, [isAlertModalShow, alertMsg]);

  return (
    <Modal centered show={show} animation={false} onHide={resetStates}>
      <Modal.Body>
        <MsgBox msg={msg} />
      </Modal.Body>
    </Modal>
  );
}

export default AlertModal;
