import { faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { TaskCatchError } from "../../Common";
import LoadingPop from "../../components/LoadingPop";
import MsgBox from "../../components/MsgBox";
import GroupItemManage from "../../components/groups/GroupItemManage";
import GroupModal from "../../components/groups/GroupModal";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";

export default function GroupManagePage() {
  const [msg, setMsg] = useState("");
  const axiosToken = useAxiosPrivate();
  const [isLoading, setIsLoading] = useState(false);
  const [selGroup, setSelGroup] = useState(null);
  const [selectedLi, setSelectedLi] = useState(null);
  const [modalShow, setModalShow] = useState(false);
  const dispatch = useDispatch();

  const showNewGroupModal = async (e) => {
    e.preventDefault();
    await setSelGroup(null);
    await setSelGroup("newGroup");
    await setSelectedLi(null);
    await setModalShow(true);
  };

  const handleGroupDelete = async () => {
    if (!selGroup || selGroup === "newGroup") return;
    const msg =
      "삭제 하시겠습니까?\n해당 그룹에 속하는 카테고리, 북마크도 함께 삭제됩니다.";
    if (!window.confirm(msg)) return;
    try {
      await setIsLoading(true);
      const data = {
        _id: selGroup,
      };
      const url = `/group/delete`;
      const res = await axiosToken.delete(url, { data: data });
      if (res && res.status === 200 && res.data && res.data === "ok") {
        const groups = await axiosToken.get(`/myGroups`);
        await dispatch({
          type: "auth/fetchMyGroups",
          payload: groups.data,
        });
        await setModalShow(false);
        await setSelGroup(null);
        await setSelectedLi(null);
      } else {
        setMsg("삭제에 실패했습니다.");
      }
    } catch (err) {
      TaskCatchError(err);
    } finally {
      await setIsLoading(false);
    }
  };

  return (
    <section className="container-xl">
      <div className="row d-flex justify-content-center">
        <h2 className="h2">그룹 관리</h2>
        <MsgBox msg={msg} />
        <div className="col-md-7 mt-md-0">
          <h3 className="h4 p-2 bg-gradient bg-dark bg-opacity-25 faj-center">
            그룹 &nbsp;
            <span className="align-middle" title="그룹 추가">
              <FontAwesomeIcon
                icon={faPlusCircle}
                size="lg"
                onClick={showNewGroupModal}
              />
            </span>
          </h3>
          <GroupItemManage
            selgroup={selGroup}
            selectedli={selectedLi}
            setselgroup={setSelGroup}
            setselectedli={setSelectedLi}
            isloading={isLoading}
            setIsLoading={setIsLoading}
            handlegroupdelete={handleGroupDelete}
            setmodalshow={setModalShow}
          />
        </div>
        <LoadingPop isLoading={isLoading} />
      </div>
      <GroupModal
        selgroup={selGroup}
        setselgroup={setSelGroup}
        isloading={isLoading}
        setIsLoading={setIsLoading}
        modalshow={modalShow}
        setmodalshow={setModalShow}
      />
    </section>
  );
}
