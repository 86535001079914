import React, { useCallback, useEffect, useState } from "react";
import * as Icon from "react-bootstrap-icons";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Delay, ScrollTo, TaskCatchError } from "../../Common";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import MsgBox from "../MsgBox";
import LoadingPop from "./../LoadingPop";

export default function GroupItemManage({
  selgroup,
  selectedli,
  setselgroup,
  setselectedli,
  isLoading,
  setIsLoading,
  handlegroupdelete,
  setmodalshow,
}) {
  const dispatch = useDispatch();
  const axiosToken = useAxiosPrivate();

  const myGroups = useSelector((state) => state.auth?.myGroups);

  const [msg, setMsg] = useState("");

  const resetGrouplistEvent = useCallback(async () => {
    //e.preventDefault() // 사용금지!
    document.querySelectorAll("#ul-list-group li").forEach((el) => {
      el.querySelector("input[type=radio]").checked = false;
      el.classList.remove("bg-selected-row");
    });
    await setselectedli(null);
    await setmodalshow(false);
  }, [setselectedli, setmodalshow]);

  useEffect(() => {
    if (!selgroup || selgroup === "newGroup") resetGrouplistEvent();
  }, [selgroup, resetGrouplistEvent]);

  const handleGroupRowClick = async (e) => {
    //e.preventDefault() // 사용금지!
    await resetGrouplistEvent(e);
    const targetLi = e.target.closest("li");
    targetLi.querySelector("input[type=radio]").checked = true;
    targetLi.classList.add("bg-selected-row");
    await setselectedli(e.target.closest("li"));
    await setselgroup(targetLi.dataset["id"]);
  };

  const showGroupModal = async (e) => {
    e.preventDefault();
    const targetLi = e.target.closest("li");
    await resetGrouplistEvent(e);
    targetLi.querySelector("input[type=radio]").checked = true;
    targetLi.classList.add("bg-selected-row");
    await setselectedli(e.target.closest("li"));
    await setselgroup(targetLi.dataset["id"]);
    await setmodalshow(true);
  };

  const moveTo = (e) => {
    if (!selectedli) {
      setMsg("그룹를 선택하세요.");
      return;
    }
    const ul = document.getElementById("ul-list-group");
    const direction = e.target.closest("button").getAttribute("data-direction");
    if (direction === "top") {
      ul.insertBefore(selectedli, ul.firstChild);
    } else if (direction === "up") {
      const wrapperParent = selectedli.parentNode;
      const wrapperPervious = selectedli.previousElementSibling;
      if (wrapperPervious)
        wrapperParent.insertBefore(selectedli, wrapperPervious);
    } else if (direction === "down") {
      const wrapperParent = selectedli.parentNode;
      const wrapperNext = selectedli.nextElementSibling;
      if (wrapperNext) wrapperParent.insertBefore(wrapperNext, selectedli);
    } else if (direction === "bottom") {
      ul.insertBefore(selectedli, null);
    }
  };

  const handleSortSave = async (e) => {
    e.preventDefault();
    try {
      await setIsLoading(true);
      const data = [],
        arrLi = document.querySelectorAll("#ul-list-group li");
      if (!arrLi.length) {
        setMsg("저장할 그룹이 없습니다.");
        return;
      }
      let idx = 0;
      await arrLi.forEach((li) => {
        data.push({
          _id: li.dataset["id"],
          sortNo: idx,
        });
        parseInt(idx++);
      });
      const url = `/group/edit/sort`;
      await axiosToken.put(url, data).then(() => {
        ScrollTo("#root > div > section > div > h2");
        setMsg("순서가 변경되었습니다.");
        Delay(2000).then(() => setMsg(""));
      });
      const myGroups = await axiosToken.get(`/myGroups`);
      if (myGroups?.status === 200 && myGroups.data?.length) {
        if (myGroups.data !== "error") {
          dispatch({
            type: "auth/fetchMyGroups",
            payload: myGroups?.data,
          });
        }
      }
    } catch (err) {
      ScrollTo("#root > div > section > div > h2");
      TaskCatchError(err);
    } finally {
      await setIsLoading(false);
    }
  };

  const handleGrouplinkClick = (e) => {
    e.preventDefault();
    const selectedGroupId = e.target.getAttribute("data-Id");
    window.open(`/openGroup/${selectedGroupId}/bookmarks`);
  };

  return (
    <div>
      <MsgBox msg={msg} />
      <div
        style={{
          maxHeight: "calc(100vh - 430px)",
          overflowY: "auto",
          backgroundColor: "#f0f0f0",
        }}
      >
        <ul className="list-group" id="ul-list-group">
          {myGroups?.map((item) => {
            const groupId = item._id,
              groupNo = item.groupNo,
              groupName = item.groupName,
              memo = item.memo ?? "",
              isImportantClass = item.isImportant ? "txt-important" : "",
              isLineThroughClass = item.isLineThrough ? "txt-line-through" : "";
            return (
              <li
                key={groupId}
                className={`list-group-item text-truncate
                ${groupId === selgroup ? "bg-selected-row" : ""}`}
                data-id={groupId}
                onClick={handleGroupRowClick}
              >
                <input
                  className="form-check-input me-3"
                  type="radio"
                  name="groupRadios"
                  id={`group-${groupNo}`}
                  value={item.groupId}
                />
                <Icon.PencilSquare
                  className="align-middle me-3"
                  title="그룹 수정"
                  onClick={showGroupModal}
                />
                {item.isPublic ? (
                  <img
                    src="/img/share.png"
                    width={22}
                    className="cursor-pointer me-3"
                    alt={groupName}
                    data-id={groupId}
                    data-isshare="true"
                    onClick={handleGrouplinkClick}
                  />
                ) : (
                  ""
                )}
                <Link to={`/categoryManage/?group=${groupNo}`}>
                  <span
                    className={`${isImportantClass} ${isLineThroughClass}`}
                    data-group-id={item.groupId}
                  >
                    {groupName}
                  </span>
                </Link>
                {memo && (
                  <div className="py-2">
                    <small
                      className="text-muted"
                      style={{ whiteSpace: "pre-line" }}
                    >
                      {memo}
                    </small>
                  </div>
                )}
              </li>
            );
          })}
        </ul>
      </div>
      <div className="btn-group col-12 mt-2">
        <button
          type="button"
          className="col btn btn-outline-secondary"
          onClick={moveTo}
          data-direction="top"
        >
          <Icon.ChevronDoubleUp />
        </button>
        <button
          type="button"
          className="col btn btn-outline-secondary"
          onClick={moveTo}
          data-direction="up"
        >
          <Icon.ChevronUp />
        </button>
        <button
          type="button"
          className="col btn btn-outline-secondary"
          onClick={moveTo}
          data-direction="down"
        >
          <Icon.ChevronDown />
        </button>
        <button
          type="button"
          className="col btn btn-outline-secondary"
          onClick={moveTo}
          data-direction="bottom"
        >
          <Icon.ChevronDoubleDown />
        </button>
      </div>
      <div className="btn-group col-12 mt-2">
        <button
          type="button"
          className="col btn btn-outline-danger"
          onClick={handlegroupdelete}
        >
          선택 삭제
        </button>
        <button
          type="button"
          className="col btn btn-outline-primary"
          onClick={handleSortSave}
        >
          순서 저장
        </button>
      </div>
      <LoadingPop isLoading={isLoading} />
    </div>
  );
}
