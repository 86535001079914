import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

export default function TopSearch() {
  const navigate = useNavigate();
  const [keyword, setKeyword] = useState("");
  const [isNotValidKeyworrd, setIsNotValidKeyworrd] = useState(false);

  const onChangeKeyword = (e) => {
    e.preventDefault();
    /** e.target.value 를 대문자로
    - 대소문자 구분없이 검색하는 방식인데 
      강제로 대문자로 표시하면 사용자 혼란을 줄일 수 있음을 기대 */
    const value = e.target.value.toUpperCase();
    setKeyword(value);
    setIsNotValidKeyworrd(false);
  };

  const onSearchSubmit = (e) => {
    e.preventDefault();
    if (!keyword) {
      e.target.closest("form").querySelector("#txt-search").focus();
      setIsNotValidKeyworrd(true);
      return;
    }
    navigate(`/search/${encodeURIComponent(keyword)}`);
  };

  return (
    <div
      className="collapse navbar-collapse col-md-4 col-lg-3 pt-3 pt-md-0 overflow-hidden"
      id="navbarText"
    >
      <form className="d-flex ms-auto" role="search" onSubmit={onSearchSubmit}>
        <input
          className={`form-control me-2 shadow-none 
            ${isNotValidKeyworrd ? "invalid-box-border" : ""}`}
          id="txt-search"
          type="search"
          placeholder="검색어를 입력하세요."
          aria-label="Search"
          value={keyword}
          onChange={onChangeKeyword}
          onBlur={() => setIsNotValidKeyworrd(false)}
        />
        <button className="btn btn-outline-success" type="submit">
          Search
        </button>
      </form>
    </div>
  );
}
