import {
  faArrowCircleRight,
  faArrowUpRightFromSquare,
  faBookmark,
  faCheck,
  faCompress,
  faHelmetSafety,
  faPlay,
  faSort,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import * as Icon from "react-bootstrap-icons";
import Masonry from "react-masonry-css";
import { Link } from "react-router-dom";
import axios from "../../api/axios";
import {
  Delay,
  TaskCatchError,
} from "../../Common";

export default function MainPage() {
  const MAIN_DATA_URL = "/datas/MainData.json";
  const [mainData, setMainData] = useState([]);

  useEffect(() => {
    //NoReferrerReload();

    const initHomeData = async () => {
      try {
        const homeData = await axios.get(MAIN_DATA_URL);
        await setMainData(homeData.data || []);
      } catch (err) {
        TaskCatchError(err);
      } finally {
        Delay(1000).then(() => window.scrollTo(0, 0));
      }
    };
    initHomeData();
  }, []);

  // const goTo = (link) => {
  //   if (link) {
  //     window.open(link, "_blank");
  //   }
  // };

  const myBreakpointsAndCols = {
    default: 4,
    1100: 3,
    700: 2,
    500: 1,
  };

  let items = mainData?.map(function (item) {
    let contentHtml = "",
      linkTitle = "",
      link = "",
      linkArrowIcon = "";
    let icon = "";
    switch (item.icon) {
      case "arrows":
        icon = <FontAwesomeIcon icon={faArrowUpRightFromSquare} />;
        break;
      case "check":
        icon = <FontAwesomeIcon icon={faCheck} />;
        break;
      case "super":
        icon = <FontAwesomeIcon icon={faCompress} />;
        break;
      case "safe":
        icon = <FontAwesomeIcon icon={faHelmetSafety} />;
        break;
      case "play":
        icon = <FontAwesomeIcon icon={faPlay} />;
        break;
      case "bookmark":
        icon = <FontAwesomeIcon icon={faBookmark} />;
        break;
      case "expand":
        icon = <Icon.ArrowsAngleExpand />;
        break;
      case "battery":
        icon = <Icon.BatteryFull />;
        break;
      case "sort":
        icon = <FontAwesomeIcon icon={faSort} />;
        break;
      case "cash":
        icon = <Icon.CashCoin />;
        break;
      case "question":
        icon = <Icon.QuestionCircle />;
        break;
      default:
        break;
    }
    if (item?.content?.length) {
      if (item?.content?.length > 1) {
        item?.content?.forEach((c) => {
          contentHtml += `<p>○ ${c}</p>`;
        });
      } else {
        item?.content?.forEach((c) => {
          contentHtml += `<p>${c}</p>`;
        });
      }
    }
    if (item.link) {
      linkArrowIcon = <FontAwesomeIcon icon={faArrowCircleRight} />;
      linkTitle = item.linkTitle ?? "링크";
      link = item.link;
    }
    return (
      <div key={item.id}>
        <h4
          className="h6 p-2 text-bg-secondary text-center"
          style={{ opacity: "0.8" }}
        >
          {icon}&nbsp;
          {item.name}
        </h4>
        <p className="bg-white">
          <span dangerouslySetInnerHTML={{ __html: contentHtml }}></span>
          <span
            className={linkTitle ? "d-inline-block w-100 text-end" : "d-none"}
          >
            <span>{linkArrowIcon}</span>&nbsp;
            <span>
              <em>
                <Link to={link}>{linkTitle}</Link>
              </em>
            </span>
            &nbsp;
          </span>
        </p>
      </div>
    );
  });

  return (
    <section className="container-xl py-2">
      <Masonry
        breakpointCols={myBreakpointsAndCols}
        className="my-masonry-grid"
        columnClassName="my-masonry-grid_column"
      >
        {items}
      </Masonry>
    </section>
  );
}
