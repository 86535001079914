import { useState } from "react";
import { Link } from "react-router-dom";
import CodeForm from "../../components/code/CodeForm";
import CodeSort from "../../components/code/CodeSort";
import CodeTree from "../../components/code/CodeTree";
import "./code.css";

const CodeManagePage = () => {
  const [code, setCode] = useState("");
  const [parentCode, setParentCode] = useState("");
  const [isCodeChanged, setIsCodeChanged] = useState(false);

  return (
    <div className="container">
      <ul className="nav nav-pills nav-fill text-nowrap flex-nowrap list-group list-group-horizontal overflow-auto justify-content-center border pb-2 my-2">
        <li className="mt-2">
          <Link className={`nav-link`} to="/enj">
            홈
          </Link>
        </li>
        <li className="mt-2">
          <Link className={`nav-link`} to="/enj/movie">
            영상
          </Link>
        </li>
        <li className="mt-2">
          <Link
            className={`nav-link active`}
            style={{ backgroundColor: "#dc67c2" }}
            to="/enj/code"
          >
            코드
          </Link>
        </li>
      </ul>
      <div className="row">
        <div>
          <div className="row">
            <div
              className="col py-3 border overflow-scroll text-truncate"
              style={{
                height: "75vh",
                maxHeight: "75vh",
                backgroundColor: "#ebe7e7",
                overflow: "auto",
              }}
            >
              <CodeTree
                code={code}
                setCode={setCode}
                parentCode={parentCode}
                setParentCode={setParentCode}
                isCodeChanged={isCodeChanged}
                setIsCodeChanged={setIsCodeChanged}
              />
            </div>
            <div
              className="col-lg-3 py-3 col-sm-12 border overflow-y-scroll"
              style={{
                height: "75vh",
                maxHeight: "75vh",
                backgroundColor: "rgb(219 208 218)",
                overflow: "auto",
              }}
            >
              <CodeSort
                code={code}
                isCodeChanged={isCodeChanged}
                setIsCodeChanged={setIsCodeChanged}
              />
            </div>
            <div
              className="col-lg-4 py-3 col-sm-12 border"
              style={{
                height: "75vh",
                maxHeight: "75vh",
                backgroundColor: "#e6e5e0",
                overflow: "auto",
              }}
            >
              <CodeForm
                codeParam={code}
                setCodeParam={setCode}
                parentCodeParam={parentCode}
                setParentCodeParam={setParentCode}
                isCodeChanged={isCodeChanged}
                setIsCodeChanged={setIsCodeChanged}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CodeManagePage;
