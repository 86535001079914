import React from "react";

const MsgBox = ({ msg = "" }) => {
  return (
    // 눈에 띄는 에러 메시지를 표시하기 위해 전역으로 사용하는 컴포넌트
    <div
      className={
        msg ? `container-fluid my-3 d-block` : `container-fluid my-3 d-none`
      }
    >
      <div className="row">
        <div className="col-12">
          <div className="alert alert-danger text-center" role="alert">
            <p style={{ marginTop: "1rem" }}>{msg}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MsgBox;
