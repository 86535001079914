import { useRef, useState } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { ConsoleLog } from "../../Common";
import { CommonMessage } from "../../CommonMessage";
import { axiosPrivate } from "../../api/axios";
import MsgBox from "../../components/MsgBox"

export default function FindPassPage() {
  const [errMsg, setErrMsg] = useState("");
  const [btnText, setBtnText] = useState("비밀번호 찾기");
  const [emailMsg, setEmailMsg] = useState("");
  const [loginIdMsg, setLoginIdMsg] = useState("");
  const [isValidEmail, setIsValidEmail] = useState(false);
  const [isValidLoginId, setIsValidLoginId] = useState(false);
  const loginIdRef = useRef();
  const emailRef = useRef();
  const btnRef = useRef();

  const invalidEmailStyle = () => {
    setEmailMsg("이메일 형식이 아닙니다.");
    emailRef.current.classList.add("invalid-box-border");
    setIsValidEmail(false);
  };

  const invalidLoginIdStyle = () => {
    setLoginIdMsg(CommonMessage.loginIdPlaceholder);
    loginIdRef.current.classList.add("invalid-box-border");
    setIsValidLoginId(false);
  };

  const validEmailStyle = () => {
    setEmailMsg("이메일 형식입니다.");
    emailRef.current.classList.remove("invalid-box-border");
    setIsValidEmail(true);
  };

  const validLoginIdStyle = () => {
    setLoginIdMsg("로그인 아이디 형식입니다.");
    loginIdRef.current.classList.remove("invalid-box-border");
    setIsValidLoginId(true);
  };

  // 이메일 유효성 체크
  const checkValidEmail = (e) => {
    const regex = /^([a-zA-Z0-9_-]+)@([a-zA-Z0-9_-]+)(\.[a-zA-Z0-9_-]+){1,2}$/;
    if (regex.test(e.target?.value)) {
      setIsValidEmail(true);
      validEmailStyle();
    } else {
      setIsValidEmail(false);
      invalidEmailStyle();
    }
  };

  // 로그인 아이디 유효성 체크
  const checkValidLoginId = (e) => {
    // 영문과 숫자만 허용되는 3~32 글자 정규식
    const regex = /^[a-zA-Z0-9]{3,32}$/;
    if (regex.test(e.target?.value)) {
      setIsValidLoginId(true);
      validLoginIdStyle();
    } else {
      setIsValidLoginId(false);
      invalidLoginIdStyle();
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!isValidLoginId) {
      invalidLoginIdStyle();
      loginIdRef.current.focus();
      return;
    }
    if (!isValidEmail) {
      invalidEmailStyle();
      emailRef.current.focus();
      return;
    }
    setErrMsg("진행중 입니다. 잠시만 기다려주세요.");
    setBtnText("진행중...");
    btnRef.current.disabled = true;
    const email = emailRef.current.value,
      loginId = loginIdRef.current.value;
    const url = "/sendEmailFindAccount";
    const data = { reqType: "uPw", email, loginId };
    await axiosPrivate
      .post(url, data)
      .then((res) => {
        setErrMsg(res.data?.message);
        setBtnText("비밀번호 찾기");
        btnRef.current.disabled = false;
        loginIdRef.current.value = "";
        emailRef.current.value = "";
      })
      .catch((err) => {
        ConsoleLog("err", err);
        setErrMsg(
          err?.message ||
            "이메일 발송에 실패했습니다. 잠시 후 다시 시도해주세요."
        );
        setBtnText("비밀번호 찾기");
        btnRef.current.disabled = false;
        loginIdRef.current.value = "";
        emailRef.current.value = "";
      });
  };

  return (
    <section className="container-xl">
      <div className="row d-flex justify-content-center">
        <div className="col-md-7 mt-md-0">
          <div className="col-12">
            <h4 className="text-center">비밀번호 찾기</h4>
            <MsgBox msg={errMsg} />
            <Form onSubmit={handleSubmit}>
              <Form.Group className="mb-3" controlId="loginId">
                <Form.Label>로그인 아이디</Form.Label>
                <Form.Control
                  type="text"
                  ref={loginIdRef}
                  placeholder="가입 시 등록한 로그인 아이디를 입력해주세요."
                  required
                  autoComplete="off"
                  onChange={checkValidLoginId}
                />
                <Form.Text className="text-muted">{loginIdMsg}</Form.Text>
              </Form.Group>
              <Form.Group className="mb-3" controlId="email">
                <Form.Label>이메일</Form.Label>
                <Form.Control
                  type="text"
                  ref={emailRef}
                  placeholder="가입 시 등록한 이메일을 입력해주세요."
                  required
                  autoComplete="off"
                  onChange={checkValidEmail}
                />
                <Form.Text className="text-muted">{emailMsg}</Form.Text>
              </Form.Group>
              <Button
                ref={btnRef}
                variant="outline-primary"
                className="col-12 fs-5"
                type="submit"
              >
                {btnText}
              </Button>
            </Form>
          </div>
        </div>
      </div>
    </section>
  );
}
