import { useEffect } from "react";
import { useSelector } from "react-redux";
import { CheckAuthRemove } from "../../Common";
import Google from "../../components/socials/Google";
import Kakao from "../../components/socials/Kakao";
import Naver from "../../components/socials/Naver";
import SignupForm from "../../components/user/SignupForm";
import "../../css/social.css";

export default function SignupPage() {
  const userId = useSelector((state) => state.auth?.userId);

  useEffect(() => {
    if (userId) window.location.href = "/signout";
    CheckAuthRemove();
  }, [userId]);

  return (
    <section className="container-xl">
      <div className="row d-flex justify-content-center">
        <div className="col-md-7 mt-md-0">
          <article className="text-center mb-5" id="social-links">
            <h4>소셜로 가입</h4>
            <div className="col d-flex align-items-center justify-content-center">
              <Naver />
              <span style={{ marginRight: "2rem" }}></span>
              <Kakao />
              <span style={{ marginRight: "2rem" }}></span>
              <Google />
            </div>
          </article>
          <div className="col-12 mt-5">
            <h4 className="text-center">직접 가입</h4>
            <SignupForm />
          </div>
        </div>
      </div>
    </section>
  );
}
