/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { Link } from "react-router-dom";
import GoToTopIcon from "./GoToTopIcon";

const Footer = () => {
  const curYear = new Date().getFullYear();

  const showPrivatePolicy = () => {
    document.querySelector("#btn-msg").innerHTML =
      "<strong>StartMypage 앱은 어떠한 개인정보도 수집하지 않습니다.</strong><br /><br />";
  };

  return (
    <div className="container-fluid bg-light m-0 pb-3 border-top">
      <footer className="container-xl d-flex flex-wrap justify-content-between align-items-center pt-4">
        <p className="col-md-6 mb-0 text-muted small">
          Copyright © 2017 - {curYear} StartMypage Team. All rights reserved.
        </p>
        <ul
          className="nav col-md-4 justify-content-end small"
          style={{ minWidth: "-webkit-fill-available" }}
        >
          <li className="nav-item">
            <Link
              style={{ cursor: "pointer" }}
              className="nav-link px-2 text-muted"
              to="/pwaGuide"
            >
              모바일 PWA
            </Link>
          </li>
          <li className="nav-item">
            <Link
              style={{ cursor: "pointer" }}
              className="nav-link px-2 text-muted"
              to="/pluginGuide"
            >
              데스크탑 플러그인
            </Link>
          </li>
          <li className="nav-item">
            <Link
              style={{ cursor: "pointer" }}
              className="nav-link px-2 text-muted"
              to="/browserSetting"
            >
              나만의 시작페이지
            </Link>
          </li>
          <li className="nav-item">
            <a
              style={{ cursor: "pointer" }}
              onClick={showPrivatePolicy}
              className="nav-link px-2 text-muted"
            >
              개인정보보호정책
            </a>
          </li>
          <li className="nav-item">
            <a
              href="mailto:startmpage@gmail.com"
              className="nav-link px-2 text-muted"
            >
              연락하기
            </a>
          </li>
          <li className="nav-item">
            <a
              href="http://doonee.net"
              target="_blank"
              rel="noreferrer"
              className="nav-link px-2 text-muted"
            >
              개발자
            </a>
          </li>
          <li style={{ width: "3rem" }}>&nbsp;</li>
        </ul>
        <div className="fst-italic text-muted w-100 text-center mt-3">
          <small>
            * SMP는 크롬 브라우저에서 가장 안정적으로 동작합니다.( 크롬 &gt;
            엣지 &gt; 파이어폭스 &gt; ... )
          </small>
        </div>
        <div className="col-12 text-center py-3" id="btn-msg">
          &nbsp;
        </div>
      </footer>
      <GoToTopIcon />
    </div>
  );
};

export default Footer;
