import { useState } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { TaskCatchError } from "../../Common";
import { CommonMessage } from "../../CommonMessage";
import { NotAllowedIds } from "../../NotAllowedWord";
import { axiosPrivate } from "../../api/axios";
import MsgBox from "../MsgBox";

/**
 * 회원가입
 */
export default function SignupForm() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [msg, setMsg] = useState("");

  const btnResetMode = (e) => {
    e.target
      .closest("form")
      .querySelector("button[type=submit]").readonly = false;
    e.target
      .closest("form")
      .querySelector("button[type=submit]").disabled = false;
    e.target.closest("form").querySelector("button[type=submit]").innerText =
      "회원가입";
  };

  const btnSubmitMode = (e, isEnd) => {
    if (isEnd) {
      e.target
        .closest("form")
        .querySelector("button[type=submit]").readonly = false;
      e.target
        .closest("form")
        .querySelector("button[type=submit]").disabled = false;
      e.target.closest("form").querySelector("button[type=submit]").innerText =
        "회원가입";
    } else {
      e.target
        .closest("form")
        .querySelector("button[type=submit]").readonly = true;
      e.target
        .closest("form")
        .querySelector("button[type=submit]").disabled = true;
      e.target.closest("form").querySelector("button[type=submit]").innerText =
        "회원가입 요청중...";
    }
  };

  const handleSave = async (e) => {
    e.preventDefault();
    try {
      btnSubmitMode(e);
      const loginId = document.getElementById("loginId"),
        loginPass = document.getElementById("loginPass"),
        passwordConfirm = document.getElementById("passwordConfirm"),
        email = document.getElementById("email");
      const data = {
        loginId: loginId?.value.trim(),
        loginPass: loginPass?.value.trim(),
        passwordConfirm: passwordConfirm?.value.trim(),
        email: email?.value.trim(),
      };
      if (!data.loginId) {
        setMsg(CommonMessage.loginIdRequiredAlert);
        loginId.classList.add("invalid-box-border");
        btnResetMode(e);
        loginId.focus();
        return;
      }
      loginId.classList.remove("invalid-box-border");
      if (data.loginId.length < 3 || data.loginId.length > 32) {
        setMsg(CommonMessage.loginIdPlaceholder);
        loginId.classList.add("invalid-box-border");
        btnResetMode(e);
        loginId.focus();
        return;
      }
      loginId.classList.remove("invalid-box-border");
      if (!data.loginPass) {
        setMsg(CommonMessage.passwordRequiredAlert);
        loginPass.classList.add("invalid-box-border");
        btnResetMode(e);
        loginPass.focus();
        return;
      }
      loginPass.classList.remove("invalid-box-border");
      if (data.loginPass.length < 4 || data.loginPass.length > 30) {
        setMsg(CommonMessage.passwordPlaceholder);
        loginPass.classList.add("invalid-box-border");
        btnResetMode(e);
        loginPass.focus();
        return;
      }
      loginPass.classList.remove("invalid-box-border");
      if (!data.passwordConfirm) {
        setMsg(CommonMessage.passwordConfirmRequiredAlert);
        passwordConfirm.classList.add("invalid-box-border");
        btnResetMode(e);
        passwordConfirm.focus();
        return;
      }
      passwordConfirm.classList.remove("invalid-box-border");
      if (data.loginPass !== data.passwordConfirm) {
        setMsg(CommonMessage.passwordNotMatchAlert);
        passwordConfirm.classList.add("invalid-box-border");
        btnResetMode(e);
        passwordConfirm.focus();
        return;
      }
      passwordConfirm.classList.remove("invalid-box-border");
      data.provider = "local";
      const signup = await axiosPrivate.post(`/user/signup`, data);
      if (!signup?.data?.ok) {
        setMsg(signup?.data?.message);
        btnResetMode(e);
        return;
      }
      e.target.closest("form").querySelector("button[type=submit]").innerText =
        "자동 로그인중...";
      // 바로 자동로그인
      const signin = await axiosPrivate.post("/user/signin", data);
      if (signin?.data?.ok) {
        const data = signin.data;
        const config = data.userConfig;
        dispatch({
          type: "auth/login",
          payload: {
            accessToken: data.result,
            userId: data.userId,
            userRoles: data.userRoles,
            provider: data.provider,
            appTitle: config?.appTitle,
            startGroupNo: config?.startGroupNo,
            myGroups: data.myGroups,
            isAllowMultiDevice: config?.isAllowMultiDevice,
            // target: config?.target,
            // theme: config?.theme,
            // basicSort: config?.basicSort,
          },
        });
        setMsg(
          CommonMessage.signupDone +
            "\n- " +
            CommonMessage.signoutCaution +
            "\n- 임시로 발급된 닉네임은 필요 시 수정해 주세요."
        );
        navigate("/", { replace: false });
      } else {
        // 자동로그인 실패 시 로그인 페이지로 이동
        navigate("/signin");
      }
    } catch (err) {
      setMsg("저장에 실패했습니다.");
      btnResetMode(e);
      TaskCatchError(err);
    }
  };

  /** 로그인 아이디 체크
   * - 금지 아이디 체크
   * - 영문, 숫자만 입력 가능
   * - 특수문자 불가 */
  const checkLoginId = (e) => {
    // 금지 아이디 체크
    if (NotAllowedIds.includes(e.target.value)) {
      setMsg(CommonMessage.notAllowedIdAlert);
      e.target.classList.add("invalid-box-border");
      e.target.value = "";
      e.target.focus();
      return;
    }
    // 영문, 숫자만 입력 가능
    const regex = /^[a-z|A-Z|0-9|*]+$/;
    if (!regex.test(e.target?.value)) {
      setMsg("로그인 아이디는 영문, 숫자만 입력 가능합니다.");
      e.target.classList.add("invalid-box-border");
      e.target.value = "";
      e.target.focus();
      return;
    }
    // // 글자수 체크
    // if (e.target.value.length < 3 || e.target.value.length > 32) {
    //   setMsg(CommonMessage.loginIdPlaceholder);
    //   e.target.classList.add("invalid-box-border");
    //   e.target.value = "";
    //   e.target.focus();
    //   return;
    // }
    // 성공
    setMsg("");
    e.target.classList.remove("invalid-box-border");
  };

  // 이메일 유효성 체크
  const checkValidEmail = (e) => {
    const regex = /^([a-zA-Z0-9_-]+)@([a-zA-Z0-9_-]+)(\.[a-zA-Z0-9_-]+){1,2}$/;
    if (!regex.test(e.target?.value)) {
      setMsg("이메일 형식이 올바르지 않습니다.");
      e.target.classList.add("invalid-box-border");
      e.target.value = "";
      e.target.focus();
      return;
    }
    setMsg("");
    e.target.classList.remove("invalid-box-border");
  };

  return (
    <>
      <MsgBox msg={msg} />
      <Form onSubmit={handleSave}>
        <Form.Group className="mb-3" controlId="loginId">
          <Form.Label>로그인 아이디</Form.Label>
          <Form.Control
            type="text"
            placeholder={CommonMessage.loginIdPlaceholder}
            autoComplete="off"
            required
            onChange={checkLoginId}
          />
        </Form.Group>
        <Form.Group className="mb-3" controlId="loginPass">
          <Form.Label>비밀번호</Form.Label>
          <Form.Control
            type="password"
            placeholder={CommonMessage.passwordPlaceholder}
            required
          />
          <Form.Text className="text-muted">
            {CommonMessage.passwordHashPlaceholder}
          </Form.Text>
        </Form.Group>
        <Form.Group className="mb-3" controlId="passwordConfirm">
          <Form.Label>비밀번호 확인</Form.Label>
          <Form.Control
            type="password"
            placeholder="비밀번호를 한번 더 입력하세요."
            required
          />
        </Form.Group>
        <Form.Group className="mb-3" controlId="email">
          <Form.Label>이메일</Form.Label>
          <Form.Control
            type="text"
            placeholder={CommonMessage.emailPlaceholder}
            required
            autoComplete="off"
            onBlur={checkValidEmail}
          />
        </Form.Group>
        <Button variant="outline-primary" className="col-12 fs-5" type="submit">
          회원가입
        </Button>
      </Form>
    </>
  );
}
