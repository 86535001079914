/**
 * 설정페이지 에서는 localStorage가 아닌 DB에서 data 가져와야 함!
 * db 저장에 성공할 때만 localStorage에 저장!
 *
 * 회원 개개인의 설정은 '로그인' 이나 '설정 저장' 버튼 클릭 시에 반영됨.(localStorage에 저장)
 */
import { useEffect, useMemo, useRef, useState } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ScrollTo, TaskCatchError } from "../../Common";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import MsgBox from "../MsgBox";
import LoadingPop from "./../LoadingPop";

export default function ConfigManage() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const axiosToken = useAxiosPrivate();

  const userId = useSelector((state) => state.auth?.userId);
  const groups = useSelector((state) => state.auth?.myGroups);

  const themes = useMemo(() => {
    return [
      { name: "기본", value: "basic" },
      { name: "어둡게", value: "dark" },
    ];
  }, []);
  const targets = useMemo(() => {
    return [
      { name: "새창", value: "_blank" },
      { name: "현재창", value: "_top" },
    ];
  }, []);
  // // 버전2 에서는 가나다 순서대로(A)만 사용하고
  // // 사용자가 개별적으로 그룹, 카테고리, 북마크를 정렬할 수 있도록 함.
  // const basicSorts = useMemo(() => {
  //   return [
  //     { name: "등록순", value: "R" },
  //     { name: "가나다 순서대로", value: "A" },
  //     { name: "가나다 역순서대로", value: "Z" },
  //     { name: "중요체크 우선 순서대로", value: "I" },
  //   ];
  // }, []);

  const [msg, setMsg] = useState("");
  const [isAllowMultiDevice, setIsAllowMultiDevice] = useState(null);
  const [themeValue, setThemeValue] = useState("basic");
  const [targetValue, setTargetValue] = useState("_blank");
  const [isLoading, setIsLoading] = useState(false);
  const [groupData, setGroupData] = useState([]);
  const [titleValue, setTitleValue] = useState(null);
  const [isInvalidTitle, setIsInvalidTitle] = useState(false);
  const [groupValue, setGroupValue] = useState("");
  const [configId, setConfigId] = useState(null);

  const titleRef = useRef(null);
  const groupRef = useRef(null);

  useEffect(() => {
    const getDatas = async () => {
      try {
        await setIsLoading(true);
        // 사용자 설정
        const myConfig = await axiosToken.get("/myConfig");
        if (myConfig?.status === 200 && myConfig?.data) {
          const {
            appTitle,
            startGroupNo,
            theme,
            target,
            _id,
            isAllowMultiDevice,
          } = await myConfig?.data;
          await setTitleValue(appTitle);
          setGroupValue(startGroupNo);
          setThemeValue(theme || themes[0].value);
          setTargetValue(target || targets[0].value);
          setConfigId(_id);
          setIsAllowMultiDevice(isAllowMultiDevice);
        }

        // 사용자 그룹
        if (groups?.length) {
          await setGroupData(groups);
        }
      } catch (err) {
        TaskCatchError(err);
      } finally {
        setIsLoading(false);
        window.scrollTo(0, 0);
      }
    };
    getDatas();
  }, [axiosToken, groups, themes, targets]);

  const handleSave = async (e) => {
    e.preventDefault();
    setMsg("");
    if (!titleRef?.current?.value?.trim()) {
      setIsInvalidTitle(true);
      titleRef?.current?.focus();
      return;
    }
    try {
      await setIsLoading(true);
      // basicSort는 버전2에서는 A(가나다 순서대로)만 사용하고
      // 사용자가 개별적으로 그룹, 카테고리, 북마크를 정렬할 수 있도록 함.
      // A: 가나다 순서대로, R: 등록순, Z: 가나다 역순서대로, I: 중요체크 우선 순서대로
      const data = {
        userId,
        startGroupNo: groupRef?.current?.value,
        appTitle: titleRef?.current?.value?.trim(),
        theme: themeValue, // 버전2에서는 basic(기본)만 사용.
        target: targetValue, // 버전2에서는 _blank(새창)만 사용.
        basicSort: "A", // 버전2에서는 A(가나다 순서대로)만 사용.
        isAllowMultiDevice,
      };
      const res = configId
        ? await axiosToken.put(`/config/edit`, data)
        : await axiosToken.post(`/config/add`, data);
      if (res?.data?.ok) {
        const data = res?.data;
        const result = data?.result;
        dispatch({
          type: "auth/fetchConfig",
          payload: {
            appTitle: result?.appTitle,
            isAllowMultiDevice: result?.isAllowMultiDevice,
            startGroupNo: result?.startGroupNo,
          },
        });
        ScrollTo("#root > div > section > div > h2");
        setMsg(data?.message);
        setTimeout(() => {
          setMsg("");
        }, 3000);
        navigate("/config", { replace: false });
      } else {
        ScrollTo("#root > div > section > div > h2");
        setMsg("error");
      }
    } catch (err) {
      setMsg("error");
      TaskCatchError(err);
    } finally {
      await setIsLoading(false);
    }
  };

  const GroupOptions = ({ groupData }) => {
    return Array?.isArray(groupData) && groupData?.length
      ? groupData?.map((gd) => {
          return (
            <option key={gd?._id} value={gd?.groupNo}>
              {gd?.groupName}
            </option>
          );
        })
      : null;
  };

  return (
    <div className="col-12 mt-2">
      <MsgBox msg={msg} />
      <Form onSubmit={handleSave}>
        <Form.Group className="mb-4" controlId="appTitle">
          <Form.Label className="fw-bold config-menu-title">
            사용할 앱 제목
            <br />
            <small>- 좌측 상단의 앱 제목으로 사용됩니다.</small>
          </Form.Label>
          <Form.Control
            ref={titleRef}
            type="text"
            className={isInvalidTitle ? `fs-3 invalid-box-border` : `fs-3`}
            placeholder="사용할 앱 제목을 입력하세요."
            defaultValue={titleValue}
            onChange={(e) => {
              if (e?.currentTarget?.value?.trim()) {
                setIsInvalidTitle(false);
              } else {
                setIsInvalidTitle(true);
              }
            }}
          />
        </Form.Group>
        <Form.Group className="mb-4" controlId="startGroup">
          <Form.Label className="fw-bold config-menu-title">
            시작그룹을 선택하세요.
            <br />
            <small>- 로그인 하면 바로 이동할 그룹입니다.</small>
            <br />
            <small>- 좌측 상단의 앱 제목을 클릭하면 바로 보여줍니다.</small>
          </Form.Label>
          <Form.Select
            ref={groupRef}
            // defaultValue={groupValue}
            value={groupValue}
            onChange={(e) => setGroupValue(e?.currentTarget?.value)}
          >
            <GroupOptions groupData={groupData} />
          </Form.Select>
        </Form.Group>
        <Form.Group className="mb-2" controlId="onedeviceCheck">
          <Form.Label className="fw-bold config-menu-title">
            로그인 중복 허용
            <br />
            <small>
              - 체크하면 다른 기기나 브라우저에서 로그인이 가능합니다. 동일
              계정으로 여러 기기에서 사용 시 편의성에서 유리합니다.
              <br />- 하나의 기기에서만 주로 사용하면 체크를 해지하는 것이
              보안에 유리합니다.
            </small>
          </Form.Label>
          <Form.Check
            type="switch"
            label="로그인 중복 허용"
            defaultChecked={isAllowMultiDevice || false}
            checked={isAllowMultiDevice || false}
            onChange={(e) => setIsAllowMultiDevice(e?.currentTarget?.checked)}
          />
        </Form.Group>
        {/* <Form.Group className="mb-3" controlId="theme">
          <Form.Label>테마를 선택하세요.</Form.Label>
          <ButtonGroup className="w-100">
            {themes?.map((theme, idx) => (
              <ToggleButton
                key={idx}
                type="radio"
                name="bookmarkTheme"
                variant="outline-secondary"
                value={theme?.value}
                checked={themeValue === theme?.value}
                onClick={() => setThemeValue(theme?.value)}
              >
                {theme?.name}
              </ToggleButton>
            ))}
          </ButtonGroup>
        </Form.Group> */}
        {/* <Form.Group className="mb-3" controlId="bookmarkTarget">
          <Form.Label>북마크 링크방식</Form.Label>
          <ButtonGroup className="w-100">
            {targets?.map((target, idx) => (
              <ToggleButton
                key={idx}
                type="radio"
                name="bookmarkTarget"
                variant="outline-secondary"
                value={target?.value}
                checked={targetValue === target?.value}
                onClick={() => setTargetValue(target?.value)}
              >
                {target?.name}
              </ToggleButton>
            ))}
          </ButtonGroup>
        </Form.Group> */}
        <Button
          variant="outline-primary"
          className="col-12 fs-5 mt-4"
          type="submit"
        >
          설정 저장
        </Button>
      </Form>
      <LoadingPop isLoading={isLoading} />
    </div>
  );
}
