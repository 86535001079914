import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isLoggedIn: false,
  userId: null,
  userRoles: [],
  provider: "",
  appTitle: "",
  startGroupNo: null,
  myGroups: [],
  isAllowMultiDevice: null,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    login: (state, action) => {
      localStorage.setItem("smpAt", action?.payload?.accessToken);
      state.isLoggedIn = true;
      state.userId = action?.payload?.userId;
      state.userRoles = action?.payload?.userRoles;
      state.provider = action?.payload?.provider;
      state.myGroups = action?.payload?.myGroups;
      state.appTitle = action?.payload?.appTitle;
      state.startGroupNo = action?.payload?.startGroupNo;
      state.isAllowMultiDevice = action?.payload?.isAllowMultiDevice;
      // target = config?.target;
      // theme = config?.theme;
      // basicSort = config?.basicSort;
    },
    fetchConfig: (state, action) => {
      state.appTitle = action?.payload?.appTitle;
      state.startGroupNo = action?.payload?.startGroupNo;
      state.isAllowMultiDevice = action?.payload?.isAllowMultiDevice;
    },
    fetchMyGroups: (state, action) => {
      state.myGroups = action?.payload;
    },
    logout: (state) => {
      localStorage.removeItem("smpAt");
      state.isLoggedIn = false;
      state.userId = null;
      state.userRoles = null;
      state.provider = null;
      state.myGroups = [];
      state.appTitle = null;
      state.startGroupNo = null;
      state.isAllowMultiDevice = null;
    },
  },
});

export const { login, fetchConfig, fetchMyGroups, logout } = authSlice.actions;

export default authSlice.reducer;
