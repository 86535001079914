import * as Icon from "react-bootstrap-icons";

export default function MyBookmarksByCategory({
  categoryNo,
  bookmarkList,
  showBookmarkModal,
  aTarget,
  prevSelBookmarkId,
}) {
  if (!categoryNo || !bookmarkList || !bookmarkList.length) return null;

  return bookmarkList?.map((item, idx) => {
    if (Number(item.categoryNo) === Number(categoryNo)) {
      const isLinethroughClass = item.isLineThrough ? "txt-line-through" : "";
      const isImportantClass = item.isImportant ? "txt-important" : "";
      const color = item.color ? `${item.color}` : "";
      const bookmarkClass = `align-middle ${isImportantClass} ${isLinethroughClass}`;
      let name = item.bookmarkName;
      const memo = item.memo;
      if (memo)
        name = `${name}<br /><small class="fw-lighter">${memo.replaceAll(
          "\n",
          "<br />"
        )}</small>`;
      if (item._id === prevSelBookmarkId) name = `<span>${name}</span>`;
      return (
        <p
          key={item._id + idx + categoryNo}
          style={{ lineHeight: "1.7rem" }}
          data-categoryno={item.categoryNo}
          data-bookmarkid={item._id}
        >
          <Icon.PencilSquare
            className="align-middle"
            title="북마크 수정"
            onClick={showBookmarkModal}
          />
          &nbsp;&nbsp;&nbsp;
          <a
            href={item.bookmarkUri}
            target={aTarget}
            rel="noopener noreferrer"
            className={bookmarkClass}
            style={{ color }}
            data-bookmark-no={item.bookmarkNo}
            dangerouslySetInnerHTML={{ __html: name }}
            onClick={(e) => {
              e.target.closest("p").classList.remove("mark-moccasin");
              e.target.closest("p").classList.add("mark-green");
            }}
          />
        </p>
      );
    } else {
      return null;
    }
  });
}
