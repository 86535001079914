/**
 * https://developers.google.com/identity/gsi/web/reference/html-reference
 */
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  Delay,
  IsValidValue,
  JsonLocalStorage,
  TaskCatchError,
} from "../../Common";
import { CommonMessage } from "../../CommonMessage";
import { axiosPrivate } from "../../api/axios";
import MsgBox from "../MsgBox";

export default function GoogleAuth() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [msg, setMsg] = useState("인증 확인중...");

  useEffect(() => {
    async function init() {
      // googleLogin?.user 가 없을 경우 중단
      if (!IsValidValue(localStorage.getItem("googleDecodedResponse"))) {
        setMsg("Error!");
        setMsg(
          "구글 소셜의 유저 정보가 없습니다. 구글 소셜 로그인을 다시 시도해 주세요."
        );
        navigate(-1);
        return;
      }
      try {
        const user = JsonLocalStorage.getItem("googleDecodedResponse");
        const loginId = "",
          snsId = await user?.email,
          email = await user?.email,
          provider = "google";
        const data = {
          loginId,
          provider,
          snsId,
          email,
        };
        // 회원가입
        /** snsId, provider 로 조회해서 사용중이면 signup.data.ok true를 던져준다. */
        const signup = await axiosPrivate.post(`/user/signup`, data);
        // 회원가입과 로그인에 동시에 사용되므로 메시지를 생략한다.
        // setMsg(signup?.data?.message);
        if (!signup?.data?.ok) {
          // 회원가입 실패 혹은 기타 오류
          setMsg("회원가입에 실패했습니다.");
          navigate(-1);
          return;
        } else {
          // 회원가입 성공(혹은 이미 가입된 경우)
          // 이미 가입된 경우는 409를 반환한다.
          if (signup?.data?.code === 200) {
            // 회원가입 성공
            setMsg(
              CommonMessage.signupDone +
                " 임시로 발급된 닉네임은 필요 시 수정해 주세요."
            );
          }
          await Delay(1500);
          // 자동 로그인 처리
          const data = {
            loginId,
            loginPass: "",
            isPersist: localStorage.getItem("socialPersistLogin"),
            provider,
            snsId,
          };
          const signin = await axiosPrivate.post("/user/signin", data);
          if (signin?.data?.ok) {
            setMsg(
              CommonMessage.loginDone + " " + CommonMessage.signoutCaution
            );
            // 로그인 성공
            const data = signin.data;
            const config = data.userConfig;
            dispatch({
              type: "auth/login",
              payload: {
                accessToken: data.result,
                userId: data.userId,
                userRoles: data.userRoles,
                provider: data.provider,
                appTitle: config?.appTitle,
                startGroupNo: config?.startGroupNo,
                myGroups: data.myGroups,
                // target: config?.target,
                // theme: config?.theme,
                // basicSort: config?.basicSort,
              },
            });

            // localStorage 에 nb (new bookmark) 값이 있으면 /bookmarkAdd 로 이동
            if (localStorage.getItem("nb")) {
              setTimeout(() => {
                const nb = JsonLocalStorage.getItem("nb");
                navigate(
                  `/bookmarkAdd/?url=${nb.url}&title=${nb.title}&text=${nb.text}`,
                  {
                    replace: false,
                  }
                );
              }, 1500);
            } else {
              setTimeout(() => {
                const redirectPage = config?.startGroupNo
                  ? `/myBookmarks/?group=${config?.startGroupNo}&ref=sigin`
                  : "/";
                navigate(redirectPage, { replace: false });
              }, 1500);
            }
          } else {
            // 로그인 실패
            navigate("/signin");
          }
        }
      } catch (err) {
        setMsg("Error!");
        TaskCatchError(err);
        navigate(-1);
      } finally {
        JsonLocalStorage.removeItem("googleDecodedResponse");
      }
    }
    init();
  }, [navigate, dispatch]);

  return <MsgBox msg={msg} />;
}
