/*
네이버 로그인 API (네아로) 의 모든 것 : 1부 (연동하기)
https://velog.io/@rxxdo/%EB%A6%AC%EC%95%A1%ED%8A%B8%EB%A1%9C-%EB%84%A4%EC%9D%B4%EB%B2%84-%EC%86%8C%EC%85%9C-%EB%A1%9C%EA%B7%B8%EC%9D%B8-%EC%A0%81%EC%9A%A9%ED%95%98%EA%B8%B0-1%EB%B6%80-%EB%84%A4%EC%95%84%EB%A1%9C-%EC%A0%81%EC%9A%A9%ED%95%98%EA%B8%B0
네이버 로그인 API (네아로) 의 모든 것 : 2부 (버튼 커스텀)
https://velog.io/@rxxdo/%EB%84%A4%EC%9D%B4%EB%B2%84-%EB%A1%9C%EA%B7%B8%EC%9D%B8-API-%EB%84%A4%EC%95%84%EB%A1%9C-%EC%9D%98-%EB%AA%A8%EB%93%A0-%EA%B2%83-%EB%B2%84%ED%8A%BC-%EC%BB%A4%EC%8A%A4%ED%85%80-feat.-useRef
[Javascript] Naver 로그인 버튼 커스텀하기
https://minggu92.tistory.com/37
https://developers.naver.com/docs/login/web/web.md
리액트로 네이버 아이디로 로그인 구현하기
https://2mojurmoyang.tistory.com/193
*/
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ConsoleError, Delay, GetBasicUrl, TaskCatchError } from "../../Common";
import { CommonMessage } from "../../CommonMessage";
import { axiosPrivate } from "../../api/axios";
import { useScript } from "../Hooks";
import MsgBox from "../MsgBox";

export default function Naver() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const callbackUrl = GetBasicUrl() + "/naverAuth";
  const naverLoginSdk =
    "https://static.nid.naver.com/js/naveridlogin_js_sdk_2.0.2.js";
  const naverLoginSdkStatus = useScript(naverLoginSdk);

  const { naver } = window;
  const [msg, setMsg] = useState("인증 확인중...");

  const init = useCallback(() => {
    const naverLogin = new naver.LoginWithNaverId({
      clientId: process.env.REACT_APP_NAVER_CLIENT_ID,
      callbackUrl: callbackUrl,
      isPopup: false,
      callbackHandle: true,
    });

    naverLogin.init();

    naverLogin.getLoginStatus(async function (status) {
      if (status) {
        // naverLogin?.user 가 없을 경우 중단
        if (!naverLogin?.user) {
          setMsg("Error!");
          setMsg(
            "네이버 소셜의 유저 정보가 없습니다. 네이버 소셜 로그인을 다시 시도해 주세요."
          );
          navigate(-1);
          return;
        }
        try {
          const user = naverLogin?.user;
          const loginId = "",
            snsId = await user?.email,
            email = await user?.email,
            provider = "naver";
          const data = {
            loginId,
            provider,
            snsId,
            email,
          };
          // 회원가입
          /** snsId, provider 로 조회해서 사용중이면 signup.data.ok true를 던져준다. */
          const signup = await axiosPrivate.post(`/user/signup`, data);
          // 회원가입과 로그인에 동시에 사용되므로 메시지를 생략한다.
          // setMsg(signup?.data?.message);
          if (!signup?.data?.ok) {
            // 회원가입 실패 혹은 기타 오류
            setMsg(signup?.data?.message);
            navigate(-1);
            return;
          } else {
            // 회원가입 성공
            if (signup?.data?.code === 200) {
              setMsg(
                CommonMessage.signupDone +
                  " 임시로 발급된 닉네임은 필요 시 수정해 주세요."
              );
            }
            await Delay(1500);
            // 자동 로그인 처리
            const data = {
              loginId,
              loginPass: "",
              isPersist: localStorage.getItem("socialPersistLogin"),
              provider,
              snsId,
            };
            const signin = await axiosPrivate.post("/user/signin", data);
            if (signin?.data?.ok) {
              // 로그인 성공
              setMsg(
                CommonMessage.loginDone + " " + CommonMessage.signoutCaution
              );
              const data = signin.data;
              const config = data.userConfig;
              dispatch({
                type: "auth/login",
                payload: {
                  accessToken: data.result,
                  userId: data.userId,
                  userRoles: data.userRoles,
                  provider: data.provider,
                  appTitle: config?.appTitle,
                  startGroupNo: config?.startGroupNo,
                  myGroups: data.myGroups,
                  isAllowMultiDevice: config?.isAllowMultiDevice,
                  // target: config?.target,
                  // theme: config?.theme,
                  // basicSort: config?.basicSort,
                },
              });
              // localStorage 에 nb (new bookmark) 값이 있으면 /bookmarkAdd 로 이동
              if (localStorage.getItem("nb")) {
                setTimeout(() => {
                  const nb = localStorage.getItem("nb");
                  navigate(
                    `/bookmarkAdd/?url=${nb.url}&title=${nb.title}&text=${nb.text}`,
                    {
                      replace: false,
                    }
                  );
                }, 1500);
              } else {
                setTimeout(() => {
                  const redirectPage = config?.startGroupNo
                    ? `/myBookmarks/?group=${config?.startGroupNo}&ref=sigin`
                    : "/";
                  navigate(redirectPage, { replace: false });
                }, 1500);
              }
            } else {
              // 로그인 실패
              navigate("/signin");
            }
          }
        } catch (err) {
          setMsg("Error!");
          TaskCatchError(err);
          navigate(-1);
        }
      } else {
        setMsg("네이버 api로 부터 데이터를 받아오지 못했습니다.");
        ConsoleError("Naver_getLoginStatus", status);
        navigate(-1);
      }
    });
  }, [navigate, dispatch, callbackUrl, naver]);

  useEffect(() => {
    if (naverLoginSdkStatus === "ready") {
      init();
    }
  }, [naverLoginSdkStatus, naver, callbackUrl, navigate, setMsg, init]);

  return <MsgBox msg={msg} />;
}
