import { faArrowDownAZ } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { AddComma, IsValidValue } from "../../Common";
import LoadingPopSpinner from "../../components/LoadingPopSpinner";
import CompanyFormEditModal from "../../components/enj/CompanyFormEditModal";
import EmployeeFormModal from "../../components/enj/EmployeeFormModal";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import useToast from "../../hooks/useToast";
import {
  initCompanies,
  initCompanyModalShow,
  initEmployee,
  initEmployees,
  initModalShow,
  resetCompany,
  resetEmployee,
} from "../../store/enjSlice";
import "./enj.css";

const EnjCompanyPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { addToast } = useToast();
  const apiServer = process.env.REACT_APP_API_DOAMIN;
  const axiosToken = useAxiosPrivate();

  const employees = useSelector((state) => state.enj?.employees);
  const employee = useSelector((state) => state.enj?.employee);
  const companies = useSelector((state) => state.enj?.companies);
  const company = useSelector((state) => state.enj?.company);

  // 도메인
  const domainSb = useSelector((state) => state.enjDomainCode?.domainSb);
  const domainKI = useSelector((state) => state.enjDomainCode?.domainKI);
  const domainNabiya = useSelector(
    (state) => state.enjDomainCode?.domainNabiya
  );
  const domainMatong = useSelector(
    (state) => state.enjDomainCode?.domainMatong
  );
  // const domainHam = useSelector((state) => state.enjDomainCode?.domainHam);
  // const domainHub = useSelector((state) => state.enjDomainCode?.domainHub);
  // const domainIJA = useSelector((state) => state.enjDomainCode?.domainIJA);
  // const domainPD = useSelector((state) => state.enjDomainCode?.domainPD);
  // const domainAVDB = useSelector((state) => state.enjDomainCode?.domainAVDB);
  // const domainMSAV = useSelector((state) => state.enjDomainCode?.domainMSAV);
  // const domainSVD = useSelector((state) => state.enjDomainCode?.domainSVD);

  const [isLoading, setIsLoading] = useState(false);
  const [sbMid, setSbMid] = useState("");

  /** 회사 typeCode가 변경될 때마다 sbam mid 변경 */
  useEffect(() => {
    if (company?.typeCode === "gunma") {
      setSbMid("sjoy3");
    } else if (company?.typeCode === "kiss") {
      setSbMid("sjoy0");
    }
  }, [company?.typeCode]);

  /**
   * 특정 회사의 모든 직원 목록 가져오기
   */
  useEffect(() => {
    const getEmployees = async () => {
      if (!company?._id) return;
      const res = await axiosToken.get(
        `${apiServer}/${company?._id}/enjEmployees`
      );
      dispatch(initEmployees(res.data));
    };
    getEmployees();
  }, [axiosToken, apiServer, company?._id, dispatch]);

  const handleDeleteEmployeeClick = async (e, _id) => {
    e.preventDefault();
    if (!IsValidValue(_id)) {
      addToast({
        type: "danger",
        text: "선택된 객체가 올바르지 않습니다.",
      });
      return;
    }
    if (!window.confirm("삭제하시겠습니까?")) return;
    setIsLoading(true);
    await axiosToken
      .delete(`${apiServer}/enjEmployee/delete`, {
        data: { _id },
      })
      .then((res) => {
        if (res.data.ok) {
          dispatch(initModalShow(false));
          addToast({
            type: "success",
            text: res.data.message || "employee 삭제 성공",
          });
          /** employees 에서 삭제된 아이템 없이 재할당 하기
           * dispatch(initEmployees()),
           */
          const data = employees.filter((d) => d._id !== _id);
          dispatch(initEmployees(data));
        } else {
          addToast({
            type: "danger",
            text: res.data.message || "employee 삭제 실패",
          });
        }
      })
      .catch((e) => {
        console.error(e);
        addToast({
          type: "danger",
          text: e.message || "employee 삭제 실패",
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleDeleteCompanyClick = async (e) => {
    e.preventDefault();
    if (!IsValidValue(company?._id)) {
      addToast({
        type: "danger",
        text: "선택된 객체가 올바르지 않습니다.",
      });
      return;
    }
    if (!window.confirm("삭제하시겠습니까?")) return;
    setIsLoading(true);
    await axiosToken
      .delete(`${apiServer}/enjCompany/delete`, {
        data: { _id: company?._id },
      })
      .then((res) => {
        if (res.data.ok) {
          dispatch(initCompanyModalShow(false));
          addToast({
            type: "success",
            text: res.data.message || "company 삭제 성공",
          });
          /** companies 에서 삭제된 아이템 없이 재할당 하기
           * dispatch(initCompanies()),
           * setFilteredCompanies() */
          const data = companies.filter((d) => d._id !== company?._id);
          dispatch(initCompanies(data));
          // setFilteredCompanies([...data]);
          dispatch(resetCompany());
          navigate("/enj");
        } else {
          addToast({
            type: "danger",
            text: res.data.message || "company 삭제 실패",
          });
        }
      })
      .catch((e) => {
        console.error(e);
        addToast({
          type: "danger",
          text: e.message || "company 삭제 실패",
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <>
      <CompanyFormEditModal
        handleDeleteCompanyClick={handleDeleteCompanyClick}
      />
      <ul className="nav nav-pills nav-fill text-nowrap flex-nowrap list-group list-group-horizontal overflow-auto justify-content-center border pb-2 my-2">
        <li className="mt-2">
          <Link
            className={`nav-link active`}
            style={{ backgroundColor: "#dc67c2" }}
            to="/enj"
          >
            홈
          </Link>
        </li>
        <li className="mt-2">
          <Link className={`nav-link`} to="/enj/movie">
            영상
          </Link>
        </li>
        <li className="mt-2">
          <Link className={`nav-link`} to="/enj/code">
            코드
          </Link>
        </li>
      </ul>
      {/** 회사 정보 */}
      <div className="col-12 py-3">
        <div className="d-flex justify-content-between">
          <h2>회사 정보</h2>
          <div>
            <div
              className="btn btn-outline-success me-2"
              onClick={() => {
                dispatch(initCompanyModalShow(true));
              }}
            >
              수정
            </div>
          </div>
        </div>
        {/** company 정보를 보기 좋게 나열한다. */}
        <div className="row border rounded" id="dvCompanyInfo">
          <div
            className={`col-auto fw-bold 
          ${company?.isLineThrough ? "text-decoration-line-through" : ""}`}
            style={{
              color: company?.companyColor || "",
              fontSize: company?.isImportant ? "1.5rem" : "1.2rem",
            }}
          >
            {company?.companyName}
          </div>
          <div className="col-auto">{company?.type?.codeName}</div>
          <div className="col-auto">{company?.area?.codeName}</div>
          <div className="col-auto">{company?.detailArea?.codeName}</div>
          <div className="col-auto">{company?.door?.codeName}</div>
          <div
            className={`col-auto ${company?.totalMeetCount ? "" : "d-none"} 
            ${company?.totalMeetCount > 1 ? "fw-bold" : 0}
            `}
          >
            총 방문횟수: {company?.totalMeetCount}
          </div>
          {/** 메모 */}
          <div
            className={`col-12 ${company?.memo ? "" : "d-none"}`}
            dangerouslySetInnerHTML={{
              __html: "<hr />" + company?.memo?.replaceAll("\n", "<br />"),
            }}
          />
          {/** 관련 링크 */}
          <div
            className={`${
              company?.typeCode === "kiss" ||
              company?.sbamScheduleIdNo ||
              company?.sbamReviewIdNo ||
              company?.nabiyaScheduleIdNo ||
              company?.matongId
                ? ""
                : "d-none"
            }`}
          >
            <hr className="pb-2" />
            <a
              rel="noreferrer"
              href={`${domainSb}/index.php?mid=${sbMid}&category=2827259&document_srl=${company?.sbamScheduleIdNo}`}
              target="_blank"
              className={company?.sbamScheduleIdNo ? "" : "d-none"}
            >
              <button className="btn btn-outline-primary me-2 mb-2">
                {`스밤 '${company?.companyName}' 출근부`}
              </button>
            </a>
            <a
              rel="noreferrer"
              href={`${domainSb}/index.php?mid=${sbMid}&category=${company?.sbamReviewIdNo}`}
              target="_blank"
              className={company?.sbamReviewIdNo ? "" : "d-none"}
            >
              <button className="btn btn-outline-primary me-2 mb-2">
                {`스밤 '${company?.companyName}' 리뷰`}
              </button>
            </a>
            <a
              rel="noreferrer"
              href={`${domainKI}/yc/partnership/${company?.kinfoScheduleIdNo}`}
              target="_blank"
              className={company?.kinfoScheduleIdNo ? "" : "d-none"}
            >
              <button className="btn btn-outline-primary me-2 mb-2">
                {`키인포 '${company?.companyName}' 출근부`}
              </button>
            </a>
            <a
              rel="noreferrer"
              href={`${domainKI}/yc/bbs/board.php?bo_table=store_review&shop_name=${company?.companyName}`}
              target="_blank"
              className={`${company?.typeCode === "kiss" ? "" : "d-none"}`}
            >
              <button className="btn btn-outline-primary me-2 mb-2">
                {`키인포 '${company?.companyName}' 리뷰`}
              </button>
            </a>
            <a
              rel="noreferrer"
              href={`${domainNabiya}/m/bbs/board.php?bo_table=b49&wr_id=${company?.nabiyaScheduleIdNo}`}
              target="_blank"
              className={company?.nabiyaScheduleIdNo ? "" : "d-none"}
            >
              <button className="btn btn-outline-primary me-2 mb-2">
                {`나비야넷 '${company?.companyName}' 출근부`}
              </button>
            </a>
            <a
              rel="noreferrer"
              href={`${domainMatong}/pages/main/main_shop_detail.php?num=${company?.matongId}`}
              target="_blank"
              className={company?.matongId ? "" : "d-none"}
            >
              <button className="btn btn-outline-primary me-2 mb-2">
                {`마통 '${company?.companyName}' 정보`}
              </button>
            </a>
          </div>
          {/** todayScheuled */}
          <div
            className={`row ${company?.todayScheduled ? "" : "d-none"}
          `}
          >
            <div>* 금일 출근 직원</div>
            <div>{company?.todayScheduled}</div>
          </div>
        </div>
      </div>
      {/** 직원 정보 */}
      <div className="col-12 my-2">
        <h2>직원 정보</h2>
      </div>
      <div className="col-12 mb-3">
        <EmployeeFormModal
          handleDeleteEmployeeClick={handleDeleteEmployeeClick}
        />
      </div>
      {/** 직원 리스트 */}
      <div className={`table-responsive text-nowrap`}>
        <table
          className="table table-sm table-hover align-middle text-center"
          id="employeeTable"
        >
          <thead className="table-primary">
            <tr>
              <th style={{ width: "70px" }}>
                이름&nbsp;
                <FontAwesomeIcon icon={faArrowDownAZ} />
              </th>
              <th style={{ width: "50px" }}>H</th>
              <th style={{ width: "50px" }}>W</th>
              <th style={{ width: "50px" }}>C</th>
              <th className="small" style={{ width: "70px" }}>
                Price
              </th>
              <th className="small" style={{ width: "50px" }}>
                Meet
              </th>
              <th className="small" style={{ width: "50px" }}>
                Score
              </th>
              <th style={{ width: "auto" }}>Memo</th>
              <th style={{ width: "50px" }}>수정</th>
              <th style={{ width: "50px" }}>삭제</th>
            </tr>
          </thead>
          <tbody>
            {employees?.map((ep, index) => {
              return (
                <tr
                  key={index}
                  className={`${employee?._id === ep._id ? "bg-warning" : ""} ${
                    !company?.todayScheduled
                      ? ""
                      : company?.todayScheduled
                          ?.split(/[,/❤️]+/)
                          ?.includes(ep?.employeeName) && !ep?.isLineThrough
                      ? ""
                      : "opacity-25 bg-dark"
                  }`}
                  onClick={() => dispatch(initEmployee(ep))}
                >
                  <td title={ep.employeeName}>
                    <Link
                      to={`/enj/employee/${ep._id}`}
                      className={`${ep.isImportant ? "fw-bold" : ""} ${
                        ep.isLineThrough ? "text-decoration-line-through" : ""
                      }`}
                      style={{
                        color: ep.employeeColor || "#000",
                      }}
                    >
                      {ep.employeeName}
                    </Link>
                  </td>
                  <td
                    className={`${
                      ep.height > 159 && ep.height < 164
                        ? "fw-bold"
                        : ep.height < 157 || ep.height > 166
                        ? "text-muted"
                        : ""
                    }`}
                    style={{ textAlign: "right" }}
                  >
                    {ep.height}
                  </td>
                  <td
                    className={`${
                      ep.weight > 43 && ep.weight < 47
                        ? "fw-bold"
                        : ep.weight < 44 || ep.weight > 49
                        ? "text-muted"
                        : ""
                    }`}
                    style={{ textAlign: "right" }}
                  >
                    {ep.weight}
                  </td>
                  <td
                    className={`${
                      ep.chest?.includes("B") || ep.chest?.includes("C")
                        ? "fw-bold"
                        : ep.chest === "A-" ||
                          ep.chest === "A" ||
                          ep.chest === "D+" ||
                          ep.chest === "E" ||
                          ep.chest === "E+" ||
                          ep.chest === "F" ||
                          ep.chest === "G"
                        ? "text-muted"
                        : ""
                    }`}
                  >
                    {ep.chest}
                  </td>
                  <td style={{ textAlign: "right" }}>
                    {AddComma(ep.currentPrice)}
                  </td>
                  <td className={ep.totalMeetCount > 1 ? "fw-bold" : ""}>
                    {ep.totalMeetCount}
                  </td>
                  <td
                    className={`${
                      ep.score > 80
                        ? "fw-bold"
                        : ep.score < 60
                        ? "text-muted"
                        : ""
                    }`}
                  >
                    {ep.score}
                  </td>
                  <td
                    title={ep.memo}
                    style={{
                      maxWidth: "1px",
                      textAlign: "left",
                    }}
                  >
                    <Link
                      to={`/enj/employee/${ep._id}`}
                      className={`${ep.isImportant ? "fw-bold" : ""} ${
                        ep.isLineThrough ? "text-decoration-line-through" : ""
                      }`}
                      style={{
                        color: ep.employeeColor,
                      }}
                    >
                      {ep.memo}
                    </Link>
                  </td>
                  <td>
                    <div
                      className="btn btn-outline-success btn-sm"
                      onClick={() => {
                        dispatch(resetEmployee());
                        dispatch(initEmployee(ep));
                        dispatch(initModalShow(true));
                      }}
                    >
                      수정
                    </div>
                  </td>
                  <td>
                    <div
                      className="btn btn-outline-danger btn-sm"
                      onClick={(e) => handleDeleteEmployeeClick(e, ep._id)}
                    >
                      삭제
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <LoadingPopSpinner isLoading={isLoading} />
      <div style={{ height: "100px" }}></div>
    </>
  );
};

export default EnjCompanyPage;
