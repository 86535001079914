import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  movies: [],
  companies: [],
  employees: [],
  links: [],
  company: null,
  employee: null,
  link: null,
  movie: null,
  modalShow: false,
  companyModalShow: false,
};

const enjSlice = createSlice({
  name: "enj",
  initialState,
  reducers: {
    initMovies: (state, action) => {
      state.movies = action.payload;
    },
    addMovies: (state, action) => {
      // movies 앞쪽에 추가
      state.movies = [action.payload, ...state.movies];
    },
    editMovies: (state, action) => {
      state.movies = state.movies.map((prevMovie) => {
        if (prevMovie._id === action.payload?._id) {
          return action.payload;
        } else {
          return prevMovie;
        }
      });
    },
    resetMovies: (state) => {
      state.movies = [];
    },
    /** companies */
    initCompanies: (state, action) => {
      state.companies = action.payload;
    },
    addCompanies: (state, action) => {
      // companies 앞쪽에 추가
      state.companies = [action.payload, ...state.companies];
    },
    editCompanies: (state, action) => {
      state.companies = state.companies.map((prevMovie) => {
        if (prevMovie._id === action.payload?._id) {
          return action.payload;
        } else {
          return prevMovie;
        }
      });
    },
    resetCompanies: (state) => {
      state.companies = [];
    },
    /** employees */
    initEmployees: (state, action) => {
      state.employees = action.payload;
    },
    addEmployees: (state, action) => {
      // employees 앞쪽에 추가
      state.employees = [action.payload, ...state.employees];
    },
    editEmployees: (state, action) => {
      state.employees = state.employees.map((prevEmployee) => {
        if (prevEmployee._id === action.payload?._id) {
          return action.payload;
        } else {
          return prevEmployee;
        }
      });
    },
    resetEmployees: (state) => {
      state.employees = [];
    },
    /** links */
    initLinks: (state, action) => {
      state.links = action.payload;
    },
    addLinks: (state, action) => {
      // links 앞쪽에 추가
      state.links = [action.payload, ...state.links];
    },
    editLinks: (state, action) => {
      state.links = state.links.map((prevEmployee) => {
        if (prevEmployee._id === action.payload?._id) {
          return action.payload;
        } else {
          return prevEmployee;
        }
      });
    },
    resetLinks: (state) => {
      state.links = [];
    },
    /** Company */
    initCompany: (state, action) => {
      state.company = action.payload;
    },
    resetCompany: (state) => {
      state.company = null;
    },
    /** Employee */
    initEmployee: (state, action) => {
      state.employee = action.payload;
    },
    resetEmployee: (state) => {
      state.employee = null;
    },
    /** Link */
    initLink: (state, action) => {
      state.link = action.payload;
    },
    resetLink: (state) => {
      state.link = null;
    },
    /** Movie */
    initMovie: (state, action) => {
      state.movie = action.payload;
    },
    resetMovie: (state) => {
      state.movie = null;
    },
    /** Modal */
    initModalShow: (state, action) => {
      state.modalShow = action.payload;
    },
    initCompanyModalShow: (state, action) => {
      state.companyModalShow = action.payload;
    },
  },
});

export const {
  initMovies,
  initCompanies,
  addCompanies,
  editCompanies,
  initEmployees,
  addEmployees,
  editEmployees,
  initLinks,
  addLinks,
  editLinks,
  initCompany,
  initEmployee,
  initLink,
  resetCompany,
  resetEmployee,
  resetLink,
  addMovies,
  editMovies,
  resetMovies,
  initMovie,
  resetMovie,
  initModalShow,
  initCompanyModalShow,
} = enjSlice.actions;

export default enjSlice.reducer;
