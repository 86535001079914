import React, { useEffect } from "react";

export default function BookmarkPreAddPage() {
  const queryParams = new URLSearchParams(window.location.search);
  const title = encodeURIComponent(queryParams.get("title"));
  const text = encodeURIComponent(queryParams.get("text"));
  const url = encodeURIComponent(queryParams.get("url"));

  useEffect(() => {
    if (url) {
      // localStorage 에 nb 라는 객체 이름으로 url, title 저장
      localStorage.setItem("nb", JSON.stringify({ url, title, text }));
      setTimeout(() => {
        window.location.href = `/BookmarkAdd/?url=${url}&title=${title}&text=${text}`;
      }, 300);
    } else {
      alert("잘못된 접근입니다.");
      window.location.href = "/";
    }
  }, [url, title, text]);

  // return <div className="empty-box">Loading...</div>;
  // 화면의 가로, 세로 중앙에 로딩중 표시
  return (
    <div className="empty-box-full-center">
      <div className="spinner-border" role="status">
        <span className="visually-hidden">Loading...</span>
      </div>
    </div>
  );
}
