import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { CommonMessage } from "../../CommonMessage";
import LoadingPop from "../../components/LoadingPop";
import Google from "../../components/socials/Google";
import Kakao from "../../components/socials/Kakao";
import Naver from "../../components/socials/Naver";
import SigninForm from "../../components/user/SigninForm";
import "../../css/social.css";

export default function SigninPage() {
  const userId = useSelector((state) => state.auth?.userId);

  const [isLoading, setIsLoading] = useState(false);
  const [cautionMsg, setCautionMsg] = useState("");
  const [isSocialPersist, setIsSocialPersist] = useState(false);

  const socialPersistLoginRef = useRef();

  useEffect(() => {
    if (userId) window.location.href = "/";
  }, [userId]);

  const handleSocialPersistLogin = (e) => {
    if (e.target.checked) {
      setCautionMsg(CommonMessage.signoutCaution);
      setIsSocialPersist(true);
    } else {
      setCautionMsg("");
      setIsSocialPersist(false);
    }
  };

  return (
    <>
      <section className="container-xl">
        <div className="row d-flex justify-content-center">
          <div className="col-md-7 mt-md-0">
            {/* <p className="fw-lighter fst-italic">
              * 보안정책: 새로운 기기나 브라우저에서 로그인 하면 기존에 로그인
              중인 기기나 브라우저에서 로그아웃 됩니다.
            </p> */}
            <article className="text-center mb-5" id="social-links">
              <h4>소셜로 로그인</h4>
              <div
                className="col d-flex align-items-center justify-content-center"
                style={{ fontSize: "0.9rem" }}
              >
                {/* 로그인 유지 체크박스 */}
                <input
                  type="checkbox"
                  id="keep-login"
                  ref={socialPersistLoginRef}
                  className="form-check-input my-1"
                  onChange={handleSocialPersistLogin}
                  // checked={localStorage.getItem("socialPersistLogin")}
                  checked={isSocialPersist || false}
                />
                <label htmlFor="keep-login">&nbsp;&nbsp;로그인 유지</label>
              </div>
              <div className="fs-6 text-danger pb-4">{cautionMsg}</div>
              <div className="col d-flex align-items-center justify-content-center">
                <Naver />
                <span style={{ marginRight: "2rem" }}></span>
                <Kakao />
                <span style={{ marginRight: "2rem" }}></span>
                <Google />
              </div>
            </article>
            <div className="col-12 mt-5">
              <h4 className="text-center">직접 로그인</h4>
              <SigninForm isLoading={isLoading} setIsLoading={setIsLoading} />
            </div>
          </div>
        </div>
      </section>
      <LoadingPop isLoading={isLoading} />
    </>
  );
}
