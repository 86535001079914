// 금지된 닉네임
exports.NotAllowedNicknames = [
  "admin",
  "관리자",
  "master",
  "마스터",
  "webmaster",
  "웹마스터",
  "doonee",
  "두니",
];

// 금지된 아이디
exports.NotAllowedIds = [
    "admin",
    "master",
    "webmaster",
    "doonee",
];