/**
 * https://carpet-part1.tistory.com/635
 * https://www.youtube.com/watch?v=auD1xmWNztI
 * https://www.youtube.com/watch?v=roxC8SMs7HU
 * https://youtu.be/roxC8SMs7HU
 * https://developers.google.com/identity/protocols/oauth2/javascript-implicit-flow#redirecting
 * https://cloud.google.com/identity-platform/docs/web/google?hl=ko#web-version-9_4
 * https://devmemory.tistory.com/99
 * 로그인버튼 커스텀
 * https://developers.google.com/identity/gsi/web/reference/js-reference
 **/
import jwt_decode from "jwt-decode";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ConsoleLog, JsonLocalStorage } from "../../Common";
import { useScript } from "../Hooks";

export default function Google() {
  const navigate = useNavigate();
  const loginSdk = "https://accounts.google.com/gsi/client";
  const loginSdkStatus = useScript(loginSdk);
  const { google } = window;

  const handleCredentialResponse = async (response) => {
    const userObject = await jwt_decode(response.credential);
    await JsonLocalStorage.setItem("googleDecodedResponse", userObject);
    await navigate("/googleAuth");
  };

  const onClickHandler = () => {
    ConsoleLog("Google_onClickHandler_Sign in with Google button clicked...");
  };

  useEffect(() => {
    if (loginSdkStatus === "ready") {
      google.accounts.id.initialize({
        client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
        callback: handleCredentialResponse, // 팝업 방식에 필요
        // 이하 페이지 이동 방식에 필요
        // ux_mode: "redirect",
        // redirect_uri: "/googleAuth",
        // access_type: "offline",
      });

      google.accounts.id.renderButton(document.getElementById("signInDiv"), {
        type: "icon",
        theme: "outline",
        // theme: 'filled_blue',
        size: "large",
        logo_alignment: "center",
        // width: 500, // icon type 이 아닐 때만 적용
        // text: "로그인", // 안먹히는 듯
        // logo: "/img/social/google.png", // 안먹히는 듯, 사용자 정의 로고 이미지 파일
        // img_attrs: { width: "200", height: "250" }, // 안먹히는 듯, 이미지 속성
        click_listener: onClickHandler,
      });

      // 로그인 자동 모달팝업
      // google.accounts.id.prompt();
    }
  });

  return <div id="signInDiv" className="btnGoogleLogin"></div>;
}
