import React from "react";
import { useSelector } from "react-redux";
import MyInfoForm from "../../components/user/MyInfoForm";
import "../../css/social.css";

export default function MyInfoPage() {
  const provider = useSelector((state) => state.auth?.provider);

  return (
    <section className="container-xl">
      <div className="row d-flex justify-content-center">
        <h2 className="h2">내 정보</h2>
        <div className="col-md-7 mt-md-0">
          <article
            className={provider === "local" ? `d-none` : `text-center mb-5`}
            id="social-links"
          >
            <h4
              className={
                provider === "naver" ||
                provider === "kakao" ||
                provider === "google"
                  ? ``
                  : `d-none`
              }
            >
              소셜 계정 연결정보
            </h4>
            <div className="col d-flex align-items-center justify-content-center">
              <span className={provider === "naver" ? `` : `d-none`}>
                <img
                  src="/img/social/naver.png"
                  height="40"
                  alt="Naver"
                  className="rounded"
                />
              </span>
              <span className={provider === "kakao" ? `` : `d-none`}>
                <img
                  src="/img/social/kakao.png"
                  height="40"
                  alt="Kakao"
                  className="rounded"
                />
              </span>
              <span className={provider === "google" ? `` : `d-none`}>
                <img
                  src="/img/social/google2.webp"
                  height="40"
                  alt="Google"
                  className="rounded"
                  style={{ border: "1px solid #ddd" }}
                />
              </span>
            </div>
          </article>

          <div className="col-12">
            <MyInfoForm />
          </div>
        </div>
      </div>
    </section>
  );
}
