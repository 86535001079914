import { useParams } from "react-router-dom";
import UsersManage from "../../components/admin/UsersManage";

export default function UsersManagePage() {
  const { count } = useParams();

  return (
    <section className="container-xxl">
      <UsersManage count={count} />
    </section>
  );
}
