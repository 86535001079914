import "moment/locale/ko";
import React, { useEffect, useState } from "react";
import Moment from "react-moment";
import { Delay, PassDateEasyText } from "../Common";

import useAxiosFetch from "../hooks/useAxiosFetch";

export default function DevHistoryPage() {
  const [historyData, setHistoryData] = useState([]);
  const { data, fetchError, isLoading } = useAxiosFetch(
    "/datas/DevHistoryData.json"
  );
  const fromBetaDate = GetDuration("2017-11-01"),
    fromReleaseDate = GetDuration("2017-11-19");

  useEffect(() => {
    setHistoryData(data);
    Delay(1000).then(() => {
      window.scrollTo(0, 0);
    });
  }, [data]);

  function GetDuration(dt) {
    return <Moment durationFromNow>{dt}</Moment>;
  }

  const ComtentLiItems = ({ content, date }) => {
    if (date === "2017-11-19") return fromReleaseDate;
    else if (date === "2017-11-01") return fromBetaDate;
    if (!content || !content[0]) return null;
    return content?.map((item) => (
      <li
        key={item}
        style={{ lineHeight: "200%", listStyleType: "decimal" }}
        dangerouslySetInnerHTML={{ __html: item }}
      ></li>
    ));
  };

  const ContentItems = ({ item }) => {
    const dateText = `${item.date} (${PassDateEasyText(item.date)})`;
    return (
      <div className="mb-4">
        <h4 className="fw-bold" style={{ paddingLeft: 0, paddingBottom: 0 }}>
          {item.title}
        </h4>
        <h6>{dateText}</h6>
        <ol>
          <ComtentLiItems content={item.content} date={item.date} />
        </ol>
      </div>
    );
  };

  const Contents = ({ data }) => {
    return (
      <div className="container col-lg-8">
        {isLoading && <div className="mb-5">Loading...</div>}
        {fetchError && <div className="mb-5">{fetchError}</div>}
        {!isLoading &&
          !fetchError &&
          data?.map((item) => <ContentItems item={item} key={item.date} />)}
      </div>
    );
  };

  return (
    <section className="container">
      <h2 className="h2">개발 히스토리</h2>
      <Contents data={historyData} />
    </section>
  );
}
