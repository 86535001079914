import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { IsValidValue } from "../../Common";
import LoadingPopSpinner from "../../components/LoadingPopSpinner";
import MovieLinkFormModal from "../../components/enj/MovieLinkFormModal";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import useToast from "../../hooks/useToast";
import useWindowSize from "../../hooks/useWindowSize";
import {
  initCountryCode,
  initCountryCodes,
  initDomainCode,
  initDomainCodes,
  initYdTypeCode,
  initYdTypeCodes,
  resetCountryCode,
  resetDomainCode,
  resetYdTypeCode,
} from "../../store/enjCodeSlice";
import {
  initModalShow,
  initMovie,
  initMovies,
  resetMovie,
} from "../../store/enjSlice";

const EnjMoviePage = () => {
  const { width } = useWindowSize();
  const ydTypeCodes = useSelector((state) => state.enjCode?.ydTypeCodes);
  const countryCodes = useSelector((state) => state.enjCode?.countryCodes);
  const domainCodes = useSelector((state) => state.enjCode?.domainCodes);
  const ydTypeCode = useSelector((state) => state.enjCode?.ydTypeCode);
  const countryCode = useSelector((state) => state.enjCode?.countryCode);
  const domainCode = useSelector((state) => state.enjCode?.domainCode);
  const movies = useSelector((state) => state.enj?.movies);
  const dispatch = useDispatch();
  const { addToast } = useToast();

  const [filteredMovies, setFilteredMovies] = React.useState([]);
  const [searchText, setSearchText] = React.useState("");
  const [isLoading, setIsLoading] = React.useState(false);
  const [error, setError] = React.useState("");

  const apiServer = process.env.REACT_APP_API_DOAMIN;
  const axiosHook = useAxiosPrivate();

  /** 영상 링크 검색
   * searchText가 변경될 때마다 영상 리스트를 검색해서 filteredMovies에 담는다.
   */
  React.useEffect(() => {
    const searchMovies = async () => {
      let filteredData = [...movies];
      // 대소문자 구분 안함.
      if (IsValidValue(searchText))
        filteredData = filteredData.filter((d) =>
          d.title.toLowerCase().includes(searchText.toLowerCase())
        );

      setFilteredMovies(filteredData);
    };
    searchMovies();
  }, [movies, searchText]);

  React.useEffect(() => {
    const fetchCodes = async () => {
      // 가져올 자식 코드의 부모 코드들을 배열 형식으로 전달
      const codes = "countries|siteDomain|YD-type";
      setIsLoading(true);
      await axiosHook
        .get(`${apiServer}/manage/codesChildCodes/${codes}`)
        .then((res) => {
          const data = res.data;
          // parentCode 가 YD-type 인 결과들 ydTypeCodes 에 담기
          const ydTypeCodes = data.filter((d) => d.parentCode === "YD-type");
          dispatch(initYdTypeCodes(ydTypeCodes));
          // parentCode 가 countries 인 결과들 countryCodes 에 담기
          const countryCodes = data.filter((d) => d.parentCode === "countries");
          dispatch(initCountryCodes(countryCodes));
          // parentCode 가 siteDomain 인 결과들 DomainCodes 에 담기
          const DomainCodes = data.filter((d) => d.parentCode === "siteDomain");
          dispatch(initDomainCodes(DomainCodes));
        })
        .catch((e) => {
          console.error(e);
          setError(e.message);
        })
        .finally(() => {
          setIsLoading(false);
        });
    };
    fetchCodes();
  }, [axiosHook, apiServer, dispatch]);

  /**
   * 영상리스트 가져오기
   */
  React.useEffect(() => {
    const fetchMovies = async () => {
      // if (!ydTypeCode && !countryCode && !domainCode) return;
      setIsLoading(true);
      await axiosHook
        .get(`${apiServer}/1000/enjMovies`)
        .then((res) => {
          const data = res.data;
          dispatch(initMovies(data));
          let filteredData = [...data];
          setFilteredMovies(filteredData);
        })
        .catch((e) => {
          console.error(e);
          setError(e.message);
        })
        .finally(() => {
          setIsLoading(false);
        });
    };
    fetchMovies();
  }, [axiosHook, apiServer, dispatch]);

  /**
   * 컨텐츠 타입, 국가, 도메인이 변경되면 영상 리스트를 가져온다.
   */
  React.useEffect(() => {
    const filterMovies = async () => {
      let filteredData = await [...movies];
      if (IsValidValue(ydTypeCode))
        filteredData = filteredData.filter((d) => d.type.code === ydTypeCode);
      if (IsValidValue(countryCode))
        filteredData = filteredData.filter(
          (d) => d.country.code === countryCode
        );
      if (IsValidValue(domainCode))
        filteredData = filteredData.filter((d) => d.domain.code === domainCode);

      await setFilteredMovies(filteredData);
    };
    filterMovies();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [movies, ydTypeCode, countryCode, domainCode]);

  /** movieEditClick */
  const movieEditClick = (e, movie) => {
    e.preventDefault();
    if (!IsValidValue(movie?._id)) {
      addToast({
        type: "danger",
        text: e.message || "선택된 객체가 올바르지 않습니다.",
      });
      return;
    }
    dispatch(resetMovie());
    dispatch(initMovie(movie));
    dispatch(initModalShow(true));
  };

  /** handleDeleteMovieClick */
  const handleDeleteMovieClick = async (e, _id) => {
    e.preventDefault();
    if (!IsValidValue(_id)) {
      addToast({
        type: "danger",
        text: "선택된 객체가 올바르지 않습니다.",
      });
      return;
    }
    if (!window.confirm("삭제하시겠습니까?")) return;
    setIsLoading(true);
    await axiosHook
      .delete(`${apiServer}/enjMovie/delete`, {
        data: { _id },
      })
      .then((res) => {
        if (res.data.ok) {
          dispatch(initModalShow(false));
          addToast({
            type: "success",
            text: res.data.message || "movie 삭제 성공",
          });
          /** movies 에서 삭제된 아이템 없이 재할당 하기
           * dispatch(initMovies()),
           * setFilteredMovies() */
          const data = movies.filter((d) => d._id !== _id);
          dispatch(initMovies(data));
          setFilteredMovies([...data]);
        } else {
          addToast({
            type: "danger",
            text: res.data.message || "movie 삭제 실패",
          });
        }
      })
      .catch((e) => {
        console.error(e);
        addToast({
          type: "danger",
          text: e.message || "movie 삭제 실패",
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  if (error) return <div>{error}</div>;

  return (
    <>
      <ul className="nav nav-pills nav-fill text-nowrap flex-nowrap list-group list-group-horizontal overflow-auto justify-content-center border pb-2 my-2">
        <li className="mt-2">
          <Link className={`nav-link`} to="/enj">
            홈
          </Link>
        </li>
        <li className="mt-2">
          <Link
            className={`nav-link active`}
            style={{ backgroundColor: "#dc67c2" }}
            to="/enj/movie"
          >
            영상
          </Link>
        </li>
        <li className="mt-2">
          <Link className={`nav-link`} to="/enj/code">
            코드
          </Link>
        </li>
      </ul>
      {/** 전체 조건 초기화 */}
      <div className="border-bottom py-2">
        <button
          className={`me-2 my-2 btn btn-outline-secondary w-100`}
          onClick={() => {
            dispatch(resetYdTypeCode());
            dispatch(resetCountryCode());
            dispatch(resetDomainCode());
          }}
        >
          전체 조건 초기화
        </button>
      </div>
      {/** types */}
      <div className="border-bottom py-2">
        <button
          className={`me-2 my-2 btn btn-outline-danger`}
          onClick={() => {
            dispatch(resetYdTypeCode());
          }}
        >
          전체 컨텐츠 타입
        </button>
        {ydTypeCodes?.map((tc, index) => {
          return (
            <button
              className={`me-2 my-2 btn ${
                ydTypeCode === tc.code ? "btn-success" : "btn-outline-success"
              }
              ${
                tc.useStatus === "USN"
                  ? "text-decoration-line-through"
                  : tc.useStatus === "USD"
                  ? "text-decoration-line-through fst-italic text-muted"
                  : tc.useStatus === "USP"
                  ? "text-success"
                  : ""
              }`}
              key={index}
              onClick={() => {
                dispatch(initYdTypeCode(tc.code));
              }}
            >
              {tc.codeName}
            </button>
          );
        })}
      </div>
      {/** countries */}
      <div className="border-bottom py-2">
        <button
          className={`me-2 my-2 btn btn-outline-danger`}
          onClick={() => {
            dispatch(resetCountryCode());
          }}
        >
          전체 국가
        </button>
        {countryCodes?.map((cc, index) => {
          return (
            <button
              className={`me-2 my-2 btn ${
                countryCode === cc.code ? "btn-success" : "btn-outline-success"
              }
              ${
                cc.useStatus === "USN"
                  ? "text-decoration-line-through"
                  : cc.useStatus === "USD"
                  ? "text-decoration-line-through fst-italic text-muted"
                  : cc.useStatus === "USP"
                  ? "text-success"
                  : ""
              }`}
              key={index}
              onClick={() => {
                dispatch(initCountryCode(cc.code));
              }}
            >
              {cc.codeName}
            </button>
          );
        })}
      </div>
      {/** DomainCodes */}
      <div className="border-bottom py-2">
        <button
          className={`me-2 my-2 btn btn-outline-danger`}
          onClick={() => {
            dispatch(resetDomainCode());
          }}
        >
          전체 도메인
        </button>
        {domainCodes?.map((sd, index) => {
          return (
            <button
              className={`me-2 my-2 btn ${
                domainCode === sd.code ? "btn-success" : "btn-outline-success"
              }
              ${
                sd.useStatus === "USN"
                  ? "text-decoration-line-through"
                  : sd.useStatus === "USD"
                  ? "text-decoration-line-through fst-italic text-muted"
                  : sd.useStatus === "USP"
                  ? "text-success"
                  : ""
              }`}
              key={index}
              onClick={() => {
                dispatch(initDomainCode(sd.code));
              }}
            >
              {sd.codeName}
            </button>
          );
        })}
      </div>
      <div className="col-12 py-4">
        <MovieLinkFormModal handleDeleteMovieClick={handleDeleteMovieClick} />
      </div>
      {/** 영상 리스트 */}
      {/** 검색 폼 */}
      <div className="col-12 pt-0 mt-0 mb-4">
        <form>
          <input
            className="form-control me-2"
            type="search"
            placeholder="영상 링크 제목 검색"
            aria-label="Search"
            value={searchText}
            onChange={(e) => {
              setSearchText(e.target.value);
            }}
          />
        </form>
      </div>
      <div className="table-responsive">
        <table
          className="table table-sm table-hover align-middle text-center"
          id="movieTable"
        >
          <thead>
            <tr>
              <th style={{ width: "60px" }}>idx</th>
              <th style={{ width: "70px" }}>타입</th>
              <th style={{ width: "60px" }}>국가</th>
              <th style={{ width: "70px" }}>도메인</th>
              <th style={{ minWidth: "200px", width: "auto" }}>제목</th>
              <th style={{ width: "150px" }}>메모</th>
              <th style={{ width: "60px" }}>수정</th>
              <th style={{ width: "60px" }}>삭제</th>
            </tr>
          </thead>
          <tbody>
            {filteredMovies?.map((movie, index) => {
              return (
                <tr key={index}>
                  <td
                    style={{
                      maxWidth: "1px",
                      textDecoration: "none",
                      color: "black",
                    }}
                  >
                    {/* {movie.idx} */}
                    {filteredMovies?.length - index}
                  </td>
                  <td
                    style={{ maxWidth: "1px" }}
                    onClick={() => {
                      dispatch(initYdTypeCode(movie.type?.code));
                    }}
                  >
                    {movie.type?.codeName}
                  </td>
                  <td
                    style={{ maxWidth: "1px" }}
                    onClick={() => {
                      dispatch(initCountryCode(movie.country?.code));
                    }}
                  >
                    {movie.country?.codeName}
                  </td>
                  <td
                    style={{ maxWidth: "1px", textAlign: "left" }}
                    onClick={() => {
                      dispatch(initDomainCode(movie.domain?.code));
                    }}
                  >
                    {movie.domain?.codeName}
                  </td>
                  <td
                    style={{ maxWidth: "1px", textAlign: "left" }}
                    title={movie?.title}
                  >
                    <a
                      href={`${movie.domain?.memo}${movie.uri}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {movie.title}
                    </a>
                  </td>
                  <td
                    className={width > 768 ? "" : "d-none"}
                    style={{ maxWidth: "1px" }}
                    title={movie?.memo}
                  >
                    <a
                      href={`${movie.domain?.memo}${movie.uri}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {movie.memo}
                    </a>
                  </td>
                  <td style={{ maxWidth: "1px" }}>
                    <button
                      className="btn btn-outline-success btn-sm"
                      onClick={(e) => movieEditClick(e, movie)}
                    >
                      수정
                    </button>
                  </td>
                  <td style={{ maxWidth: "1px" }}>
                    <button
                      className="btn btn-outline-danger btn-sm"
                      onClick={(e) => handleDeleteMovieClick(e, movie._id)}
                    >
                      삭제
                    </button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
        <div style={{ height: "100px" }}></div>
      </div>
      <LoadingPopSpinner isLoading={isLoading} />
    </>
  );
};

export default EnjMoviePage;
