import {
  faPencilSquare,
  faPlusCircle,
  faSquareShareNodes,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";

export default function MyBookmarksCategoryTitle({
  item = null,
  showCategoryModal = false,
  showBookmarkModal = false,
}) {
  const categoryId = item._id,
    categoryNo = item.categoryNo,
    categoryName = item.categoryName,
    isImportant = item.isImportant,
    isLineThrough = item.isLineThrough,
    isPublic = item.isPublic,
    memo = item.memo,
    groupNo = item.groupNo;
  const isImportantClass = isImportant ? "txt-important-title" : "",
    isLineThroughClass = isLineThrough ? "txt-line-through" : "",
    // bootstrap colors: primary, secondary, success, danger, warning, info, light, dark
    memoHtml = memo
      ? `<p><small><em>(${memo?.replaceAll(
          "\n",
          "<br />"
        )})</em></small></p><hr />`
      : "";

  return (
    <>
      <h6
        key={categoryId}
        className="faj-center"
        style={{ lineHeight: "1.7rem" }}
      >
        <Link
          className={`${isPublic ? "" : "d-none"} me-3`}
          to={`/openCategory/${categoryId}/bookmarks`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <FontAwesomeIcon
            icon={faSquareShareNodes}
            size="lg"
            color="#a5d9f7"
          />
        </Link>
        {/* onclick 시 부모창 고정 해야하는 과제 있음. */}
        <span
          className={`${isImportantClass} ${isLineThroughClass} me-3`}
          data-categoryid={categoryId}
          data-categoryno={categoryNo}
          dangerouslySetInnerHTML={{
            __html: `<strong>${categoryName}</strong>`,
          }}
          onClick={showCategoryModal}
        />
        <span
          className="align-middle"
          data-categoryno={categoryNo}
          data-bookmarkid={"newBookmark"}
          title="북마크 추가"
        >
          <FontAwesomeIcon
            icon={faPlusCircle}
            size="lg"
            onClick={showBookmarkModal}
          />
        </span>
        &nbsp;&nbsp;&nbsp;
        <Link to={`/bookmarkManage/?group=${groupNo}&category=${categoryNo}`}>
          <FontAwesomeIcon icon={faPencilSquare} size="lg" />
        </Link>
      </h6>
      <span
        dangerouslySetInnerHTML={{
          __html: memoHtml,
        }}
      ></span>
    </>
  );
}

// MyBookmarksCategoryTitle.defaultProps = {
//   item: null,
//   showCategoryModal: false,
//   showBookmarkModal: false,
// };
