import React, { useCallback, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
//import { axiosPrivate } from "../api/axios";
import {
  faBookmark,
  faChartPie,
  faObjectGroup,
  faShareFromSquare,
  faSquareShareNodes,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { TaskCatchError } from "../Common";
import LoadingMark from "../components/LoadingMark";
import useAxiosPrivate from "../hooks/useAxiosPrivate";

export default function SearchPage() {
  const axiosToken = useAxiosPrivate();
  const { keyword } = useParams();
  const regExp = new RegExp(keyword, "gi");
  const [length, setLength] = useState(-1);
  const [msg, setMsg] = useState("Searching...");
  const [arrGroup, setArrGroup] = useState([]);
  const [arrCategory, setArrCategory] = useState([]);
  const [arrBookmark, setArrBookmark] = useState([]);

  // |이 코드는 useCallback을 사용하여 검색 기능을 구현하는 함수입니다.
  // |
  // |좋은 점:
  // |- useCallback을 사용하여 함수를 최적화하고, 불필요한 렌더링을 방지합니다.
  // |- async/await을 사용하여 비동기 처리를 하고, axios를 통해 서버와 통신합니다.
  // |- 검색어가 없는 경우, 빈 배열을 반환하여 불필요한 통신을 방지합니다.
  // |- 검색 결과를 그룹, 카테고리, 북마크로 구분하여 저장하고, 각각의 상태를 업데이트합니다.
  // |
  // |나쁜 점:
  // |- 에러 처리가 alert으로 간단하게 되어 있어, 사용자 경험을 해칠 수 있습니다. 더 나은 방법으로 에러 처리를 구현하는 것이 좋습니다.
  const getSearch = useCallback(
    async (keyword) => {
      await setMsg("Searching...");
      await setLength(-1);

      if (!keyword) {
        return;
      }

      await setArrGroup([]);
      await setArrCategory([]);
      await setArrBookmark([]);

      await axiosToken
        .get(`/search/${encodeURIComponent(keyword)}`)
        .then((res) => {
          if (res?.status === 200 && res.data?.length) {
            const resData = res.data;
            if (resData === "error") return;
            setLength(resData.length);
            let groups = [],
              categories = [],
              bookmarks = [];
            resData.forEach((r) => {
              // 그룹, 카테고리, 북마크를 각각의 Name 으로 구분하여 저장합니다.
              if (r.groupName) {
                // group
                groups.push(r);
              } else if (r.categoryName) {
                // category
                categories.push(r);
              } else {
                // bookmark
                bookmarks.push(r);
              }
            });
            setArrGroup(groups);
            setArrCategory(categories);
            setArrBookmark(bookmarks);
          } else {
            setLength(0);
          }
        })
        .catch((err) => {
          TaskCatchError(err);
        });
    },
    [axiosToken]
  );

  useEffect(() => {
    if (!keyword) {
      setMsg("검색어를 입력하세요.");
      return;
    }
    getSearch(keyword);
  }, [keyword, getSearch]);

  return (
    <section className="container-xl py-2">
      <LoadingMark length={length} msg={msg} />
      <h4>
        <FontAwesomeIcon icon={faObjectGroup} /> 그룹
      </h4>

      <div className="search-results">
        <ul style={{ listStyle: "circle" }}>
          <li className={arrGroup?.length ? "d-none fst-italic" : "fst-italic"}>
            검색결과가 없습니다.
          </li>
          {arrGroup?.map((g) => {
            const isImportantClass = g.isImportant ? "txt-important" : "",
              isLineThroughClass = g.isLineThrough ? "txt-line-through" : "";
            const groupMemo = g.memo
              ? `<br><small className="text-muted"> - ${g.memo}</small>`
              : "";
            const nameHtml =
              g.groupName.replace(regExp, `<mark>${keyword}</mark>`) +
              groupMemo;
            const uri = `/categoryManage/?group=${g.groupNo}`;
            return (
              <li key={g._id} style={{ fontSize: "medium" }}>
                <Link
                  className={`${g.isPublic ? "" : "d-none"} me-3`}
                  to={`/openGroup/${g._id}/bookmarks`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FontAwesomeIcon
                    icon={faShareFromSquare}
                    size="lg"
                    color="rgb(74, 116, 144)"
                  />
                </Link>
                <Link className="me-2" to={uri}>
                  [그룹 관리]
                </Link>
                <span
                  className={`${isImportantClass} ${isLineThroughClass}`}
                  dangerouslySetInnerHTML={{ __html: nameHtml }}
                />
              </li>
            );
          })}
        </ul>
      </div>
      <h4>
        <FontAwesomeIcon icon={faChartPie} /> 카테고리
      </h4>
      <div className="search-results">
        <ul style={{ listStyle: "circle" }}>
          <li
            className={arrCategory?.length ? "d-none fst-italic" : "fst-italic"}
          >
            검색결과가 없습니다.
          </li>
          {arrCategory?.map((c) => {
            const isImportantClass = c.isImportant ? "txt-important" : "",
              isLineThroughClass = c.isLineThrough ? "txt-line-through" : "";
            const categoryClass = isLineThroughClass
              ? isLineThroughClass
              : isLineThroughClass + " " + isImportantClass;
            const categoryMemo = c.memo
              ? `<br><small className="text-muted"> - ${c.memo}</small>`
              : "";
            const nameHtml =
              c.categoryName?.replace(regExp, `<mark>${keyword}</mark>`) +
              categoryMemo;
            const uri = `/bookmarkManage/?group=${c.groupNo}&category=${c.categoryNo}`;
            return (
              <li key={c._id} style={{ fontSize: "medium" }}>
                <Link
                  className={`${c.isPublic ? "" : "d-none"} me-3`}
                  to={`/openCategory/${c._id}/bookmarks`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FontAwesomeIcon
                    icon={faSquareShareNodes}
                    size="lg"
                    color="rgb(74, 116, 144)"
                  />
                </Link>
                <Link className="me-2" to={uri}>
                  [카테고리 관리]
                </Link>
                <span
                  className={categoryClass}
                  dangerouslySetInnerHTML={{ __html: nameHtml }}
                />
              </li>
            );
          })}
        </ul>
      </div>
      <h4>
        <FontAwesomeIcon icon={faBookmark} /> 북마크
      </h4>
      <div className="search-results">
        <ul style={{ listStyle: "circle" }}>
          <li
            className={arrBookmark?.length ? "d-none fst-italic" : "fst-italic"}
          >
            검색결과가 없습니다.
          </li>
          {arrBookmark?.map((b) => {
            // const isImportantClass = b.isImportant
            //     ? "txt-important"
            //     : "text-primary-light",
            //   isLineThroughClass = b.isLineThrough ? "txt-line-through" : "";
            // const bookmarkClass = isLineThroughClass
            //   ? isLineThroughClass
            //   : isLineThroughClass + " " + isImportantClass;
            const bookmarkMemo = b.memo
              ? `<br><small className="text-muted"> - ${b.memo}</small>`
              : "";
            const nameHtml =
              b.bookmarkName?.replace(regExp, `<mark>${keyword}</mark>`) +
              bookmarkMemo;
            const uri = b.bookmarkUri,
              groupNo = b.groupNo,
              categoryNo = b.categoryNo;
            return (
              <li key={b._id} style={{ fontSize: "medium" }}>
                <Link
                  to={`/bookmarkManage/?group=${groupNo}&category=${categoryNo}&bookmark=${b._id}`}
                >
                  [북마크 관리]
                </Link>
                <Link className="mx-2" onClick={() => window.open(uri)}>
                  [링크]
                </Link>
                <span
                  // className={`${bookmarkClass}`}
                  dangerouslySetInnerHTML={{ __html: nameHtml }}
                />
              </li>
            );
          })}
        </ul>
      </div>
    </section>
  );
}
