/**
 * FOR TEST ONLY
 */
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";

export default function TopGroups({
  selectedGroup,
  setSelectedGroup,
  curPath,
}) {
  const navigate = useNavigate();

  const myGroups = useSelector((state) => state.auth?.myGroups);
  const [groupHtml, setGroupHtml] = useState("");

  useEffect(() => {
    if (!curPath.toUpperCase().includes("/MYBOOKMARKS")) setSelectedGroup(null);
  }, [curPath, setSelectedGroup]);

  useEffect(() => {
    const group = myGroups?.map((item) => {
      const isActive = Number(item.groupNo) === Number(selectedGroup),
        groupId = item._id,
        groupNo = Number(item.groupNo),
        groupName = item.groupName,
        isPublic = item.isPublic,
        isImportantClass = item.isImportant
          ? "text-important fw-bold"
          : "text-primary-group",
        isLineThroughClass = item.isLineThrough ? "txt-line-through" : "";

      const handleGrouplinkClick = (e) => {
        e.preventDefault();
        const selectedGroupNo = e.target.getAttribute("data-no");
        const selectedGroupId = e.target.getAttribute("data-Id");
        navigate(`/myBookmarks/?group=${selectedGroupNo}`);
        if (e.target.getAttribute("data-isshare"))
          window.open(`/openGroup/${selectedGroupId}/bookmarks`);
      };

      return (
        <li
          key={groupNo}
          className={
            isActive ? "nav-item short-title active" : "nav-item short-title"
          }
          // style={{ overflow: "initial" }}
          title={groupName}
          onMouseOver={(e) => (e.target.style.fontWeight = "bold")}
          onMouseOut={(e) => (e.target.style.fontWeight = "")}
        >
          {/* 폰트어썸 아이콘을 직접 혹은 컴포넌트 방식으로 사용하면 
        selGroup이 null로 변해서 북마크가 사라짐!
        이미지는 괜찮음.
        이미지를 컴포넌트 형식으로 분리해서 리턴받으면 폰트어썸 아이콘과 같은 현상 발생!
        폰트어썸 아이콘도 컴포넌트 이니 컴포넌트 사용방식에 문제가 있는 듯.
        <FontAwesomeIcon icon={faSquareShareNodes} size="lg" color='orange'
          data-no={groupNo} data-isshare="true"
          onClick={handleGrouplinkClick} />&nbsp;&nbsp; */}
          {isPublic ? (
            <img
              src="/img/share.png"
              width={22}
              alt={groupName}
              style={{ marginRight: "7px", cursor: "pointer" }}
              data-no={groupNo}
              data-id={groupId}
              data-isshare="true"
              onClick={handleGrouplinkClick}
            />
          ) : (
            ""
          )}
          <Link
            className={`${isImportantClass} ${isLineThroughClass}`}
            data-no={groupNo}
            onClick={handleGrouplinkClick}
          >
            {groupName}
          </Link>
        </li>
      );
    });
    setGroupHtml(group);
  }, [myGroups, selectedGroup, navigate]);

  return groupHtml;
}
