import { faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { IsValidValue, TaskCatchError } from "../../Common";
import LoadingPop from "../../components/LoadingPop";
import MsgBox from "../../components/MsgBox";
import BookmarkItemManage from "../../components/bookmarks/BookmarkItemManage";
import BookmarkModal from "../../components/bookmarks/BookmarkModal";
import CategorySel from "../../components/categories/CategorySel";
import GroupSel from "../../components/groups/GroupSel";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";

export default function BookmarkManagePage() {
  const myGroups = useSelector((state) => state.auth?.myGroups);

  const queryParams = new URLSearchParams(window.location.search);
  const group = queryParams.get("group");
  const category = queryParams.get("category");
  const bookmark = queryParams.get("bookmark");

  const [groupNo, setGroupNo] = useState(() => {
    return group;
  });
  const [categoryNo, setCategoryNo] = useState(() => {
    return category;
  });
  const [bookmarkId, setBookmarkId] = useState(() => {
    return bookmark;
  });

  const [msg, setMsg] = useState("");
  const axiosToken = useAxiosPrivate();
  const [bookmarkDelId, setBookmarkDelId] = useState(null);
  const [categoryData, setCategoryData] = useState([]);
  const [bookmarkData, setBookmarkData] = useState([]);
  const [selectedLi, setSelectedLi] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [show, setShow] = useState(false);

  // setGroupNo: 선택된 그룹
  useEffect(() => {
    if (!myGroups || !myGroups.length) return;
    setGroupNo((group && parseInt(group)) || myGroups[0].groupNo);
  }, [myGroups, group]);

  /** 그룹이 선택되면 해당 그룹의 카테고리 목록을 가져오고, 선택 카테고리를 할당한다. */
  const initCategoryData = useCallback(async () => {
    if (!myGroups || !myGroups.length || !groupNo || !parseInt(groupNo)) {
      setCategoryData([]);
      setCategoryNo(null);
      setBookmarkData([]);
      // setBookmarkId(null);
      return;
    }
    try {
      await setCategoryData([]);
      await setCategoryNo(null);
      const categories = await axiosToken.get(`/myGroup/${groupNo}/categories`);
      if (categories?.status === 200) {
        if (categories?.data === "error") return;
        if (categories?.data?.length) {
          await setCategoryData(categories?.data);
          // [주의!] setCategoryData?[0].categoryNo 로 하면 무한반복 에러 발생
          // await setCategoryNo(
          //   (category && parseInt(category)) || categories?.data[0]?.categoryNo
          // );
        }
      }
    } catch (err) {
      TaskCatchError(err);
    }
  }, [axiosToken, groupNo, myGroups]);

  /** category가 변경되면 categoryNo도 변경한다. */
  useEffect(() => {
    if (!categoryData || !categoryData.length) {
      setCategoryNo(null);
    } else {
      setCategoryNo(
        (category && parseInt(category)) || categoryData[0].categoryNo
      );
    }
  }, [category, categoryData]);

  /** 선택된 카테고리가 설정되면 해당 카테고리의 북마크 목록을 가져온다. */
  const initBookmarkDataList = useCallback(async () => {
    if (
      !categoryData ||
      !categoryData?.length ||
      !categoryNo ||
      !parseInt(categoryNo)
    ) {
      setBookmarkData([]);
      // setBookmarkId(null);
      return;
    }
    try {
      await setBookmarkData([]);
      const bookmarks = await axiosToken.get(
        `/myCategory/${categoryNo}/bookmarks`
      );
      if (bookmarks?.status === 200) {
        if (bookmarks?.data === "error") return;
        if (bookmarks?.data?.length) {
          await setBookmarkData(bookmarks.data);
        }
      }
    } catch (err) {
      TaskCatchError(err);
    }
  }, [categoryData, categoryNo, axiosToken]);

  // setCategoryData, setCategoryNo
  useEffect(() => {
    initCategoryData();
  }, [initCategoryData]);

  // setBookmarkData
  useEffect(() => {
    initBookmarkDataList();
  }, [initBookmarkDataList]);

  useEffect(() => {
    setTimeout(() => {
      document
        .querySelector(
          "#root > div > section > div.row > div.col-md.mt-4.mt-md-0 > h3"
        )
        .scrollIntoView({ behavior: "smooth", block: "center" });
    }, 1000);
  }, []);

  const handleBookmarkDelete = async (e) => {
    e.preventDefault();
    if (!IsValidValue(bookmarkDelId)) {
      setMsg("삭제할 북마크를 선택해주세요.");
      return;
    }
    if (!window.confirm("삭제 하시겠습니까?")) return;
    try {
      await setIsLoading(true);
      const url = `/bookmark/delete`;
      const data = {
        _id: bookmarkDelId,
      };
      // {data}: delete는 중괄호로 감싸야 함!
      const res = await axiosToken.delete(url, { data });
      if (res?.data === "ok") {
        setShow(false);
        initBookmarkDataList(categoryNo);
        setBookmarkDelId(null);
        setBookmarkId(null);
        setSelectedLi(null);
        setBookmarkData(null);
      } else {
        setMsg("삭제되지 않았습니다.");
      }
    } catch (err) {
      TaskCatchError(err);
    } finally {
      await setIsLoading(false);
    }
  };

  const resetBookmarklistEvent = () => {
    document.querySelectorAll("#ul-list-bookmark li").forEach((el) => {
      el.querySelector("input[type=radio]").checked = false;
      el.classList.remove("bg-selected-row");
    });
    setSelectedLi(null);
  };

  const handleBookmarkRowClick = (e) => {
    resetBookmarklistEvent();
    const li = e.target.closest("li");
    setSelectedLi(li);
    li.querySelector("input[type=radio]").checked = true;
    li.classList.add("bg-selected-row");
    // setBookmarkId() 하면 팝업 오픈하니 지정하지 않는다!??
    setBookmarkDelId(li.dataset["id"]);
    //setBookmarkId(li.dataset["id"]);
  };

  const showBookmarkModal = async (e) => {
    //await setGroupNo(queryParams.get("group"));
    if (e.target.closest("li")) {
      // 북마크 수정
      await setBookmarkId(null);
      await setBookmarkId(e.target.closest("li").dataset["id"]);
    } else {
      // 새 북마크 추가
      //await resetBookmarklistEvent();
      await setBookmarkId("newBookmark");
    }
    if (groupNo && categoryNo) {
      await setShow(true);
    } else {
      await setShow(false);
      alert(
        "로그인 연장으로 인해 그룹이 초기화 되었습니다.\n다시 선택해주세요."
      );
    }
  };

  return (
    <section className="container-xl">
      <div className="row">
        <h2 className="h2">북마크 관리</h2>
        <MsgBox msg={msg} />
        <div className="col-md-2">
          <div className="col-sm-12 col-lg-12 mb-4">
            <h3 className="h4 p-2 bg-gradient bg-dark bg-opacity-25 faj-center">
              그룹
            </h3>
            <GroupSel
              groupData={myGroups}
              groupNo={groupNo}
              setGroupNo={setGroupNo}
              setCategoryData={setCategoryData}
              setCategoryNo={setCategoryNo}
              setBookmarkData={setBookmarkData}
            />
          </div>
        </div>
        <div className="col-md-3">
          <div className="col-sm-12 col-lg-12">
            <h3 className="h4 p-2 bg-gradient bg-dark bg-opacity-25 faj-center">
              카테고리
            </h3>
            <CategorySel
              categoryData={categoryData}
              groupNo={groupNo}
              categoryNo={categoryNo}
              setCategoryNo={setCategoryNo}
            />
          </div>
        </div>
        <div className="col-md mt-4 mt-md-0">
          <h3 className="h4 p-2 bg-gradient bg-dark bg-opacity-25 faj-center">
            북마크 &nbsp;
            <span className="align-middle" title="북마크 추가">
              <FontAwesomeIcon
                icon={faPlusCircle}
                size="lg"
                onClick={showBookmarkModal}
              />
            </span>
          </h3>
          <BookmarkItemManage
            bookmarkData={bookmarkData}
            showBookmarkModal={showBookmarkModal}
            handleBookmarkRowClick={handleBookmarkRowClick}
            selectedLi={selectedLi}
            bookmarkId={bookmarkId}
            handleBookmarkDelete={handleBookmarkDelete}
            setisloading={setIsLoading}
            initBookmarkDataList={initBookmarkDataList}
            categoryNo={categoryNo}
            initBookmarkData={initBookmarkDataList}
            setBookmarkData={setBookmarkData}
          />
        </div>
        <BookmarkModal
          groupNo={groupNo}
          categoryNo={categoryNo}
          bookmarkId={bookmarkId}
          setBookmarkId={setBookmarkId}
          categoryData={categoryData}
          initBookmarkDataList={initBookmarkDataList}
          isloading={isLoading}
          setIsLoading={setIsLoading}
          setSelectedLi={setSelectedLi}
          handleBookmarkDelete={handleBookmarkDelete}
          show={show}
          setShow={setShow}
          //clientPos={clientPos}
          //setClientPos={setClientPos}
        />
      </div>
      <LoadingPop isLoading={isLoading} />
    </section>
  );
}
