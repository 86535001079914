import { useRef, useState } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { axiosPrivate } from "../../api/axios";

import MsgBox from "../../components/MsgBox";

export default function FindIdPage() {
  const [errMsg, setErrMsg] = useState("");
  const [btnText, setBtnText] = useState("아이디 찾기");
  const [msg, setMsg] = useState("");
  const [isValidEmail, setIsValidEmail] = useState(false);
  const emailRef = useRef();
  const btnRef = useRef();

  const invalidEmailStyle = () => {
    setMsg("이메일 형식이 아닙니다.");
    emailRef.current.classList.add("invalid-box-border");
    setIsValidEmail(false);
  };

  const validEmailStyle = () => {
    setMsg("이메일 형식입니다.");
    emailRef.current.classList.remove("invalid-box-border");
    setIsValidEmail(true);
  };

  // 이메일 유효성 체크
  const checkValidEmail = (e) => {
    const regex = /^([a-zA-Z0-9_-]+)@([a-zA-Z0-9_-]+)(\.[a-zA-Z0-9_-]+){1,2}$/;
    if (regex.test(e.target?.value)) {
      setIsValidEmail(true);
      validEmailStyle();
    } else {
      setIsValidEmail(false);
      invalidEmailStyle();
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!isValidEmail) {
      invalidEmailStyle();
      emailRef.current.focus();
      return;
    }
    setErrMsg("진행중 입니다. 잠시만 기다려주세요.");
    setBtnText("진행중...");
    btnRef.current.disabled = true;
    const email = emailRef.current.value;
    const url = "/sendEmailFindAccount";
    const data = { reqType: "uId", email };
    await axiosPrivate
      .post(url, data)
      .then((res) => {
        setErrMsg(res.data?.message);
        setBtnText("아이디 찾기");
        btnRef.current.disabled = false;
        emailRef.current.value = "";
      })
      .catch((err) => {
        setErrMsg(
          err?.message ||
            "이메일 발송에 실패했습니다. 잠시 후 다시 시도해주세요."
        );
        setBtnText("아이디 찾기");
        btnRef.current.disabled = false;
        emailRef.current.value = "";
      });
  };

  return (
    <section className="container-xl">
      <div className="row d-flex justify-content-center">
        <div className="col-md-7 mt-md-0">
          <div className="col-12">
            <h4 className="text-center">아이디 찾기</h4>
            <MsgBox msg={errMsg} />
            <Form onSubmit={handleSubmit}>
              <Form.Group className="mb-3" controlId="email">
                <Form.Label>이메일</Form.Label>
                <Form.Control
                  type="text"
                  ref={emailRef}
                  placeholder="가입 시 등록한 이메일을 입력해주세요."
                  required
                  autoComplete="off"
                  onChange={checkValidEmail}
                />
                <Form.Text className="text-muted">{msg}</Form.Text>
              </Form.Group>
              <Button
                ref={btnRef}
                variant="outline-primary"
                className="col-12 fs-5"
                type="submit"
              >
                {btnText}
              </Button>
            </Form>
          </div>
        </div>
      </div>
    </section>
  );
}
