import axios from "axios";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ConsoleError, Delay, GetBasicUrl, TaskCatchError } from "../../Common";
import { CommonMessage } from "../../CommonMessage";
import { axiosPrivate } from "../../api/axios";
import MsgBox from "../MsgBox";

const KakaoAuth = () => {
  const dispatch = useDispatch();
  const [msg, setMsg] = useState("인증 확인중...");
  const navigate = useNavigate();

  const getAuthToken = useCallback(async () => {
    const resultParams = new URL(document.location.toString()).searchParams;
    const code = resultParams.get("code"); // 인가코드 받는 부분
    const domain = "https://kauth.kakao.com/oauth/token";
    const grantType = "authorization_code";
    const clientId = process.env.REACT_APP_KAKAO_API_KEY;
    const redirectUrl = GetBasicUrl() + "/kakaoAuth";
    const requestUri =
      `${domain}` +
      `?grant_type=${grantType}` +
      `&client_id=${clientId}` +
      `&redirect_uri=${redirectUrl}` +
      `&code=${code}`;

    return await axios
      .post(requestUri, {
        headers: {
          "Content-type": "application/x-www-form-urlencoded;charset=utf-8",
        },
      })
      .then((res) => {
        if (res && res.status === 200 && res.data) {
          const access_token = res.data.access_token;
          return access_token;
        }
      })
      .catch((err) => {
        ConsoleError("KakaoAuth_getAuthToken", err);
        navigate(-1);
        return null;
      });
  }, [navigate]);

  const getUserInfo = useCallback(
    async (access_token) => {
      try {
        const userinfo = await axios.get(`https://kapi.kakao.com/v2/user/me`, {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded;charset=utf-8",
            Authorization: `bearer ${access_token}`,
          },
        });
        // 카카오 api 로부터 유저 정보 반환 시
        if (userinfo?.status === 200 && userinfo?.data) {
          if (userinfo?.data === "error" || !userinfo?.data?.kakao_account) {
            setMsg("카카오 소셜 유저데이터 조회에 실패했습니다.");
            setMsg("카카오 소셜 유저데이터 조회에 실패했습니다.");
            navigate(-1);
            return;
          }
          if (!userinfo?.data?.id) {
            setMsg("카카오 아이디 정보가 없습니다.");
            navigate(-1);
            return;
          }
          const user = userinfo?.data;
          const loginId = "",
            snsId = await user?.id,
            email = await user?.kakao_account?.email,
            provider = "kakao";
          const data = {
            loginId,
            provider,
            snsId,
            email,
          };
          // 회원가입
          /** snsId, provider 로 조회해서 사용중이면 signup.data.ok true를 던져준다. */
          const signup = await axiosPrivate.post(`/user/signup`, data);
          // 회원가입과 로그인에 동시에 사용되므로 메시지를 생략한다.
          // setMsg("Loading...");
          if (!signup?.data?.ok) {
            // 회원가입 실패 혹은 기타 오류
            setMsg(signup?.data?.message);
            navigate(-1);
            return;
          } else {
            // 회원가입 성공
            if (signup?.data?.code === 200) {
              // 회원가입 성공 (혹은 이미 가입된 회원)
              setMsg(
                CommonMessage.signupDone +
                  " 임시로 발급된 닉네임은 필요 시 수정해 주세요."
              );
            }
            await Delay(1500);
            // 자동 로그인 처리
            const data = {
              loginId,
              loginPass: "",
              isPersist: localStorage.getItem("socialPersistLogin"),
              provider,
              snsId,
            };
            const signin = await axiosPrivate.post("/user/signin", data);
            if (signin?.data?.ok) {
              // 로그인 성공
              setMsg(
                CommonMessage.loginDone + " " + CommonMessage.signoutCaution
              );
              const data = signin.data;
              const config = data.userConfig;
              dispatch({
                type: "auth/login",
                payload: {
                  accessToken: data.result,
                  userId: data.userId,
                  userRoles: data.userRoles,
                  provider: data.provider,
                  appTitle: config?.appTitle,
                  startGroupNo: config?.startGroupNo,
                  myGroups: data.myGroups,
                  isAllowMultiDevice: config?.isAllowMultiDevice,
                  // target: config?.target,
                  // theme: config?.theme,
                  // basicSort: config?.basicSort,
                },
              });
              // localStorage 에 nb (new bookmark) 값이 있으면 /bookmarkAdd 로 이동
              if (localStorage.getItem("nb")) {
                setTimeout(() => {
                  const nb = localStorage.getItem("nb");
                  navigate(
                    `/bookmarkAdd/?url=${nb.url}&title=${nb.title}&text=${nb.text}`,
                    {
                      replace: false,
                    }
                  );
                }, 1500);
              } else {
                setTimeout(() => {
                  const redirectPage = config?.startGroupNo
                    ? `/myBookmarks/?group=${config?.startGroupNo}&ref=sigin`
                    : "/";
                  navigate(redirectPage, { replace: false });
                }, 1500);
              }
            } else {
              // 로그인 실패
              navigate("/signin");
            }
          }
        } else {
          // 카카오 api 로부터 유저 정보 반환 실패 시
          setMsg("카카오 소셜 유저데이터가 정상적으로 반환되지 않았습니다.");
          setMsg("카카오 소셜 유저데이터가 정상적으로 반환되지 않았습니다.");
          navigate(-1);
          return;
        }
      } catch (err) {
        TaskCatchError(err);
        setMsg("Error!");
        setMsg("Error!");
        navigate(-1);
      }
    },
    [navigate, dispatch]
  );

  useEffect(() => {
    const init = async () => {
      const token = await getAuthToken();
      if (token) await getUserInfo(token);
      else {
        setMsg("카카오 계정 정보가 전달되지 않았습니다. 다시 시도해주세요.");
        navigate(-1);
        return;
      }
    };
    init();
  }, [navigate, getAuthToken, getUserInfo]);

  return <MsgBox msg={msg} />;
};

export default KakaoAuth;
