import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Delay } from "../Common";
import { axiosPrivate } from "../api/axios";
import useRefreshToken from "./useRefreshToken";

const useAxiosPrivate = () => {
  const navigate = useNavigate();
  const refresh = useRefreshToken();
  const MAX_RETRY_COUNT = 2;

  useEffect(() => {
    const requestIntercept = axiosPrivate.interceptors.request.use(
      (config) => {
        if (!config.headers["Authorization"]) {
          /** 현재 프로젝트:
           * 정상적으로 만료된 액세스 토큰(code: 419)이 있어야 리플래시 토큰을 발급해주는 구조
           * 새로고침 시 auth가 초기화 되서 토큰 자체가 사라짐
           * 해결방안: localStorage에 액세스 토큰 저장해서 사용
           * localStorage에 저장된 액세스 토큰이 없으면 로그인 페이지로 이동
           */
          const accessToken = localStorage.getItem("smpAt");
          config.headers["Authorization"] = `Bearer ${accessToken}`;
        }
        return config;
      },
      (error) => Promise.reject(error)
    );

    const responseIntercept = axiosPrivate.interceptors.response.use(
      (response) => response,
      async (error) => {
        //alert("[responseIntercept_error] ", error);
        const prevRequest = error?.config;
        if (error?.code === "ERR_NETWORK") {
          // alert("네트워크 오류", error?.message || "네트워크 오류");
          //alert("response intercept 네트워크 오류");
          //navigate("/signout", { replace: false });
          window.location.href = "/signout";
          //window.location.replace("/signout");
          // return;
        }
        // 401 = Unauthorized (인증X)
        // 403 = Forbidden (권한없음)
        // 419 = TokenExpiredError (인증만료)
        /** 현재 프로젝트:
         * 정상적으로 만료된 액세스 토큰(code: 419)이 있어야 리플래시 토큰을 발급해주는 구조
         * 새로고침 시 auth가 초기화 되서 토큰 자체가 사라짐
         * 해결방안: localStorage에 액세스 토큰 저장해서 사용
         * localStorage에 저장된 액세스 토큰이 없으면 로그인 페이지로 이동
         */
        if (error?.response?.status === 419 && !prevRequest?.sent) {
          //window.location.href = "/tokenRenew";
          //if (!prevRequest?.sent) {
          const rf = await refresh();
          if (!rf?.newAccessToken || rf?.newAccessToken === "notCreatedToken") {
            // 간헐적으로 알 수 없는 이유로 리플래시 토큰이 생성되지 않을 때
            prevRequest.retryCount = prevRequest.retryCount ?? 0;
            console.log("RETRY COUNT: ", prevRequest.retryCount);
            const shouldRetry = prevRequest.retryCount < MAX_RETRY_COUNT;
            if (shouldRetry) {
              //await Delay(1000); // 1초 후 재요청(부하를 줄이기 위함)
              prevRequest.retryCount += 1;
              // return axiosPrivate.request(prevRequest);
              // return await axiosPrivate.request(prevRequest);
              return await Delay(500).then(() => {
                axiosPrivate.request(prevRequest);
              });
            }
            // else {
            //   // 재요청 횟수 초과 시 로그아웃
            // alert("토큰 재요청 횟수 초과");
            // window.location.replace("/signout");
            window.location.href = "/signout";
            //   //navigate("/signout", { replace: false });
            //   return;
            // }
          } else {
            // 새로운 유효한 토큰이 생성되면
            const newAccessToken = rf?.newAccessToken;
            prevRequest.sent = true;
            prevRequest.headers["Authorization"] = `Bearer ${newAccessToken}`;
            // prevRequest: 새로운 토큰으로 재요청
            // prevReqest 생략할 경우 실시간 반영이 안됨!(이틀삽질)
            return axiosPrivate(prevRequest);
          }
        }
        // else {
        //   // 유효한 인증만료 토큰이 없으면 강제 로그아웃
        // alert("유효한 토큰 부재로 로그아웃 합니다.");
        window.location.href = "/signout";
        //   //navigate("/signout", { replace: false });
        //   return;
        // }
        return Promise.reject(error);
      }
    );

    return () => {
      axiosPrivate.interceptors.request.eject(requestIntercept);
      axiosPrivate.interceptors.response.eject(responseIntercept);
    };
  }, [refresh, navigate]);

  return axiosPrivate;
};

export default useAxiosPrivate;
