import { useParams } from "react-router-dom";
import UserBookmarkManage from "../../components/admin/UserBookmarkManage";

export default function UserBookmarkManagePage() {
  const { userId, count } = useParams();

  return (
    <section className="container-xxl">
      <UserBookmarkManage userId={userId} count={count} />
    </section>
  );
}
