import { faSquareShareNodes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useLocation, useNavigate } from "react-router-dom";

export default function CategorySel({ categoryData, categoryNo, groupNo }) {
  const navigate = useNavigate();
  const location = useLocation();

  const handleSelect = (e) => {
    e.preventDefault();
    const categoryNo = e.target.closest("li").getAttribute("data-value");
    const currentURL = location.pathname; // 여러 경로에서 사용될 수 있기에..
    navigate(`${currentURL}?group=${groupNo}&category=${categoryNo}`, {
      replace: false,
    });
  };

  const handleManageSelect = (e) => {
    e.preventDefault();
    navigate(`/categoryManage/?group=${groupNo}`, { replace: false });
  };

  const handleOpenCategorylinkClick = (e) => {
    e.preventDefault();
    const selectedGroupId = e.target.closest("span").getAttribute("data-Id");
    window.open(`/openCategory/${selectedGroupId}/bookmarks`);
  };

  return (
    <div style={{ maxHeight: "calc(100vh - 340px)", overflowY: "auto" }}>
      <ul className="list-group" id="ul-list-group">
        {categoryData?.map((d) => {
          const cNo = d.categoryNo,
            cId = d._id,
            categoryName = d.categoryName,
            isImportantClass = d.isImportant ? "txt-important" : "",
            isLineThroughClass = d.isLineThrough ? "txt-line-through" : "";
          return (
            <li
              key={d._id}
              data-value={cNo}
              className={`list-group-item text-truncate cursor-pointer 
                                ${
                                  Number(cNo) === Number(categoryNo)
                                    ? "bg-selected-row"
                                    : ""
                                }`}
              title={d.categoryName}
              onClick={handleSelect}
            >
              {d.isPublic ? (
                <span
                  data-id={cId}
                  className="me-2"
                  onClick={handleOpenCategorylinkClick}
                >
                  <FontAwesomeIcon
                    icon={faSquareShareNodes}
                    size="lg"
                    color="rgb(74, 116, 144)"
                  />
                </span>
              ) : (
                ""
              )}
              <span className={`${isImportantClass} ${isLineThroughClass}`}>
                {categoryName}
              </span>
            </li>
          );
        })}
        <li
          data-value=""
          className="list-group-item text-truncate cursor-pointer"
          onClick={handleManageSelect}
          title="카테고리 관리"
        >
          :: 카테고리 관리 ::
        </li>
      </ul>
    </div>
  );
}
